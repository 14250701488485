<div
  class="flex items-center w-full"
  [ngClass]="
    big
      ? 'flex-col justify-center pt-7 px-7'
      : 'p-7  flex-row justify-between max-h-[150px] h-[150px]'
  "
>
  <!-- logo -->
  <d1iv
    class="h-auto"
    [ngClass]="
      big
        ? 'w-2/3 mx-auto text-center flex items-center justify-center'
        : 'w-3/12'
    "
  >
    <img
      class="min-h-13 max-h-16"
      *ngIf="logo"
      [src]="logo"
      (click)="openSetup()"
    />
  </d1iv>
  <!-- end logo -->

  <!-- center line -->
  <div
    class="flex flex-col items-center justify-end flex-1 w-4/12 h-auto"
    *ngIf="showLine && !big"
  >
    <!-- <span class="w-full h-2 rounded-full bg-gray-snow/40"></span> -->

    <!-- counter -->
    <div class="relative w-full h-2 rounded-full bg-gray-snow/40">
      <div
        class="h-2 text-xs font-medium leading-none text-center rounded-full bg-primaryx text-primary-dark"
        style="width: {{ (countdown / countdownFull) * 100 }}%"
        *ngIf="counter"
      >
        <div
          class="absolute top-0 left-0 w-full h-2 px-2 py-2 -mt-1 rounded-full bg-primaryx opacity-10 -z-1"
          [ngClass]="countdown >= 1 ? 'visible animate-ping' : 'invisible'"
        ></div>
      </div>

      <!-- <h1
        class="absolute flex flex-col items-center justify-center w-full my-2 text-4xl text-center1"
        *ngIf="counter && countdown >= 0"
      >
        <span class="font-semibold animate-ping">{{ countdown }} </span>
        <span class="px-1 text-base">seconds</span>
      </h1> -->
    </div>

    <!-- end counter -->
  </div>
  <!-- end center line -->

  <!-- title -->
  <ng-container *ngIf="!big">
    <div
      class="flex flex-col items-end justify-end w-6/12 h-auto pt-6 text-right"
    >
      <h1 class="text-4xl font-semibold leading-[0px] text-primary-dark pb-2">
        <ng-container *ngIf="title !== ''">{{ title }}</ng-container>
      </h1>

      <h1
        class="text-2xl m-0 leading-[0px]x"
        [ngClass]="
          superSubtitle
            ? 'text-primaryx font-bold'
            : 'font-medium text-primary-dark/85'
        "
      >
        <ng-container *ngIf="subtitle !== ''">{{ subtitle }}</ng-container>
      </h1>

      <ng-container *ngIf="subtitleLeft !== ''">
        <h1
          class="text-2xl font-medium text-primary-dark/85"
          [ngClass]="
            longSubLeft ? 'absolute top-[90%] w-6/12' : 'leading-[0px]'
          "
        >
          {{ subtitleLeft }}
        </h1>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="big">
    <div
      class="flex flex-col items-center justify-center h-auto text-center"
      [ngClass]="thanks ? 'w-11/12 pt-6' : 'w-2/3 pt-14'"
    >
      <ng-container *ngIf="title !== ''">
        <h1
          class="pb-1 text-4xl font-semibold leading-[0px]x text-primary-dark"
        >
          {{ title }}
        </h1>
      </ng-container>

      <ng-container *ngIf="subtitle !== ''">
        <h1
          class="m-0 text-3xl"
          [ngClass]="
            superSubtitle
              ? 'text-primaryx font-bold'
              : 'font-medium text-primary-dark/85'
          "
        >
          {{ subtitle }}
        </h1>
      </ng-container>
      <ng-container *ngIf="subtitleThird !== ''">
        <h1
          class="m-0 text-3xl"
          [ngClass]="
            superSubtitle
              ? 'text-primaryx font-bold'
              : 'font-medium text-primary-dark/85'
          "
        >
          {{ subtitleThird }}
        </h1>
      </ng-container>
    </div>
  </ng-container>
  <!-- end title -->
</div>
