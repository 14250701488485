import {Component, HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {LangService} from './core/services/lang.service';
// import { NgwWowService } from 'ngx-wow';
import {VersionCheckService} from './version-check.service';

@Component ( { selector : 'app-root', templateUrl : './app.component.html', styleUrls : [ './app.component.scss' ] } )
export class AppComponent
{
  title = 'vending-frontend';
  theme: string;
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  // private wowService: NgwWowService,
  constructor( public translate: TranslateService,
               private langService: LangService,
               private versionCheckService: VersionCheckService )
  {
    this.setLanguage( );

    // this.wowService.init();
    this.theme = localStorage.getItem( 'theme' ) ?? 'default';

    document.addEventListener( 'contextmenu', event => event.preventDefault( ) );

    versionCheckService.initVersionCheck( );
  }

  @HostListener( 'window:resize', [ '$event' ] ) onResize( event )
  {
    console.warn( 'event', event );
    console.log( `%c Screen size changed. Now app will be reloaded`, "color: black; background: orange; font-size: 15px" );
    setTimeout ( ( ) => { location.reload( ); }, 1000 )
  }

  setLanguage( )
  {
    this.langService.setLanguage( );
    this.translate.use( this.langService.getLanguage( ) );
  }
}
