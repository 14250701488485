<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen text-white bg-primaryx box-main"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="flex flex-col items-center justify-center flex-1 w-full px-20 py-10 text-center wow fadeIn animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <h2 class="text-5xl font-bold">
        {{ "RETAILER_PICKUP.MESSAGE" | translate }}
      </h2>

      <div
        class="w-9/12 mx-auto my-16 text-5xl font-bold text-center outline-none text-primaryx-50 focus:none"
      >
        <input
          #codeInput
          type="text"
          [(ngModel)]="code"
          autocomplete="off"
          placeholder="{{ 'COMMON.INPUT_PICKUP_CODE' | translate }}"
          class="w-full px-6 py-4 text-center outline-none text-primaryx-50 rounded-xl focus:none"
          (keyup.enter)="onEnter()"
          maxlength="10"
          autofocus
        />
      </div>

      <div
        class="flex flex-row items-center self-end justify-center w-full h-auto pt-6 space-x-4 pb-14"
      >
        <app-btn-sanusx
          (btnClick)="cancel()"
          [label]="'COMMON.BTN_CANCEL' | translate"
          buttonType="neutral"
          class="wow slideInLeft animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
        </app-btn-sanusx>
        <app-btn-sanusx
          (btnClick)="pickup()"
          [label]="'COMMON.BTN_SUBMIT' | translate"
          buttonType="secondary"
          class="wow slideInRight animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
        </app-btn-sanusx>
      </div>
    </div>
  </div>

  <!-- modal -->
  <div
    class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-gray-light-80 backdrop-blur-sm z-90"
    *ngIf="error"
  >
    <div
      class="flex flex-col justify-between w-9/12 px-10 pb-10 mx-auto bg-white h-97 min-h-97 wow bounceIn animated"
      data-wow-delay="0s"
      data-wow-duration=".3s"
    >
      <div class="w-full h-auto mb-10">
        <div
          class="flex items-center justify-center w-24 h-24 mx-auto -mt-12 bg-white border-white rounded-full text-red border-10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
          >
            <path
              id="Path_13548"
              data-name="Path 13548"
              d="M102,52A50,50,0,1,1,52,2,50,50,0,0,1,102,52ZM58.25,77A6.25,6.25,0,1,1,52,70.75,6.25,6.25,0,0,1,58.25,77ZM52,20.75A6.25,6.25,0,0,0,45.75,27V52a6.25,6.25,0,0,0,12.5,0V27A6.25,6.25,0,0,0,52,20.75Z"
              transform="translate(-2 -2)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <div
        class="self-center flex-1 w-full h-auto mx-auto mt-10 text-5xl font-semibold text-center text-red"
      >
        {{ error | translate }}
      </div>

      <div
        class="flex flex-row items-start self-end justify-between w-10/12 mx-auto mt-10"
      >
        <app-btn-sanusx
          (btnClick)="errorClose()"
          [label]="'COMMON.BTN_REENTER' | translate"
          buttonType="primary"
          [fullwidth]="true"
          class="mx-auto"
        >
        </app-btn-sanusx>
      </div>
    </div>
  </div>
  <!-- END modal -->

  <div
    class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-white-80 backdrop-blur-sm z-90 wow fadeIn animated"
    data-wow-delay="0s"
    data-wow-duration="0.2s"
    *ngIf="loaderActive"
  >
    <div
      id="loader-sanusx"
      class="relative z-20 flex flex-col items-center justify-center flex-grow text-primaryx-50"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
        <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

        <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
      </svg>
      <h3 class="my-10 font-semibold tracking-wider uppercase text-gray">
        {{ "COMMON.PLEASE_WAIT" | translate }}
      </h3>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="theme === 'default'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <div class="flex flex-col justify-center first-block bg-primaryx-bg">
      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-5 wow bounceInDown animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <app-cloud
          class="flex flex-col justify-end w-full h-full"
          [ngClass]="
            fullHdHalf ? '-mb-40' : fullHd ? ' -mb-48' : ' -mb-24x -mb-36'
          "
        >
        </app-cloud>
        <div
          class="absolute flex flex-col items-center justify-center mx-auto -ml-6 bg-white rounded-full w-52 h-52 text-primaryx"
          [ngClass]="
            fullHdHalf ? 'mb-3' : fullHd ? ' ml-[-30px] -mb-14' : '-mb-20 '
          "
          style="border-radius: 9999px !important"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60%"
            height="60%"
            viewBox="0 0 135.07 136.083"
          >
            <g
              id="Group_7323"
              data-name="Group 7323"
              transform="translate(0 50)"
            >
              <path
                id="Path_13561"
                data-name="Path 13561"
                d="M60.792-16.64,29.874-3.876,35.045,8.7,65.963-4.039a6.8,6.8,0,0,0,4.219-6.287,6.776,6.776,0,0,0-.517-2.613,6.833,6.833,0,0,0-8.873-3.7"
                transform="translate(51.432 56.564)"
                fill="#4a5568"
              />
              <path
                id="Path_13562"
                data-name="Path 13562"
                d="M30.792-45.724c0-.005,0-.008,0-.014a7.036,7.036,0,0,0-.408-.778c-.082-.142-.142-.3-.229-.435a6.585,6.585,0,0,0-.591-.724c-.087-.1-.158-.215-.25-.31a6.811,6.811,0,0,0-7.376-1.521L4.262-42.387A6.805,6.805,0,0,0,.495-33.534a6.8,6.8,0,0,0,8.854,3.769l11.39-4.589L48.921,34.714a6.811,6.811,0,1,0,12.612-5.147Z"
                transform="translate(0)"
                fill="#4a5568"
              />
              <path
                id="Path_13563"
                data-name="Path 13563"
                d="M80.889.623l-31,12.735a2.3,2.3,0,0,1-3-1.252L37.264-11.339a2.3,2.3,0,0,1,1.252-3l31-12.735a2.3,2.3,0,0,1,3,1.252L82.141-2.374a2.3,2.3,0,0,1-1.252,3M96.936-2.169,82.887-36.364a10.088,10.088,0,0,0-13.17-5.5L27.97-24.713a10.089,10.089,0,0,0-5.5,13.17L36.518,22.652a10.089,10.089,0,0,0,13.17,5.5L91.436,11a10.091,10.091,0,0,0,5.5-13.17"
                transform="translate(37.374 12.697)"
                fill="#4a5568"
              />
              <path
                id="Path_13564"
                data-name="Path 13564"
                d="M37.906-15A20.412,20.412,0,1,0,58.318,5.412,20.413,20.413,0,0,0,37.906-15m0,13.608a6.8,6.8,0,1,1-6.8,6.8,6.814,6.814,0,0,1,6.8-6.8"
                transform="translate(30.118 60.258)"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col items-center justify-center w-full text-center middle-blockx wow fadeIn animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <h1 class="self-end mx-auto text-center title text-primaryx-dark">
        {{ "RETAILER_PICKUP.MESSAGE" | translate }}
      </h1>
      <div
        class="w-9/12 mx-auto text-center uppercase border-t-0 border-b-8 border-l-0 border-r-0 outline-none text-7xl text-primaryx-dark border-gray-ultra focus:none"
      >
        <input
          #codeInput
          type="text"
          [(ngModel)]="code"
          autocomplete="off"
          placeholder="{{ 'COMMON.INPUT_PICKUP_CODE' | translate }}"
          class="text-center uppercase outline-none focus:none"
          (keyup.enter)="onEnter()"
          maxlength="10"
          (ngModelChange)="onChange($event)"
          (blur)="onBlurKeyboard($event)"
          value="{{ value }}"
          spellcheck="false"
          autofocus
        />
      </div>
    </div>

    <div
      class="flex flex-col items-center justify-center third-block bg-primaryx-bg wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <div
        class="w-9/12 mx-auto mt-4 mb-6"
        [ngClass]="onScreenKeyboard ? 'flex' : 'hidden'"
      >
        <div class="simple-keyboard-pickup"></div>
      </div>
      <div class="flex flex-row items-start justify-between w-9/12 mx-auto">
        <button
          class="mr-auto btn btn-action border-gray-light group active:border-active"
          [ngClass]="fullHdHalf && 'btn-action--middle'"
          (click)="cancel()"
        >
          <span class="icon bg-gray group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49.498"
              height="49.498"
              viewBox="0 0 49.498 49.498"
            >
              <path
                id="close_icon"
                data-name="close_icon"
                d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                transform="translate(950.191 -4593.964) rotate(-45)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label text-gray group-active:text-active">
            {{ "COMMON.BTN_CANCEL" | translate }}
          </span>
        </button>
        <button
          class="ml-auto btn btn-action btn-action--success border-primaryx-success active:border-active group"
          [ngClass]="fullHdHalf && 'btn-action--middle'"
          (click)="pickup()"
        >
          <span class="icon bg-primaryx-success group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.086"
              height="21.035"
              viewBox="0 0 28.086 21.035"
            >
              <path
                id="icon_check"
                data-name="icon_check"
                d="M27.017-15.018a3.5,3.5,0,0,0,1.069-2.517,3.517,3.517,0,0,0-3.5-3.5,3.5,3.5,0,0,0-2.517,1.069L10.543-8.441,6.017-12.967A3.5,3.5,0,0,0,3.5-14.035a3.517,3.517,0,0,0-3.5,3.5A3.5,3.5,0,0,0,1.069-8.018l7,7a3.517,3.517,0,0,0,4.949,0Z"
                transform="translate(0 21.035)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </span>
          <span class="label text-primaryx-success group-active:text-active">
            {{ "COMMON.CHECK" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>

  <!-- modal -->
  <div
    class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-gray-light-80 backdrop-blur-sm z-90"
    *ngIf="error"
  >
    <div
      class="flex flex-col justify-between w-9/12 px-10 pb-10 mx-auto bg-white h-97 min-h-97 rounded-xl wow bounceIn animated"
      data-wow-delay="0s"
      data-wow-duration=".3s"
    >
      <div class="w-full h-auto mb-10">
        <div
          class="flex items-center justify-center w-24 h-24 mx-auto -mt-12 bg-white border-white rounded-full text-red border-10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
          >
            <path
              id="Path_13548"
              data-name="Path 13548"
              d="M102,52A50,50,0,1,1,52,2,50,50,0,0,1,102,52ZM58.25,77A6.25,6.25,0,1,1,52,70.75,6.25,6.25,0,0,1,58.25,77ZM52,20.75A6.25,6.25,0,0,0,45.75,27V52a6.25,6.25,0,0,0,12.5,0V27A6.25,6.25,0,0,0,52,20.75Z"
              transform="translate(-2 -2)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <div
        class="self-center flex-1 w-full h-auto mx-auto mt-10 text-5xl font-semibold text-center text-red"
      >
        {{ error | translate }}
      </div>

      <div
        class="flex flex-row items-start self-end justify-between w-10/12 mx-auto mt-10"
      >
        <button
          class="mx-auto btn btn-action btn-action--success-long border-primaryx-success active:border-active group"
          [ngClass]="fullHdHalf && 'btn-action--middle'"
          (click)="errorClose()"
        >
          <span class="icon bg-primaryx-success group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.086"
              height="21.035"
              viewBox="0 0 28.086 21.035"
            >
              <path
                id="icon_check"
                data-name="icon_check"
                d="M27.017-15.018a3.5,3.5,0,0,0,1.069-2.517,3.517,3.517,0,0,0-3.5-3.5,3.5,3.5,0,0,0-2.517,1.069L10.543-8.441,6.017-12.967A3.5,3.5,0,0,0,3.5-14.035a3.517,3.517,0,0,0-3.5,3.5A3.5,3.5,0,0,0,1.069-8.018l7,7a3.517,3.517,0,0,0,4.949,0Z"
                transform="translate(0 21.035)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </span>
          <span class="label text-primaryx-success group-active:text-active">
            {{ "COMMON.BTN_REENTER" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
  <!-- END modal -->

  <div
    class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-white-80 backdrop-blur-sm z-90 wow fadeIn animated"
    data-wow-delay="0s"
    data-wow-duration="0.2s"
    *ngIf="loaderActive"
  >
    <div
      id="loader"
      class="relative z-20 flex flex-col items-center justify-center flex-grow text-primaryx-50"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
        <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

        <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
      </svg>
      <h3 class="my-10 font-semibold tracking-wider uppercase text-gray">
        {{ "COMMON.PLEASE_WAIT" | translate }}
      </h3>
    </div>
  </div>
</ng-container>
