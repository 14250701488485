<ng-container *ngIf="theme === 'pickup_landscape'">
  <div
    class="w-full h-screen flex flex-col items-start justify-between bg-primaryx text-white"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="w-full flex flex-col items-center justify-center text-center px-27 relative"
    >
      <div
        class="flex w-[120] h-[120] rounded-full mx-auto flex-col items-center justify-center wow pulse animated mt-4 mb-12 text-green bg-white"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
      >
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          version="1.1"
          style="
            fill: currentColor;
            fill-rule: evenodd;
            clip-rule: evenodd;
            stroke-linejoin: round;
            stroke-miterlimit: 2;
          "
        >
          <g transform="matrix(1,0,0,1,-705.831,-1352.83)">
            <path
              d="M765.831,1472.83C798.746,1472.83 825.831,1445.74 825.831,1412.83C825.831,1379.91 798.746,1352.83 765.831,1352.83C732.917,1352.83 705.832,1379.91 705.831,1412.83C705.831,1445.74 732.916,1472.83 765.831,1472.83ZM747.877,1438.78L747.914,1438.92L746.664,1437.67L753.481,1444.49L808.22,1389.75L799.636,1381.17L753.534,1427.27L732.027,1405.76L723.443,1414.35L747.877,1438.78Z"
            />
          </g>
        </svg>
      </div>
      <h2 class="w-8/12 mx-auto text-4xl font-semibold mb-8">
        {{ "LOGIN_USER.CODE_CORRECT" | translate }}
      </h2>
      <h2 class="mt-2 w-full mx-auto text-4xl font-bold" *ngIf="!back">
        {{ "LOCKER_RENT_CODE_CORRECT.AGB_1" | translate }}
        <span
          (click)="openAgb()"
          class="font-semibold underline focus:outline-none"
        >
          {{ getContent("linkText") }}
          <!-- {{ "LOCKER_RENT_CODE_CORRECT.AGB_2" | translate }} -->
        </span>
        {{ "LOCKER_RENT_CODE_CORRECT.AGB_3" | translate }}
      </h2>
      <h2 class="mt-2 w-full mx-auto text-4xl font-bold" *ngIf="back">
        {{ "LOCKER_RENT_CODE_CORRECT.AGB_4" | translate }}
      </h2>
    </div>
    <div class="w-full self-center flex flex-col justify-start items-start">
      <div
        class="w-full h-auto flex flex-row space-x-16 items-center justify-center py-14 self-end"
      >
        <ng-container *ngIf="!back">
          <app-btn-sanusx
            (btnClick)="cancel()"
            [label]="'COMMON.BTN_CANCEL' | translate"
            [squared]="true"
            buttonType="neutral"
            class="wow slideInLeft animated"
          >
          </app-btn-sanusx>
          <app-btn-sanusx
            (btnClick)="agree(false)"
            [label]="'COMMON.BTN_SUBMIT' | translate"
            [squared]="true"
            [redBorder]="true"
            buttonType="secondary"
            class="wow slideInRight animated"
          >
          </app-btn-sanusx>
        </ng-container>
        <ng-container *ngIf="back">
          <app-btn-sanusx
            (btnClick)="agree(false)"
            [label]="'COMMON.BTN_NO' | translate"
            [squared]="true"
            buttonType="neutral"
            class="wow slideInLeft animated"
          >
          </app-btn-sanusx>

          <app-btn-sanusx
            (btnClick)="agree(true)"
            [label]="'COMMON.BTN_YES' | translate"
            [squared]="true"
            [redBorder]="true"
            buttonType="secondary"
            class="wow slideInRight animated"
          >
          </app-btn-sanusx>
        </ng-container>
      </div>
    </div>

    <!-- modal agb -->
    <div
      class="absolute left-0 top-0 mx-auto border-2 border-red text-black text-3xl text-left leading-relaxed tracking-normal bg-white self-start flex flex-col justify-start items-start px-8 py-4 wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
      *ngIf="modalOpened"
      style="width: 90vw; height: 84vh; left: 5vw; top: 7rem"
    >
      <div
        (click)="closeAgb()"
        class="absolute h-20 w-20 top-4 right-0 text-gray text-9xl font-bold hover:text-secondary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-20 w-20"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <span
        class="w-full title text-5xl text-red leading-relaxed font-bold mb-6"
      >
        {{ getContent("agbTitle") }}
      </span>
      <div
        class="w-full h-full pr-16 overflow-y-scroll flex flex-col space-y-12"
        innerHTML="{{ getContent('agbText') }}"
      ></div>
    </div>
    <!-- END modal agb -->
  </div>
</ng-container>

<ng-container *ngIf="theme === 'pickup_portrait'">
  <div
    class="w-full h-screen flex flex-col items-start justify-between bg-primaryx text-white"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="w-full flex flex-col items-center justify-center text-center px-27 relative"
    >
      <h2 class="w-8/12 mx-auto text-6xl font-bold mb-8">
        {{ "LOGIN_USER.CODE_CORRECT" | translate }}
      </h2>

      <div
        class="flex w-[211.83] h-[211.828] rounded-full mx-auto flex-col items-center justify-center wow pulse animated my-10 text-green bg-white"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="211.83"
          height="211.828"
          viewBox="0 0 211.83 211.828"
        >
          <g
            id="Group_7451"
            data-name="Group 7451"
            transform="translate(0 141.301)"
          >
            <path
              id="Path_13624"
              data-name="Path 13624"
              d="M105.915,70.527A105.915,105.915,0,0,0,211.83-35.388,105.915,105.915,0,0,0,105.915-141.3,105.915,105.915,0,0,0,0-35.388,105.915,105.915,0,0,0,105.915,70.527ZM84.206-3.655,46.242-41.619,37.33-32.708,75.295,5.257l-.094.094,8.912,8.912L174.5-76.126l-8.911-8.911Z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </g>
        </svg>
      </div>
      <ng-container>
        <h2 class="mt-2 w-full mx-auto text-6xl font-bold" *ngIf="!back">
          {{ "LOCKER_RENT_CODE_CORRECT.AGB_1" | translate }}

          <span
            (click)="openAgb()"
            class="font-bold underline focus:outline-none"
          >
            {{ getContent("linkText") }}
          </span>
          {{ "LOCKER_RENT_CODE_CORRECT.AGB_3" | translate }}
        </h2>
        <h2 class="mt-2 w-full mx-auto text-6xl font-bold" *ngIf="back">
          {{ "LOCKER_RENT_CODE_CORRECT.AGB_4" | translate }}
        </h2>
      </ng-container>
    </div>
    <div
      class="w-full self-center flex flex-col justify-start items-start wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <div
        class="w-full h-auto flex flex-col space-y-16 items-center justify-center pt-6 pb-14 self-end"
      >
        <ng-container *ngIf="!back">
          <app-btn-sanusx
            (btnClick)="agree(false)"
            [label]="'COMMON.BTN_SUBMIT' | translate"
            [squared]="true"
            [redBorder]="true"
            buttonType="secondary"
          >
          </app-btn-sanusx>
          <app-btn-sanusx
            (btnClick)="cancel()"
            [label]="'COMMON.BTN_CANCEL' | translate"
            [squared]="true"
            buttonType="neutral"
          >
          </app-btn-sanusx>
        </ng-container>
        <ng-container *ngIf="back">
          <app-btn-sanusx
            (btnClick)="agree(true)"
            [label]="'COMMON.BTN_YES' | translate"
            [squared]="true"
            [redBorder]="true"
            buttonType="secondary"
          >
          </app-btn-sanusx>
          <app-btn-sanusx
            (btnClick)="agree(false)"
            [label]="'COMMON.BTN_NO' | translate"
            [squared]="true"
            buttonType="neutral"
          >
          </app-btn-sanusx>
        </ng-container>
      </div>
    </div>

    <!-- modal agb -->
    <div
      class="absolute left-0 top-0 mx-auto border-2 border-red text-black text-3xl text-left leading-relaxed tracking-normal bg-white self-start flex flex-col justify-start items-start px-8 py-4 wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
      *ngIf="modalOpened"
      style="width: 90vw; height: 90vh; left: 5vw; top: 7rem"
    >
      <div
        (click)="closeAgb()"
        class="absolute h-20 w-20 top-4 right-0 text-gray text-9xl font-bold hover:text-secondary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-20 w-20"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <span
        class="w-full title text-5xl text-red leading-relaxed font-bold mb-6"
      >
        {{ getContent("agbTitle") }}
      </span>
      <div
        class="w-full h-full pr-16 overflow-y-scroll flex flex-col space-y-12"
        innerHTML="{{ getContent('agbText') }}"
      ></div>
    </div>
    <!-- END modal agb -->
  </div>
</ng-container>
