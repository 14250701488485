import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { MachineError } from '../../models/error';
import { CoreState } from '../../state/core.state';
import { GlobalEnums } from '../../state/core.state';


// Should become dialog window
@Component({
  selector: 'out-of-order',
  templateUrl: './out-of-order.component.html',
  styleUrls: ['./out-of-order.component.scss']
})
export class OutOfOrderComponent implements OnInit {
  public GlobalEnums = GlobalEnums;
  theme: string;
  logo: string;  
  openSetupCount: number = 0;
  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(private store: Store) {
    // this.theme = localStorage.getItem('theme') ?? 'default';
    this.theme = 'default';
    this.logo = localStorage.getItem('logo') ?? this.GlobalEnums.defaultLogo;
  }

  ngOnInit(): void {
  }

  openSetup() {
    this.openSetupCount++;

    if (this.openSetupCount === this.GlobalEnums.openSetupCount) {
      // this.store.dispatch(new Navigate(['/core', 'setup']))
    }
  }

  swipe(e: TouchEvent, when: string): void {
    console.warn('TouchEvent', e);
    console.warn('when', when);

    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipeDirection = direction[0] < 0 ? 'left' : 'right';
        // Do whatever you want with swipe
        // console.warn('horizontal swipe', swipeDirection);

      } else if (duration < 1000 //
        && Math.abs(direction[1]) > 30 // Long enough
        && Math.abs(direction[1]) > Math.abs(direction[0] * 3)) { // Vertical enough
        const swipeDirection = direction[1] < 1 ? 'up' : 'down';
        // Do whatever you want with swipe
        // console.warn('vertical swipe', swipeDirection);
      }
    }
  }
}
