import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { LockerSkiState, LockerSkiStateModel } from 'src/app/locker/state/locker-ski.state';

@Component({
  selector: 'app-card-scanner',
  templateUrl: './card-scanner.component.html',
  styleUrls: ['./card-scanner.component.scss']
})
export class SkiCardScannerComponent implements OnInit {
  skiServiceType: string = "";
  numberOfLockers: number;

  constructor(private store: Store) {
    this.numberOfLockers = this.store.selectSnapshot<LockerSkiStateModel>(LockerSkiState).numberOfLockers;
    this.skiServiceType = this.store.selectSnapshot<LockerSkiStateModel>(LockerSkiState).action;
  }

  ngOnInit(): void {
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    switch (event.key) {
      case "4":
        //
        break;
      case "3":
        //
        break;
      case "2":
        //
        break;
      case "1":
        // this.onNextStep();
        break;
      case "8":
        //
        break;
      case "7":
        //
        break;
      case "6":
        //
        break;
      case "5":
        //
        break;
    }
  }

  // onNextStep() {
  //   if (this.skiServiceType === 'rent') {
  //     this.store.dispatch(new Navigate(['/locker', 'ski', 'lock-wait']));
  //   } else if (this.skiServiceType === 'reclaim') {
  //     this.store.dispatch(new Navigate(['/locker', 'ski', 'unlock-selection']));
  //   }
  // }
}
