import {TemplateBindingParseResult} from '@angular/compiler';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Navigate} from '@ngxs/router-plugin';
import {
  Action,
  createSelector,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {append, patch, removeItem} from '@ngxs/store/operators';
import {fork} from 'child_process';
import {debug} from 'console';
import {stat} from 'fs';
import {
  forkJoin,
  interval,
  Observable,
  Observer,
  of,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  delay,
  flatMap,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import {Alert} from 'selenium-webdriver';
import {MachineError} from 'src/app/core/models/error';
import {Slot} from 'src/app/core/models/slot';
import {MqttHelperService} from 'src/app/core/services/mqtt.service';
import {PingService} from 'src/app/core/services/ping.service';
import {PrintService} from 'src/app/core/services/print.service';
import {Core} from 'src/app/core/state/core.actions';
import {
  CoreState,
  CoreStateModel,
  ModuleType,
} from 'src/app/core/state/core.state';
import {Locker} from 'src/app/locker/state/locker.actions';

import {Item} from '../../core/models/item';
import {
  ControlCode,
  ControlService,
} from '../../core/services/control.service';
import {Category} from '../models/category';
import {
  MqttDevice,
  MqttModule,
  MqttProduct,
  MqttSlot,
} from '../models/mqtt-stock-update';
import {PaymentResult} from '../models/payment-result';
import {CategoryService} from '../services/category.service';
import {ProductService} from '../services/product.service';
import {WwksService} from '../services/wwks.service';

import {Retailer} from './retailer.actions';

export enum RetailerError {
  SUCCESS                            = 0,
  NOT_CONFIGURED                     = 1,
  HATCH_OPEN                         = 2,
  TIMEOUT                            = 3,
  COMMAND_ERROR                      = 4,
  BUSY                               = 5,
  COMMUNICATION_ERROR                = 6,
  TRAY_NOT_CLEAR                     = 7,
  MAINTENANCE_IN_PROGRESS            = 8,
  BLOCKED                            = 9,
  BOX_NOT_FOUND                      = 10,
  PRODUCT_STICKS_OUT                 = 11,
  PUSHER_STUCK                       = 12,
  UNKNOWN_MACHINE_ERROR              = 13,
  BOX_ALREADY_EXISTS                 = 14,
  OUT_OF_BOUNDS                      = 15,
  INVALID_POSITION                   = 16,
  HEALTH_CHECK_ERROR                 = 17,
  NO_CONNECTION_TO_ADAPTER           = 100,
  COULD_NOT_LOAD_PRODUCTS_CATEGORIES = 101,
  PRODUCT_NOT_EXIST                  = 102,
  NOT_ENOUGH_PRODUCTS                = 103,
  INTERNAL_ADAPTER_ERROR             = 503,
  UNKNOWN_HTTP_ERROR                 = 999,
}

export enum VendingTypes {
  STANDARD   = 'standard',
  WWKS2      = 'wwks2',
  PREORDER   = 'preorder',
  SKI        = 'ski',
  NO_RELEASE = 'no_release',
}

export enum PreorderType {
  HOME    = 'home',
  PAY     = 'pay',
  RECEIPT = 'receipt',
}

export class RetailerStateModel
{
  categories: Category [];
  selectedCategory: Category;
  selectedItem: Item;
  selectedPickup: any;
  shoppingCart: any [];
  lastPaymentResult: PaymentResult;
  items: Item [];
  ads: boolean [];
  active: boolean;
  search: string;
  releasedProducts: Map< string, Map< number, any [] >>;
  errorTobaccoSlots: Map< number, any [] >;
  errorRetailSlots: Map< number, any [] >;
  printReceipt: boolean;
  trayCleared: boolean;
}

@State
< RetailerStateModel >( {
  name : 'retailer',
  defaults : {
              categories : [],
              selectedCategory : null,
              selectedItem : null,
              shoppingCart : [],
              selectedPickup : null,
              lastPaymentResult : null,
              items : null,
              ads : [],
              active : false,
              search : '',
              releasedProducts : null,
              errorTobaccoSlots : null,
              errorRetailSlots : null,
              printReceipt : false,
              trayCleared : false,
              },
} ) @Injectable ( ) export class RetailerState implements NgxsOnInit
{
  constructor( private store: Store,
               private controlService: ControlService,
               private categoryService: CategoryService,
               private productService: ProductService,
               private printService: PrintService,
               private pingService: PingService,
               private wwksService: WwksService,
               private router: Router,
               private translate: TranslateService,
               private mqttHelperService: MqttHelperService )
  {
  }

  ngxsOnInit( ctx?: StateContext< any >)
  {
    setInterval ( ( ) => this.store.dispatch( new Retailer.HealthCheck( ) ), 60000 );
    setInterval ( ( ) => this.store.dispatch( new Retailer.GetTemperature( ) ), 181000 );
  }

  @Selector( ) static categories( state: RetailerStateModel ): Category []
  {
    return state.categories.filter( ( c ) => c.name !== 'Abholer' );
  }

  @Selector( ) static items( state: RetailerStateModel ): Item []
  {
    return state.items
      .filter( ( i ) => {
        if ( state.selectedCategory )
        {
          return ( i.product.categories.map( ( c ) => c.id )
                     .filter( ( productCategoryId ) =>
                                productCategoryId == state.selectedCategory.id ||
                                this.productBelongToSubcategory( productCategoryId, state.selectedCategory ) )
                     .length > 0 );
        }
        else
        {
          return true;
        }
      } )
      .filter( ( i ) => i.product.categories.map( ( c ) => c.name ).indexOf( 'Abholer' ) == -1 )
      .filter( ( i ) => i.product.name.toLowerCase( ).indexOf( state.search.toLowerCase( ) ) != -1 ||
                        ( i.product.tags && i.product.tags.toLowerCase( ).indexOf( state.search.toLowerCase( ) ) != -1 ) );
  }

  static productBelongToSubcategory( productCategoryId: number, selectedCategory: Category ): unknown
  {
    return ( selectedCategory [ 'children' ] &&
             selectedCategory [ 'children' ].filter( ( subcategory ) => productCategoryId == subcategory.id ).length > 0 );
  }

  @Selector( ) static selectedCategory( state: RetailerStateModel ): Category { return state.selectedCategory; }

  @Selector( ) static selectedItem( state: RetailerStateModel ): Item { return state.selectedItem; }

  @Selector( ) static selectedPickup( state: RetailerStateModel ): any { return state.selectedPickup; }

  @Selector( ) static ads( state: RetailerStateModel ): boolean [] { return state.ads; }

  static productsByCategory( categoryId: number )
  {
    return createSelector ( [ RetailerState ],
                            ( state: RetailerStateModel ) => RetailerState.items( state ).filter(
                              ( i ) => i.product.categories.map( ( c ) => c.id )
                                         .filter( ( d ) => ( categoryId == null ? true : d == categoryId ) )
                                         .length > 0 ) );
  }

  @Action( Retailer.Configure ) configure( ctx: StateContext< RetailerStateModel >)
  {
    let config = this.store.selectSnapshot< CoreStateModel >( CoreState ).config;
    if ( config )
    {
      if ( !config.doHealthCheck )
      {
        console.log( 'not doing configure' );
        return of ( true );
      }
    }
    else
    {
      return of ( true );
    }
    if ( config.controlSoftwareType != 'mqtt' )
    {
      this.prepareErrorMessage( ctx );
    }
    else
    {
      return of ( true );
    }
  }

  prepareErrorMessage( ctx: any )
  {
    return this.controlService.healthCheck( ).pipe(
      switchMap ( ( res ) => {
        if ( res.controlError == ControlCode.NOT_CONFIGURED )
        {
          return this.controlService.configure( );
        }
        else if ( res.controlError != ControlCode.SUCCESS )
        {
          this.store.dispatch( new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                                                  ModuleType.RETAILER,
                                                                  'Konfigurierung fehlgeschlagen',
                                                                  true ),
                                               ModuleType.RETAILER ) );
          // Retry Initialization every 5 Seconds
          return timer ( 5000 ).pipe( switchMap ( ( ) => this.configure( ctx ) ) );
        }
        else
        {
          return of ( true );
        }
      } ),
      catchError ( ( error ) => {
        if ( error.error instanceof ErrorEvent )
        {
          // Client-side errors
          this.store.dispatch( new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                                                  ModuleType.RETAILER,
                                                                  'Konfigurierung fehlgeschlagen',
                                                                  true ),
                                               ModuleType.RETAILER ) );
        }
        else
        {
          if ( error.status == 0 )
          {
            this.store.dispatch( new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                                                    ModuleType.RETAILER,
                                                                    'Konfigurierung fehlgeschlagen',
                                                                    true ),
                                                 ModuleType.RETAILER ) );
          }
          else
          {
            this.store.dispatch( new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                                                    ModuleType.RETAILER,
                                                                    'Konfigurierung fehlgeschlagen',
                                                                    true ),
                                                 ModuleType.RETAILER ) );
          }
        }
        // Retry Initialization every 5 Seconds
        return timer ( 5000 ).pipe( switchMap ( ( ) => this.configure( ctx ) ) );
      } ) );
  }

  @Action( Retailer.HealthCheck ) healthCheck( ctx: StateContext< RetailerStateModel >)
  {
    // tmp no health check for pickup
    let config = this.store.selectSnapshot< CoreStateModel >( CoreState ).config;
    if ( config )
    {
      if ( !config.doHealthCheck )
      {
        return of ( true );
      }
    }
    else
    {
      return of ( true );
    }

    let route: string = this.router.url;

    if ( ( route &&
           ( route.toLowerCase( ).startsWith( '/core/setup' ) || route.toLowerCase( ).startsWith( '/retailer/release' ) ||
             route.toLowerCase( ).startsWith( '/retailer/retrieve' ) ||
             route.toLowerCase( ).startsWith( '/retailer/finish' ) ) ) ||
         route.toLowerCase( ).startsWith( '/locker/delivery/door-oppened' ) )
    {
      console.log( 'not doing healthCheck in setup' );
      return of ( true );
    }

    if ( !ctx.getState( ).active )
    {
      if ( config.controlSoftwareType === 'mqtt' )
      {
        return this.processHealthCheckMqtt( ctx );
      }
      else
      {
        return this.processHealthCheck( );
      }
    }
  }

  processHealthCheckMqtt( ctx: StateContext< RetailerStateModel >)
  {
    return this.mqttHelperService.healthCheck( ).pipe(
      tap ( ( res ) => {
        let blockMachine             = false;
        let errorTobaccoSlots        = new Map ( );
        let errorRetailSlots         = new Map ( );
        let errorObSlots             = new Map ( );
        let doorOpenSlots: number [] = [];

        if ( res.success )
        {
          console.log( 'mqtt health check result', res.message );
          let terminalData: any [] = res.message [ 'terminal24' ];
          let tobaccoData: any []  = res.message [ 'tobacco24' ];
          let retailData: any []   = res.message [ 'retail24' ];
          let obData: any []       = res.message [ 'officebutler24' ];
          if ( terminalData && terminalData.find( ( device ) => device.state == 'ready' ) )
          {
            // Check Tobacco error slots
            if ( tobaccoData )
            {
              console.log( 'Tobacco, slot check' );
              errorTobaccoSlots = this.getErorSlots( tobaccoData, errorTobaccoSlots );
            }
            // Check Retail error slots
            if ( retailData )
            {
              console.log( 'Retail, slot check' );
              errorRetailSlots = this.getErorSlots( retailData, errorRetailSlots );
            }
            // Check if machine still active, only if no module is ready block
            if ( !( tobaccoData && tobaccoData.find( ( device ) => device.state == 'ready' ) ) &&
                 !( retailData && retailData.find( ( device ) => device.state == 'ready' ) ) )
            {
              blockMachine = true;
            }
          }
          else if ( obData && obData.find( ( device ) => device.state == 'ready' ) )
          {
            console.log( 'officebutler ok, slot check' );
            this.getErorSlots( obData, errorObSlots );
            doorOpenSlots = this.getDoorOpenSlots( obData, doorOpenSlots );
            if ( doorOpenSlots.length > 0 )
            {
              blockMachine = true;
            }
          }
          else
          {
            blockMachine = true;
          }
        }
        else
        {
          console.log( 'no health check response' );
          blockMachine = false;  //! luki
                                 //? no health check for testing
        }

        console.log( 'errorSlots', [...errorTobaccoSlots.entries( ) ] );
        console.log( 'errorSlotsRetail', [...errorRetailSlots.entries( ) ] );
        console.log( 'errorSlotsOb', [...errorObSlots.entries( ) ] );

        if ( !blockMachine )
        {
          let route: string = this.router.url;

          if ( route && route.toLowerCase( ).startsWith( '/core/screensaver' ) )
          {
            ctx.patchState( {
              errorTobaccoSlots : errorTobaccoSlots,
              errorRetailSlots : errorRetailSlots,
            } );
          }

          let activeError =
            this.store.selectSnapshot< CoreStateModel >( CoreState ).error [ ModuleType.RETAILER ] as MachineError;
          if ( activeError != undefined && activeError.errorNumber == RetailerError.HEALTH_CHECK_ERROR )
          {
            this.store.dispatch( new Core.Error( null, ModuleType.RETAILER ) );
            this.store.dispatch( new Navigate ( [ '/core/screensaver' ] ) );
          }
        }
        else
        {
          console.log( 'door oppen slots:', JSON.stringify( doorOpenSlots ) );
          this.controlService.log( 'ERROR', 'health check error: ' + JSON.stringify( res ) ).subscribe( );
          this.store.dispatch( new Core.Error(
            new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                               ModuleType.RETAILER,
                               doorOpenSlots.length > 0 ? this.translate.instant( 'ERROR.DOOR_NOT_CLOSED_OB' ) + ':' +
                                                            Array.from( doorOpenSlots.values( ) )
                                                        : this.translate.instant( 'ERROR.HEALTH_CHECK_MAINTENANCE' ),
                               true ),
            ModuleType.RETAILER ) );
        }
      } ),
      catchError ( ( error ) => {
        this.controlService.log( 'ERROR', 'health check error: ' + error ).subscribe( );
        return this.store.dispatch(
          new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                             ModuleType.RETAILER,
                                             this.translate.instant( 'ERROR.HEALTH_CHECK_MAINTENANCE' ),
                                             true ),
                          ModuleType.RETAILER ) );
      } ) );
  }

  private getErorSlots( data: any [], errorSlots: Map< any, any >)
  {
    for ( let device of data )
    {
      if ( device.slots_with_error )
      {
        let numbers: number [] = [];
        if ( device.slots_with_error )
        {
          device.slots_with_error.forEach( ( element ) => {
            if ( element.number )
            {
              numbers.push( element.number );
            }
          } );
        }
        if ( numbers.length > 0 )
        {
          errorSlots.set( device.device_nr, numbers );
        }
      }
    }
    return errorSlots;
  }
  private getDoorOpenSlots( data: any [], errorSlots: number [] )
  {
    for ( let device of data )
    {
      if ( device.slots_with_error )
      {
        if ( device.slots_with_error )
        {
          device.slots_with_error.forEach( ( element ) => {
            if ( element.number && element.error == 'door_open' )
            {
              errorSlots.push( element.number );
            }
          } );
        }
      }
    }
    return errorSlots;
  }

  processHealthCheck( )
  {
    return this.controlService.healthCheck( ).pipe(
      tap ( ( res ) => {
        if ( res.controlError == ControlCode.NOT_CONFIGURED )
        {
          return forkJoin ( [ this.store.dispatch( new Retailer.Configure( ) ) ] );
        }
        else if ( res.controlError == ControlCode.SUCCESS )
        {
          if ( res.status != 0 )
          {
            let healtchCheckError = this.parseHealthCheckError( res.status );
            this.store.dispatch( new Core.Error(
              new MachineError ( RetailerError.HEALTH_CHECK_ERROR, ModuleType.RETAILER, healtchCheckError, true ),
              ModuleType.RETAILER ) );
          }
          else
          {
            let activeError =
              this.store.selectSnapshot< CoreStateModel >( CoreState ).error [ ModuleType.RETAILER ] as MachineError;
            if ( activeError != undefined && activeError.errorNumber == RetailerError.HEALTH_CHECK_ERROR )
            {
              this.store.dispatch( new Core.Error( null, ModuleType.RETAILER ) );
              this.store.dispatch( new Navigate ( [ '/core/screensaver' ] ) );
            }
          }
        }
        else
        {
          let controlErrorMessage = this.getControlErrorMessage( res.controlError );
          this.store.dispatch( new Core.Error(
            new MachineError ( RetailerError.HEALTH_CHECK_ERROR, ModuleType.RETAILER, controlErrorMessage, true ),
            ModuleType.RETAILER ) );
        }
      } ),
      catchError ( ( error ) => {
        if ( error.error instanceof ErrorEvent )
        {
          // Client-side errors
          return this.store.dispatch( new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                                                         ModuleType.RETAILER,
                                                                         'UNKNOWN_HTTP_ERROR: ' + error.message,
                                                                         true ),
                                                      ModuleType.RETAILER ) );
        }
        else
        {
          // Server-side errors
          if ( error.status == 503 )
          {
            return this.store.dispatch( new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                                                           ModuleType.RETAILER,
                                                                           'INTERNAL_ADAPTER_ERROR: ' + error.message,
                                                                           true ),
                                                        ModuleType.RETAILER ) );
          }
          else if ( error.status == 0 )
          {
            return this.store.dispatch( new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                                                           ModuleType.RETAILER,
                                                                           'NO_CONNECTION_TO_ADAPTER: ' + error.message,
                                                                           true ),
                                                        ModuleType.RETAILER ) );
          }
          else
          {
            return this.store.dispatch( new Core.Error( new MachineError ( RetailerError.HEALTH_CHECK_ERROR,
                                                                           ModuleType.RETAILER,
                                                                           'UNKNOWN_ERROR: ' + error.message,
                                                                           true ),
                                                        ModuleType.RETAILER ) );
          }
        }
      } ) );
  }

  @Action( Retailer.GetTemperature ) getTemperature( ctx: StateContext< RetailerStateModel >)
  {
    let doTemperatureCheck = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.doTemperatureCheck;
    if ( !ctx.getState( ).active && doTemperatureCheck )
    {
      return this.controlService.getTemperature( ).pipe(
        tap ( ( res ) => {
          if ( res.controlError == ControlCode.SUCCESS )
          {
            this.pingService.sendTemperatureInfo( res.temperature ).subscribe( );
          }

          return of ( true );
        } ),
        catchError ( ( error ) => {
          this.controlService.log( 'ERROR', 'getTemperature error: ' + error ).subscribe( );
          return of ( true );
        } ) );
    }
  }

  getControlErrorMessage( controlError: ControlCode )
  {
    try
    {
      return RetailerError [ controlError ];
    }
    catch ( error )
    {
      return 'Unknown';
    }
  }

  @Action( Retailer.Load ) load( ctx: StateContext< RetailerStateModel >): Observable< boolean >
  {
    let vendingType = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.vendingType;

    if ( vendingType == VendingTypes.WWKS2 )
    {
      return this.loadProductsWWKS( ctx );
    }
    else if ( vendingType == VendingTypes.PREORDER )
    {
      return this.loadProductsPreorder( ctx );
    }
    else
    {
      return this.loadProductsStandard( ctx );
    }
  }

  loadProductsStandard( ctx: StateContext< RetailerStateModel >): Observable< boolean >
  {
    return forkJoin ( [
             // this.categoryService.getCategories(),
             this.categoryService.getCategoriesHierarchy( ),
             this.productService.getItems( ),
           ] )
      .pipe( tap ( ( [ categories, items ] ) => {
               console.warn( 'categories', categories );
               console.warn( 'items', items );
               // filter if slots are in error
               let tobaccoError = ctx.getState( ).errorTobaccoSlots;
               let retailError  = ctx.getState( ).errorRetailSlots;

               console.info( 'tobacco errorSlots on load', tobaccoError );
               console.info( ' retail errorSlots on load', retailError );
               if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.defaultProductsTag !== '' )
               {
                 items = this.putDefaultProductsOnTop( items );
               }

               console.log( 'New Version of Test Tobacco' )

               if ( tobaccoError && tobaccoError.size > 0 || retailError && retailError.size > 0 )
               {
                 items.forEach( ( item ) => {
                   let notAvailable = true;
                   // check if at least one slot without error is found for the
                   // product
                   item.slots.forEach( ( slot ) => {
                     if ( slot.containerCode && slot.containerType == 'Tobacco' )  // check if tobacco slot is an error
                     {
                       if ( !( tobaccoError && tobaccoError.size > 0 ) ||  // there are noe error
                                                                           // slots for tobacco or
                            !( tobaccoError.has( +slot.containerCode ) &&
                               tobaccoError.get( +slot.containerCode ).includes( slot.slotIndex ) ) )  // error slots do not
                       // contain current slot
                       {
                         notAvailable = false;
                       }
                     }
                     else if ( slot.containerCode && slot.containerType == 'Vending' )  // check if retail slot is an error
                     {
                       if ( !( retailError && retailError.size > 0 ) ||  // there are noe error
                                                                         // slots for retail or
                            !( retailError.has( +slot.containerCode ) &&
                               retailError.get( +slot.containerCode ).includes( slot.slotIndex ) ) )  // error slots do not
                       // contain current slot
                       {
                         notAvailable = false;
                       }
                     }
                     else
                     {
                       notAvailable = false;
                     }
                   } )
                   if ( notAvailable )
                   {
                     console.log( 'set errorState true', item.product.id );
                     item.product.errorState = true;
                   }
                   else
                   {
                     item.product.errorState = false;
                   }
                 } );
               }

               // if (tobaccoError && tobaccoError.size > 0) {
               //   let errorSlots = tobaccoError;
               //   items.forEach((item) =>
               //     item.slots.forEach((slot) => {
               //       if (
               //         slot.containerCode &&
               //         slot.containerType == 'Tobacco' &&
               //         errorSlots.has(+slot.containerCode) &&
               //         errorSlots.get(+slot.containerCode).includes(slot.slotIndex)
               //       ) {
               //         console.log('set errorState true', item.product.id);
               //         item.product.errorState = true;
               //       }
               //     })
               //   );
               // }
               // if (retailError && retailError.size > 0) {
               //   let errorSlots = retailError;
               //   items.forEach((item) =>
               //     item.slots.forEach((slot) => {
               //       if (
               //         slot.containerCode &&
               //         slot.containerType == 'Vending' &&
               //         errorSlots.has(+slot.containerCode) &&
               //         errorSlots.get(+slot.containerCode).includes(slot.slotIndex)
               //       ) {
               //         console.log('set errorState true', item.product.id);
               //         item.product.errorState = true;
               //       }
               //     })
               //   );
               // }
               items.forEach( ( item ) => { console.log( 'errorState:', item.product.errorState ); } );

               items = items.filter(
                 ( item ) => ( item.product && item.product.categories && item.product.categories.length > 0 ) &&
                             ( item.product.errorState == false ||
                               item.product.errorState == undefined )  //! need to undefined for normal retail
               );

               console.log( 'filered items slot', items );

               const categoriesWithProducts = new Set ( );
               items.forEach( ( item ) => {
                 if ( item.product && item.product.categories && item.product.categories.length > 0 )
                 {
                   item.product.categories.forEach( ( cat ) => {
                     if ( categoriesWithProducts.size == 0 || !categoriesWithProducts.has( cat.id ) )
                     {
                       categoriesWithProducts.add( cat.id );
                     }
                   } );
                 }
               } );

               ctx.patchState( {
                 categories : this.getCategoriesContainingProducts( categories, categoriesWithProducts ),
                 items : items,
               } );

               let activeError =
                 this.store.selectSnapshot< CoreStateModel >( CoreState ).error [ ModuleType.RETAILER ] as MachineError;
               if ( activeError != undefined && activeError.errorNumber == RetailerError.COULD_NOT_LOAD_PRODUCTS_CATEGORIES )
               {
                 this.store.dispatch( new Core.Error( null, ModuleType.RETAILER ) );
                 this.store.dispatch( new Navigate ( [ '/core/screensaver' ] ) );
               }
             } ),
             switchMap ( ( ) => {
               if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.controlSoftwareType != 'mqtt' )
               {
                 return of ( true );
               }
               let items = ctx.getState( ).items;
               console.log( items );
               let data = this.prepareStockDataMqtt( items );

               return this.mqttHelperService.updateStock( data );
             } ),
             map ( ( ) => true ),
             catchError ( ( error ) => {
               console.log( error );
               this.store.dispatch( new Core.Error( new MachineError ( RetailerError.COULD_NOT_LOAD_PRODUCTS_CATEGORIES,
                                                                       ModuleType.RETAILER,
                                                                       'Produkte konnten nicht geladen werden',
                                                                       true ),
                                                    ModuleType.RETAILER ) );
               return timer ( 10000 ).pipe(
                 switchMap ( ( ) => this.store.dispatch( new Navigate ( [ '/core/screensaver' ] ) ) ) );
             } ) );
  }

  private putDefaultProductsOnTop( items: Item [] )
  {
    console.log( 'putting items on top by Tag' );
    const targetObjects = items.filter(
      ( item ) => item.product.tags != null &&
                  item.product.tags.toUpperCase( ).includes(
                    this.store.selectSnapshot< CoreStateModel >( CoreState ).config.defaultProductsTag.toUpperCase( ) ) );
    console.log( 'targetObjects' );
    const otherObjects = items.filter(
      ( item ) =>
        item.product.tags == null ||
        ( item.product.tags != null &&
          !item.product.tags.toUpperCase( ).includes(
            this.store.selectSnapshot< CoreStateModel >( CoreState ).config.defaultProductsTag.toUpperCase( ) ) ) );
    items = [...targetObjects, ...otherObjects ];
    return items;
  }

  private prepareStockDataMqtt( items: Item [] )
  {
    let allSlots: Slot [] = [];
    for ( let item of items )
    {
      allSlots.push( ...item.slots );
    }
    let tabacoModule = { devices : [] } as MqttModule;
    let retailmodule = { devices : [] } as MqttModule;

    console.log( 'allslots', allSlots );

    for ( let slot of allSlots )
    {
      let product      = { } as MqttProduct;
      product.id       = slot.productId;
      let mqttSlot     = { } as MqttSlot;
      mqttSlot.product = product;
      mqttSlot.number  = slot.slotIndex;
      mqttSlot.stock   = slot.quantity;

      if ( slot.containerType == 'Tobacco' )
      {
        if ( !tabacoModule.devices )
        {
          let device = {
            device_nr : +slot.containerCode,
            slots : [],
          } as MqttDevice;
          device.slots.push( mqttSlot );
          tabacoModule.devices.push( device );
        }
        else if ( tabacoModule.devices &&
                  tabacoModule.devices.findIndex( ( d ) => d.device_nr == slot.containerCode ) == -1 )
        {
          let device = {
            device_nr : +slot.containerCode,
            slots : [],
          } as MqttDevice;
          device.slots.push( mqttSlot );
          tabacoModule.devices.push( device );
        }
        else if ( tabacoModule.devices.findIndex( ( d ) => d.device_nr == slot.containerCode ) != -1 )
        {
          let indexToUpdate = tabacoModule.devices.findIndex( ( d ) => d.device_nr == slot.containerCode );
          tabacoModule.devices [ indexToUpdate ].slots.push( mqttSlot );
        }
      }
      else if ( slot.containerType == 'Vending' )
      {
        if ( !retailmodule.devices )
        {
          let device = {
            device_nr : +slot.containerCode,
            slots : [],
          } as MqttDevice;
          device.slots.push( mqttSlot );
          retailmodule.devices.push( device );
        }
        else if ( retailmodule.devices &&
                  retailmodule.devices.findIndex( ( d ) => d.device_nr == slot.containerCode ) == -1 )
        {
          let device = {
            device_nr : +slot.containerCode,
            slots : [],
          } as MqttDevice;
          device.slots.push( mqttSlot );
          retailmodule.devices.push( device );
        }
        else if ( retailmodule.devices.findIndex( ( d ) => d.device_nr == slot.containerCode ) != -1 )
        {
          let indexToUpdate = retailmodule.devices.findIndex( ( d ) => d.device_nr == slot.containerCode );
          retailmodule.devices [ indexToUpdate ].slots.push( mqttSlot );
        }
      }
    }
    tabacoModule.device_cnt = tabacoModule.devices.length;
    retailmodule.device_cnt = retailmodule.devices.length;

    let data = { tobacco24 : tabacoModule, retail24 : retailmodule };
    console.log( 'data', data );
    return data;
  }

  private getCategoriesContainingProducts( categories: Category [], categoriesWithProducts: Set< unknown >)
  {
    let categoriesContainingProducts = [];

    if ( categories )
    {
      categories.forEach( ( category ) => {
        let subCategoriesContainingProducts = [];

        if ( category [ 'children' ] && category [ 'children' ].length > 0 )
        {
          category [ 'children' ].forEach( ( subCategory ) => {
            if ( categoriesWithProducts.size > 0 && categoriesWithProducts.has( subCategory.id ) )
            {
              subCategoriesContainingProducts.push( subCategory );
            }
          } );
        }

        if ( subCategoriesContainingProducts.length > 0 )
        {
          category [ 'children' ] = subCategoriesContainingProducts;
          categoriesContainingProducts.push( category );
        }
        else if ( categoriesWithProducts.size > 0 && categoriesWithProducts.has( category.id ) )
        {
          category [ 'children' ] = [];
          categoriesContainingProducts.push( category );
        }
      } );
    }
    return categoriesContainingProducts;
  }

  loadProductsWWKS( ctx: StateContext< RetailerStateModel >): Observable< boolean >
  {
    return forkJoin ( [
             this.categoryService.getCategoriesHierarchy( ),
             this.productService.getProductsWithCategories( ),
             this.wwksService.getAsrProducts( ),
           ] )
      .pipe( tap ( ( [ categories, products, asrProducts ] ) => {
               let items: Item [] = [];

               const categoriesWithProducts = new Set ( );

               products.forEach( ( product ) => {
                 if ( product.categories && product [ 'externalId' ] && asrProducts [ product [ 'externalId' ] ] )
                 {
                   items.push( {
                     product : product,
                     slots : [],
                     wwksArticle : asrProducts [ product [ 'externalId' ] ],
                     container : null,
                   } );
                 }
               } );

               items.forEach( ( item ) => {
                 if ( item.product && item.product.categories && item.product.categories.length > 0 )
                 {
                   item.product.categories.forEach( ( cat ) => {
                     if ( categoriesWithProducts.size == 0 || !categoriesWithProducts.has( cat.id ) )
                     {
                       categoriesWithProducts.add( cat.id );
                     }
                   } );
                 }
               } );
               if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.defaultProductsTag !== '' )
               {
                 items = this.putDefaultProductsOnTop( items );
               }

               ctx.patchState( {
                 categories : this.getCategoriesContainingProducts( categories, categoriesWithProducts ),
                 items : items,
               } );

               let activeError =
                 this.store.selectSnapshot< CoreStateModel >( CoreState ).error [ ModuleType.RETAILER ] as MachineError;
               if ( activeError != undefined && activeError.errorNumber == RetailerError.COULD_NOT_LOAD_PRODUCTS_CATEGORIES )
               {
                 this.store.dispatch( new Core.Error( null, ModuleType.RETAILER ) );
                 this.store.dispatch( new Navigate ( [ '/core/screensaver' ] ) );
               }
             } ),
             map ( ( ) => true ),
             catchError ( ( error ) => {
               console.log( error );
               this.store.dispatch( new Core.Error( new MachineError ( RetailerError.COULD_NOT_LOAD_PRODUCTS_CATEGORIES,
                                                                       ModuleType.RETAILER,
                                                                       'Produkte konnten nicht geladen werden',
                                                                       true ),
                                                    ModuleType.RETAILER ) );
               return timer ( 10000 ).pipe(
                 switchMap ( ( ) => this.store.dispatch( new Navigate ( [ '/core/screensaver' ] ) ) ) );
             } ) );
  }

  loadProductsPreorder( ctx: StateContext< RetailerStateModel >): Observable< boolean >
  {
    return forkJoin ( [
             this.categoryService.getCategoriesHierarchy( ),
             this.productService.getProductsWithCategories( ),
           ] )
      .pipe( tap ( ( [ categories, products ] ) => {
               let items: Item [] = [];

               const categoriesWithProducts = new Set ( );

               products.forEach( ( product ) => {
                 if ( !product.externalId )
                 {
                   product.externalId = product.id;
                 }

                 if ( product.categories && product.categories.length > 0 )
                 {
                   product.categories.forEach( ( cat ) => {
                     if ( categoriesWithProducts.size == 0 || !categoriesWithProducts.has( cat.id ) )
                     {
                       categoriesWithProducts.add( cat.id );
                     }
                   } );
                 }

                 items.push( {
                   product : product,
                   slots : [],
                   wwksArticle : { quantity : 9999 },
                   container : null,
                 } );
               } );

               ctx.patchState( {
                 categories : this.getCategoriesContainingProducts( categories, categoriesWithProducts ),
                 items : items,
               } );

               let activeError =
                 this.store.selectSnapshot< CoreStateModel >( CoreState ).error [ ModuleType.RETAILER ] as MachineError;
               if ( activeError != undefined && activeError.errorNumber == RetailerError.COULD_NOT_LOAD_PRODUCTS_CATEGORIES )
               {
                 this.store.dispatch( new Core.Error( null, ModuleType.RETAILER ) );
                 this.store.dispatch( new Navigate ( [ '/core/screensaver' ] ) );
               }
             } ),
             map ( ( ) => true ),
             catchError ( ( error ) => {
               console.log( error );
               this.store.dispatch( new Core.Error( new MachineError ( RetailerError.COULD_NOT_LOAD_PRODUCTS_CATEGORIES,
                                                                       ModuleType.RETAILER,
                                                                       'Produkte konnten nicht geladen werden',
                                                                       true ),
                                                    ModuleType.RETAILER ) );
               return timer ( 10000 ).pipe(
                 switchMap ( ( ) => this.store.dispatch( new Navigate ( [ '/core/screensaver' ] ) ) ) );
             } ) );
  }

  @Action( Retailer.SelectCategory )
  selectCategory( ctx: StateContext< RetailerStateModel >, action: Retailer.SelectCategory )
  {
    console.log( 'selectcategories', action );
    if ( action != null )
    {
      const findItemNested = ( arr, itemId, nestingKey ) => arr.reduce( ( a, item ) => {
        if ( a )
          return a;
        if ( item.id === itemId )
          return item;
        if ( item [ nestingKey ] )
          return findItemNested ( item [ nestingKey ], itemId, nestingKey );
      }, null );
      ctx.patchState( {
        selectedCategory : findItemNested ( ctx.getState( ).categories, action.id, 'children' ),
        // selectedCategory: ctx.getState().categories.find((c) => c.id ==
        // action.id),
      } );
    }
    else
    {
      ctx.patchState( {
        selectedCategory : null,
      } );
    }
  }

  @Action( Retailer.SelectProduct ) selectProduct( ctx: StateContext< RetailerStateModel >, action: Retailer.SelectProduct )
  {
    if ( !ctx.getState( ).items )
    {
      // this.store.dispatch(new Retailer.Load()).subscribe(() =>
      this.store.dispatch( new Retailer.SelectProduct( action.id ) );
      //)
    }
    else
    {
      let item = ctx.getState( ).items.find( ( i ) => i.product.id == action.id );

      ctx.patchState( {
        selectedItem : item,
        search : '',
      } );
    }
  }

  @Action( Retailer.AddProductToCart )
  addItemToCart( ctx: StateContext< RetailerStateModel >, action: Retailer.AddProductToCart )
  {
    let item = ctx.getState( ).items.find( ( i ) => i.product.id == action.id );

    let itemsArray = [];
    for ( let i = 0; i < action.quantity; i++ )
    {
      itemsArray.push( item );
    }

    console.log( 'add to cart', itemsArray );

    ctx.setState( patch ( {
      shoppingCart : append ( itemsArray ),
    } ) );

    return of ( true );
  }

  @Action( Retailer.RemoveProductFromCart )
  removeProductFromCart( ctx: StateContext< RetailerStateModel >, action: Retailer.RemoveProductFromCart )
  {
    const index = ctx.getState( ).shoppingCart.findIndex( ( object ) => { return object.product.id === action.id; } );

    ctx.setState( patch ( {
      shoppingCart : removeItem ( index ),
    } ) );

    return of ( true );
  }

  @Action( Retailer.RemoveAllProductInstancesFromCart )
  removeAllProductInstancesFromCart( ctx: StateContext< RetailerStateModel >,
                                     action: Retailer.RemoveAllProductInstancesFromCart )
  {
    while ( true )
    {
      let index = ctx.getState( ).shoppingCart.findIndex( ( object ) => { return object.product.id === action.id; } );

      if ( index >= 0 )
      {
        ctx.setState( patch ( {
          shoppingCart : removeItem ( index ),
        } ) );
      }
      else
      {
        break;
      }
    }

    return of ( true );
  }

  @Action( Retailer.EmptyCart ) emptyCart( ctx: StateContext< RetailerStateModel >, action: Retailer.EmptyCart )
  {
    ctx.setState( patch ( {
      shoppingCart : [],
    } ) );

    return of ( true );
  }

  @Action( Retailer.BuyProduct ) buyProduct( ctx: StateContext< RetailerStateModel >, action: Retailer.BuyProduct )
  {
    ctx.patchState( {
      active : true,
    } );

    let selectedItem = ctx.getState( ).selectedItem;

    if ( !selectedItem )
    {
      return throwError (
        new MachineError ( RetailerError.PRODUCT_NOT_EXIST, ModuleType.RETAILER, 'Produkt existiert nicht', false ) );
    }

    // Run payment
    this.store.dispatch( new Navigate ( [ '/retailer', 'payment' ] ) );

    // dispatch core.payment
    return this.store.dispatch( new Core.StartPayment( selectedItem.product.grossPrice ) )
      .pipe(
        catchError ( ( error ) => {
          ctx.patchState( {
            active : false,
          } );
          return throwError ( error );
        } ),
        switchMap ( ( ) => this.store
                             .dispatch( new Retailer.ReleaseProductNew( selectedItem.slots [ 0 ],
                                                                        selectedItem.product,
                                                                        selectedItem.container,
                                                                        true ) )
                             .pipe( catchError ( ( error: MachineError ) => {
                                      console.log( error );
                                      ctx.patchState( {
                                        active : false,
                                      } );

                                      return of ( { errorOccured : true, error : error } );
                                    } ),
                                    switchMap ( ( result: any ) => {
                                      if ( result && result.errorOccured )
                                      {
                                        return this.store.dispatch( new Core.CancelPayment( ) )
                                          .pipe(
                                            map ( ( ) => {
                                              let cancelPayment =
                                                this.store.selectSnapshot< CoreStateModel >( CoreState ).cancelPaymentResult;
                                              console.log( 'cancelPayment', cancelPayment );
                                              return cancelPayment;
                                            } ),
                                            switchMap ( ( paymentResult: any ) => {
                                              return of ( true );
                                              // skip cancel print
                                              if ( paymentResult && 'ZVT' === paymentResult.paymentType &&
                                                   paymentResult.data != null && paymentResult.data.printData )
                                              {
                                                return this.store
                                                  .dispatch( new Retailer.PrintReceipt( selectedItem, paymentResult ) )
                                                  .pipe( catchError ( ( error ) => {
                                                    console.log( 'printer error', error );
                                                    // TODO: Warn about Printer
                                                    // Issue. But for now we want
                                                    // to continue
                                                    return of ( true );
                                                  } ) );
                                              }
                                              else
                                              {
                                                return of ( true );
                                              }
                                            } ),
                                            switchMap ( ( ) => {
                                              let coreStateModel = this.store.selectSnapshot< CoreStateModel >( CoreState );
                                              let cardNumber     = coreStateModel.config.isOfficeButler
                                                                   ? coreStateModel.activeUser.userData.authenticationId
                                                                   : null;

                                              return this.productService.createErrorSale(
                                                ctx.getState( ).selectedItem,
                                                'Ausgabefehler - Storniert (' + result.error.errorNumber + ')',
                                                coreStateModel.config.isOfficeButler,
                                                cardNumber );
                                            } ),

                                            switchMap ( ( ) => {
                                              this.store.dispatch( new Retailer.Reset( ) );
                                              return throwError ( result.error );
                                            } ) );
                                      }
                                      else
                                      {
                                        return of ( result );
                                      }
                                    } ) ) ),
        switchMap ( ( ) => {
          if ( selectedItem.product.productType == 2 )
          {
            return this.productService.takePickup( ctx.getState( ).selectedPickup.pickupCode )
              .pipe( catchError ( ( error ) => { return of ( true ); } ) );
          }
          else
          {
            return this.productService.reduceQuantity( selectedItem.slots [ 0 ] )
              .pipe( catchError ( ( error ) => { return of ( true ); } ) );
          }
        } ),
        switchMap ( ( ) => {
          console.log( 'checkLocker' );
          if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.isOfficeButler &&
               this.store.selectSnapshot< CoreStateModel >( CoreState ).config.controlSoftwareType == 'mqtt' )
          {
            return this.store.dispatch( new Locker.CheckLockerDoor( selectedItem.slots [ 0 ].slotIndex ) );
          }
          else
          {
            return of ( true );
          }
        } ),
        switchMap ( ( ) => this.store.dispatch( new Core.FinishPayment( selectedItem.product.grossPrice ) ) ),
        switchMap ( ( ) => this.store.dispatch( new Navigate ( [ '/retailer', 'finish' ] ) ) ),
        map ( ( ) => {
          let paymentResult = this.store.selectSnapshot< CoreStateModel >( CoreState ).finishPaymentResult;
          if ( paymentResult && 'TIM' === paymentResult.paymentType && paymentResult.data != null )
          {
            return paymentResult;
          }
          else if ( paymentResult && 'ZVT' === paymentResult.paymentType && paymentResult.data != null &&
                    paymentResult.data.printData )
          {
            return paymentResult;
          }
          else
          {
            console.log( 'activePayment' );

            return this.store.selectSnapshot< CoreStateModel >( CoreState ).activePayment;
          }
        } ),
        switchMap (
          ( activePayment ) =>
            this.store.dispatch( new Retailer.PrintReceipt( selectedItem, activePayment ) ).pipe( catchError ( ( error ) => {
              console.log( 'printer error', error );
              // TODO: Warn about Printer Issue. But
              // for now we want to continue
              return of ( true );
            } ) ) ),
        switchMap ( ( ) => {
          let coreStateModel = this.store.selectSnapshot< CoreStateModel >( CoreState );
          let cardNumber = coreStateModel.config.isOfficeButler ? coreStateModel.activeUser.userData.authenticationId : null;

          return this.productService.createSale( selectedItem, coreStateModel.config.isOfficeButler, cardNumber )
            .pipe( catchError ( ( error ) => {
              // TODO: Warn about Sale not created.
              console.log( 'error ', error );
              return of ( true );
            } ) );
        } ),
        switchMap ( ( ) => this.store.dispatch( new Retailer.Reset( ) ) ) );
  }

  @Action( Retailer.BuyCart ) buyCart( ctx: StateContext< RetailerStateModel >, action: Retailer.BuyCart )
  {
    if ( !ctx.getState( ).shoppingCart )
    {
      return throwError (
        new MachineError ( RetailerError.PRODUCT_NOT_EXIST, ModuleType.RETAILER, 'Produkt existiert nicht', false ) );
    }

    let totalForPay = Number (
      ctx.getState( ).shoppingCart.map( ( item ) => item.product.grossPrice ).reduce( ( a, b ) => a + b, 0 ).toFixed( 2 ) );

    let vendingType = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.vendingType;

    if ( vendingType == VendingTypes.STANDARD &&
         this.store.selectSnapshot< CoreStateModel >( CoreState ).config.controlSoftwareType == 'mqtt' )
    {
      console.log( ctx.getState( ).shoppingCart );

      return this.buyCartArticlesMQTT( ctx, totalForPay, ctx.getState( ).shoppingCart );
    }
    else
    {
      let articles: any [] = [];

      ctx.getState( ).shoppingCart.forEach( ( cartItem ) => {
        const index = articles.findIndex( ( object ) => { return object.articleId === cartItem.product.externalId; } );

        if ( index == -1 )
        {
          articles.push( {
            articleId : cartItem.product.externalId,
            product : cartItem.product,
            quantity : 1,
          } );
        }
        else
        {
          articles [ index ].quantity += 1;
        }
      } );

      console.log( 'articles' );
      console.log( articles );

      if ( vendingType == VendingTypes.WWKS2 )
      {
        return this.buyCartArticlesWWKS( ctx, totalForPay, articles );
      }
      else if ( vendingType == VendingTypes.NO_RELEASE )
      {
        return this.buyCartArticlesNoRelease( ctx, totalForPay );
      }
      else if ( vendingType == VendingTypes.PREORDER )
      {
        return this.buyCartArticlesPreorder( ctx, totalForPay, articles, action );
      }
    }
  }

  private buyCartArticlesPreorder( ctx: StateContext< RetailerStateModel >,
                                   totalForPay: number,
                                   articles: any [],
                                   action: Retailer.BuyCart )
  {
    ctx.patchState( {
      active : true,
    } );

    if ( action.preorderType == PreorderType.HOME || action.preorderType == PreorderType.RECEIPT )
    {
      this.store.dispatch( new Navigate ( [ '/retailer', 'finish' ] ) );

      return this.store.dispatch( new Retailer.PrintReceiptCartPreorder( articles, totalForPay ) )
        .pipe( catchError ( ( error ) => {
                 // TODO: Warn about Printer Issue. But for now we want to
                 // continue
                 return of ( true );
               } ),
               switchMap ( ( ) => this.store.dispatch( new Retailer.Reset( ) ) ) );
    }
    else
    {
      this.store.dispatch( new Navigate ( [ '/retailer', 'payment' ] ) );

      return of ( 'dummy' ).pipe(
        delay ( 5000 ),
        switchMap ( ( ) => { return this.store.dispatch( new Navigate ( [ '/retailer', 'finish' ] ) ); } ),
        switchMap ( ( ) => {
          return this.store.dispatch( new Retailer.PrintReceiptCartPreorder( articles, totalForPay ) )
            .pipe( catchError ( ( error ) => {
                     // TODO: Warn about Printer Issue. But for now we want to
                     // continue
                     return of ( true );
                   } ),
                   switchMap ( ( ) => this.store.dispatch( new Retailer.Reset( ) ) ) );
        } ) );
    }
  }

  private buyCartArticlesWWKS( ctx: StateContext< RetailerStateModel >, totalForPay: number, articles: any [] )
  {
    ctx.patchState( {
      active : true,
    } );

    console.log( totalForPay );
    console.log( articles );
    // Run payment
    this.store.dispatch( new Navigate ( [ '/retailer', 'payment' ] ) );
    let productsTaken: boolean = false;

    return this.controlService.healthCheck( ).pipe(
      catchError ( ( error ) => { return throwError ( error ); } ),
      switchMap ( ( ) => this.store.dispatch( new Core.StartPayment( totalForPay ) ).pipe( catchError ( ( error ) => {
        ctx.patchState( {
          active : false,
          shoppingCart : [],
        } );
        return throwError ( error );
      } ) ) ),
      switchMap ( ( ) => this.store.dispatch( new Retailer.OutputWWKSArticlesSimple( articles, 'standard' ) )
                           .pipe( catchError ( ( error: MachineError ) => {
                             ctx.patchState( {
                               active : false,
                             } );

                             this.store.dispatch( new Core.CancelPayment( ) );
                             // TODO
                             this.productService
                               .createCartErrorSale( ctx.getState( ).shoppingCart,
                                                     'Ausgabefehler - Storniert (Robot timout)' )
                               .subscribe( );
                             this.store.dispatch( new Retailer.Reset( ) );

                             return throwError ( error );
                           } ) ) ),
      switchMap ( ( ) => {
        let cart = JSON.parse( JSON.stringify( ctx.getState( ).shoppingCart ) );

        this.markReleasedProductsWWKS2( ctx, cart );
        console.log( 'cart Total', cart );

        ctx.patchState( {
          shoppingCart : cart,
        } );

        totalForPay = Number ( ctx.getState( )
                                 .shoppingCart.filter( ( item ) => item.isReleased )
                                 .map( ( item ) => item.product.grossPrice )
                                 .reduce( ( a, b ) => a + b, 0 )
                                 .toFixed( 2 ) );

        console.log( 'totalpay', totalForPay );
        return of ( true );
      } ),
      switchMap ( ( ) => {
        if ( ctx.getState( ).selectedPickup != null && ctx.getState( ).selectedPickup.product.productType == 2 )
        {
          return this.productService.takePickup( ctx.getState( ).selectedPickup.pickupCode )
            .pipe( catchError ( ( error ) => { return of ( true ); } ) );
        }
        else
        {
          return of ( true );
        }
      } ),
      switchMap ( ( ) => this.store.dispatch( new Core.FinishPayment( totalForPay ) ) ),
      map ( ( ) => {
        let paymentResult = this.store.selectSnapshot< CoreStateModel >( CoreState ).finishPaymentResult;
        console.log( 'Payment Result:', paymentResult );
        if ( paymentResult && 'TIM' === paymentResult.paymentType && paymentResult.data != null )
        {
          return paymentResult;
        }
        else if ( paymentResult && 'ZVT' === paymentResult.paymentType && paymentResult.data != null &&
                  paymentResult.data.printData )
        {
          return paymentResult;
        }
        else
        {
          let firstPaymentResult = this.store.selectSnapshot< CoreStateModel >( CoreState ).activePayment;
          console.log( 'First payment result' + firstPaymentResult );

          return firstPaymentResult;
        }
      } ),
      // TODO
      switchMap ( ( activePayment: PaymentResult ) =>
                    this.store.dispatch( new Retailer.PrintReceiptCart( activePayment ) ).pipe( catchError ( ( error ) => {
                      // TODO: Warn about Printer Issue. But for now we want to
                      // continue
                      console.log( 'error print' + error );
                      console.log( 'error print' + activePayment.data );
                      return of ( true );
                    } ) ) ),
      switchMap ( ( ) => {
        let cart: any [];
        cart = ctx.getState( ).shoppingCart;

        return this.productService.createCartSale( cart, totalForPay, '' ).pipe( catchError ( ( error ) => {
          console.log( 'sale failed to create' + error );
          this.controlService.log( 'ERROR', 'Failed to create sale for cart' + cart + error );
          return of ( true );
        } ) );
      } ),
      switchMap ( ( ) => {
        this.store.dispatch( new Navigate ( [ '/retailer', 'retrieve' ] ) );
        return this.controlService.terminalCheckHatch( ).pipe( catchError ( ( error ) => { return of ( true ); } ) );
      } ),
      switchMap ( ( ) => {
        this.store.dispatch( new Navigate ( [ '/retailer', 'finish-confirm', 'wwks', 'true' ] ) );
        let waitConfirmationObservable = new Observable ( ( observer: Observer< object >) => {
          this.wwksService.purchaseFinishedCB = ( ) => {
            console.log( 'purchase finished' );

            observer.next( { } );
            observer.complete( );
          };
        } );

        return waitConfirmationObservable;
      } ),
      switchMap ( ( ) => this.store.dispatch( new Retailer.Reset( ) ) ) );
  }
  private buyCartArticlesNoRelease( ctx: StateContext< RetailerStateModel >, totalForPay: number )
  {
    ctx.patchState( {
      active : true,
    } );

    console.log( totalForPay );
    // Run payment
    this.store.dispatch( new Navigate ( [ '/retailer', 'payment' ] ) );
    let productsTaken: boolean = false;

    return this.controlService.coffeeBlockPayment( ).pipe(
      catchError ( ( error ) => {
        let machineError = new MachineError ( 999, ModuleType.RETAILER, 'ERROR.PAYMENT_ALREADY_IN_USE', false );

        return throwError ( machineError );
      } ),
      switchMap ( ( ) => this.store.dispatch( new Core.StartPayment( totalForPay ) ).pipe( catchError ( ( error ) => {
        ctx.patchState( {
          active : false,
          shoppingCart : [],
        } );
        this.controlService.coffeeEnablePayment( ).pipe( catchError ( ( error ) => { return throwError ( error ); } ) );
        return throwError ( error );
      } ) ) ),
      map ( ( ) => {
        let firstPaymentResult = this.store.selectSnapshot< CoreStateModel >( CoreState ).activePayment;
        console.log( 'First payment result' + firstPaymentResult );
        return firstPaymentResult;
      } ),
      // TODO
      switchMap ( ( activePayment: PaymentResult ) =>
                    this.store.dispatch( new Retailer.PrintReceiptCart( activePayment ) ).pipe( catchError ( ( error ) => {
                      // TODO: Warn about Printer Issue. But for now we want to
                      // continue
                      console.log( 'error print' + error );
                      console.log( 'error print' + activePayment.data );
                      return of ( true );
                    } ) ) ),
      switchMap ( ( ) => {
        let cart: any [];
        cart = ctx.getState( ).shoppingCart;

        return this.productService.createCartSale( cart, totalForPay, '' ).pipe( catchError ( ( error ) => {
          console.log( 'sale failed to create' + error );
          this.controlService.log( 'ERROR', 'Failed to create sale for cart' + cart + error );
          return this.controlService.coffeeEnablePayment( ).pipe(
            catchError ( ( error ) => { return throwError ( error ); } ) );
        } ) );
      } ),
      switchMap ( ( ) => this.store.dispatch( new Navigate ( [ '/retailer', 'finish' ] ) ) ),
      switchMap ( ( ) => {
        return this.controlService.coffeeEnablePayment( ).pipe(
          catchError ( ( error ) => { return throwError ( error ); } ) );
      } ),
      switchMap ( ( ) => this.store.dispatch( new Retailer.Reset( ) ) ) );
  }

  private buyCartArticlesMQTT( ctx: StateContext< RetailerStateModel >, totalForPay: number, products: any [] )
  {
    ctx.patchState( {
      active : true,
    } );

    console.log( totalForPay );
    console.log( products );
    // Run payment
    this.store.dispatch( new Navigate ( [ '/retailer', 'payment' ] ) );

    let outtakeError = '';

    return this.store.dispatch( new Core.StartPayment( totalForPay ) )
      .pipe(
        catchError ( ( error ) => {
          ctx.patchState( {
            active : false,
            shoppingCart : [],
          } );
          return throwError ( error );
        } ),
        switchMap ( ( ) => this.store.dispatch( new Retailer.OutputProductsMqtt( products ) )
                             .pipe( catchError ( ( error: MachineError ) => {
                               console.log( 'error', error );
                               ctx.patchState( {
                                 active : false,
                               } );

                               this.store.dispatch( new Core.CancelPayment( ) );
                               // TODO
                               this.productService
                                 .createCartErrorSale( ctx.getState( ).shoppingCart,
                                                       'Ausgabefehler - Storniert (' + error.errorNumber + ')' )
                                 .subscribe( );
                               this.store.dispatch( new Retailer.Reset( ) );

                               return throwError ( error );
                             } ) ) ),
        switchMap ( ( ) => {
          let cart = JSON.parse( JSON.stringify( ctx.getState( ).shoppingCart ) );

          this.markReleasedProducts( ctx, cart );
          ctx.patchState( {
            shoppingCart : cart,
          } );

          totalForPay = Number ( ctx.getState( )
                                   .shoppingCart.filter( ( item ) => item.isReleased )
                                   .map( ( item ) => item.product.grossPrice )
                                   .reduce( ( a, b ) => a + b, 0 )
                                   .toFixed( 2 ) );

          console.log( 'totalpay', totalForPay );
          return of ( true );
        } ),
        switchMap ( ( ) => {
          for ( let item of ctx.getState( ).shoppingCart )
          {
            if ( item.product.productType == 2 )
            {
              this.productService.takePickup( ctx.getState( ).selectedPickup.pickupCode )
                .pipe( catchError ( ( error ) => { return of ( true ); } ) );
            }
            else if ( item.isReleased )
            {
              console.log( 'reduce Quanttiy', item.slots [ 0 ] );
              this.productService.reduceQuantity( item.slots [ 0 ] )
                .pipe( catchError ( ( error ) => { return of ( true ); } ) )
                .subscribe( ( res ) => console.log( res ), ( error ) => console.log( error ) );
            }
          }
          return of ( true );
        } ),
        switchMap ( ( ) => this.store.dispatch( new Core.FinishPayment( totalForPay ) ) ),
        map ( ( ) => {
          let paymentResult = this.store.selectSnapshot< CoreStateModel >( CoreState ).finishPaymentResult;
          if ( paymentResult && 'TIM' === paymentResult.paymentType && paymentResult.data != null )
          {
            return paymentResult;
          }
          else if ( paymentResult && 'ZVT' === paymentResult.paymentType && paymentResult.data != null &&
                    paymentResult.data.printData )
          {
            return paymentResult;
          }
          else
          {
            console.log( 'activePayment' );
            return this.store.selectSnapshot< CoreStateModel >( CoreState ).activePayment;
          }
        } ),
        switchMap ( ( activePayment ) => {
          let print = ctx.getState( ).printReceipt;
          if ( print )
          {
            return this.store.dispatch( new Retailer.PrintReceiptCart( activePayment ) ).pipe( catchError ( ( error ) => {
              console.log( 'error print' + error );
              console.log( 'error print' + activePayment.data );
              return of ( true );
            } ) );
          }
          else
          {
            return of ( true );
          }
        } ),
        switchMap ( ( ) => {
          return this.store.dispatch( new Retailer.MonitorOuttakeMqtt( true ) ).pipe( catchError ( ( error: any ) => {
            console.log( 'error', error );
            outtakeError = error;
            return of ( true );
          } ) );
        } ),
        switchMap ( ( ) => {
          let cart: any [];

          if ( ctx.getState( ).selectedPickup != null && ctx.getState( ).selectedPickup.product.productType == 2 )
          {
            cart = ctx.getState( ).selectedPickup.pickupProducts;
          }
          else
          {
            cart = ctx.getState( ).shoppingCart;
          }
          let failedItems = [];
          if ( ctx.getState( ).releasedProducts.size > 0 )
          {
            failedItems = cart.filter( ( item ) => !item.isReleased );
            cart        = cart.filter( ( item ) => item.isReleased );
          }
          let note = '';
          if ( failedItems.length > 0 )
          {
            note = 'Error with products: ' + failedItems.map( ( item ) => item.product.name ).toString( ) + outtakeError;
          }

          return this.productService.createCartSale( cart, totalForPay, note ).pipe( catchError ( ( error ) => {
            console.log( 'create sale error', error );
            return of ( true );
          } ) );
        } ),
        switchMap ( ( ) => {
          this.store.dispatch( new Navigate ( [ '/retailer', 'finish-confirm', 'mqtt', 'true' ] ) );
          let waitConfirmationObservable = new Observable ( ( observer: Observer< object >) => {
            this.wwksService.purchaseFinishedCB = ( ) => {
              console.log( 'pf cb' );

              observer.next( { } );
              observer.complete( );
            };
          } );

          return waitConfirmationObservable;
        } ),
        switchMap ( ( ) => this.store.dispatch( new Retailer.Reset( ) ) ) );
  }

  private markReleasedProducts( ctx: StateContext< RetailerStateModel >, cart: any )
  {
    let releasedProducts = ctx.getState( ).releasedProducts;
    console.log( 'size of released products:', releasedProducts.size );
    console.log( 'released products', JSON.stringify( releasedProducts ) );

    releasedProducts.forEach(
      ( device_data: Map< number, any [] >,
        type: string ) => { device_data.forEach( ( slots: any [], device_nr: number ) => {
        for ( let releasedSlot of slots )
        {
          for ( let item of cart )
          {
            if ( releasedSlot.product_id == item.product.id && releasedSlot.state == 'success' && !item.isReleased )
            {
              item.isReleased = true;
              // TODO add check of containerType
              let usedSlot    = item.slots.find( ( slot ) => slot.containerType == type && slot.containerCode == device_nr &&
                                                          slot.slotIndex == releasedSlot.slot_nr );
              item.slots      = [ usedSlot ];
              break;
            }
          }
        }
      } ) } );
  }

  private markReleasedProductsWWKS2( ctx: StateContext< RetailerStateModel >, cart: any )
  {
    let releasedProducts = ctx.getState( ).releasedProducts;
    console.log( 'released', releasedProducts );
    releasedProducts.forEach(
      ( device_data: Map< number, any [] >, type: string ) => { device_data.forEach( ( value: any, key: number ) => {
        for ( let i = 0; i < value; i++ )
        {
          for ( let item of cart )
          {
            if ( key == item.product.externalId && !item.isReleased )
            {
              item.isReleased = true;
              break;
            }
          }
        }
      } ) } );
  }

  @Action( Retailer.CheckPickupCode )
  checkPickupCode( ctx: StateContext< RetailerStateModel >, action: Retailer.CheckPickupCode )
  {
    let vendingType = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.vendingType;

    if ( vendingType == VendingTypes.WWKS2 )
    {
      return this.checkPickupCodeWwks2( ctx, action.code );
    }
    else if ( vendingType == VendingTypes.PREORDER )
    {
      return this.checkPickupCodeStandard( ctx, action.code );
    }
    else
    {
      return this.checkPickupCodeStandard( ctx, action.code );
    }
  }

  checkPickupCodeStandard( ctx: StateContext< RetailerStateModel >, actionCode: any )
  {
    return this.productService.getPickup( actionCode ).pipe( switchMap ( ( pickup ) => {
      if ( !pickup )
      {
        console.log( 'no no no' );
        return throwError (
          new MachineError ( RetailerError.PRODUCT_NOT_EXIST, ModuleType.RETAILER, 'Abhol existiert nicht', false ) );
      }
      else
      {
        ctx.patchState( {
          selectedPickup : pickup,
        } );

        return this.store.dispatch( new Navigate ( [ '/retailer', 'pickup-detail' ] ) );
      }
    } ) );
  }

  checkPickupCodeWwks2( ctx: StateContext< RetailerStateModel >, actionCode: any )
  {
    let pickupData: any;
    return this.productService.getPickupWithItems( actionCode )
      .pipe(
        switchMap ( ( pickupWithPickupProducts ) => {
          if ( !pickupWithPickupProducts )
          {
            return throwError (
              new MachineError ( RetailerError.PRODUCT_NOT_EXIST, ModuleType.RETAILER, 'Abhol existiert nicht', false ) );
          }
          pickupData = pickupWithPickupProducts;
          // let pickup: any = pickupWithPickupProducts.pickup;
          // pickup["pickupProducts"] =
          // pickupWithPickupProducts.pickupProducts;
          console.log( 'data0', pickupWithPickupProducts );
          return of ( true );
        } ),
        switchMap ( ( ) => {
          console.log( 'data1', pickupData );
          let articles: any [] = [];
          pickupData.pickupProducts.forEach( ( pickupProduct ) => {
            articles.push( {
              articleId : pickupProduct.product [ 'external_id' ],
            } );
          } );
          return this.wwksService.getAsrProductsCriteria( {
            articles : articles,
            type : 'pickup',
          } );
        } ),
        switchMap ( ( value ) => {
          let asrProducts;
          console.log( value );
          asrProducts = value;
          console.log( 'wwks result', asrProducts );
          for ( let pickupProduct of pickupData.pickupProducts )
          {
            if ( !asrProducts [ pickupProduct.product [ 'external_id' ] ] ||
                 asrProducts [ pickupProduct.product [ 'external_id' ] ].quantity < pickupProduct.quantity )
            {
              console.log( 'erroerr' );
              return throwError ( new MachineError ( RetailerError.NOT_ENOUGH_PRODUCTS,
                                                     ModuleType.RETAILER,
                                                     'Eine Abholung ist noch nicht möglich. Bitte versuch es später',
                                                     false ) );
            }
          }
          return of ( true );
        } ),
        switchMap ( ( ) => {
          ctx.patchState( {
            selectedPickup : pickupData,
          } );
          return this.store.dispatch( new Navigate ( [ '/retailer', 'pickup-detail' ] ) );
        } ),
        catchError ( ( error ) => { return throwError ( error ); } ) );
  }

  @Action( Retailer.PickupProduct ) pickupProduct( ctx: StateContext< RetailerStateModel >, action: Retailer.PickupProduct )
  {
    ctx.patchState( {
      active : true,
    } );

    let selectedPickup = ctx.getState( ).selectedPickup;

    if ( !selectedPickup )
    {
      return throwError (
        new MachineError ( RetailerError.PRODUCT_NOT_EXIST, ModuleType.RETAILER, 'Produkt existiert nicht', false ) );
    }

    let vendingType = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.vendingType;

    if ( vendingType == VendingTypes.WWKS2 )
    {
      return this.pickupProductWwks2( ctx, selectedPickup );
    }
    if ( vendingType == VendingTypes.PREORDER )
    {
      return this.pickupProductWwks2( ctx, selectedPickup );
    }
    else
    {
      return this.pickupProductStandard( ctx, selectedPickup );
    }
  }

  pickupProductStandard( ctx: StateContext< RetailerStateModel >, selectedPickup: any )
  {
    if ( selectedPickup.product.grossPrice === 0 )
    {
      return this.store
        .dispatch(
          new Retailer.ReleaseProductNew( selectedPickup.slot, selectedPickup.product, selectedPickup.container, false ) )
        .pipe( switchMap ( ( ) => this.productService.takePickup( selectedPickup.pickupCode )
                                    .pipe( catchError ( ( error ) => { return of ( true ); } ) ) ),
               switchMap ( ( ) => this.store.dispatch( new Navigate ( [ '/retailer', 'finish' ] ) ) ),
               switchMap ( ( ) => this.store.dispatch( new Retailer.Reset( ) ) ),
               catchError ( ( error: MachineError ) => {
                 ctx.patchState( {
                   active : false,
                 } );

                 this.store.dispatch( new Retailer.Reset( ) );
                 return throwError ( error );
               } ) );
    }
    else
    {
      ctx.patchState( {
        selectedItem : {
                        product : selectedPickup.product,
                        slots : [ selectedPickup.slot ],
                        wwksArticle : null,
                        container : selectedPickup.container,
                        },
      } );

      return this.store.dispatch( new Retailer.BuyProduct( selectedPickup.product.id ) );
    }
  }

  pickupProductWwks2( ctx: StateContext< RetailerStateModel >, selectedPickup: any )
  {
    let articles: any []       = [];
    let productsTaken: boolean = false;

    selectedPickup.pickupProducts.forEach( ( cartItem ) => {
      articles.push( {
        articleId : cartItem.product [ 'external_id' ],
        quantity : cartItem.quantity,
      } );
    } );

    ctx.patchState( {
      active : true,
    } );
    let totalForPay = selectedPickup.product.grossPrice;
    console.log( selectedPickup.product.grossPrice );
    console.log( articles );
    // Run payment
    this.store.dispatch( new Navigate ( [ '/retailer', 'payment' ] ) );

    return this.controlService.healthCheck( ).pipe(
      catchError ( ( error ) => { return throwError ( error ); } ),
      switchMap ( ( ) => {
        if ( totalForPay === 0 )
        {
          return of ( true );
        }
        this.store.dispatch( new Core.StartPayment( totalForPay ) ).pipe( catchError ( ( error ) => {
          ctx.patchState( {
            active : false,
            shoppingCart : [],
          } );
          return throwError ( error );
        } ) );
      } ),
      switchMap ( ( ) => this.store.dispatch( new Retailer.OutputWWKSArticles( articles, 'pickup' ) )
                           .pipe( catchError ( ( error: MachineError ) => {
                             ctx.patchState( {
                               active : false,
                             } );
                             this.controlService.terminalAbort( ).subscribe( );
                             if ( totalForPay === 0 )
                             {
                               return throwError ( error );
                             }
                             this.store.dispatch( new Core.CancelPayment( ) );
                             // TODO
                             this.store.dispatch( new Retailer.Reset( ) );
                             return throwError ( error );
                           } ) ) ),
      switchMap ( ( ) => {
        return this.productService.takePickup( ctx.getState( ).selectedPickup.pickupCode )
          .pipe( catchError ( ( error ) => { return of ( true ); } ) );
      } ),
      switchMap ( ( ) => {
        if ( totalForPay === 0 )
        {
          return of ( true );
        }
        this.store.dispatch( new Core.FinishPayment( totalForPay ) );
      } ),
      map ( ( ) => {
        if ( totalForPay === 0 )
        {
          return of ( true );
        }
        let paymentResult = this.store.selectSnapshot< CoreStateModel >( CoreState ).finishPaymentResult;
        if ( paymentResult && 'TIM' === paymentResult.paymentType && paymentResult.data != null )
        {
          return paymentResult;
        }
        else if ( paymentResult && 'ZVT' === paymentResult.paymentType && paymentResult.data != null &&
                  paymentResult.data.printData )
        {
          return paymentResult;
        }
        else
        {
          let firstPaymentResult = this.store.selectSnapshot< CoreStateModel >( CoreState ).activePayment;
          console.log( 'First payment result' + firstPaymentResult );

          return firstPaymentResult;
        }
      } ),
      switchMap ( ( activePayment: PaymentResult ) => {
        if ( totalForPay === 0 )
        {
          return of ( true );
        }
        this.store.dispatch( new Retailer.PrintReceiptCart( activePayment ) ).pipe( catchError ( ( error ) => {
          // TODO: Warn about Printer Issue. But for now we want to
          // continue
          console.log( 'error print' + error );
          console.log( 'error print' + activePayment.data );
          return of ( true );
        } ) );
      } ),
      switchMap ( ( ) => {
        this.store.dispatch( new Navigate ( [ '/retailer', 'retrieve' ] ) );
        return this.controlService.terminalCheckOuttake( );
      } ),
      map ( ( res ) => {
        catchError ( ( error ) => throwError ( error ) );
        console.log( 'result of checkHatch', res );
        if ( res.msg == '0' )
        {
          productsTaken = true;
        }
        return of ( true );
      } ),
      switchMap ( ( ) => {
        console.log( 'productsTaken', productsTaken );
        this.store.dispatch( new Navigate ( [ '/retailer', 'finish-confirm', 'wwks', productsTaken ] ) );
        let waitConfirmationObservable = new Observable ( ( observer: Observer< object >) => {
          this.wwksService.purchaseFinishedCB = ( ) => {
            console.log( 'pf cb' );

            observer.next( { } );
            observer.complete( );
          };
        } );
        return waitConfirmationObservable;
      } ),
      switchMap ( ( ) => {
        this.store.dispatch( new Retailer.Reset( ) );

        return this.controlService.terminalFinishRelease( ).pipe( catchError ( ( error ) => { return of ( true ); } ) );
      } ),
      catchError ( ( error: MachineError ) => {
        ctx.patchState( {
          active : false,
        } );
        this.store.dispatch( new Retailer.Reset( ) );
        return throwError ( error );
      } ) );
  }

  @Action( Retailer.ReleaseProductNew )
  releaseProductNew( ctx: StateContext< RetailerStateModel >, action: Retailer.ReleaseProductNew )
  {
    this.store.dispatch( new Navigate ( [ '/retailer', 'release' ] ) );

    let container: string;
    if ( action.container && action.container.containerType == 'SHELF' )
    {
      container = 'locker';
    }
    else
    {
      container = 'vending';
    }
    if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.isOfficeButler &&
         this.store.selectSnapshot< CoreStateModel >( CoreState ).config.controlSoftwareType == 'mqtt' )
    {
      console.log( 'locker open comand' );
      return this.mqttHelperService.openLocker( action.slot ).pipe( tap ( ( res ) => {
        if ( !res.success )
        {
          throw new MachineError ( res.controlError, ModuleType.RETAILER, 'Locker open failed:' + res.message, false );
        }
      } ) );
    }
    else
    {
      return this.controlService.releaseContent( action.slot.slotIndex, container )
        .pipe( tap ( ( res ) => {
                 if ( res.controlError != ControlCode.SUCCESS )
                 {
                   throw new MachineError (
                     res.controlError,
                     ModuleType.RETAILER,
                     action.customerCharged ? 'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE' : 'ERROR.RELEASE_CONTENT_FAILED',
                     false );
                 }
               } ),
               switchMap ( ( ) => {
                 if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.showProductInfoScreen &&
                      action.product.productType !== 1 )
                 {
                   this.store.dispatch( new Navigate ( [ '/retailer', 'info' ] ) );
                   return of ( 'dummy' ).pipe( delay ( 5000 ) );
                 }
                 else
                 {
                   return of ( true );
                 }
               } ),
               switchMap ( ( ) => {
                 if ( container == 'vending' )
                 {
                   this.store.dispatch( new Navigate ( [ '/retailer', 'retrieve' ] ) );
                   return this.controlService.openHatch( );
                 }
                 else
                 {
                   return of ( { controlError : ControlCode.SUCCESS } );
                 }
               } ),
               tap ( ( res ) => {
                 if ( res.controlError != ControlCode.SUCCESS )
                 {
                   throw new MachineError (
                     res.controlError,
                     ModuleType.RETAILER,
                     action.customerCharged ? 'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE' : 'ERROR.RELEASE_CONTENT_FAILED',
                     false );
                 }
               } ) );
    }
  }

  @Action( Retailer.OutputWWKSArticles )
  outputWWKSArticles( ctx: StateContext< RetailerStateModel >, action: Retailer.OutputWWKSArticles )
  {
    this.store.dispatch( new Navigate ( [ '/retailer', 'release' ] ) );
    console.log( 'output started' );

    return this.controlService.terminalReleaseContent( action.articles.length )
      .pipe( tap ( ( res ) => {
               console.log( res );
               if ( res.controlError != ControlCode.SUCCESS )
               {
                 console.log( 'error' );
                 throw new MachineError ( res.controlError,
                                          ModuleType.RETAILER,
                                          'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE',
                                          false );
               }
             } ),
             switchMap ( ( ) => {
               console.log( 'outputProducts' );
               return this.wwksService.outputProducts( {
                 articles : action.articles,
                 type : action.actionType,
               } );
             } ),
             tap ( ( res ) => {
               if ( res.task.status != 'Completed' && ( !res.task.articleList || res.task.articleList.length == 0 ) )
               {
                 this.controlService.terminalAbort( );
                 throw new MachineError ( res.controlError,
                                          ModuleType.RETAILER,
                                          'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE',
                                          false );
               }
               else
               {
                 let successProducts = new Map ( );
                 let deviceProducts  = new Map ( );
                 for ( let article of res.task.articleList )
                 {
                   deviceProducts.set( article.id, article.packList.length );
                 }
                 successProducts.set( 'wwks',
                                      deviceProducts );  // ! Due to new structure for Tobacco
                 console.log( 'success Products', successProducts );
                 ctx.patchState( {
                   releasedProducts : successProducts,
                 } );
               }
             } ),
             switchMap ( ( ) => {
               console.log( 'outputProducts' );
               return timer ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.wwks2ReleaseTimeout );
             } ),
             switchMap ( ( ) => {
               this.store.dispatch( new Navigate ( [ '/retailer', 'retrieve' ] ) );
               return this.controlService.terminalStartOuttake( );
             } ),
             tap ( ( res ) => {
               if ( res.controlError != ControlCode.SUCCESS )
               {
                 throw new MachineError ( res.controlError,
                                          ModuleType.RETAILER,
                                          'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE',
                                          false );
               }
             } ) );
  }

  @Action( Retailer.OutputWWKSArticlesSimple )
  outputWWKSArticlesSimple( ctx: StateContext< RetailerStateModel >, action: Retailer.OutputWWKSArticlesSimple )
  {
    this.store.dispatch( new Navigate ( [ '/retailer', 'release' ] ) );

    return this.wwksService
      .outputProducts( {
        articles : action.articles,
        type : action.actionType,
      } )
      .pipe(
        tap ( ( res ) => {
          if ( res.task.status != 'Completed' && ( !res.task.articleList || res.task.articleList.length == 0 ) )
          {
            throw new MachineError ( res.controlError,
                                     ModuleType.RETAILER,
                                     'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE',
                                     false );
          }
          else
          {
            let successProducts = new Map ( );
            let deviceProducts  = new Map ( );
            for ( let article of res.task.articleList )
            {
              deviceProducts.set( article.id, article.packList.length );
            }
            successProducts.set( 'wwks', deviceProducts );
            console.log( 'success Products', successProducts );
            ctx.patchState( {
              releasedProducts : successProducts,
            } );
          }
        } ),
        switchMap (
          ( ) => { return timer ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.wwks2ReleaseTimeout ); } ),
        switchMap ( ( ) => { return this.controlService.terminalOpenHatch( ); } ),
        tap ( ( res ) => {
          if ( res.controlError != ControlCode.SUCCESS )
          {
            throw new MachineError ( res.controlError,
                                     ModuleType.RETAILER,
                                     'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE',
                                     false );
          }
        } ) );
  }

  @Action( Retailer.OutputProductsMqtt )
  outputProductsMqtt( ctx: StateContext< RetailerStateModel >, action: Retailer.OutputProductsMqtt )
  {
    this.store.dispatch( new Navigate ( [ '/retailer', 'release' ] ) );

    // TODO timeout after output
    let productIds: number [] = [];

    action.products.forEach( ( cartItem ) => { productIds.push( cartItem.product.id ); } );
    this.controlService.log( 'INFO', 'purchase started for products: ' + productIds ).subscribe( );

    return this.mqttHelperService.releaseProduct( productIds )
      .pipe( tap ( ( res ) => {
               this.controlService.log( 'INFO', 'mqtt release content result: ' + JSON.stringify( res ) ).subscribe( );
               console.log( res );
               if ( !res.success )
               {
                 this.controlService.log( 'ERROR', 'release product failed:' + res ).subscribe( );
                 console.log( 'Produktausgabe fehlgeschlagen.' );
                 throw new MachineError ( 13, ModuleType.RETAILER, 'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE', false );
               }
               let successProducts = new Map ( );
               let tobaccoProducts = new Map ( );
               let retailProducts  = new Map ( );
               if ( res.message )
               {
                 let tobaccoDevices: any [] = res.message [ 'releases' ].tobacco24;
                 if ( tobaccoDevices )
                 {
                   for ( let device of tobaccoDevices )
                   {
                     let succ: any [] = device.slot_data.filter( ( s ) => s.state == 'success' );
                     if ( succ && succ.length > 0 )
                       tobaccoProducts.set( device.device_nr, succ );
                   }
                   if ( tobaccoProducts.size > 0 )  // only add if there are products
                   {
                     successProducts.set( 'Tobacco', tobaccoProducts );
                   }
                 }

                 let retailDevices: any [] = res.message [ 'releases' ].retail24;
                 if ( retailDevices )
                 {
                   for ( let device of retailDevices )
                   {
                     let succ: any [] = device.slot_data.filter( ( s ) => s.state == 'success' );
                     if ( succ && succ.length > 0 )
                       retailProducts.set( device.device_nr, succ );
                   }
                   if ( retailProducts.size > 0 )  // only add if there are products
                   {
                     successProducts.set( 'Vending', retailProducts );
                   }
                 }

                 console.log( 'successProducts', successProducts );
                 if ( successProducts && successProducts.size > 0 )
                 {
                   ctx.patchState( {
                     releasedProducts : successProducts,
                   } );
                 }
                 else
                 {
                   this.controlService.log( 'ERROR', 'no product released stop purchase' ).subscribe( );
                   console.log( 'Produktausgabe fehlgeschlagen.' );
                   throw new MachineError ( res.controlError,
                                            ModuleType.RETAILER,
                                            'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE',
                                            false );
                 }
               }
             } ),
             tap ( ( res ) => {
               if ( !res.success )
               {
                 this.controlService.log( 'ERROR', 'release product failed:' + res ).subscribe( );
                 throw new MachineError ( res.controlError,
                                          ModuleType.RETAILER,
                                          'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE',
                                          false );
               }
             } ) );
  }

  @Action( Retailer.MonitorOuttakeMqtt )
  monitorOuttakeMqtt( ctx: StateContext< RetailerStateModel >, action: Retailer.MonitorOuttakeMqtt )
  {
    if ( action.autoLock )
      this.store.dispatch( new Navigate ( [ '/retailer', 'retrieve' ] ) );

    return this.mqttHelperService.startAndMonitorOuttake( action.autoLock ).pipe( tap ( ( res ) => {
      if ( !res.success )
      {
        this.controlService.log( 'ERROR', 'release product failed:' + res ).subscribe( );
        throw new MachineError ( res.controlError, ModuleType.RETAILER, 'ERROR.RELEASE_CONTENT_FAILED_NO_CHARGE', false );
      }
    } ) );
  }

  delayedRetry( delayMs: number, maxRetry = 3 )
  {
    let retries = maxRetry;

    return ( src: Observable< any >) => src.pipe(
             retryWhen ( ( errors: Observable< any >) => errors.pipe(
                           delay ( delayMs ),
                           mergeMap ( ( error ) => retries-- > 0 ? of ( error ) : throwError ( 'exceded max retry' ) ) ) ) );
  }

  @Action( Retailer.Reset ) reset( ctx: StateContext< RetailerStateModel >)
  {
    ctx.patchState( {
      selectedCategory : null,
      selectedItem : null,
      selectedPickup : null,
      search : '',
      active : false,
      shoppingCart : [],
      releasedProducts : null,
      printReceipt : false,
    } );

    return of ( true );
  }

  @Action( Retailer.ActionSelection ) actionSelection( ctx: StateContext< RetailerStateModel >)
  {
    ctx.patchState( {
      categories : [],
      selectedCategory : null,
      selectedItem : null,
      items : null,
      active : false,
      search : '',
    } );

    this.store.dispatch( new Navigate ( [ '/core/selection-full' ] ) );
  }

  @Action( Retailer.Search ) search( ctx: StateContext< RetailerStateModel >, action: Retailer.Search )
  {
    ctx.patchState( {
      search : action.search,
    } );
  }

  @Action( Retailer.SetPrintReceipt ) setPrintReceipt( ctx: StateContext< RetailerStateModel >)
  {
    ctx.patchState( {
      printReceipt : true,
    } );
  }

  @Action( Retailer.PrintReceipt ) printReceipt( ctx: StateContext< RetailerStateModel >, action: Retailer.PrintReceipt )
  {
    if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.isOfficeButler )
    {
      of ( true );
    }
    else
    {
      let printHeader: string = '';
      let printFooter: string = '';

      if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config != null )
      {
        printHeader = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printHeader;
        printFooter = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printFooter;
      }

      if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printerType === 'v2' )
      {
        return this.store.dispatch( new Core.PrintV2(
          this.printService.generateReceiptV2( action.item, action.paymentResult, printHeader, printFooter ) ) );
      }
      else
      {
        return this.store.dispatch( new Core.Print(
          this.printService.generateReceiptFromItem( action.item, action.paymentResult, printHeader, printFooter ) ) );
      }
    }
  }

  @Action( Retailer.PrintExternalPaymentReceipt )
  printExternalPaymentReceipt( ctx: StateContext< RetailerStateModel >, action: Retailer.PrintExternalPaymentReceipt )
  {
    let printHeader: string = '';
    let printFooter: string = '';

    if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config != null )
    {
      printHeader = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printHeader;
      printFooter = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printFooter;
    }

    if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printerType === 'v2' )
    {
      return of ( true );
      // return this.store.dispatch(
      //   new Core.PrintV2(
      //     this.printService.generateReceiptV2(
      //       action.item,
      //       action.paymentResult,
      //       printHeader,
      //       printFooter
      //     )
      //   )
      // );
    }
    else
    {
      return this.store.dispatch( new Core.Print( this.printService.generateReceiptFromExternalPayment( action.data,
                                                                                                        action.paymentResult,
                                                                                                        printHeader,
                                                                                                        printFooter ) ) );
    }
  }

  @Action( Retailer.PrintReceiptCart )
  printReceiptCart( ctx: StateContext< RetailerStateModel >, action: Retailer.PrintReceiptCart )
  {
    let printHeader: string = '';
    let printFooter: string = '';

    if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config != null )
    {
      printHeader = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printHeader;
      printFooter = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printFooter;
    }
    if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printerType === 'v2' )
    {
      return this.store.dispatch( new Core.PrintV2(
        this.printService
          .generateReceiptV2FromCart( ctx.getState( ).shoppingCart, action.paymentResult, printHeader, printFooter ) ) );
    }
    else
    {
      return this.store.dispatch( new Core.Print( this.printService.generateReceiptFromCart( ctx.getState( ).shoppingCart,
                                                                                             action.paymentResult,
                                                                                             printHeader,
                                                                                             printFooter ) ) );
    }
  }

  @Action( Retailer.PrintReceiptCartPreorder )
  printReceiptCartPreorder( ctx: StateContext< RetailerStateModel >, action: Retailer.PrintReceiptCartPreorder )
  {
    let printHeader: string = '';
    let printFooter: string = '';

    if ( this.store.selectSnapshot< CoreStateModel >( CoreState ).config != null )
    {
      printHeader = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printHeader;
      printFooter = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.printFooter;
    }

    return this.store.dispatch( new Core.PrintPreorder(
      this.printService.generateReceiptFromCartPreorder( action.articles, action.totalPrice, printHeader, printFooter ) ) );
  }

  parseHealthCheckError( status: number ): string
  {
    let result = '';

    if ( this.isBitSet( status, 0 ) )
      result += this.translate.instant( 'ERROR.HEALTH_CHECK_MAINTENANCE' );
    if ( this.isBitSet( status, 1 ) )
      result += this.translate.instant( 'ERROR.HEALTH_CHECK_HATCH_OPEN' );
    if ( this.isBitSet( status, 2 ) )
      result += this.translate.instant( 'ERROR.HEALTH_CHECK_TRAY_NOT_EMPTY' );
    if ( this.isBitSet( status, 3 ) )
      result += this.translate.instant( 'ERROR.HEALTH_CHECK_OBSTACLE' );
    if ( this.isBitSet( status, 4 ) )
      result += this.translate.instant( 'ERROR.HEALTH_CHECK_LIGHT_BOARD' );

    return result;
  }

  isBitSet( num, bit ) { return ( num >> bit ) % 2 != 0; }
}
