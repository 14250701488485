import {
  Component,
  Output,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Input,
} from '@angular/core';
import { Store } from '@ngxs/store';
import Keyboard from 'simple-keyboard';
import {
  CoreState,
  CoreStateModel,
  GlobalEnums,
} from '../../../state/core.state';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'password-dialog',
  templateUrl: './password-dialog.component.html',
})
export class PasswordDialog implements OnChanges, AfterViewInit {
  public keyboard: Keyboard;
  theme: string;
  public value: string = '';
  public password = '';
  actualPassword: string = GlobalEnums.setupPassword;
  @ViewChild('passwordInput')
  public passwordInput: ElementRef;
  error = false;
  increaseFontSize: boolean = false;
  @Input() loginSubmitted: boolean = false;
  @Output() lockedStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() errorStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() valueStatus: EventEmitter<boolean> = new EventEmitter();

  constructor(private store: Store) {
    this.theme = 'sanusx';

    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config != null &&
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .setupPassword != ''
    ) {
      this.actualPassword =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.setupPassword;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.loginSubmitted) {
      this.login();
    }
  }

  ngAfterViewInit() {
    this.keyboardInit();
  }

  handleBackspaceForRealKeyboard() {
    this.onChange(this.value.slice(0, -1));
  }

  keyboardInit() {
    setTimeout(() => this.passwordInput.nativeElement.focus(), 1000);

    this.keyboard = new Keyboard('.simple-keyboard-setup', {
      onChange: (input) => this.onChange(input),
      onKeyPress: (button) => {
        if (button === '{bksp}') this.handleBackspaceForRealKeyboard();
      },
    });

    this.keyboard.setOptions({
      physicalKeyboardHighlightPress: true,
      physicalKeyboardHighlight: true,
      layout: {
        default: [
          '1 2 3 4 5 6 7 8 9 0 ß',
          'Q W E R T Z U I O P Ü',
          'A S D F G H J K L Ö Ä',
          'Y X C V B N M {space} {bksp}',
        ],
        shift: [
          '1 2 3 4 5 6 7 8 9 0 ß',
          'Q W E R T Z U I O P Ü',
          'A S D F G H J K L Ö Ä',
          'Y X C V B N M {space} {bksp}',
        ],
      },
      display: {
        '{bksp}': '    ',
        '{space}': 'SPACE',
        ß: '   ',
      },
      theme: 'new-theme-keyboard',
      buttonTheme: [
        {
          class: 'backspace',
          buttons: '{bksp}',
        },
        {
          class: 'ss',
          buttons: 'ß',
        },
        {
          class: 'space-new',
          buttons: '{space}',
        },
      ],
    });
  }

  onBlurKeyboard(event) {
    this.passwordInput.nativeElement.focus();
  }

  onChange(input: string) {
    this.value = input;
    this.password = input;
    if (input.length >= 5) {
      this.valueStatus.emit(true);
    }
    if (input.length >= 1) {
      this.increaseFontSize = true;
    } else if (input.length <= 1) {
      this.increaseFontSize = false;
      this.keyboard.clearInput();
    }
  }

  login() {
    if (this.password == this.actualPassword) {
      this.lockedStatus.emit(false);
      this.store.dispatch(new Navigate(['/core/setup', 'setup-menu']));
    } else {
      this.error = true;
    }
  }

  errorClose() {
    this.errorStatus.emit(true);
    this.error = false;
    this.loginSubmitted = false;
    this.value = '';
    this.password = '';
    this.valueStatus.emit(false);
    this.passwordInput.nativeElement.focus();
    this.keyboard.clearInput();
  }

  cancel() {
    this.store.dispatch(new Navigate(['/']));
  }
}
