<ng-container *ngIf="screenSaver">
  <!-- <div
            class="w-full h-auto flex flex-col items-center justify-center relative"
          > -->
  <div
    class="w-96 h-32 px-4 text-4xl space-x-4 text-center relative py-2 border-5 text-nex-gray-dark border-nex-gray-dark/10 bg-nex-gray-dark/20 flex flex-row items-center justify-center rounded-2xl transition-all"
    (click)="toggleLanguageList()"
  >
    <div class="w-24 h-24 justify-center items-center flex">
      <svg
        width="48"
        height="48"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM9.71002 19.6674C8.74743 17.6259 8.15732 15.3742 8.02731 13H4.06189C4.458 16.1765 6.71639 18.7747 9.71002 19.6674ZM10.0307 13C10.1811 15.4388 10.8778 17.7297 12 19.752C13.1222 17.7297 13.8189 15.4388 13.9693 13H10.0307ZM19.9381 13H15.9727C15.8427 15.3742 15.2526 17.6259 14.29 19.6674C17.2836 18.7747 19.542 16.1765 19.9381 13ZM4.06189 11H8.02731C8.15732 8.62577 8.74743 6.37407 9.71002 4.33256C6.71639 5.22533 4.458 7.8235 4.06189 11ZM10.0307 11H13.9693C13.8189 8.56122 13.1222 6.27025 12 4.24799C10.8778 6.27025 10.1811 8.56122 10.0307 11ZM14.29 4.33256C15.2526 6.37407 15.8427 8.62577 15.9727 11H19.9381C19.542 7.8235 17.2836 5.22533 14.29 4.33256Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div class="flex flex-1">
      {{ "LANGUAGE.SELECT_LANGUAGE" | translate }}
    </div>
  </div>
  <div
    class="absolute bottom-full mb-2 rounded-2xl drop-shadow-md w-full h-auto flex flex-col space-y-4 items-center justify-center bg-white px-4 py-4"
    *ngIf="showLangList"
  >
    <div
      *ngFor="let flag of availableLanguages"
      class="w-full h-33 px-6 py-4 rounded-2xl transition-all flex flex-row items-center justify-center"
      [ngClass]="
        currentLang === flag.id
          ? 'border-5 border-nex-gray bg-nex-gray-light'
          : 'border-8 border-transparent'
      "
      (click)="setPublicLanguage(); toggleLanguageList()"
    >
      <span class="w-1/3">
        <img [src]="flag.icon" [alt]="flag.id" [title]="flag.name" />
      </span>
      <span class="w-auto flex-1 flex text-2xl px-4 text-next-gray text-left">
        {{ flag.name }}
      </span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!screenSaver">
  <button
    (click)="toggleLanguageList()"
    class="w-32 h-13 z-50 bg-nex-gray-light space-x-2 px-2 flex-row items-center justify-start flex text-nex-gray-dark active:bg-nex-gray-light/70 rounded-xl border-2 border-transparent active:border-nex-gray-light active:shadow-inner-light"
  >
    <div class="w-13 h-13 justify-center items-center flex">
      <svg
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM9.71002 19.6674C8.74743 17.6259 8.15732 15.3742 8.02731 13H4.06189C4.458 16.1765 6.71639 18.7747 9.71002 19.6674ZM10.0307 13C10.1811 15.4388 10.8778 17.7297 12 19.752C13.1222 17.7297 13.8189 15.4388 13.9693 13H10.0307ZM19.9381 13H15.9727C15.8427 15.3742 15.2526 17.6259 14.29 19.6674C17.2836 18.7747 19.542 16.1765 19.9381 13ZM4.06189 11H8.02731C8.15732 8.62577 8.74743 6.37407 9.71002 4.33256C6.71639 5.22533 4.458 7.8235 4.06189 11ZM10.0307 11H13.9693C13.8189 8.56122 13.1222 6.27025 12 4.24799C10.8778 6.27025 10.1811 8.56122 10.0307 11ZM14.29 4.33256C15.2526 6.37407 15.8427 8.62577 15.9727 11H19.9381C19.542 7.8235 17.2836 5.22533 14.29 4.33256Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div
      class="text-xl w-full h-13 uppercase justify-center items-center flex text-left"
    >
      {{ "LANGUAGE.SELECT_LANGUAGE_SHORT" | translate }}
    </div>
  </button>
  <div
    class="absolute z-40 top-full -mt-2 rounded-b-2xl drop-shadow-md w-32 h-auto flex flex-col space-y-4 items-center justify-center bg-nex-gray pt-6 pb-4 py-4"
    *ngIf="showLangList"
  >
    <div
      *ngFor="let flag of availableLanguages"
      class="w-28 h-28 px-4 py-2 rounded-xl transition-all flex flex-row items-center justify-center"
      [ngClass]="
        currentLang === flag.id
          ? 'border-2 border-nex-gray bg-nex-gray-light'
          : 'border-2 border-transparent'
      "
      (click)="setPublicLanguage(); toggleLanguageList()"
    >
      <img [src]="flag.icon" [alt]="flag.id" [title]="flag.name" />
    </div>
  </div>
</ng-container>
