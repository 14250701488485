<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen bg-white"
  >
    <app-header [showSearch]="false" class="w-full"></app-header>

    <!-- product and menu part -->
    <div class="flex flex-col items-center justify-start flex-grow w-full">
      <!-- product  -->
      <div
        class="flex flex-col items-start justify-start flex-grow w-full h-full px-16 py-8"
      >
        <!-- product item content -->
        <div
          class="flex flex-col items-start justify-between w-full h-full pt-2 bg-white border-2 border-primaryx"
        >
          <!-- image part -->
          <div
            class="relative flex flex-col items-start justify-start w-full h-auto"
          >
            <div class="relative w-full h-auto">
              <div
                class="relative flex flex-col items-center justify-center w-full overflow-hidden text-center bg-white h-97 max-h-97 z-15"
              >
                <img
                  style="max-width: 98%; max-height: 98%"
                  [src]="
                    selectedImage != undefined
                      ? (selectedImage | safe)
                      : '/assets/placeholder.png'
                  "
                />
              </div>
              <!-- <div
                class="absolute top-0 left-0 z-30 flex flex-row items-center justify-between w-full h-full "
                *ngIf="imageObject.length > 1"
              >
                <button
                  class=" btn btn-mini-action btn-mini-action--photo btn-mini-action--photo-prev group"
                  (click)="prevImageClick()"
                  *ngIf="imgStep >= 1"
                >
                  <span class="-rotate-90 icon group-active:bg-active">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.086"
                      height="27.993"
                      viewBox="0 0 28.086 27.993"
                    >
                      <path
                        id="arrow_up"
                        data-name="arrow_up"
                        d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                        transform="translate(0 27.993)"
                        fill="currentColor"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>

                <button
                  class="ml-auto btn btn-mini-action btn-mini-action--photo btn-mini-action--photo-next group"
                  (click)="nextImageClick()"
                  *ngIf="imageObject.length > imgStep + 1"
                >
                  <span class="rotate-90 icon group-active:bg-active">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.086"
                      height="27.993"
                      viewBox="0 0 28.086 27.993"
                    >
                      <path
                        id="arrow_up"
                        data-name="arrow_up"
                        d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                        transform="translate(0 27.993)"
                        fill="currentColor"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
              </div> -->
            </div>

            <div
              class="absolute top-0 z-20 w-24 h-full overflow-hidden overflow-y-scroll bg-white right-4 hidevScrollbar sanusx-slide"
              *ngIf="imageObject.length > 1"
            >
              <ng-image-slider
                (imageClick)="onImageClicked($event)"
                [images]="imageObject"
                [imageSize]="imageSize"
                [imagePopup]="false"
                [lazyLoading]="true"
                [manageImageRatio]="true"
                [showArrow]="false"
                [infinite]="false"
                [autoSlide]="{ interval: 2, stopOnHover: false }"
                #nav
              ></ng-image-slider>
            </div>
          </div>
          <!-- END: image part -->

          <!-- content col -->
          <div
            class="flex flex-col items-start justify-start flex-1 w-auto h-full mx-7"
          >
            <div class="hidden w-full h-auto text-2xl font-normal text-gray">
              Brand name
            </div>
            <div
              class="flex flex-row items-center justify-start w-full h-auto my-2 text-2xl text-gray"
            >
              <span
                *ngFor="let category of (item$ | async).product.categories"
                class="pr-2"
                >{{ category.name }}</span
              >
            </div>
            <div
              class="w-full h-auto text-4xl font-semibold text-primaryx-dark"
            >
              {{ (item$ | async).product.name }}
            </div>

            <!-- <div class="w-full h-auto text-3xl font-normal text-gray">290 g</div>
        -->

            <div
              class="w-full pr-8 overflow-hidden overflow-y-scroll text-2xl font-normal text-left h-96 overflow-ellipsis hideScrollbar mt-7 text-gray"
              [innerHtml]="(item$ | async).product.description"
            ></div>
          </div>
          <!-- END: content col -->

          <!-- product footer -->

          <div
            class="flex flex-row items-end justify-between w-full flex-end bg-gray-ultra"
          >
            <!-- back btn -->
            <div class="w-1/3 h-auto pr-4 mr-auto">
              <button
                class="btn-sanusx btn-back bg-tertiary active:bg-active"
                (click)="back()"
              >
                <span class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.125"
                    height="26.25"
                    viewBox="0 0 13.125 26.25"
                  >
                    <path
                      id="Path_13621"
                      data-name="Path 13621"
                      d="M0-2.687,13.125,10.438v-26.25Z"
                      transform="translate(0 15.812)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <span class="label text-primaryx">
                  {{ "COMMON.BACK" | translate }}
                </span>
              </button>
            </div>
            <!-- END back btn -->

            <!-- quantity increase -->
            <!-- END quantity increase -->

            <!-- action col -->
            <div class="flex flex-col items-start justify-start w-1/3 h-full">
              <!-- action box -->

              <div
                class="flex flex-col items-start justify-between w-full h-auto"
              >
                <div
                  *ngIf="showProductPrices"
                  class="flex flex-row items-center justify-end w-full h-auto py-3 pl-2 pr-4"
                >
                  <div
                    class="hidden w-full h-auto text-xl font-normal text-gray"
                  >
                    0,97 € je 100 g
                  </div>
                  <span
                    class="flex flex-col flex-grow w-full ml-auto text-4xl font-bold text-right text-primaryx-dark"
                  >
                    € {{ (item$ | async).product.grossPrice.toFixed(2) }}
                  </span>
                </div>

                <button
                  class="w-full mx-auto btn-sanusx btn-action-buy bg-tertiary active:bg-active group border-2 border-primaryx"
                  (click)="onBuyProduct()"
                >
                  <span *ngIf="showProductPrices" class="label text-primaryx">
                    {{ "COMMON.BUY_ONLY" | translate }}
                  </span>
                  <span *ngIf="!showProductPrices" class="label text-primaryx">
                    {{ "COMMON.BTN_PICKUP" | translate }}
                  </span>
                </button>
              </div>

              <!-- END: action box -->
            </div>
            <!-- END: action col -->
          </div>

          <!-- END product footer -->
        </div>
        <!-- END product item content -->
      </div>
      <!-- END product  -->
    </div>
    <!-- END product and menu part -->
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'default'">
  <div
    class="flex flex-row items-start justify-between w-full h-screen bg-primaryx-bg"
  >
    <!-- first column -->
    <div
      class="
      bg-white
      h-screen
      shadow-md
      py-10
      px-10
      relative
      z-50
      flex flex-col items-start justify-start
      {{ !menuPanelOpened ? 'w-main-normal' : 'w-main-short' }}
      {{
        rightHand
          ? 'rounded-r-4xl shadow-md order-1'
          : 'rounded-l-4xl shadow-md-inv order-2'
      }}
    "
      *ngIf="item$ | async"
    >
      <ng-container *ngIf="!infoPanelOpened">
        <!-- image part -->
        <div class="flex flex-col items-start justify-start w-full h-auto">
          <div class="relative w-full h-auto">
            <div
              class="relative flex flex-col items-center justify-center w-full overflow-hidden text-center bg-white h-[45rem] max-h-[45rem] border-gray-light border-5 rounded-2xl z-15"
            >
              <img
                style="max-width: 100%; max-height: 100%; object-fit: contain"
                [src]="
                  selectedImage != undefined
                    ? (selectedImage | safe)
                    : '/assets/placeholder.png'
                "
              />

              <!-- badge new -->
              <span
                class="pi-details-img-badge pi-details-new"
                *ngIf="
                  (item$ | async).product.tags !== null &&
                  (item$ | async).product.tags.toUpperCase().includes('NEU')
                "
              >
                {{ "COMMON.NEW" | translate }}
              </span>
              <!-- end badge new -->

              <!-- badge action -->
              <span
                class="pi-details-img-badge pi-details-action"
                *ngIf="
                  (item$ | async).product.tags !== null &&
                  (item$ | async).product.tags.toUpperCase().includes('AKTION')
                "
              >
                <!-- circular text -->
                <svg
                  class="circle-text"
                  viewBox="0 0 100 100"
                  width="100%"
                  height="100%"
                >
                  <defs>
                    <path
                      id="circle"
                      d="
        M 50, 50
        m -25, 0
        a 25,25 0 1,1 50,0
        a 25,25 0 1,1 -50,0"
                    />
                  </defs>
                  <text font-size="32">
                    <textPath xlink:href="#circle">
                      {{ "COMMON.ACTION" | translate }}
                    </textPath>
                  </text>
                </svg>
                <!-- END circular text -->

                <!-- fire icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="32"
                  viewBox="0 0 28 32"
                  class="action-icon"
                >
                  <path
                    d="M21.79,3.106a2,2,0,0,0-2.9-.77A5.993,5.993,0,0,0,17.246,4.1a13.965,13.965,0,0,0-1.14,2.232,36.178,36.178,0,0,0-1.68,5.468,62.727,62.727,0,0,0-1.226,7.16,5.28,5.28,0,0,1-1.89-2.134,12.329,12.329,0,0,1-.8-5.308A2,2,0,0,0,7.1,10.1a14,14,0,1,0,19.8,0,28.831,28.831,0,0,1-2.7-2.934,24.626,24.626,0,0,1-2.414-4.06ZM21.24,28.24A6,6,0,0,1,11,24a10.835,10.835,0,0,0,5,1c0-2,1-8,2.5-9a11.557,11.557,0,0,0,2.742,3.758,6,6,0,0,1,0,8.484Z"
                    transform="translate(-3 -2)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
                <!-- END fire icon -->
              </span>
              <!-- end badge action -->

              <!-- badge discount -->
              <ng-container
                *ngIf="(item$ | async).product.priceType === 'SALE'"
              >
                <span
                  class="pi-details-img-badge pi-details-discount bg-primaryx"
                >
                  -{{ percentDifference.toFixed(2).slice(0, -3) }}%
                </span>
              </ng-container>
              <!-- end badge discount -->
            </div>
            <div
              class="absolute top-0 left-0 z-30 flex flex-row items-center justify-between w-full h-full"
              *ngIf="imageObject.length > 1"
            >
              <button
                class="btn btn-mini-action btn-mini-action--photo btn-mini-action--photo-prev group"
                (click)="prevImageClick()"
                *ngIf="imgStep >= 1"
              >
                <span class="-rotate-90 icon group-active:bg-active">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28.086"
                    height="27.993"
                    viewBox="0 0 28.086 27.993"
                  >
                    <path
                      id="arrow_up"
                      data-name="arrow_up"
                      d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                      transform="translate(0 27.993)"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>

              <button
                class="ml-auto btn btn-mini-action btn-mini-action--photo btn-mini-action--photo-next group"
                (click)="nextImageClick()"
                *ngIf="imageObject.length > imgStep + 1"
              >
                <span class="rotate-90 icon group-active:bg-active">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28.086"
                    height="27.993"
                    viewBox="0 0 28.086 27.993"
                  >
                    <path
                      id="arrow_up"
                      data-name="arrow_up"
                      d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                      transform="translate(0 27.993)"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>

          <div
            class="relative w-full h-auto py-2 my-3 overflow-hidden bg-white max-h-27x min-h-27x default-slide"
            *ngIf="imageObject.length > 1"
          >
            <div
              class="absolute top-0 left-0 z-10 block w-20 h-full bg-gradient-to-r from-white to-transparent"
            ></div>

            <ng-image-slider
              (imageClick)="onImageClicked($event)"
              [images]="imageObject"
              [imageSize]="imageSize"
              [imagePopup]="false"
              [lazyLoading]="true"
              [manageImageRatio]="true"
              [showArrow]="false"
              [infinite]="false"
              [autoSlide]="{ interval: 2, stopOnHover: false }"
              #nav
            ></ng-image-slider>

            <div
              class="absolute top-0 right-0 z-10 block w-20 h-full bg-gradient-to-r from-transparent to-white"
            ></div>
          </div>
        </div>
        <!-- END: image part -->

        <!-- item.product.tags !== null &&
                    item.product.tags.toUpperCase().includes('NEU') -->

        <!-- main part -->
        <div
          class="
          w-full
          h-auto
          flex flex-row
          items-start
          justify-bewtween
          {{ imageObject.length <= 1 ? 'mt-3' : '' }}
        "
        >
          <!-- content col -->
          <div
            class="
            w-auto
            h-auto
            flex flex-1 flex-col
            items-start
            justify-start
            {{ rightHand ? 'order-1 pr-4 ' : 'order-2 pl-4 ' }}
          "
          >
            <div class="w-full h-auto text-3xl font-normal text-gray">
              <ng-container
                *ngIf="
                  !(item$ | async).product.brandName ||
                  !(item$ | async).product.brandName
                "
              >
                <span class="invisible">---</span>
              </ng-container>
              {{ (item$ | async).product.brandName }}
            </div>
            <!-- <div
              class="grid w-full h-auto grid-flow-row grid-cols-2 my-2 gap-x-2 gap-y-2 text-gray"
              [ngClass]="fullHdHalf ? 'text-3xl ' : 'text-2xl '"
            >
              <span
                *ngFor="let category of (item$ | async).product.categories"
                class="pr-2"
                >{{ category.name }}</span
              >
            </div> -->
            <div
              class="w-full h-auto font-semibold text-primaryx-dark"
              [ngClass]="fullHdHalf ? 'text-7xl ' : 'text-5xl '"
            >
              {{ (item$ | async).product.name }}
            </div>

            <div class="w-full h-auto text-3xl font-normal text-gray">
              <ng-container
                *ngIf="
                  !(item$ | async).product.packagingQuantity ||
                  !(item$ | async).product.packagingQuantity.toString().trim()
                "
              >
                <span class="invisible">---</span>
              </ng-container>
              {{ (item$ | async).product.packagingQuantity }}
            </div>
            <div class="w-full h-auto text-2xl font-normal text-gray">
              <ng-container
                *ngIf="
                  !(item$ | async).product.pricePerItem ||
                  !(item$ | async).product.pricePerItem.toString().trim()
                "
              >
                <span class="invisible">---</span>
              </ng-container>

              {{ (item$ | async).product.pricePerItem }}
            </div>

            <div
              class="w-full font-normal text-left mt-7 text-gray"
              [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
              [innerHtml]="
                (item$ | async).product.shortDescription
                  ? (item$ | async).product.shortDescription
                  : ((item$ | async).product.description
                    | removeFormatting
                    | slice : 0 : 150)
              "
            >
              <!-- {{
                (item$ | async).product.description?.length > 450
                  ? ((item$ | async).product.description | slice: 0:450) + ".."
                  : (item$ | async).product.description
              }} -->
            </div>
            <!-- <span
              class="inline-flex pr-4 font-bold uppercase focus:outline-none text-active"
              *ngIf="(item$ | async).product.description?.length > 150"
              (click)="productInfo()"
            >
              [ {{ "RETAILER_PRODUCT.PRODUCT_INFO" | translate }} ]
            </span> -->
          </div>
          <!-- END: content col -->

          <!-- action col -->
          <div
            class="w-74 h-full flex flex-col items-start space-y-3 justify-start {{
              rightHand ? 'order-2' : 'order-1'
            }}"
          >
            <!-- action box -->

            <div
              class="bg-gray-ultra/20 flex flex-col items-start space-y-3 justify-between w-full h-auto bg-primaryx-bgx rounded-4xl"
            >
              <!-- price box -->

              <!-- regular price type -->
              <ng-container *ngIf="!priceType || priceType === 'REGULAR'">
                <div
                  class="flex flex-row items-center w-full h-auto py-5 rounded-full px-7 bg-gray-ultra"
                >
                  <span
                    class="flex flex-col items-center justify-center text-primaryx"
                    [ngClass]="fullHdHalf && 'h-12'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="47"
                      height="47"
                      viewBox="0 0 47 47"
                    >
                      <path
                        id="icon_tag"
                        data-name="icon_tag"
                        d="M48.14,23.423a2.938,2.938,0,0,1,0,4.154L27.577,48.14a2.938,2.938,0,0,1-4.154,0L2.861,27.577A2.929,2.929,0,0,1,2,25.5V10.813A8.813,8.813,0,0,1,10.813,2H25.5a2.929,2.929,0,0,1,2.077.861ZM10.813,13.75a2.938,2.938,0,1,0-2.938-2.938A2.938,2.938,0,0,0,10.813,13.75Z"
                        transform="translate(-2 -2)"
                        fill="currentColor"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <span
                    class="flex flex-col flex-grow w-full ml-auto font-bold text-right text-primaryx-dark"
                    [ngClass]="fullHdHalf ? 'text-4xl ' : 'text-3xl '"
                  >
                    € {{ (item$ | async).product.grossPrice.toFixed(2) }}
                  </span>
                </div>
              </ng-container>
              <!-- END regular price type -->

              <!-- sale price type -->
              <ng-container *ngIf="priceType === 'SALE'">
                <!-- <div
                  class="flex flex-row items-center w-full h-auto py-5 rounded-full px-7 bg-gray-ultra"
                >
                  <span
                    class="flex flex-col items-center justify-center text-primaryx"
                    [ngClass]="fullHdHalf && 'h-12'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="47"
                      height="47"
                      viewBox="0 0 47 47"
                    >
                      <path
                        id="icon_tag"
                        data-name="icon_tag"
                        d="M48.14,23.423a2.938,2.938,0,0,1,0,4.154L27.577,48.14a2.938,2.938,0,0,1-4.154,0L2.861,27.577A2.929,2.929,0,0,1,2,25.5V10.813A8.813,8.813,0,0,1,10.813,2H25.5a2.929,2.929,0,0,1,2.077.861ZM10.813,13.75a2.938,2.938,0,1,0-2.938-2.938A2.938,2.938,0,0,0,10.813,13.75Z"
                        transform="translate(-2 -2)"
                        fill="currentColor"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <span
                    class="flex flex-col flex-grow w-full ml-auto font-bold text-right text-primaryx-dark"
                    [ngClass]="fullHdHalf ? 'text-4xl ' : 'text-3xl '"
                  >
                    € {{ (item$ | async).product.grossPrice.toFixed(2) }}
                  </span>
                </div> -->

                <div
                  class="grid w-full grid-cols-2 grid-rows-1 gap-2 text-right h-24 max-h-24 rounded-full"
                >
                  <!-- old price and difference -->
                  <div
                    class="flex flex-col w-full h-full leading-tight justify-center items-end rounded-l-full bg-red-ultra-light ml-auto font-medium text-right text-gray pl-1 pr-2 py-1"
                    [ngClass]="fullHdHalf ? 'text-2xl' : 'text-2xl'"
                  >
                    <span
                      class="line-through decoration-[red] decoration-double"
                    >
                      {{
                        (item$ | async).product.standardGrossPrice.toFixed(2)
                      }}
                      €
                    </span>
                    <span> - {{ priceDifference.toFixed(2) }} € </span>
                  </div>
                  <!-- END old price and difference -->

                  <!-- sale price -->
                  <div
                    class="flex flex-col w-full h-full justify-center items-center rounded-r-full bg-gray-ultra mr-auto font-bold text-center text-red pr-1 pl-2 py-1"
                    [ngClass]="fullHdHalf ? 'text-4xl' : 'text-4xl'"
                  >
                    € {{ (item$ | async).product.grossPrice.toFixed(2) }}
                  </div>
                  <!-- END sale price -->
                </div>
              </ng-container>
              <!-- END sale price type -->

              <!-- END price box -->

              <!-- quantity update -->
              <ng-container *ngIf="useShoppingCart">
                <div
                  class="w-full h-auto py-2 px-2 flex items-center justify-center space-x-2"
                >
                  <!-- btn decrease -->
                  <span class="flex items-center justify-center">
                    <button
                      class="btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                      [disabled]="itemCartQuantity === 1 || statusCartMessage"
                      (pointerup)="decreaseProductQuantity()"
                      [touchClick]
                    >
                      <span class="icon bg-primaryx group-active:bg-active">
                        <ng-container *ngIf="!statusCartMessage">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            class="text-primaryx"
                          >
                            <g
                              id="Group_7167"
                              data-name="Group 7167"
                              transform="translate(-1.778 -1.778)"
                            >
                              <rect
                                id="Rectangle_17007"
                                data-name="Rectangle 17007"
                                width="40"
                                height="40"
                                rx="20"
                                transform="translate(1.778 1.778)"
                                fill="currentColor"
                              />
                              <g
                                id="Group_6960"
                                data-name="Group 6960"
                                transform="translate(9.778 9.778)"
                              >
                                <path
                                  id="Path_13154"
                                  data-name="Path 13154"
                                  d="M0,0H24V24H0Z"
                                  fill="none"
                                />
                                <line
                                  id="Line_4"
                                  data-name="Line 4"
                                  x2="17.695"
                                  transform="translate(3.152 11.739)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="6"
                                />
                              </g>
                            </g>
                          </svg>
                        </ng-container>

                        <ng-container *ngIf="statusCartMessage">
                          <svg
                            class="w-12 h-12 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </ng-container>
                      </span>
                    </button>
                  </span>
                  <!-- end btn decrease -->
                  <span
                    class="flex items-center justify-center h-full px-4 font-bold text-center text-primaryx"
                    [ngClass]="fullHdHalf ? 'text-6xl' : 'text-4xl'"
                  >
                    {{ itemCartQuantity }}
                  </span>
                  <!-- btn increase -->
                  <span class="flex items-center justify-center">
                    <button
                      class="btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                      [disabled]="
                        cartIsFull ||
                        hasNoMoreItemsLeftInStock() ||
                        statusCartMessage
                      "
                      (pointerup)="increaseProductQuantity()"
                      [touchClick]
                    >
                      <span
                        class="rotate-90 icon bg-primaryx group-active:bg-active"
                      >
                        <ng-container *ngIf="!statusCartMessage">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            class="text-primaryx"
                          >
                            <g
                              id="Group_7167"
                              data-name="Group 7167"
                              transform="translate(-1.445 -1.778)"
                            >
                              <rect
                                id="Rectangle_17007"
                                data-name="Rectangle 17007"
                                width="40"
                                height="40"
                                rx="20"
                                transform="translate(1.445 1.778)"
                                fill="currentColor"
                              />
                              <g
                                id="Group_6960"
                                data-name="Group 6960"
                                transform="translate(9.444 9.778)"
                              >
                                <path
                                  id="Path_13154"
                                  data-name="Path 13154"
                                  d="M0,0H24V24H0Z"
                                  fill="none"
                                />
                                <line
                                  id="Line_3"
                                  data-name="Line 3"
                                  y2="17.695"
                                  transform="translate(12.305 3.197)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="6"
                                />
                                <line
                                  id="Line_4"
                                  data-name="Line 4"
                                  x2="17.695"
                                  transform="translate(3.152 11.739)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="6"
                                />
                              </g>
                            </g>
                          </svg>
                        </ng-container>
                        <ng-container *ngIf="statusCartMessage">
                          <svg
                            class="w-12 h-12 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </ng-container>
                      </span>
                    </button>
                  </span>
                  <!-- end btn increase -->
                </div>
              </ng-container>
              <!-- END quantity update -->

              <!-- BUY BUTTON -->
              <button
                class="w-full mx-auto btn btn-action btn-action--buy-big bg-primaryx border-primaryx-30 active:border-active active:bg-active group"
                [ngClass]="fullHdHalf && 'btn-action--middle'"
                [disabled]="
                  statusCartMessage || cartItems.length >= cartQuantityLimit
                "
                style="max-width: 100% !important; width: 100% !important"
                (click)="useShoppingCart ? addProductToCart() : onBuyProduct()"
              >
                <span class="icon">
                  <ng-container *ngIf="!statusCartMessage">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6.75,24a2.3,2.3,0,0,1-1.593-.622,2.043,2.043,0,0,1-.657-1.5,2.19,2.19,0,0,1,2.249-2.112A2.121,2.121,0,1,1,6.75,24Zm12-.006A2.12,2.12,0,1,1,21,21.878,2.189,2.189,0,0,1,18.749,23.994Zm.75-5.646H6.622A3,3,0,0,1,3.85,16.607a2.692,2.692,0,0,1,.65-3.076l1.34-1.259L3.8,4.607c-.006-.019-.012-.04-.016-.059L3.331,2.824H1.5A1.46,1.46,0,0,1,0,1.412,1.46,1.46,0,0,1,1.5,0h3A1.482,1.482,0,0,1,5.955,1.072l.465,1.75H22.5a1.515,1.515,0,0,1,1.276.67,1.33,1.33,0,0,1,.066,1.373l-4.5,8.468A1.506,1.506,0,0,1,18,14.115H8.121l-1.5,1.411H19.5a1.414,1.414,0,1,1,0,2.823ZM10.359,7.927a.751.751,0,1,0,0,1.5H12.75v2.25a.8.8,0,0,0,1.594,0V9.427h2.39a.751.751,0,1,0,0-1.5h-2.39V5.678a.8.8,0,0,0-1.594,0V7.927Z"
                      />
                    </svg>
                  </ng-container>
                  <ng-container *ngIf="statusCartMessage">
                    <svg
                      class="w-12 h-12 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </ng-container>
                </span>
                <span class="label">
                  <ng-container *ngIf="useShoppingCart">
                    {{ "COMMON.ADD_TO_CART" | translate }}
                  </ng-container>
                  <ng-container *ngIf="!useShoppingCart">
                    {{ "COMMON.BUY_ONLY" | translate }}
                  </ng-container>
                </span>
              </button>
              <!-- end BUY BUTTON -->
            </div>

            <!-- END: action box -->

            <div class="w-full flex">
              <!-- product info btn -->
              <button
                class="w-full mx-auto btn btn-action btn-action--info border-primaryx active:border-active group"
                [ngClass]="fullHdHalf && 'btn-action--middle'"
                style="max-width: 100% !important; width: 100% !important"
                (click)="productInfo()"
              >
                <span class="icon bg-primaryx group-active:bg-active">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5.516"
                    height="21.056"
                    viewBox="0 0 5.516 21.056"
                  >
                    <path
                      id="Path_13576"
                      data-name="Path 13576"
                      d="M3.92-16.016a3.016,3.016,0,0,1-2.03-.616,2.237,2.237,0,0,1-.714-1.792,2.2,2.2,0,0,1,.714-1.764A3.016,3.016,0,0,1,3.92-20.8q2.772,0,2.772,2.38a2.237,2.237,0,0,1-.714,1.792A3.062,3.062,0,0,1,3.92-16.016ZM3.92.252a2.34,2.34,0,0,1-1.834-.7,2.916,2.916,0,0,1-.63-2.016v-8.848a2.916,2.916,0,0,1,.63-2.016,2.34,2.34,0,0,1,1.834-.7,2.431,2.431,0,0,1,1.862.7,2.835,2.835,0,0,1,.658,2.016v8.848A2.874,2.874,0,0,1,5.8-.448,2.423,2.423,0,0,1,3.92.252Z"
                      transform="translate(-1.176 20.804)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <span class="label text-primaryx group-active:text-active">{{
                  "RETAILER_PRODUCT.PRODUCT_INFO" | translate
                }}</span>
              </button>
              <!-- END product info btn -->
            </div>
          </div>
          <!-- END: action col -->
        </div>
        <!-- END: main part --> </ng-container
      ><ng-container
        *ngIf="
          !infoPanelOpened && (item$ | async).product.description && fullHd
        "
      >
        <!-- content col -->
        <div
          class="flex flex-col items-start justify-start flex-1 w-full h-flex"
        >
          <div class="w-full h-auto mb-1">
            <div
              class="flex items-center justify-center w-18 h-18 mx-auto text-white rounded-full bg-gray-light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.516"
                height="21.056"
                viewBox="0 0 5.516 21.056"
              >
                <path
                  id="Path_13576"
                  data-name="Path 13576"
                  d="M3.92-16.016a3.016,3.016,0,0,1-2.03-.616,2.237,2.237,0,0,1-.714-1.792,2.2,2.2,0,0,1,.714-1.764A3.016,3.016,0,0,1,3.92-20.8q2.772,0,2.772,2.38a2.237,2.237,0,0,1-.714,1.792A3.062,3.062,0,0,1,3.92-16.016ZM3.92.252a2.34,2.34,0,0,1-1.834-.7,2.916,2.916,0,0,1-.63-2.016v-8.848a2.916,2.916,0,0,1,.63-2.016,2.34,2.34,0,0,1,1.834-.7,2.431,2.431,0,0,1,1.862.7,2.835,2.835,0,0,1,.658,2.016v8.848A2.874,2.874,0,0,1,5.8-.448,2.423,2.423,0,0,1,3.92.252Z"
                  transform="translate(-1.176 20.804)"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <!--
        <div class="w-full h-auto text-3xl font-normal text-gray">290 g</div>
        <div class="w-full h-auto text-2xl font-normal text-gray">
          0,97 € je 100 g
        </div> -->

          <div
            #targetProductInfo1
            class="product-info-content overflow-hidden w-full h-full px-4 mb-5 border-gray-light border-5 rounded-2xl z-15"
            [ngClass]="[
              imageObject.length > 1
                ? ' max-h-[26rem] '
                : 'text-2xl max-h-[36rem] '
            ]"
            [innerHtml]="(item$ | async).product.description | trust : 'html'"
          ></div>

          <div class="w-full h-flex mt-3 flex">
            <!-- product info btn -->
            <button
              class="w-full mx-auto mb-5 btn btn-action btn-action--info border-primaryx active:border-active group"
              [ngClass]="fullHdHalf && 'btn-action--middle'"
              style="max-width: 100% !important; width: 100% !important"
              (click)="productInfo()"
            >
              <span class="icon bg-primaryx group-active:bg-active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.516"
                  height="21.056"
                  viewBox="0 0 5.516 21.056"
                >
                  <path
                    id="Path_13576"
                    data-name="Path 13576"
                    d="M3.92-16.016a3.016,3.016,0,0,1-2.03-.616,2.237,2.237,0,0,1-.714-1.792,2.2,2.2,0,0,1,.714-1.764A3.016,3.016,0,0,1,3.92-20.8q2.772,0,2.772,2.38a2.237,2.237,0,0,1-.714,1.792A3.062,3.062,0,0,1,3.92-16.016ZM3.92.252a2.34,2.34,0,0,1-1.834-.7,2.916,2.916,0,0,1-.63-2.016v-8.848a2.916,2.916,0,0,1,.63-2.016,2.34,2.34,0,0,1,1.834-.7,2.431,2.431,0,0,1,1.862.7,2.835,2.835,0,0,1,.658,2.016v8.848A2.874,2.874,0,0,1,5.8-.448,2.423,2.423,0,0,1,3.92.252Z"
                    transform="translate(-1.176 20.804)"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <span class="label text-primaryx group-active:text-active">{{
                "RETAILER_PRODUCT.PRODUCT_INFO" | translate
              }}</span>
            </button>
            <!-- END product info btn -->
          </div>
        </div>
        <!-- END: content col -->
      </ng-container>
      <!-- END: info panel -->
      <!-- info panel -->
      <ng-container *ngIf="infoPanelOpened">
        <!-- content col -->
        <div
          class="flex flex-col items-start justify-start flex-1 w-full h-full"
        >
          <div class="w-full h-auto mb-10">
            <div
              class="flex items-center justify-center w-18 h-18 mx-auto text-white rounded-full bg-gray-light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.516"
                height="21.056"
                viewBox="0 0 5.516 21.056"
              >
                <path
                  id="Path_13576"
                  data-name="Path 13576"
                  d="M3.92-16.016a3.016,3.016,0,0,1-2.03-.616,2.237,2.237,0,0,1-.714-1.792,2.2,2.2,0,0,1,.714-1.764A3.016,3.016,0,0,1,3.92-20.8q2.772,0,2.772,2.38a2.237,2.237,0,0,1-.714,1.792A3.062,3.062,0,0,1,3.92-16.016ZM3.92.252a2.34,2.34,0,0,1-1.834-.7,2.916,2.916,0,0,1-.63-2.016v-8.848a2.916,2.916,0,0,1,.63-2.016,2.34,2.34,0,0,1,1.834-.7,2.431,2.431,0,0,1,1.862.7,2.835,2.835,0,0,1,.658,2.016v8.848A2.874,2.874,0,0,1,5.8-.448,2.423,2.423,0,0,1,3.92.252Z"
                  transform="translate(-1.176 20.804)"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>

          <div
            class="flex flex-wrap items-center justify-center w-full h-auto text-3xl font-normal text-gray"
          >
            <span class="p-3">
              {{ (item$ | async).product.brandName }}
            </span>
          </div>

          <div
            class="w-full h-auto font-semibold text-center text-primaryx-dark"
            [ngClass]="fullHdHalf ? 'text-7xl ' : 'text-5xl '"
          >
            {{ (item$ | async).product.name }}
          </div>
          <!--
        <div class="w-full h-auto text-3xl font-normal text-gray">290 g</div>
        <div class="w-full h-auto text-2xl font-normal text-gray">
          0,97 € je 100 g
        </div> -->

          <div
            #targetProductInfo
            class="product-info-content w-full h-full px-4 font-normal text-left mt-7 text-gray"
            [ngClass]="[
              fullHdHalf ? 'text-5xl leading-relaxed' : 'text-2xl ',
              (item$ | async).product.description?.length > 800
                ? 'overflow-y-scroll'
                : ''
            ]"
            [innerHtml]="(item$ | async).product.description"
          ></div>

          <!-- vertical scroll buttons -->
          <div
            class="w-full h-24 flex flex-row items-center justify-between pt-4 mt-6 border-t-2 border-t-gray-light-50"
            *ngIf="(item$ | async).product.description?.length > 800"
          >
            <button
              class="self-center mx-auto btn btn-mini-action border-gray-light active:border-active group"
              (click)="scrollDownText()"
            >
              <span class="icon bg-gray group-active:bg-active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.086"
                  height="27.993"
                  viewBox="0 0 28.086 27.993"
                  class="rotate-180"
                >
                  <path
                    id="arrow_up"
                    data-name="arrow_up"
                    d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                    transform="translate(0 27.993)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button>
            <button
              class="self-center mx-auto btn btn-mini-action border-gray-light active:border-active group"
              (click)="scrollUpText()"
            >
              <span class="icon bg-gray group-active:bg-active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.086"
                  height="27.993"
                  viewBox="0 0 28.086 27.993"
                >
                  <path
                    id="arrow_up"
                    data-name="arrow_up"
                    d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                    transform="translate(0 27.993)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button>
          </div>
          <!-- end vertical scroll buttons -->
        </div>
        <!-- END: content col -->
      </ng-container>
      <!-- END: info panel -->
    </div>
    <!-- END: first column -->

    <!-- side column -->
    <app-sidebar
      [backBtnCloseInfoPanel]="infoPanelOpened"
      (infoPanelStatus)="infoPanelHandler($event)"
      [productPage]="true"
      [hideSearchButton]="true"
      [useShoppingCart]="useShoppingCart"
      [statusCartMessage]="statusCartMessage"
      [cartQuantity]="cartItems.length"
      [hideCategories]="true"
      [fullHdHalf]="fullHdHalf"
      [menuPanelOpened]="menuPanelOpened"
      (menuPanelStatus)="menuPanelHandler($event)"
      [searchPanelOpened]="searchPanelOpened"
      (searchPanelStatus)="searchPanelHandler($event)"
      [rightHand]="rightHand"
      (rightHandStatus)="rightHandHandler($event)"
      class="{{ rightHand ? 'order-2 pr-3' : 'order-1 pl-3 ' }}"
      *ngIf="!menuPanelOpened"
    ></app-sidebar>
    <!-- END: side column -->
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen bg-white"
  >
    <!-- central adv banner -->
    <app-nex-central-ad
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    ></app-nex-central-ad>
    <!-- end central adv banner -->

    <!-- nex cetral wrapper -->
    <div class="w-full h-full flex-1 flex flex-row">
      <!-- nex product area -->
      <div
        class="flex-1 h-full bg-nex-white px-6 py-6 space-y-6 flex flex-col items-start justify-between"
      >
        <!-- header area -->
        <div class="w-full h-auto flex flex-row items-center justify-between">
          <!-- logo -->
          <div class="w-1/3">
            <img
              class="h-16 max-h-16 object-contain"
              *ngIf="logo"
              [src]="logo"
            />
          </div>
          <!-- end logo -->

          <!-- active category -->
          <div
            class="w-auto flex flex-1 justify-end items-center text-right text-2xl font-medium text-nex-gray"
          ></div>
          <!-- end active category -->
        </div>
        <!-- end header area -->

        <!-- breadcrumbs area -->
        <div class="w-full h-13 flex flex-row items-center justify-between">
          <div class="w-auto flex-1 flex flex-row items-center justify-start">
            <button
              (click)="back()"
              class="w-13 h-13 relative z-50 bg-nex-gray-light flex-row items-center justify-start flex text-nex-gray-dark active:bg-nex-gray-light/70 rounded-xl border-2 border-transparent active:border-nex-gray-light active:shadow-inner-light"
            >
              <div class="w-full h-13 justify-center items-center flex">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6667 26.6667C26.6667 27.0203 26.5262 27.3594 26.2762 27.6095C26.0261 27.8595 25.687 28 25.3333 28H6.66668C6.31305 28 5.97392 27.8595 5.72387 27.6095C5.47382 27.3594 5.33334 27.0203 5.33334 26.6667V14.6667H1.33334L15.1027 2.14934C15.3482 1.92597 15.6681 1.80219 16 1.80219C16.3319 1.80219 16.6519 1.92597 16.8973 2.14934L30.6667 14.6667H26.6667V26.6667ZM10 17.3333C10 18.9246 10.6322 20.4508 11.7574 21.576C12.8826 22.7012 14.4087 23.3333 16 23.3333C17.5913 23.3333 19.1174 22.7012 20.2426 21.576C21.3679 20.4508 22 18.9246 22 17.3333H19.3333C19.3333 18.2174 18.9822 19.0652 18.357 19.6904C17.7319 20.3155 16.8841 20.6667 16 20.6667C15.116 20.6667 14.2681 20.3155 13.643 19.6904C13.0179 19.0652 12.6667 18.2174 12.6667 17.3333H10Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
            <!-- activeParentCategory -->
            <ng-container *ngIf="activeParentCategory !== null">
              <button
                (click)="onCategorySelected(activeParentCategory.id)"
                class="relative z-40 w-auto min-w-13 h-13 text-2xl pl-6 -ml-4 pr-2 bg-white border-nex-gray-light flex-row items-center justify-start flex text-nex-gray-dark active:bg-nex-gray-light/70 rounded-r-xl border-2 active:border-nex-gray-light active:shadow-inner-light"
              >
                <div class="w-full h-13 justify-center items-center flex">
                  {{ activeParentCategory.name | ellipsis : 64 }}
                </div>
              </button>
            </ng-container>
            <!-- END activeParentCategory -->
            <ng-container *ngIf="(item$ | async).product.categories">
              <button
                (click)="onCategorySelected(productCategories[0].id)"
                class="relative z-30 w-auto min-w-13 h-13 text-2xl pl-6 -ml-4 pr-2 bg-white border-nex-gray-light flex-row items-center justify-start flex text-nex-gray-dark active:bg-nex-gray-light/70 rounded-r-xl border-2 active:border-nex-gray-light active:shadow-inner-light"
              >
                <div class="w-full h-13 justify-center items-center flex">
                  {{ productCategories[0].name | ellipsis : 64 }}
                </div>
              </button>
            </ng-container>
          </div>
          <div
            class="w-auto max-w-72 pl-3 flex flex-row items-center justify-end space-x-3"
          >
            <!-- select language -->
            <ng-container *ngIf="!multiLanguage">
              <app-nex-lang-switcher></app-nex-lang-switcher>
            </ng-container>
            <!-- END select language -->
          </div>
        </div>
        <!-- end breadcrumbs area -->

        <!-- product area -->
        <div class="flex flex-1 flex-col w-full h-full space-y-6">
          <ng-container *ngIf="!infoPanelOpened">
            <!-- product image area -->
            <div
              class="nex-image-area flex flex-col items-start justify-start w-full h-auto"
            >
              <div class="relative w-full h-auto">
                <div
                  class="relative flex flex-col items-center justify-center w-full overflow-hidden text-center bg-white h-97 max-h-97 border-nex-gray-light border-5 rounded-2xl z-15"
                >
                  <img
                    style="max-width: 98%; max-height: 98%"
                    [src]="
                      selectedImage != undefined
                        ? (selectedImage | safe)
                        : '/assets/placeholder.png'
                    "
                  />

                  <!-- badge new -->
                  <span
                    class="pi-details-img-badge pi-details-new"
                    *ngIf="
                      (item$ | async).product.tags !== null &&
                      (item$ | async).product.tags.toUpperCase().includes('NEU')
                    "
                  >
                    {{ "COMMON.NEW" | translate }}
                  </span>
                  <span
                    class="pi-details-img-badge pi-details-promo"
                    *ngIf="
                      (item$ | async).product.tags !== null &&
                      (item$ | async).product.tags
                        .toUpperCase()
                        .includes('PROMO')
                    "
                  >
                    {{ "COMMON.PROMO" | translate }}
                  </span>
                  <!-- end badge new -->

                  <!-- badge action -->
                  <span
                    class="pi-details-img-badge pi-details-action"
                    *ngIf="
                      (item$ | async).product.tags !== null &&
                      (item$ | async).product.tags
                        .toUpperCase()
                        .includes('AKTIONEN')
                    "
                  >
                    <!-- circular text -->
                    <svg
                      class="circle-text"
                      viewBox="0 0 100 100"
                      width="100%"
                      height="100%"
                    >
                      <defs>
                        <path
                          id="circle"
                          d="
        M 50, 50
        m -25, 0
        a 25,25 0 1,1 50,0
        a 25,25 0 1,1 -50,0"
                        />
                      </defs>
                      <text font-size="32">
                        <textPath xlink:href="#circle">
                          {{ "COMMON.ACTION" | translate }}
                        </textPath>
                      </text>
                    </svg>
                    <!-- END circular text -->

                    <!-- fire icon -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="32"
                      viewBox="0 0 28 32"
                      class="action-icon"
                    >
                      <path
                        d="M21.79,3.106a2,2,0,0,0-2.9-.77A5.993,5.993,0,0,0,17.246,4.1a13.965,13.965,0,0,0-1.14,2.232,36.178,36.178,0,0,0-1.68,5.468,62.727,62.727,0,0,0-1.226,7.16,5.28,5.28,0,0,1-1.89-2.134,12.329,12.329,0,0,1-.8-5.308A2,2,0,0,0,7.1,10.1a14,14,0,1,0,19.8,0,28.831,28.831,0,0,1-2.7-2.934,24.626,24.626,0,0,1-2.414-4.06ZM21.24,28.24A6,6,0,0,1,11,24a10.835,10.835,0,0,0,5,1c0-2,1-8,2.5-9a11.557,11.557,0,0,0,2.742,3.758,6,6,0,0,1,0,8.484Z"
                        transform="translate(-3 -2)"
                        fill="currentColor"
                        fill-rule="evenodd"
                      />
                    </svg>
                    <!-- END fire icon -->
                  </span>
                  <!-- end badge action -->

                  <!-- badge discount -->
                  <ng-container
                    *ngIf="(item$ | async).product.priceType === 'SALE'"
                  >
                    <span
                      class="pi-details-img-badge pi-details-discount bg-primaryx"
                    >
                      -{{ percentDifference.toFixed(2).slice(0, -3) }}%
                    </span>
                  </ng-container>
                  <!-- end badge discount -->
                </div>
                <div
                  class="absolute top-0 left-0 z-30 flex flex-row items-center justify-between w-full h-full"
                  *ngIf="imageObject.length > 1"
                  (swiperight)="prevSlide()"
                  (swipeleft)="nextSlide()"
                >
                  <button
                    (click)="prevImageClick()"
                    *ngIf="imgStep >= 1"
                    class="w-24 h-16 bg-nex-gray-light flex-row items-center justify-start flex text-nex-gray disabled:invisible active:opacity-70 rounded-r-xl active:shadow-inner-light"
                  >
                    <div
                      class="p-4 w-full rotate-180 h-16 justify-center items-center flex"
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 16L10 28L10 4L22 16Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </button>

                  <button
                    (click)="nextImageClick()"
                    *ngIf="imageObject.length > imgStep + 1"
                    class="ml-auto w-24 h-16 bg-nex-gray-light flex-row items-center justify-start flex text-nex-gray disabled:invisible active:opacity-70 rounded-l-xl active:shadow-inner-light"
                  >
                    <div
                      class="p-4 w-full h-16 justify-center items-center flex"
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 16L10 28L10 4L22 16Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>

              <div
                class="relative w-full h-auto py-2 my-3 overflow-hidden bg-white max-h-27x min-h-27x nex-slide"
                *ngIf="imageObject.length > 1"
              >
                <div
                  class="absolute top-0 left-0 z-10 block w-20 h-full bg-gradient-to-r from-white to-transparent"
                ></div>

                <ng-image-slider
                  (imageClick)="onImageClicked($event)"
                  [images]="imageObject"
                  [imageSize]="imageSize"
                  [imagePopup]="false"
                  [lazyLoading]="true"
                  [manageImageRatio]="true"
                  [showArrow]="false"
                  [infinite]="false"
                  [autoSlide]="{ interval: 2, stopOnHover: false }"
                  #nav
                ></ng-image-slider>

                <div
                  class="absolute top-0 right-0 z-10 block w-20 h-full bg-gradient-to-r from-transparent to-white"
                ></div>
              </div>
            </div>
            <!-- end product image area -->

            <!-- product details and actions -->
            <div class="w-full h-full flex flex-1 flex-row">
              <!-- suggested product -->
              <ng-container
                *ngIf="suggestedProductId && suggestedProductId !== null"
              >
                <div
                  class="mr-8 w-full h-full flex flex-col overflow-hidden items-center justify-between rounded-2xl border-4 border-nex-gray-light bg-white"
                >
                  <span
                    class="w-full px-6 py-6 items-center justify-center flex-1 flex object-cover"
                  >
                    <img src="assets/temp/tbc2.png" alt="" />
                  </span>
                  <div
                    class="w-full min-h-64 px-6 py-6 flex flex-col rounded-t-xl rounded-b-2xl border-4 border-white space-y-4 items-start justify-between bg-nex-gray-light"
                  >
                    <div class="w-full flex flex-col items-start justify-start">
                      <span
                        class="w-full text-4xl font-semibold text-nex-gray-ultra"
                      >
                        {{ "SUGGESTED_PRODUCT.TITLE" | translate }}
                      </span>
                      <span class="w-full text-2xl text-nex-gray-dark">
                        {{ "SUGGESTED_PRODUCT.MESSAGE" | translate }}
                      </span>
                    </div>
                    <button
                      class="w-full btn bg-nex-gray py-2 px-2 space-x-4 h-16 flex flex-row items-center justify-start text-left group rounded-xl border-2 border-nex-gray active:border-nex-gray-light active:opacity-70 active:shadow-inner-light"
                      (click)="productInfo()"
                    >
                      <span
                        class="w-16 h-full flex items-center justify-center bg-nex-gray rounded-xl text-white"
                      >
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3336 20.2267L25.5909 7.97205L27.4763 9.85738L13.3349 23.9987L4.84961 15.5134L6.73494 13.628L13.3349 20.228L13.3336 20.2267Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span class="text-white text-2xl font-medium">
                        {{ "SUGGESTED_PRODUCT.MORE" | translate }}
                      </span>
                    </button>
                  </div>
                </div>
              </ng-container>
              <!-- END suggested product -->

              <div
                class="w-full h-full flex"
                [ngClass]="
                  suggestedProductId && suggestedProductId !== null
                    ? 'flex-col justify-start'
                    : 'flex-row'
                "
              >
                <!-- product details -->
                <div
                  class="w-auto flex flex-col items-start justify-start space-y-4"
                  [ngClass]="
                    suggestedProductId && suggestedProductId !== null
                      ? 'h-auto pb-6'
                      : 'h-full flex-1'
                  "
                >
                  <div class="w-full h-auto text-3xl font-normal text-gray">
                    <ng-container
                      *ngIf="
                        !(item$ | async).product.brandName ||
                        !(item$ | async).product.brandName
                      "
                    >
                      <span class="invisible">---</span>
                    </ng-container>
                    {{ (item$ | async).product.brandName }}
                  </div>

                  <div
                    class="w-full h-auto font-semibold text-primaryx-dark"
                    [ngClass]="fullHdHalf ? 'text-7xl ' : 'text-5xl '"
                  >
                    {{ (item$ | async).product.name }}
                  </div>

                  <div
                    class="w-full px-4 h-28 flex flex-row items-center justify-between rounded-xl bg-nex-gray-light"
                  >
                    <!-- regular price type -->
                    <ng-container *ngIf="!priceType || priceType === 'REGULAR'">
                      <div
                        class="flex flex-row items-center justify-start space-x-2 w-full h-auto px-4"
                      >
                        <span
                          class="flex flex-col h-10 items-center justify-center text-primaryx"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 47 47"
                          >
                            <path
                              id="icon_tag"
                              data-name="icon_tag"
                              d="M48.14,23.423a2.938,2.938,0,0,1,0,4.154L27.577,48.14a2.938,2.938,0,0,1-4.154,0L2.861,27.577A2.929,2.929,0,0,1,2,25.5V10.813A8.813,8.813,0,0,1,10.813,2H25.5a2.929,2.929,0,0,1,2.077.861ZM10.813,13.75a2.938,2.938,0,1,0-2.938-2.938A2.938,2.938,0,0,0,10.813,13.75Z"
                              transform="translate(-2 -2)"
                              fill="currentColor"
                              fill-rule="evenodd"
                            />
                          </svg>
                        </span>
                        <span
                          class="flex flex-col flex-grow text-3xl w-full ml-auto font-bold text-left text-nex-gray-ultra"
                        >
                          € {{ (item$ | async).product.grossPrice.toFixed(2) }}
                        </span>
                      </div>
                    </ng-container>
                    <!-- END regular price type -->

                    <!-- sale price type -->
                    <ng-container *ngIf="priceType === 'SALE'">
                      <div
                        class="grid w-2/3 grid-cols-2 grid-rows-1 gap-2 text-right h-24 max-h-24 rounded-full"
                      >
                        <!-- sale price -->
                        <div
                          class="flex flex-row items-center justify-start space-x-2 w-full h-auto px-4"
                        >
                          <span
                            class="flex flex-col h-10 items-center justify-center text-nex-red"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="100%"
                              height="100%"
                              viewBox="0 0 47 47"
                            >
                              <path
                                id="icon_tag"
                                data-name="icon_tag"
                                d="M48.14,23.423a2.938,2.938,0,0,1,0,4.154L27.577,48.14a2.938,2.938,0,0,1-4.154,0L2.861,27.577A2.929,2.929,0,0,1,2,25.5V10.813A8.813,8.813,0,0,1,10.813,2H25.5a2.929,2.929,0,0,1,2.077.861ZM10.813,13.75a2.938,2.938,0,1,0-2.938-2.938A2.938,2.938,0,0,0,10.813,13.75Z"
                                transform="translate(-2 -2)"
                                fill="currentColor"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </span>
                          <span
                            class="flex flex-col flex-grow text-4xl w-full ml-auto font-bold text-left text-nex-red"
                          >
                            €
                            {{ (item$ | async).product.grossPrice.toFixed(2) }}
                          </span>
                        </div>
                        <!-- END sale price -->
                        <!-- old price and difference -->
                        <div
                          class="flex flex-col w-full h-full text-2xl first-letter:leading-tight justify-center items-end ml-auto font-medium text-right text-nex-gray-dark pl-1 pr-2 py-1"
                        >
                          <span
                            class="line-through decoration-[red] decoration-double"
                          >
                            {{
                              (
                                item$ | async
                              ).product.standardGrossPrice.toFixed(2)
                            }}
                            €
                          </span>
                          <span> - {{ priceDifference.toFixed(2) }} € </span>
                        </div>
                        <!-- END old price and difference -->
                      </div>
                    </ng-container>
                    <!-- END sale price type -->

                    <div
                      class="w-1/3 h-full flex flex-col items-end justify-center"
                    >
                      <div
                        class="w-full h-auto text-3xl text-right font-normal text-gray"
                      >
                        <ng-container
                          *ngIf="
                            !(item$ | async).product.packagingQuantity ||
                            !(item$ | async).product.packagingQuantity
                              .toString()
                              .trim()
                          "
                        >
                          <span class="invisible">---</span>
                        </ng-container>
                        {{ (item$ | async).product.packagingQuantity }}
                        <!-- 25kg -->
                      </div>
                      <div
                        class="w-full h-auto text-2xl text-right font-normal text-gray"
                      >
                        <ng-container
                          *ngIf="
                            !(item$ | async).product.pricePerItem ||
                            !(item$ | async).product.pricePerItem
                              .toString()
                              .trim()
                          "
                        >
                          <span class="invisible">---</span>
                        </ng-container>

                        {{ (item$ | async).product.pricePerItem }}
                        <!-- 0,41 € / 1 st -->
                      </div>
                    </div>
                  </div>
                  <!-- product short description -->
                  <ng-container
                    *ngIf="
                      (item$ | async).product.shortDescription !== undefined &&
                      (item$ | async).product.shortDescription !== ''
                    "
                  >
                    <div
                      class="w-full font-normal text-left mt-7 text-gray"
                      [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
                      [innerHtml]="
                        (item$ | async).product.shortDescription
                          | removeFormatting
                          | slice : 0 : 150
                      "
                    ></div>
                  </ng-container>
                  <!-- END product short description -->
                </div>

                <!-- end product details -->

                <!-- product actions -->
                <div
                  class="h-auto flex flex-col items-start justify-start space-y-4"
                  [ngClass]="
                    suggestedProductId && suggestedProductId !== null
                      ? 'w-full'
                      : 'w-1/2  pl-4'
                  "
                >
                  <!-- BUY BUTTON -->
                  <button
                    *ngIf="!(item$ | async).product.errorState"
                    class="w-full btn bg-nex-green py-2 px-2 space-x-4 h-24 flex flex-row items-center justify-start text-left group rounded-xl border-2 border-transparent active:border-nex-gray-light active:opacity-70 active:shadow-inner-light"
                    [disabled]="statusCartMessage"
                    (click)="onBuyProduct()"
                  >
                    <span
                      class="w-16 h-full flex items-center justify-center bg-white rounded-lg text-nex-green"
                    >
                      <ng-container *ngIf="!statusCartMessage">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3336 20.2267L25.5909 7.97205L27.4763 9.85738L13.3349 23.9987L4.84961 15.5134L6.73494 13.628L13.3349 20.228L13.3336 20.2267Z"
                            fill="currentColor"
                          />
                        </svg>
                      </ng-container>
                      <ng-container *ngIf="statusCartMessage">
                        <svg
                          class="w-12 h-12 text-nex-green animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </ng-container>
                    </span>
                    <span class="text-white text-2xl font-medium">
                      {{ "COMMON.BUY" | translate }}
                    </span>
                  </button>
                  <!-- end BUY BUTTON -->

                  <!-- product info btn -->
                  <ng-container *ngIf="(item$ | async).product.description">
                    <button
                      class="w-full btn bg-white py-2 px-2 space-x-4 h-16 flex flex-row items-center justify-start text-left group rounded-xl border-2 border-nex-gray-dark active:border-nex-gray-light active:opacity-70 active:shadow-inner-light"
                      (click)="productInfo()"
                    >
                      <span
                        class="w-16 h-full flex items-center justify-center bg-white rounded-xl text-nex-gray-dark"
                      >
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3333 2.66663L28 9.33329V28.0106C27.9996 28.3615 27.86 28.698 27.6117 28.946C27.3635 29.194 27.0269 29.3333 26.676 29.3333H5.324C4.97384 29.3309 4.63869 29.1908 4.39096 28.9433C4.14322 28.6958 4.00279 28.3608 4 28.0106V3.98929C4 3.25863 4.59333 2.66663 5.324 2.66663H21.3333ZM14.6667 9.33329V12H17.3333V9.33329H14.6667ZM14.6667 14.6666V22.6666H17.3333V14.6666H14.6667Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span class="text-nex-gray-dark text-2xl font-medium">{{
                        "RETAILER_PRODUCT.PRODUCT_INFO" | translate
                      }}</span>
                    </button>
                  </ng-container>
                  <!-- END product info btn -->

                  <!--  Not available info -->
                  <div
                    *ngIf="(item$ | async).product.errorState"
                    class="w-full px-4 h-28 flex flex-row items-center justify-between rounded-xl bg-red-light"
                  >
                    <ng-container>
                      <div
                        class="flex flex-row items-center justify-start space-x-2 w-full h-auto px-4"
                      >
                        <span
                          class="flex flex-col flex-grow text-3xl w-full ml-auto font-bold text-left text-nex-gray-ultra"
                        >
                          {{ "RETAILER_PRODUCT.NOT_AVAILABLE" | translate }}
                        </span>
                      </div>
                    </ng-container>
                  </div>
                  <!--  END Not available info -->

                  <ng-container *ngIf="useShoppingCart">
                    <!-- quantity and info btn -->
                    <div
                      *ngIf="!(item$ | async).product.errorState"
                      class="w-full h-auto flex flex-row justify-between items-center space-x-4"
                    >
                      <!-- quantity update -->
                      <div
                        class="w-1/3 h-auto py-2 flex items-center justify-between space-x-2"
                      >
                        <!-- btn decrease -->

                        <button
                          class="btn w-16 h-24 rounded-xl bg-white py-2 px-2 border-2 flex items-center justify-center border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                          [disabled]="
                            itemCartQuantity === 1 || statusCartMessage
                          "
                          (click)="decreaseProductQuantity()"
                        >
                          <span class="group-active:bg-active">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="text-primaryx"
                            >
                              <path
                                d="M12.5711 12.5714H19.4282H30.6663V19.4286H19.4282H12.5711H1.33301V12.5714H12.5711Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>

                        <!-- end btn decrease -->
                        <span
                          class="flex items-center justify-center h-full px-4 font-medium text-center text-nex-black"
                          [ngClass]="fullHdHalf ? 'text-6xl' : 'text-4xl'"
                        >
                          {{ itemCartQuantity }}
                        </span>
                        <!-- btn increase -->

                        <button
                          class="btn w-16 h-24 rounded-xl bg-white py-2 px-2 border-2 flex items-center justify-center border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                          [disabled]="
                            cartIsFull ||
                            hasNoMoreItemsLeftInStock() ||
                            statusCartMessage
                          "
                          (click)="increaseProductQuantity()"
                        >
                          <span class="group-active:bg-active">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="text-primaryx"
                            >
                              <path
                                d="M12.5711 12.5715V1.33337H19.4282V12.5715H30.6663V19.4286H19.4282V30.6667H12.5711V19.4286H1.33301V12.5715H12.5711Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>

                        <!-- end btn increase -->
                      </div>
                      <!-- END quantity update -->

                      <!-- BUY BUTTON -->
                      <button
                        class="w-2/3 btn bg-primaryx py-2 px-2 space-x-4 h-24 flex flex-row items-center justify-start text-left group rounded-xl border-2 border-transparent active:border-nex-gray-light active:opacity-70 active:shadow-inner-light"
                        [disabled]="statusCartMessage"
                        (click)="addProductToCart()"
                      >
                        <span
                          class="w-16 h-full flex items-center justify-center bg-white rounded-lg text-primaryx"
                        >
                          <ng-container *ngIf="!statusCartMessage">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.33301 4.23165L5.65567 8.55565V24.0023C5.65567 24.3559 5.79615 24.6951 6.0462 24.9451C6.29625 25.1952 6.63539 25.3356 6.98901 25.3356H22.989V22.669H8.32234V20.0023H24.6637C24.9504 20.0024 25.2294 19.9101 25.4595 19.7391C25.6896 19.5681 25.8584 19.3275 25.941 19.053L29.141 8.38631C29.2009 8.18715 29.2134 7.97674 29.1774 7.77189C29.1415 7.56705 29.0581 7.37345 28.934 7.20657C28.8099 7.0397 28.6485 6.90417 28.4626 6.81082C28.2768 6.71748 28.0716 6.6689 27.8637 6.66898H7.54234L3.21834 2.34631L1.33301 4.23165ZM6.24146 30.0832C6.61653 30.4583 7.12524 30.669 7.65567 30.669C8.18611 30.669 8.69481 30.4583 9.06989 30.0832C9.44496 29.7081 9.65567 29.1994 9.65567 28.669C9.65567 28.1385 9.44496 27.6298 9.06989 27.2548C8.69481 26.8797 8.18611 26.669 7.65567 26.669C7.12524 26.669 6.61653 26.8797 6.24146 27.2548C5.86639 27.6298 5.65567 28.1385 5.65567 28.669C5.65567 29.1994 5.86639 29.7081 6.24146 30.0832ZM22.2415 30.0832C22.6165 30.4583 23.1252 30.669 23.6557 30.669C24.1861 30.669 24.6948 30.4583 25.0699 30.0832C25.445 29.7081 25.6557 29.1994 25.6557 28.669C25.6557 28.1385 25.445 27.6298 25.0699 27.2548C24.6948 26.8797 24.1861 26.669 23.6557 26.669C23.1252 26.669 22.6165 26.8797 22.2415 27.2548C21.8664 27.6298 21.6557 28.1385 21.6557 28.669C21.6557 29.1994 21.8664 29.7081 22.2415 30.0832ZM15.0646 9.33329V12.3982H11.9997V14.2684H15.0646V17.3333H16.9347V14.2684H19.9997V12.3982H16.9347V9.33329H15.0646Z"
                                fill="currentColor"
                              />
                            </svg>
                          </ng-container>
                          <ng-container *ngIf="statusCartMessage">
                            <svg
                              class="w-8 h-8 text-primaryx animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </ng-container>
                        </span>
                        <span class="text-white text-2xl font-medium">
                          {{ "COMMON.ADD_TO_CART" | translate }}
                        </span>
                      </button>
                      <!-- end BUY BUTTON -->
                    </div>
                    <!-- end quantity and info btn -->
                  </ng-container>
                </div>
                <!-- end product actions -->
              </div>
            </div>
            <!-- end product details and actions -->
          </ng-container>

          <!-- info panel -->
          <ng-container *ngIf="infoPanelOpened">
            <!-- content col -->
            <div
              class="flex flex-col items-start justify-start flex-1 w-full h-full space-y-6"
            >
              <!-- product details infopanel -->
              <div
                class="w-full flex flex-col items-start justify-start space-y-4"
              >
                <div class="w-full h-auto text-3xl font-normal text-gray">
                  <ng-container
                    *ngIf="
                      !(item$ | async).product.brandName ||
                      !(item$ | async).product.brandName
                    "
                  >
                    <span class="invisible">---</span>
                  </ng-container>
                  {{ (item$ | async).product.brandName }}
                </div>

                <div
                  class="w-full h-auto font-semibold text-primaryx-dark text-5xl"
                >
                  {{ (item$ | async).product.name }}
                </div>

                <div
                  class="w-full px-4 h-28 flex flex-row items-center justify-between rounded-xl bg-nex-gray-light"
                >
                  <!-- regular price type -->
                  <ng-container *ngIf="!priceType || priceType === 'REGULAR'">
                    <div
                      class="flex flex-row items-center justify-start space-x-2 w-full h-auto px-4"
                    >
                      <span
                        class="flex flex-col h-10 items-center justify-center text-primaryx"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          viewBox="0 0 47 47"
                        >
                          <path
                            id="icon_tag"
                            data-name="icon_tag"
                            d="M48.14,23.423a2.938,2.938,0,0,1,0,4.154L27.577,48.14a2.938,2.938,0,0,1-4.154,0L2.861,27.577A2.929,2.929,0,0,1,2,25.5V10.813A8.813,8.813,0,0,1,10.813,2H25.5a2.929,2.929,0,0,1,2.077.861ZM10.813,13.75a2.938,2.938,0,1,0-2.938-2.938A2.938,2.938,0,0,0,10.813,13.75Z"
                            transform="translate(-2 -2)"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span
                        class="flex flex-col flex-grow w-full ml-auto font-bold text-left text-primaryx-dark"
                        [ngClass]="fullHdHalf ? 'text-4xl ' : 'text-3xl '"
                      >
                        € {{ (item$ | async).product.grossPrice.toFixed(2) }}
                      </span>
                    </div>
                  </ng-container>
                  <!-- END regular price type -->

                  <!-- sale price type -->
                  <ng-container *ngIf="priceType === 'SALE'">
                    <div
                      class="grid w-2/3 grid-cols-2 grid-rows-1 gap-2 text-right h-24 max-h-24 rounded-full"
                    >
                      <!-- sale price -->
                      <div
                        class="flex flex-row items-center justify-start space-x-2 w-full h-auto px-4"
                      >
                        <span
                          class="flex flex-col h-10 items-center justify-center text-nex-red"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 47 47"
                          >
                            <path
                              id="icon_tag"
                              data-name="icon_tag"
                              d="M48.14,23.423a2.938,2.938,0,0,1,0,4.154L27.577,48.14a2.938,2.938,0,0,1-4.154,0L2.861,27.577A2.929,2.929,0,0,1,2,25.5V10.813A8.813,8.813,0,0,1,10.813,2H25.5a2.929,2.929,0,0,1,2.077.861ZM10.813,13.75a2.938,2.938,0,1,0-2.938-2.938A2.938,2.938,0,0,0,10.813,13.75Z"
                              transform="translate(-2 -2)"
                              fill="currentColor"
                              fill-rule="evenodd"
                            />
                          </svg>
                        </span>
                        <span
                          class="flex flex-col flex-grow text-4xl w-full ml-auto font-bold text-left text-nex-red"
                        >
                          €
                          {{ (item$ | async).product.grossPrice.toFixed(2) }}
                        </span>
                      </div>
                      <!-- END sale price -->
                      <!-- old price and difference -->
                      <div
                        class="flex flex-col w-full h-full text-2xl first-letter:leading-tight justify-center items-end ml-auto font-medium text-right text-nex-gray-dark pl-1 pr-2 py-1"
                      >
                        <span
                          class="line-through decoration-[red] decoration-double"
                        >
                          {{
                            (item$ | async).product.standardGrossPrice.toFixed(
                              2
                            )
                          }}
                          €
                        </span>
                        <span> - {{ priceDifference.toFixed(2) }} € </span>
                      </div>
                      <!-- END old price and difference -->
                    </div>
                  </ng-container>
                  <!-- END sale price type -->

                  <div
                    class="w-1/3 h-full flex flex-col items-end justify-center"
                  >
                    <div
                      class="w-full h-auto text-3xl text-right font-normal text-gray"
                    >
                      <ng-container
                        *ngIf="
                          !(item$ | async).product.packagingQuantity ||
                          !(item$ | async).product.packagingQuantity
                            .toString()
                            .trim()
                        "
                      >
                        <span class="invisible">---</span>
                      </ng-container>
                      {{ (item$ | async).product.packagingQuantity }}
                      <!-- 25kg -->
                    </div>
                    <div
                      class="w-full h-auto text-2xl text-right font-normal text-gray"
                    >
                      <ng-container
                        *ngIf="
                          !(item$ | async).product.pricePerItem ||
                          !(item$ | async).product.pricePerItem
                            .toString()
                            .trim()
                        "
                      >
                        <span class="invisible">---</span>
                      </ng-container>
                      {{ (item$ | async).product.pricePerItem }}
                      <!-- 0,41 € / 1 st -->
                    </div>
                  </div>
                </div>
                <!-- product short description -->
                <ng-container
                  *ngIf="
                    (item$ | async).product.shortDescription !== undefined &&
                    (item$ | async).product.shortDescription !== ''
                  "
                >
                  <div
                    class="w-full font-normal text-left mt-7 text-gray"
                    [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
                    [innerHtml]="
                      (item$ | async).product.shortDescription
                        | removeFormatting
                        | slice : 0 : 150
                    "
                  ></div>
                </ng-container>
                <!-- END product short description -->
                <!-- <span
              class="inline-flex pr-4 font-bold uppercase focus:outline-none text-active"
              *ngIf="(item$ | async).product.description?.length > 150"
              (click)="productInfo()"
            >
              [ {{ "RETAILER_PRODUCT.PRODUCT_INFO" | translate }} ]
            </span> -->
              </div>

              <!-- end product details infopanel -->

              <!-- product info main content -->
              <div
                class="w-full flex flex-1 flex-col relative items-start justify-start"
              >
                <div
                  #targetProductInfo
                  class="nex-product-info-content w-full max-h-[50vh] font-normal text-left text-gray"
                  [ngClass]="[
                    fullHdHalf ? 'text-5xl leading-relaxed' : 'text-2xl ',
                    (item$ | async).product.description?.length > 800
                      ? 'overflow-y-scroll'
                      : ''
                  ]"
                  [innerHtml]="(item$ | async).product.description"
                ></div>

                <!-- hand SWIPE gesture -->
                <!-- <div
                  class="w-64 h-64 flex items-center rotate-[270deg] justify-center absolute left-[30%] top-[20%]"
                >
                  <div class="hand-icon"></div>
                </div> -->
                <!-- END hand SWIPE gesture -->
              </div>
              <!-- END product info main content -->

              <!-- vertical scroll buttons -->
              <div
                class="w-full h-24 grid grid-rows-1 grid-cols-2 gap-x-4 items-center justify-between overflow-hidden mt-6"
                *ngIf="(item$ | async).product.description?.length > 800"
              >
                <button
                  (click)="scrollDownText()"
                  class="h-24 bg-nex-gray-light flex-row items-center justify-start flex text-nex-gray-dark disabled:invisible active:opacity-70 rounded-xl active:shadow-inner-light"
                >
                  <div
                    class="p-4 w-full h-16 rotate-90 justify-center items-center flex"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22 16L10 28L10 4L22 16Z" fill="currentColor" />
                    </svg>
                  </div>
                </button>

                <button
                  (click)="scrollUpText()"
                  class="h-24 bg-nex-gray-light flex-row items-center justify-start flex text-nex-gray-dark disabled:invisible active:opacity-70 rounded-xl active:shadow-inner-light"
                >
                  <div
                    class="p-4 w-full h-16 -rotate-90 justify-center items-center flex"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22 16L10 28L10 4L22 16Z" fill="currentColor" />
                    </svg>
                  </div>
                </button>
              </div>
              <!-- end vertical scroll buttons -->

              <!-- product actions infopanel -->
              <div
                class="w-full h-auto flex flex-col items-start justify-start space-y-4"
              >
                <!--  Not available info -->
                <div
                  *ngIf="(item$ | async).product.errorState"
                  class="w-full px-4 h-28 flex flex-row items-center justify-between rounded-xl bg-red-light"
                >
                  <ng-container>
                    <div
                      class="flex flex-row items-center justify-start space-x-2 w-full h-auto px-4"
                    >
                      <span
                        class="flex flex-col flex-grow text-3xl w-full ml-auto font-bold text-left text-nex-gray-ultra"
                      >
                        {{ "RETAILER_PRODUCT.NOT_AVAILABLE" | translate }}
                      </span>
                    </div>
                  </ng-container>
                </div>
                <!--  END Not available info -->
                <!-- quantity and info btn infopanel-->
                <div
                  *ngIf="!(item$ | async).product.errorState"
                  class="w-full h-auto flex flex-row justify-between items-center space-x-4"
                >
                  <ng-container *ngIf="useShoppingCart">
                    <!-- quantity update infopanel-->
                    <div
                      class="w-1/3 h-auto py-2 flex items-center justify-between space-x-2"
                    >
                      <!-- btn decrease infopanel-->

                      <button
                        class="btn w-16 h-24 rounded-xl bg-white py-2 px-2 border-2 flex items-center justify-center border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                        [disabled]="itemCartQuantity === 1 || statusCartMessage"
                        (click)="decreaseProductQuantity()"
                      >
                        <span class="group-active:bg-active">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-primaryx"
                          >
                            <path
                              d="M12.5711 12.5714H19.4282H30.6663V19.4286H19.4282H12.5711H1.33301V12.5714H12.5711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>

                      <!-- end btn decrease infopanel-->
                      <span
                        class="flex items-center justify-center h-full px-4 font-medium text-center text-nex-black"
                        [ngClass]="fullHdHalf ? 'text-6xl' : 'text-4xl'"
                      >
                        {{ itemCartQuantity }}
                      </span>
                      <!-- btn increase infopanel-->

                      <button
                        class="btn w-16 h-24 rounded-xl bg-white py-2 px-2 border-2 flex items-center justify-center border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                        [disabled]="
                          cartIsFull ||
                          hasNoMoreItemsLeftInStock() ||
                          statusCartMessage
                        "
                        (click)="increaseProductQuantity()"
                      >
                        <span class="group-active:bg-active">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-primaryx"
                          >
                            <path
                              d="M12.5711 12.5715V1.33337H19.4282V12.5715H30.6663V19.4286H19.4282V30.6667H12.5711V19.4286H1.33301V12.5715H12.5711Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>

                      <!-- end btn increase infopanel-->
                    </div>
                    <!-- END quantity update infopanel-->
                  </ng-container>

                  <div
                    *ngIf="!(item$ | async).product.errorState"
                    class="w-full h-auto flex flex-row justify-between items-center space-x-4"
                  >
                    <ng-container *ngIf="useShoppingCart">
                      <!-- BUY BUTTON add to cart infopanel-->
                      <button
                        class="w-1/2 btn bg-primaryx py-2 px-2 space-x-4 h-24 flex flex-row items-center justify-start text-left group rounded-xl border-2 border-transparent active:border-nex-gray-light active:opacity-70 active:shadow-inner-light"
                        [disabled]="statusCartMessage"
                        (click)="addProductToCart()"
                      >
                        <span
                          class="w-16 h-full flex items-center justify-center bg-white rounded-lg text-primaryx"
                        >
                          <ng-container *ngIf="!statusCartMessage">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.33301 4.23165L5.65567 8.55565V24.0023C5.65567 24.3559 5.79615 24.6951 6.0462 24.9451C6.29625 25.1952 6.63539 25.3356 6.98901 25.3356H22.989V22.669H8.32234V20.0023H24.6637C24.9504 20.0024 25.2294 19.9101 25.4595 19.7391C25.6896 19.5681 25.8584 19.3275 25.941 19.053L29.141 8.38631C29.2009 8.18715 29.2134 7.97674 29.1774 7.77189C29.1415 7.56705 29.0581 7.37345 28.934 7.20657C28.8099 7.0397 28.6485 6.90417 28.4626 6.81082C28.2768 6.71748 28.0716 6.6689 27.8637 6.66898H7.54234L3.21834 2.34631L1.33301 4.23165ZM6.24146 30.0832C6.61653 30.4583 7.12524 30.669 7.65567 30.669C8.18611 30.669 8.69481 30.4583 9.06989 30.0832C9.44496 29.7081 9.65567 29.1994 9.65567 28.669C9.65567 28.1385 9.44496 27.6298 9.06989 27.2548C8.69481 26.8797 8.18611 26.669 7.65567 26.669C7.12524 26.669 6.61653 26.8797 6.24146 27.2548C5.86639 27.6298 5.65567 28.1385 5.65567 28.669C5.65567 29.1994 5.86639 29.7081 6.24146 30.0832ZM22.2415 30.0832C22.6165 30.4583 23.1252 30.669 23.6557 30.669C24.1861 30.669 24.6948 30.4583 25.0699 30.0832C25.445 29.7081 25.6557 29.1994 25.6557 28.669C25.6557 28.1385 25.445 27.6298 25.0699 27.2548C24.6948 26.8797 24.1861 26.669 23.6557 26.669C23.1252 26.669 22.6165 26.8797 22.2415 27.2548C21.8664 27.6298 21.6557 28.1385 21.6557 28.669C21.6557 29.1994 21.8664 29.7081 22.2415 30.0832ZM15.0646 9.33329V12.3982H11.9997V14.2684H15.0646V17.3333H16.9347V14.2684H19.9997V12.3982H16.9347V9.33329H15.0646Z"
                                fill="currentColor"
                              />
                            </svg>
                          </ng-container>
                          <ng-container *ngIf="statusCartMessage">
                            <svg
                              class="w-8 h-8 text-primaryx animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </ng-container>
                        </span>
                        <span class="text-white text-2xl font-medium">
                          {{ "COMMON.ADD_TO_CART" | translate }}
                        </span>
                      </button>
                      <!-- end BUY BUTTON add to cart infopanel-->
                    </ng-container>
                    <!-- BUY BUTTON infopanel-->
                    <button
                      class="btn bg-nex-green py-2 px-2 space-x-4 h-24 flex flex-row items-center justify-start text-left group rounded-xl border-2 border-transparent active:border-nex-gray-light active:opacity-70 active:shadow-inner-light"
                      [ngClass]="useShoppingCart ? 'w-1/2' : 'w-full'"
                      [disabled]="statusCartMessage"
                      (click)="onBuyProduct()"
                    >
                      <span
                        class="w-16 h-full flex items-center justify-center bg-white rounded-lg text-nex-green"
                      >
                        <ng-container *ngIf="!statusCartMessage">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.3336 20.2267L25.5909 7.97205L27.4763 9.85738L13.3349 23.9987L4.84961 15.5134L6.73494 13.628L13.3349 20.228L13.3336 20.2267Z"
                              fill="currentColor"
                            />
                          </svg>
                        </ng-container>
                        <ng-container *ngIf="statusCartMessage">
                          <svg
                            class="w-12 h-12 text-nex-green animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </ng-container>
                      </span>
                      <span class="text-white text-2xl font-medium">
                        {{ "COMMON.BUY" | translate }}
                      </span>
                    </button>
                    <!-- end BUY BUTTON infopanel-->
                  </div>
                </div>
                <!-- end quantity and info btn infopanel-->
              </div>
              <!-- end product actions infopanel-->
            </div>
            <!-- END: content col -->
          </ng-container>
          <!-- END: info panel -->
        </div>
        <!-- end product area -->
      </div>
      <!-- end nex product area -->
    </div>
    <!-- end nex cetral wrapper -->

    <!-- nex action footer area -->
    <app-nex-footer
      [backBtnCloseInfoPanel]="infoPanelOpened"
      (infoPanelStatus)="infoPanelHandler($event)"
      [productPage]="true"
      [hideSearchButton]="true"
      [useShoppingCart]="useShoppingCart"
      [infoPanel]="infoPanel"
      [statusCartMessage]="statusCartMessage"
      [cartQuantity]="cartItems.length"
      [cartTotalValue]="cartTotalValue"
      [searchPanelOpened]="searchPanelOpened"
      (searchPanelStatus)="searchPanelHandler($event)"
    ></app-nex-footer>
    <!-- end nex action footer area -->
  </div>

  <!-- success added to cart animation -->
  <div
    class="hidden fixed top-0 left-0 xflex flex-col items-center justify-center w-screen h-screen bg-white/80 backdrop-blur-none z-90"
    *ngIf="statusCartMessage"
  >
    <!-- sign -->
    <div
      class="flex items-center justify-center rounded-full w-80 h-80 bg-nex-green/20 wow bounceIn animated"
      data-wow-delay="0"
      data-wow-duration=".2s"
    >
      <div
        class="flex items-center justify-center w-64 h-64 rounded-full bg-nex-green/40 wow bounceIn animated"
        data-wow-delay="0"
        data-wow-duration=".3s"
      >
        <div
          class="flex flex-col items-center justify-center w-48 h-48 p-6 mx-auto bg-nex-green rounded-full text-white wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration=".3s"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="182.66"
            height="182.66"
            viewBox="0 0 182.66 182.66"
            class="wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration=".3s"
          >
            <path
              id="Path_13266"
              data-name="Path 13266"
              d="M93.33,184.66A91.33,91.33,0,1,0,2,93.33,91.33,91.33,0,0,0,93.33,184.66ZM135.65,78.569a11.415,11.415,0,1,0-16.144-16.144L81.914,100.02,67.153,85.259A11.415,11.415,0,1,0,51.009,101.4l22.834,22.831a11.416,11.416,0,0,0,16.143,0L135.65,78.569Z"
              transform="translate(-2 -2)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- end sign -->
  </div>
  <!-- end success added to cart animation -->
</ng-container>
