import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Idle } from '@ng-idle/core';
import { Retailer } from '../../state/retailer.actions';
import { RetailerState } from '../../state/retailer.state';
import { Observable } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import { ModuleType } from 'src/app/core/state/core.state';

@Component({
  selector: 'app-pickup-details',
  templateUrl: './pickup-details.component.html',
  styleUrls: ['./pickup-details.component.scss'],
})
export class PickupDetailsComponent implements OnInit {
  @Select(RetailerState.selectedPickup)
  pickup$: Observable<any>;
  theme: string;
  fullHdHalf: boolean = false;

  constructor(private store: Store, private idle: Idle) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    console.warn('this.fullHdHalf', this.fullHdHalf);
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  pickup(): void {
    this.store.dispatch(new Retailer.PickupProduct()).subscribe(
      () => {
        setTimeout(() => {
          this.store.dispatch(new Navigate(['/core/screensaver']));
        }, 10000);
      },
      (error: MachineError) => {
        this.store
          .dispatch(new Core.Error(error, ModuleType.RETAILER))
          .subscribe(() => {
            setTimeout(() => {
              this.store.dispatch(new Navigate(['/core/screensaver']));
            }, 10000);
          });
      }
    );
  }

  cancel(): void {
    this.store.dispatch(new Retailer.ActionSelection());
  }
}
