import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel, GlobalEnums } from 'src/app/core/state/core.state';
import { Retailer } from 'src/app/retailer/state/retailer.actions';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router'
import { LangService } from 'src/app/core/services/lang.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public GlobalEnums = GlobalEnums;
  theme: string;
  multiLanguage: boolean = false;
  currentLang: string;
  public availableLanguages = [];
  @Input() showBackButton: boolean = false;
  @Input() showSearch: boolean = false;
  @Input() doubleLogos: boolean = false;
  @Input() showLangFlags: boolean = false;
  @Input() personPhoto: string = "";
  logo: string;
  logo2: string;
  openSetupCount: number = 0;


  constructor(private store: Store, private router: Router, private langService: LangService) {

    this.currentLang = this.langService.getLanguage();
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo = this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      this.logo2 = this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo2;
      this.multiLanguage = this.store.selectSnapshot<CoreStateModel>(CoreState).config.multiLanguage;
    }
    this.theme = localStorage.getItem('theme') ?? 'default';

  }

  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus()
    }
  }

  ngOnInit(): void {
  }

  search($event) {
    this.store.dispatch(new Retailer.Search($event.target.value));
  }

  setPublicLanguage() {
    switch (this.currentLang) {
      case 'de':
        this.langService.setPublicLanguage('en');
        this.langService.getNewLanguage('en');
        this.langService.setLanguage();
        this.langService.emitChangeLang('en');
        break;
      case 'en':
        this.langService.setPublicLanguage('de');
        this.langService.getNewLanguage('de');
        this.langService.setLanguage();
        this.langService.emitChangeLang('de');
        break;

      default:

    }
    this.currentLang = this.langService.getLanguage();
  }

  openSetup() {
    this.openSetupCount++;

    if (this.openSetupCount === this.GlobalEnums.openSetupCount) {
      this.store.dispatch(new Navigate(['/core', 'setup']))
    }
  }

  backToSelection(route: string) {
    this.router.navigate(['/core/selection-full']);
  }

}
