<!-- default -->
<ng-container *ngIf="theme === 'default'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen px-6 bg-white"
  >
    <!-- infowindow header -->
    <div
      class="flex flex-col items-stretch min-h-[40%] max-h-[40%] justify-between w-full px-8 pt-18 bg-nex-gray-light rounded-b-4xl relative z-10 wow bounceInDown animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <div class="w-full mx-auto flex-1 flex items-center justify-center">
        <img (click)="openSetup()" [src]="logo" class="h-40 max-h-40" />
      </div>

      <!-- screen sign symbol -->
      <div class="w-full h-auto self-end justify-self-end">
        <!-- sign -->
        <div
          class="-mb-32 flex items-center justify-center mx-auto rounded-full w-80 h-80 bg-white-20 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <div
              class="flex flex-col items-center justify-center w-48 h-48 mx-auto bg-white rounded-full text-primaryx wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="wow bounceIn animated"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="9" />
                <line x1="9" y1="10" x2="9.01" y2="10" />
                <line x1="15" y1="10" x2="15.01" y2="10" />
                <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" />
              </svg>
            </div>
          </div>
        </div>
        <!-- end sign -->
      </div>
      <!-- end screen sign symbol -->
    </div>
    <!-- end infowindow header -->

    <!-- infowindow msg part -->
    <div
      class="flex flex-col vert-shadow items-center justify-center flex-1 w-11/12 mx-auto pb-32 pt-52 px-8 h-auto bg-nex-gray-light-ultra relative -top-12 z-5 rounded-b-4xl wow bounceInDown animated"
      data-wow-delay="200ms"
      data-wow-duration=".4s"
    >
      <!-- msg -->
      <div
        class="w-full h-auto flex flex-col items-center justify-center wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1.5s"
      >
        <h1
          class="text-4xl font-bold text-center text-primaryx wow fadeIn animated"
          data-wow-delay="100ms"
          data-wow-duration="1.5s"
        >
          {{ "ERROR.OUT_OF_ORDER" | translate }}
        </h1>

        <h2
          class="text-4xl font-medium text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1.5s"
        >
          {{ "ERROR.TRY_AGAIN" | translate }}
        </h2>
      </div>
      <!-- end msg -->
    </div>
    <!-- end infowindow msg part -->

    <!-- infowindow empty space at the bottom -->
    <div class="w-full block h-52"></div>
    <!-- end infowindow empty space at the bottom -->
  </div>
</ng-container>
