<!-- theme === 'sanusx' or theme === 'default' -->
<ng-container *ngIf="theme !== 'ski'">
  <div
    class="relative flex items-center justify-center w-24 h-24 overflow-hidden bg-center bg-no-repeat bg-cover mb-14"
  >
    <app-svg-stripe
      [icon]="'setup-outer'"
      class="z-0 text-red"
    ></app-svg-stripe>
    <span
      class="absolute flex items-center justify-center w-12 h-12 ml-px top-5 left-6 z-5"
    >
      <app-svg-stripe [icon]="'lock'" [white]="true"></app-svg-stripe>
    </span>
  </div>
  <h2
    class="w-7/12 mx-auto text-5xl font-bold leading-normal text-center text-white wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
  >
    {{ "SETUP.TITLE_MENU" | translate }}
  </h2>
  <h2
    class="w-full mx-auto text-4xl font-normal leading-normal text-center text-white-70 wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
  >
    {{ "SETUP.SUBTITLE" | translate }}
  </h2>
  <div
    class="flex flex-col items-center justify-start flex-1 w-full px-16 text-center"
  >
    <setup-dialog
      [hatchData]="hatchDataExport"
      (hatchLoaderStatus)="hatchLoaderStatusHandler($event)"
      *ngIf="showHatch"
      class="z-up z-100"
    >
    </setup-dialog>

    <div
      class="flex flex-wrap items-center content-center justify-center w-full h-auto mx-auto mt-8"
      *ngIf="!actionInProgress && !isWWKS2 && !(isMqtt && isOfficeButler)"
    >
      <btn-big-box
        (btnClick)="moveToBox()"
        [label]="'Move to box'"
        [smBtn]="true"
      >
        <app-svg-stripe [icon]="'move'"></app-svg-stripe>
      </btn-big-box>
      <btn-big-box (btnClick)="push()" [label]="'Push'" [smBtn]="true">
        <app-svg-stripe [icon]="'push'"></app-svg-stripe>
      </btn-big-box>
      <btn-big-box (btnClick)="withdraw()" [label]="'Withdraw'" [smBtn]="true">
        <app-svg-stripe [icon]="'wallet'"></app-svg-stripe>
      </btn-big-box>
      <btn-big-box
        (btnClick)="openHatch()"
        [label]="'Open hatch'"
        [smBtn]="true"
      >
        <app-svg-stripe [icon]="'export'"></app-svg-stripe>
      </btn-big-box>
      <!-- <btn-big-box (btnClick)="testPosition()" [label]="'Test position'" [smBtn]="true">
        <app-svg-stripe [icon]="'axis'"></app-svg-stripe>
      </btn-big-box> -->
      <btn-big-box (btnClick)="homeAxes()" [label]="'Home'" [smBtn]="true">
        <app-svg-stripe [icon]="'axis'"></app-svg-stripe>
      </btn-big-box>
      <!-- <btn-big-box (btnClick)="runSetup()" [label]="'Run setup'" [smBtn]="true">
        <app-svg-stripe [icon]="'play'"></app-svg-stripe>
      </btn-big-box> -->
      <!-- <ng-container *ngIf="pickup2Module || dropoffModule">
        <btn-big-box
          (btnClick)="uncompletedPickupItems()"
          [label]="'Load Products'"
          [smBtn]="true"
        >
          <app-svg-stripe [icon]="'truck-plus'"></app-svg-stripe>
        </btn-big-box>
      </ng-container> -->
    </div>
    <div
      class="flex flex-wrap items-center content-center justify-center w-full h-auto mx-auto mt-8"
      *ngIf="!actionInProgress && isWWKS2"
    >
      <btn-big-box
        (btnClick)="openHatch()"
        [label]="'Open hatch'"
        [smBtn]="true"
      >
        <app-svg-stripe [icon]="'export'"></app-svg-stripe>
      </btn-big-box>
      <btn-big-box
        (btnClick)="unblock()"
        [label]="'Unblock device'"
        [smBtn]="true"
      >
        <app-svg-stripe [icon]="'start'"></app-svg-stripe>
      </btn-big-box>
    </div>
    <div
      class="flex flex-wrap items-center content-center justify-center w-full h-auto mx-auto mt-8"
      *ngIf="isOfficeButler && isMqtt"
    >
      <btn-big-box
        (btnClick)="openLockersOb()"
        [label]="'Open Locker'"
        [smBtn]="true"
      >
        <app-svg-stripe
          [icon]="'unlock'"
          class="text-primaryx"
        ></app-svg-stripe>
      </btn-big-box>
      <btn-big-box
        (btnClick)="openMultipleLockersOb()"
        [label]="'Open Whole Row'"
        [smBtn]="true"
      >
        <app-svg-stripe
          [icon]="'unlock'"
          class="text-primaryx"
        ></app-svg-stripe>
      </btn-big-box>
    </div>
    <div
      class="flex flex-wrap items-center content-center justify-center w-full h-auto mx-auto mt-8"
      *ngIf="actionInProgress"
    >
      <h2
        class="w-7/12 mx-auto text-5xl font-bold leading-normal text-center text-white"
      >
        {{ "COMMON.PLEASE_WAIT" | translate }}
      </h2>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="theme === 'ski'">
  <div
    class="relative flex items-center justify-center w-24 h-24 overflow-hidden bg-center bg-no-repeat bg-cover my-8"
  >
    <app-svg-stripe
      [icon]="'setup-outer'"
      class="z-0 text-red"
    ></app-svg-stripe>
    <span
      class="absolute flex items-center justify-center w-12 h-12 ml-px top-5 left-6 z-5"
    >
      <app-svg-stripe [icon]="'lock'" [white]="true"></app-svg-stripe>
    </span>
  </div>
  <h2
    class="w-7/12 mx-auto text-5xl font-bold leading-normal text-center text-white wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
  >
    {{ "SETUP.TITLE_MENU" | translate }}
  </h2>
  <h2
    class="w-full mx-auto text-4xl font-normal leading-normal text-center text-white-70 wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
  >
    {{ "SETUP.SUBTITLE" | translate }}
  </h2>
  <div
    class="flex flex-col items-center justify-start flex-1 w-full px-16 text-center"
  >
    <div
      class="flex flex-wrap items-center content-center justify-center w-full h-auto mx-auto mt-8"
      *ngIf="!actionInProgress"
    >
      <btn-big-box
        (btnClick)="openLockers()"
        [label]="'SETUP.OPEN_LOCKERS' | translate"
        [smBtn]="true"
      >
        <app-svg-stripe
          [icon]="'unlock'"
          class="text-primaryx"
        ></app-svg-stripe>
      </btn-big-box>

      <btn-big-box
        (btnClick)="selectLockers()"
        [label]="'SETUP.SELECT_LOCKERS' | translate"
        [smBtn]="true"
      >
        <app-svg-stripe
          [icon]="'select'"
          class="text-primaryx"
        ></app-svg-stripe>
      </btn-big-box>
    </div>
    <div
      class="flex flex-wrap items-center content-center justify-center w-full h-auto mx-auto mt-8"
      *ngIf="actionInProgress"
    >
      <h2
        class="w-7/12 mx-auto text-5xl font-bold leading-normal text-center text-white"
      >
        {{ "COMMON.PLEASE_WAIT" | translate }}
      </h2>
    </div>
  </div>
</ng-container>
