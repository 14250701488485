<!-- old default -->
<ng-container *ngIf="theme === 'defaultold'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen bg-gradient-to-b from-gray-dark/80 to-gray-dark/20"
    *ngIf="(error | async) != null"
  >
    <div
      class="flex flex-col items-center flex-1 w-full h-full text-center middle-block"
    >
      <div class="w-9/12 mx-auto">
        <!-- logo -->
        <div class="flex flex-row justify-center w-6/12 mx-auto text-center">
          <img (click)="openSetup()" class="h-24" [src]="logo" />
        </div>
        <!-- END:logo -->
      </div>
    </div>

    <div
      class="flex flex-col items-stretch justify-start bg-white items-startx error-block wow bounceInUp animated"
      [ngClass]="showDetailedError ? 'error-block-expanded' : ''"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <!-- sign -->
      <div
        class="flex items-center justify-center mx-auto rounded-full w-80 h-80 bg-white-20 -mt-52 wow bounceIn animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div
          class="flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex flex-col items-center justify-center w-48 h-48 mx-auto bg-white rounded-full text-primaryx wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="wow bounceIn animated"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <line x1="9" y1="10" x2="9.01" y2="10" />
              <line x1="15" y1="10" x2="15.01" y2="10" />
              <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" />
            </svg>
          </div>
        </div>
      </div>
      <!-- end sign -->

      <!-- error title -->
      <div
        class="flex flex-col items-center justify-start w-9/12 mx-auto mt-6 transition-all"
        [ngClass]="showDetailedError ? '-mt-12 scale-95 mb-20' : 'flex-1'"
      >
        <h2
          class="flex flex-col self-end mx-auto space-y-10 font-semibold text-center text-primaryx title"
        >
          <span>
            {{
              (error | async).errorNumber !== 17
                ? ("ERROR.MESSAGE_TITLE" | translate)
                : ("ERROR.OUT_OF_ORDER" | translate)
            }}
          </span>

          <p class="text-4xl font-medium text-gray">
            {{
              contactInfo
                ? ("ERROR.CONTACT_HELP" | translate) + contactInfo
                : ("ERROR.TRY_AGAIN" | translate)
            }}
          </p>
        </h2>
      </div>
      <!-- end error title -->

      <!-- error description -->
      <div
        class="flex flex-col items-center self-end justify-start w-full h-auto mx-auto mt-20 error-swipe-block bg-gray-ultra"
        [ngClass]="showDetailedError ? 'flex-1 error-swipe-block-expanded' : ''"
      >
        <div
          class="flex items-center justify-center w-2/12 mx-auto -mt-20 cursor-pointer"
          (touchstart)="swipe($event, 'start')"
          (touchend)="swipe($event, 'end')"
        >
          <div
            class="flex items-center justify-center w-20 h-20 p-2 rounded-full bg-gray-ultra text-primaryx"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <line x1="12" y1="8" x2="12.01" y2="8" />
              <polyline points="11 12 12 12 12 16 13 16" />
            </svg>
          </div>
        </div>

        <div
          class="flex flex-col items-center justify-center flex-1 w-11/12 mx-auto"
        >
          <div
            class="flex flex-col justify-center h-full px-6 py-4 space-y-4 text-lg font-normal text-center transition-transform error-desc text-primaryx"
            [ngClass]="showDetailedError ? 'error-desc-expanded' : ''"
          >
            <!-- error no 1   -->
            <h4>
              {{ (error | async).message | translate }}
            </h4>

            <!-- error no 2 3 4 ...   -->
          </div>
        </div>
      </div>
      <!-- end error description -->
    </div>
  </div>
</ng-container>

<!-- sanusx (only use default theme error 01/2023) -->
<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen text-white bg-gray"
    *ngIf="(error | async) != null"
  >
    <app-header [showSearch]="false" class="w-full"></app-header>
    <div
      class="flex flex-col items-center justify-center flex-1 w-full text-center px-27"
    >
      <div
        class="flex flex-col items-center justify-center w-full h-auto mx-auto my-10 text-white wow pulse animated"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
        (click)="displayDetailedError()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="211.83"
          height="211.828"
          viewBox="0 0 211.83 211.828"
        >
          <g
            id="Group_7452"
            data-name="Group 7452"
            transform="translate(0 141.301)"
          >
            <path
              id="Path_13625"
              data-name="Path 13625"
              d="M105.915,70.527A105.915,105.915,0,0,0,211.83-35.388,105.915,105.915,0,0,0,105.915-141.3,105.915,105.915,0,0,0,0-35.388,105.915,105.915,0,0,0,105.915,70.527Zm0-114.826,40.738-40.739,8.911,8.912L114.827-35.387,155.565,5.352l-8.911,8.912L105.915-26.475,65.177,14.263,56.265,5.352,97-35.387,56.265-76.125l8.912-8.912Z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </g>
        </svg>
      </div>
      <h2 class="mx-auto mt-2">
        {{
          (error | async).errorNumber !== 17
            ? ("ERROR.MESSAGE_TITLE" | translate)
            : ("ERROR.OUT_OF_ORDER" | translate)
        }}
      </h2>

      <div class="px-6 py-4 text-xl font-normal text-center text-yellow-400">
        <h4>
          {{ (error | async).message | translate }}
        </h4>
      </div>
    </div>
  </div>
</ng-container>

<!-- pickup_landscape (only use default theme error 01/2023) -->
<ng-container *ngIf="theme === 'pickup_landscape'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen text-white bg-gray-light-50"
  >
    <!-- <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header> -->
    <div
      class="flex flex-col items-center justify-center flex-1 w-full text-center px-27"
    >
      <div
        class="flex w-[120] h-[120] rounded-full mx-auto flex-col items-center justify-center wow pulse animated mt-4 mb-12 text-gray bg-white"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
        (click)="displayDetailedError()"
      >
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          xmlns:serif="http://www.serif.com/"
          style="
            fill: currentColor;
            fill-rule: evenodd;
            clip-rule: evenodd;
            stroke-linejoin: round;
            stroke-miterlimit: 2;
          "
        >
          <g transform="matrix(1,0,0,1,-512.807,-1216.38)">
            <g transform="matrix(1.2,0,0,1.2,-102.561,-243.275)">
              <path
                d="M562.807,1316.38C590.236,1316.38 612.807,1293.81 612.807,1266.38C612.806,1238.95 590.236,1216.38 562.807,1216.38C535.378,1216.38 512.808,1238.95 512.807,1266.38C512.807,1293.81 535.378,1316.38 562.807,1316.38ZM556.816,1297.75L556.816,1285.72L568.841,1285.72L568.841,1297.75L556.816,1297.75ZM559.426,1281.57L556.302,1249.73L556.302,1235.01L569.312,1235.01L569.312,1249.73L566.231,1281.57L559.426,1281.57Z"
              />
            </g>
          </g>
        </svg>
      </div>
      <h2 class="w-full mx-auto mt-2 text-4xl font-semibold text-gray">
        {{
          (error | async).errorNumber !== 17
            ? ("ERROR.MESSAGE_TITLE" | translate)
            : ("ERROR.OUT_OF_ORDER" | translate)
        }}
      </h2>
      <h4 class="w-full mx-auto mt-6 text-4xl font-normal text-gray">
        {{ (error | async).message | translate }}
      </h4>
    </div>
  </div>
</ng-container>

<!-- pickup_portrait (only use default theme error 01/2023) -->
<ng-container *ngIf="theme === 'pickup_portrait'"></ng-container>

<!-- ski (only use default theme error 01/2023) -->
<ng-container *ngIf="theme === 'ski'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <app-ski-bg
      [isLight]="false"
      [useAnimation]="true"
      [showImg]="false"
      [clockCenter]="true"
      [bigSelection]="true"
      [hasCentral]="true"
      [hasStatus]="true"
    >
      <ng-container slot="top">
        <app-header-ski
          [title]="'ERROR.MESSAGE_TITLE' | translate"
          [subtitle]="'LOCKER_SKI.ERROR_PRESS_ANY_KEY' | translate"
          [big]="true"
        ></app-header-ski>
      </ng-container>

      <!-- central part -->
      <ng-container slot="central">
        <ng-container>
          <div
            class="flex flex-col items-center justify-center w-8/12 pt-32 mx-auto space-y-4 text-center"
          >
            <span class="text-2xl font-medium text-center text-gray">
              {{ (error | async).message | translate }}
            </span>
          </div>
        </ng-container>
      </ng-container>
      <!-- end central part -->

      <ng-container slot="status">
        <div
          class="flex flex-col items-start justify-start ski-thanks-block bg-gray wow bounceInUp animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            (click)="displayDetailedError()"
            class="flex items-center justify-center mx-auto rounded-full w-52 h-52 bg-white-20 -mt-36 wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <div
              class="flex items-center justify-center w-40 h-40 rounded-full bg-white-40 wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <div
                class="flex flex-col items-center justify-center p-4 mx-auto text-white rounded-full w-28 h-28 bg-gray wow bounceIn animated"
                data-wow-delay="0"
                data-wow-duration="1s"
              >
                <div
                  class="flex flex-col items-center justify-center w-full h-full bg-white rounded-full text-gray"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60%"
                    height="60%"
                    viewBox="0 0 110.267 104.151"
                  >
                    <g
                      id="Group_7918"
                      data-name="Group 7918"
                      transform="translate(-236.867 10.407)"
                    >
                      <g
                        id="Group_7919"
                        data-name="Group 7919"
                        transform="translate(-75.001 -69)"
                      >
                        <path
                          id="Vector"
                          d="M0,0V25"
                          transform="translate(367.005 95.633)"
                          fill="none"
                          stroke="#4a5568"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="10"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M50,94.1H19.7C2.348,94.1-4.9,81.7,3.5,66.547l15.6-28.1,14.7-26.4c8.9-16.052,23.5-16.052,32.4,0l14.7,26.454,15.6,28.1c8.4,15.152,1.1,27.554-16.2,27.554H50Z"
                          transform="translate(317.001 63.593)"
                          fill="none"
                          stroke="#4a5568"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="10"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M0,0H.045"
                          transform="translate(366.978 135.639)"
                          fill="none"
                          stroke="#4a5568"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="10"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </app-ski-bg>
  </div>
</ng-container>

<!-- new default -->
<ng-container *ngIf="theme === 'default'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen px-6 bg-white"
    *ngIf="(error | async) != null"
  >
    <!-- infowindow header -->
    <div
      class="flex flex-col items-stretch min-h-[40%] max-h-[40%] justify-between w-full px-8 pt-18 bg-nex-gray-light rounded-b-4xl relative z-10 wow bounceInDown animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <div class="w-full mx-auto flex-1 flex items-center justify-center">
        <img (click)="openSetup()" [src]="logo" class="h-40 max-h-40" />
      </div>

      <!-- screen sign symbol -->
      <div class="w-full h-auto self-end justify-self-end">
        <!-- sign -->
        <div
          class="-mb-32 flex items-center justify-center mx-auto rounded-full w-80 h-80 bg-white-20 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <div
              class="flex flex-col items-center justify-center w-48 h-48 mx-auto bg-white rounded-full text-primaryx wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="wow bounceIn animated"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="9" />
                <line x1="9" y1="10" x2="9.01" y2="10" />
                <line x1="15" y1="10" x2="15.01" y2="10" />
                <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" />
              </svg>
            </div>
          </div>
        </div>
        <!-- end sign -->
      </div>
      <!-- end screen sign symbol -->
    </div>
    <!-- end infowindow header -->

    <!-- infowindow msg part -->
    <div
      class="flex flex-col vert-shadow items-center justify-center flex-1 w-11/12 mx-auto pb-32 pt-52 px-8 h-auto bg-nex-gray-light-ultra relative -top-12 z-5 rounded-b-4xl wow bounceInDown animated"
      data-wow-delay="200ms"
      data-wow-duration=".4s"
    >
      <!-- msg -->
      <div
        class="w-full h-auto flex flex-col items-center justify-center wow fadeIn animated mb-12"
        [ngClass]="showDetailedError ? 'scale-95' : ''"
        data-wow-delay="1s"
        data-wow-duration="1.5s"
      >
        <h1
          class="text-4xl font-bold text-center text-primaryx wow fadeIn animated"
          data-wow-delay="100ms"
          data-wow-duration="1s"
        >
          {{
            (error | async).errorNumber !== 17
              ? ("ERROR.MESSAGE_TITLE" | translate)
              : ("ERROR.OUT_OF_ORDER" | translate)
          }}
        </h1>

        <h2
          class="text-4xl font-medium text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "ERROR.TRY_AGAIN" | translate }}
        </h2>
      </div>
      <!-- end msg -->

      <!-- error description -->
      <div
        class="nex-error-vertical-scroll flex-col justify-start min-h-[500px] px-6 pt-4 pb-4 space-y-4 text-lg font-normal text-center transition-transform text-nex-gray-dark wow fadeIn animated"
        [ngClass]="showDetailedError ? 'flex' : 'hidden'"
        data-wow-delay="1.5s"
        data-wow-duration="1s"
      >
        <h4>
          {{ (error | async).message | translate }}
        </h4>
      </div>
      <!-- end error description -->

      <!-- read more swipe btn -->
      <div
        class="absolute left-0 bottom-0 w-full h-auto flex items-center justify-center"
        (touchstart)="swipe($event, 'start')"
        (touchend)="swipe($event, 'end')"
      >
        <div
          class="-mb-10 flex items-center justify-center w-20 h-20 p-4 rounded-full bg-nex-gray-light-ultra text-primaryx"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="transition-all"
            [ngClass]="showDetailedError ? 'rotate-180' : ''"
          >
            <path
              d="M12.0001 19.1643L18.2072 12.9572L16.793 11.543L12.0001 16.3359L7.20718 11.543L5.79297 12.9572L12.0001 19.1643ZM12.0001 13.5144L18.2072 7.30728L16.793 5.89307L12.0001 10.686L7.20718 5.89307L5.79297 7.30728L12.0001 13.5144Z"
            ></path>
          </svg>
        </div>
      </div>
      <!-- end read more swipe btn -->
    </div>
    <!-- end infowindow msg part -->

    <!-- infowindow empty space at the bottom -->
    <div
      class="w-full block"
      [ngClass]="showDetailedError ? 'h-24' : 'h-107'"
    ></div>
    <!-- end infowindow empty space at the bottom -->
  </div>
</ng-container>
