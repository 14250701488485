<ng-container *ngIf="theme === 'pickup_landscape'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen text-white bg-primaryx"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="flex flex-col items-center justify-center flex-1 w-full px-20 pt-10 text-center wow fadeIn animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <h2 class="w-8/12 mx-auto text-5xl font-bold leading-normal">
        {{ "LOGIN_USER.ENTER_CODE" | translate }}
      </h2>

      <div
        class="w-8/12 mx-auto mt-8 mb-6 text-5xl font-bold text-center outline-none text-primaryx-50 focus:none"
      >
        <!--  -->
        <input
          #cardInput
          type="text"
          autocomplete="off"
          placeholder="{{ 'LOGIN_USER.CARD_ID' | translate }}"
          class="w-full px-6 py-4 font-semibold text-center border-2 outline-none text-primaryx-50 border-red focus:none"
          maxlength="10"
          (keyup.enter)="onEnter()"
          (blur)="onBlurKeyboard($event)"
          value="{{ value }}"
          autofocus
        />
      </div>
      <div class="flex flex-row w-11/12 fullhd_horizontal_keyboard">
        <!-- <input
          (input)="onInputChange($event)"
          class="input text-red"
          value="{{ value }}"
          placeholder="Tap on the virtual keyboard to start"
        /> -->
        <div class="simple-keyboard-pickup"></div>
      </div>
    </div>
    <div
      class="flex flex-row items-center self-end justify-center w-full h-auto pt-4 space-x-16 pb-14"
    >
      <app-btn-sanusx
        (btnClick)="cancel()"
        [label]="'COMMON.BTN_CANCEL' | translate"
        [squared]="true"
        buttonType="neutral"
        class="wow slideInLeft animated"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>

      <app-btn-sanusx
        (btnClick)="loginWithCard()"
        [label]="'COMMON.BTN_SUBMIT' | translate"
        [squared]="true"
        [redBorder]="true"
        buttonType="secondary"
        class="wow slideInRight animated"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="theme === 'pickup_portrait'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen text-white bg-primaryx"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="flex flex-col items-center justify-center flex-1 w-full px-20 py-10 text-center wow fadeIn animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <h2 class="w-8/12 mx-auto text-6xl font-bold leading-normal">
        {{ "LOGIN_USER.ENTER_CODE" | translate }}
      </h2>

      <div
        class="w-11/12 mx-auto my-16 text-5xl font-bold text-center outline-none text-primaryx-50 focus:none"
      >
        <!--  -->
        <input
          #cardInput
          [(ngModel)]="cardNumber"
          type="text"
          autocomplete="off"
          placeholder="{{ 'LOGIN_USER.CARD_ID' | translate }}"
          class="w-full px-6 py-4 font-semibold text-center border-2 outline-none text-primaryx-50 border-red focus:none"
          maxlength="10"
          (keyup.enter)="onEnter()"
          autofocus
        />
      </div>

      <div
        class="flex flex-col items-center self-end justify-center w-full h-auto pt-24 space-y-16 pb-14"
      >
        <app-btn-sanusx
          (btnClick)="loginWithCard()"
          [label]="'COMMON.BTN_SUBMIT' | translate"
          [squared]="true"
          [redBorder]="true"
          buttonType="secondary"
          class="wow slideInRight animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
        </app-btn-sanusx>
        <app-btn-sanusx
          (btnClick)="cancel()"
          [label]="'COMMON.BTN_CANCEL' | translate"
          [squared]="true"
          buttonType="neutral"
          class="wow slideInLeft animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
        </app-btn-sanusx>
      </div>
    </div>
  </div>
</ng-container>

<!-- modal -->
<div
  class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-gray-light-80 backdrop-blur-sm z-90"
  *ngIf="loginFailed"
>
  <div
    class="flex flex-col justify-between w-9/12 px-10 pb-10 mx-auto bg-white h-97 min-h-97 wow bounceIn animated"
    data-wow-delay="0s"
    data-wow-duration=".3s"
  >
    <div class="w-full h-auto mb-10">
      <div
        class="flex items-center justify-center w-24 h-24 mx-auto -mt-12 bg-white border-white rounded-full text-red border-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 100 100"
        >
          <path
            id="Path_13548"
            data-name="Path 13548"
            d="M102,52A50,50,0,1,1,52,2,50,50,0,0,1,102,52ZM58.25,77A6.25,6.25,0,1,1,52,70.75,6.25,6.25,0,0,1,58.25,77ZM52,20.75A6.25,6.25,0,0,0,45.75,27V52a6.25,6.25,0,0,0,12.5,0V27A6.25,6.25,0,0,0,52,20.75Z"
            transform="translate(-2 -2)"
            fill="currentColor"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>

    <div
      class="self-center flex-1 w-full h-auto mx-auto mt-10 text-5xl font-semibold text-center text-red"
    >
      {{ "LOGIN_USER.LOGIN_FAILED" | translate }}
    </div>

    <div
      class="flex flex-row items-start self-end justify-between w-10/12 mx-auto mt-10"
    >
      <app-btn-sanusx
        (btnClick)="errorClose()"
        [label]="'COMMON.BTN_REENTER' | translate"
        buttonType="secondary"
        [fullwidth]="true"
        class="mx-auto"
        [squared]="true"
        [redBorder]="true"
      >
      </app-btn-sanusx>
    </div>
  </div>
</div>
<!-- END modal -->

<div
  class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-white-80 backdrop-blur-sm z-90 wow fadeIn animated"
  data-wow-delay="0s"
  data-wow-duration="0.2s"
  *ngIf="loaderActive"
>
  <div
    id="loader-sanusx"
    class="relative z-20 flex flex-col items-center justify-center flex-grow text-primaryx-50"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
      <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

      <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
    </svg>
    <h3 class="my-10 font-semibold tracking-wider uppercase text-gray">
      {{ "COMMON.PLEASE_WAIT" | translate }}
    </h3>
  </div>
</div>
