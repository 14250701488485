<button
  class="btn-sanusx btn-cat-box wow fadeInUp"
  [ngClass]="smBtn ? 'btn-cat-box--sm' : ''"
  data-wow-delay="0.5s"
  data-wow-duration="0.5s"
  (click)="onClickButton($event)"
>
  <div class="icon">
    <ng-content></ng-content>
  </div>
  <div class="label text-primaryx">{{ label }}</div>
</button>
