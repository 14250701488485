<ng-container *ngIf="buttonType === 'biground'">
  <button
    type="button"
    (click)="onClickButton($event)"
    class="btn btn-round-bigx btn-round-sc-sm group {{ extraCss }}"
  >
    <div class="icon border-primaryx group-active:border-active">
      <span class="icon-in bg-primaryx group-active:bg-active">
        <ng-content></ng-content>
      </span>
    </div>
    <span class="label text-primaryx group-active:text-active">
      {{ label }}
    </span>
  </button>
</ng-container>
<ng-container *ngIf="buttonType === 'bigroundSc'">
  <button
    type="button"
    (pointerup)="onClickButton($event)"
    [touchClick]
    class="btn  group {{ extraCss }}"
  >
    <div class="icon border-primaryx group-active:border-active">
      <span class="icon-in bg-primaryx group-active:bg-active">
        <ng-content></ng-content>
      </span>
    </div>
    <span class="label text-primaryx group-active:text-active">
      {{ label }}
    </span>
  </button>
</ng-container>
<ng-container *ngIf="buttonType === 'side'"></ng-container>
<ng-container *ngIf="buttonType === 'back'">
  <!-- <button
    class="btn btn-back bg-primaryx-50 active:bg-active"
    (click)="onClickButton($event)"
  >
    <span class="icon"><ng-content></ng-content></span>
    <span class="label">{{ label }}</span>
  </button> -->
</ng-container>
<ng-container *ngIf="buttonType === 'category'"></ng-container>
<ng-container *ngIf="buttonType === 'top'"></ng-container>
<ng-container *ngIf="buttonType === 'buy-card'"></ng-container>
<ng-container *ngIf="buttonType === 'buy-details'"></ng-container>
<ng-container *ngIf="buttonType === 'action'"></ng-container>
<ng-container *ngIf="buttonType === 'action-mini'"></ng-container>
