import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import { ModuleType } from 'src/app/core/state/core.state';
import { Retailer } from '../../state/retailer.actions';
import { PreorderType, RetailerState, RetailerStateModel } from '../../state/retailer.state';

@Component({
  selector: 'app-total-selection',
  templateUrl: './total-selection.component.html',
  styleUrls: ['./total-selection.component.scss']
})
export class TotalSelectionComponent implements OnInit {
  theme: string;
  totalForPay: Number = 0;
  portrait: boolean;
  fullHd: boolean = false;
  fullHdHalf: boolean = false;

  constructor(private router: Router, private store: Store) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.portrait = JSON.parse(localStorage.getItem('portrait')) ?? true;
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;

    let cartItems = this.store.selectSnapshot<RetailerStateModel>(RetailerState).shoppingCart;

    this.totalForPay = Number(
      cartItems
        .map((item) => item.product.grossPrice)
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    );
  }

  ngOnInit(): void {
  }

  homeDelivery() {
    this.finishPreorder(PreorderType.HOME);
  }

  payAtTerminal() {
    this.finishPreorder(PreorderType.PAY);
  }
  
  printReceipt() {
    this.finishPreorder(PreorderType.RECEIPT);
  }

  cancelRetailer() {
    this.router.navigate(['/retailer/selection']);
  }

  finishPreorder(preorderType: string) {
    this.store
      .dispatch(new Retailer.BuyCart(preorderType))
      .subscribe(
        () => {
          console.log('sdasdasdsadasee')
          setTimeout(() => {
            this.store.dispatch(new Navigate(["/core/screensaver"]));
          }, 10000);
        },
        (error: MachineError) => {
          this.store
            .dispatch(new Core.Error(error, ModuleType.RETAILER))
            .subscribe(() => {
              setTimeout(() => {
                this.store.dispatch(new Navigate(["/core/screensaver"]));
              }, 10000);
            });
        }
      );
  }
}
