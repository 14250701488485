<ng-container *ngIf="theme === 'default'">
  <div
    class="w-full h-screen flex flex-col items-start justify-center bg-primaryx-bg"
  >
    <div class="w-full flex flex-col items-center justify-center text-center">
      <div class="w-full flex flex-col items-center justify-center">
        <app-cloud-big
          class="wow bounceInDown animated relative z-15 h-107 mx-auto"
          data-wow-delay="0"
          data-wow-duration="1s"
        ></app-cloud-big>
        <div
          class="absolute flex w-53 h-53 mt-[2.625rem] -ml-[0.625rem] mx-auto rounded-full flex-col items-center justify-center text-7xl font-extrabold text-center text-primaryx wow bounceIn animated z-20"
          style="border-radius: 9999px !important"
          data-wow-delay="0.5s"
          data-wow-duration="1s"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60%"
            height="60%"
            viewBox="0 0 135.07 130.127"
            class="wow tada animated"
            data-wow-delay="0"
            data-wow-duration="1s"
            data-wow-iteration="2"
          >
            <g
              id="Group_7413"
              data-name="Group 7413"
              transform="translate(0 48)"
            >
              <path
                id="Path_13557"
                data-name="Path 13557"
                d="M92.183-4.624H76.351C87.2-48,57.374-48,57.374-48H49.865A12.5,12.5,0,0,0,37.448-33.93l.38,3.118a12.741,12.741,0,0,1-1.816,8.35L26.952-9.42A6.786,6.786,0,0,0,29.169-.091a6.787,6.787,0,0,0,9.348-2.22l11.43-16.9a13.722,13.722,0,0,0,2.006-7.1,11.668,11.668,0,0,0-.108-1.681L51.464-31A3.06,3.06,0,0,1,54.5-34.445h2.874a8.588,8.588,0,0,1,5.368,2.874C64.206-29.7,67.134-23.682,63.2-7.9L59,8.931H91.072a6.19,6.19,0,0,1,5.964,7.862L86.273,55.885a6.238,6.238,0,0,1-5.991,4.554H21.21c-3.953,0-7.434,3.242-7.2,7.19a6.753,6.753,0,0,0,6.764,6.365H80.933a18.865,18.865,0,0,0,18.218-13.88l11.278-40.8A18.925,18.925,0,0,0,92.183-4.624"
                transform="translate(23.955)"
                fill="#4a5568"
              />
              <path
                id="Path_13558"
                data-name="Path 13558"
                d="M35.243,33.027a3.031,3.031,0,0,1-3.036,3.036H16.591a3.031,3.031,0,0,1-3.036-3.036V-12.409a3.031,3.031,0,0,1,3.036-3.036H32.207a3.031,3.031,0,0,1,3.036,3.036ZM40.15-29H8.648A8.654,8.654,0,0,0,0-20.352V40.971a8.654,8.654,0,0,0,8.648,8.648h31.5A8.654,8.654,0,0,0,48.8,40.971V-20.352A8.654,8.654,0,0,0,40.15-29"
                transform="translate(0 32.509)"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
      </div>
      <!-- messages -->
      <div
        class="w-9/12 mx-auto wow fadeIn animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <h1
          *ngIf="productsTaken"
          class="title mx-auto text-center text-gray self-end font-bold"
        >
          {{ "RETAILER_FINISH_ORDER.TITLE" | translate }}
        </h1>

        <h1
          *ngIf="!productsTaken"
          class="title mx-auto text-center text-red self-end font-bold"
        >
          {{ "RETAILER_FINISH_ORDER.WARNING" | translate }}
        </h1>

        <h2 class="title mt-2 mx-auto text-center text-gray self-end">
          {{ "RETAILER_FINISH_ORDER.MESSAGE" | translate }}
        </h2>
      </div>
      <!-- end messages -->

      <!-- action buttons -->
      <div
        *ngIf="!finished"
        class="w-9/12 mt-32 mx-auto wow fadeIn animated flex flex-row justify-center items-center space-x-12"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div class="w-1/2">
          <button
            class="w-full mx-auto btn btn-action btn-action--buy-big-ultra bg-white border-gray active:border-active active:bg-active group"
            [ngClass]="fullHdHalf && 'btn-action--middle'"
            (click)="openOnClick()"
          >
            <span class="label text-gray">
              {{ "RETAILER_FINISH_ORDER.OPEN_AGAIN" | translate }}
            </span>
          </button>
        </div>

        <div class="w-1/2">
          <button
            class="w-full mx-auto btn btn-action btn-action--buy-big-ultra bg-success border-success active:border-active active:bg-active group"
            [ngClass]="fullHdHalf && 'btn-action--middle'"
            (click)="finishOnClick()"
          >
            <span class="label text-white">
              {{ "RETAILER_FINISH_ORDER.FINISH" | translate }}
            </span>
          </button>
        </div>
      </div>
      <!-- end action buttons -->
    </div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen px-6 bg-white"
  >
    <!-- infowindow header -->
    <div
      class="flex flex-col items-stretch min-h-[30%] max-h-[30%] justify-end w-full px-8 pt-18 bg-nex-green rounded-b-4xl relative z-10 wow bounceInDown animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <!-- screen sign symbol -->
      <div class="w-full h-auto self-end justify-self-end">
        <!-- sign -->
        <div
          class="-mb-37 flex items-center justify-center mx-auto rounded-full w-80 h-80 bg-white-20 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <div
              class="flex flex-col items-center justify-center w-48 h-48 p-6 mx-auto bg-white rounded-full text-nex-green wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <svg
                width="80%"
                height="80%"
                viewBox="0 0 200 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.2886 83.4378L44.2011 81.4332L52.2194 173.083L29.307 175.088C25.0889 175.457 21.3703 172.337 21.0013 168.119L14.3193 91.7435C13.9503 87.5255 17.0706 83.8068 21.2886 83.4378ZM60.8491 70.0267L105.456 16.8664C106.682 15.405 108.797 15.0677 110.416 16.0749L117.355 20.389C121.3 22.8415 123.382 27.4263 122.632 32.0104L116.851 67.3812L165.733 63.1046C174.169 62.3665 181.606 68.6071 182.344 77.0432L183.75 93.115C183.925 95.1107 183.707 97.1207 183.109 99.0316L164.497 158.494C163.569 161.459 160.943 163.571 157.848 163.842L75.1319 171.079C70.9139 171.448 67.1953 168.328 66.8262 164.109L59.0847 75.623C58.9074 73.5973 59.5422 71.5843 60.8491 70.0267Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
        <!-- end sign -->
      </div>
      <!-- end screen sign symbol -->
    </div>
    <!-- end infowindow header -->

    <!-- infowindow msg part -->
    <div
      class="flex flex-col vert-shadow items-center justify-center flex-1 w-11/12 mx-auto pb-24 pt-28 px-8 h-auto bg-nex-gray-light-ultra relative -top-12 z-5 rounded-b-4xl wow bounceInDown animated"
      data-wow-delay="200ms"
      data-wow-duration=".4s"
    >
      <!-- msg 2 -->
      <div
        class="w-full h-auto flex flex-col items-center justify-center wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <h1
          class="text-6xl font-bold text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "RETAILER_FINISH_ORDER.TITLE" | translate }}
        </h1>
        <h2
          class="text-6xl font-medium text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "RETAILER_FINISH_ORDER.MESSAGE" | translate }}
        </h2>
      </div>
      <!-- end msg 2 -->
    </div>
    <!-- end infowindow msg part -->

    <!-- infowindow empty space at the bottom -->
    <div class="w-full h-24 block"></div>
    <!-- end infowindow empty space at the bottom -->

    <div
      *ngIf="!finished"
      class="w-11/12 mx-auto h-24.2 flex flex-row items-center justify-between wow fadeInUp animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <div
        class="h-full flex-1 flex flex-row space-x-4 items-center justify-center rounded-t-4xl bg-nex-gray-light"
      >
        <!-- BUTTON -->
        <button
          (click)="openOnClick()"
          class="max-w-64 w-auto h-20 bg-transparent flex-row items-center justify-start flex text-primaryx active:bg-white/70 rounded-xl border-2 border-primaryx active:border-nex-gray-dark active:shadow-inner-light"
        >
          <div class="p-4 w-16 h-16 justify-start items-start flex">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9998 29.3337C8.63584 29.3337 2.6665 23.3643 2.6665 16.0003C2.6665 8.63633 8.63584 2.66699 15.9998 2.66699C23.3638 2.66699 29.3332 8.63633 29.3332 16.0003C29.3332 23.3643 23.3638 29.3337 15.9998 29.3337ZM22.4265 22.7683C24.036 21.2428 25.0497 19.1941 25.2861 16.9891C25.5224 14.7842 24.966 12.5671 23.7164 10.7351C22.4668 8.90314 20.6058 7.57592 18.4667 6.99124C16.3276 6.40656 14.0502 6.60263 12.0425 7.54433L13.3425 9.88433C14.3571 9.44338 15.4654 9.26183 16.5677 9.35605C17.6699 9.45027 18.7313 9.8173 19.6563 10.4241C20.5814 11.0308 21.3408 11.8582 21.8663 12.8317C22.3918 13.8051 22.6668 14.8941 22.6665 16.0003H18.6665L22.4265 22.7683ZM19.9572 24.4563L18.6572 22.1163C17.6426 22.5573 16.5343 22.7388 15.432 22.6446C14.3298 22.5504 13.2683 22.1834 12.3433 21.5766C11.4183 20.9699 10.6588 20.1424 10.1333 19.169C9.60785 18.1955 9.33287 17.1066 9.33317 16.0003H13.3332L9.57317 9.23233C7.96369 10.7578 6.94996 12.8066 6.7136 15.0115C6.47724 17.2165 7.03369 19.4335 8.28326 21.2655C9.53283 23.0975 11.3939 24.4247 13.533 25.0094C15.6721 25.5941 17.9495 25.398 19.9572 24.4563Z"
                fill="currentColor"
              />
            </svg>
          </div>

          <div
            class="pr-6 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
          >
            {{ "RETAILER_FINISH_ORDER.OPEN_AGAIN" | translate }}
          </div>
        </button>
        <!-- end BUTTON -->

        <!-- BUTTON -->
        <button
          (click)="finishOnClick()"
          class="max-w-64 w-auto h-20 bg-nex-green flex-row items-center justify-start flex text-white active:bg-nex-green/70 rounded-xl border-2 border-transparent active:border-nex-green active:shadow-inner-light"
        >
          <div class="p-4 w-16 h-16 justify-start items-start flex">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9998 29.3337C8.63584 29.3337 2.6665 23.3643 2.6665 16.0003C2.6665 8.63633 8.63584 2.66699 15.9998 2.66699C23.3638 2.66699 29.3332 8.63633 29.3332 16.0003C29.3332 23.3643 23.3638 29.3337 15.9998 29.3337ZM14.6705 21.3337L24.0972 11.9057L22.2132 10.0203L14.6705 17.563L10.8985 13.791L9.01317 15.6763L14.6705 21.3337Z"
                fill="currentColor"
              />
            </svg>
          </div>

          <div
            class="pr-6 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
          >
            {{ "RETAILER_FINISH_ORDER.FINISH" | translate }}
          </div>
        </button>
        <!-- end BUTTON -->
      </div>
    </div>
  </div>
</ng-container>
