import { Component, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { Store } from '@ngxs/store';
import { Control } from '../../../state/control.actions';
import { CoreState, CoreStateModel } from '../../../state/core.state';
import { Navigate } from '@ngxs/router-plugin';
import { MachineError } from '../../../models/error';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import Keyboard from 'simple-keyboard';
import { ControlService } from 'src/app/core/services/control.service';
import { VendingTypes } from 'src/app/retailer/state/retailer.state';

enum SetupActivity {
  OPEN_HATCH,
  RUN_SETUP,
}

@Component({
  selector: 'app-setup-menu',
  templateUrl: './setup-menu.component.html',
  styleUrls: ['./setup-menu.component.scss'],
})
export class SetupMenuComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'w-full h-full flex flex-col items-center';
  theme: string;
  hatchDataExport: any;
  public loginSuccess = false;
  public loginSubmitted = false;
  public loginEnabled = false;
  uncompletedPickupItemsActive: boolean = false;
  loadLockerPreviewActive: boolean = false;
  showHatch = false;
  subs = new SubSink();
  pickup2Module: boolean = false;
  dropoffModule: boolean = false;
  actionInProgress: boolean = false;
  isWWKS2: boolean = false;
  isOfficeButler: boolean = false;
  isMqtt: boolean = false;

  constructor(
    private store: Store,
    private idle: Idle,
    private controlService: ControlService
  ) {
    this.theme = localStorage.getItem('theme') ?? 'default';

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      // this.pickup2Module = this.store.selectSnapshot<CoreStateModel>(CoreState).config.modules.pickup2;
      // this.dropoffModule = this.store.selectSnapshot<CoreStateModel>(CoreState).config.modules.dropoff;
      if (
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .vendingType == VendingTypes.WWKS2
      ) {
        this.isWWKS2 = true;
      }

      this.isOfficeButler =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.isOfficeButler;

      if (
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .controlSoftwareType == 'mqtt'
      ) {
        this.isMqtt = true;
      }

      this.pickup2Module = true;
      this.dropoffModule = true;
    }
  }

  ngOnDestroy(): void {
    this.idle.stop();
    this.idle.setIdle(30);
    this.subs.unsubscribe();
    this.showHatch = false;
  }

  ngOnInit(): void {
    this.idle.setIdle(140);
    this.idle.watch();
  }

  lockedStatusHandler(status: boolean) {
    this.loginSuccessModal();
  }

  errorStatusHandler(status: boolean) {
    this.loginSubmitted = false;
  }

  valueStatusHandler(status: boolean) {
    this.loginEnabled = status;
  }

  loginSuccessModal() {
    this.loginSuccess = true;
    setTimeout(() => {
      this.loginSuccess = false;
    }, 1200);
  }

  loginWithPassword() {
    this.loginSubmitted = !this.loginSubmitted;
  }

  hatchLoaderStatusHandler(status: boolean) {
    this.showHatch = status;
  }

  goToLockerPreviewHandler(status: boolean) {
    this.loadLockerPreviewActive = status;
  }

  openHatch(): void {
    let hatchObservable = this.store.dispatch(new Control.OpenHatch());

    this.hatchDataExport = {
      observable: hatchObservable,
      activity: SetupActivity.OPEN_HATCH,
    };

    this.showHatch = true;
  }

  moveToBox(): void {
    this.store.dispatch(
      new Navigate(['/core/setup', 'setup-keyboard-input', 'move-to-box'])
    );
  }

  push(): void {
    this.actionInProgress = true;
    // this.store.dispatch(new Navigate(['/core/setup', 'setup-keyboard-input', 'push']));
    this.controlService.push(100).subscribe((res) => {
      console.log(res);
      this.actionInProgress = false;
      //this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
    });
  }

  withdraw(): void {
    this.actionInProgress = true;
    this.controlService.withdraw().subscribe((res) => {
      console.log(res);
      this.actionInProgress = false;
      // this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
    });
  }

  homeAxes(): void {
    this.actionInProgress = true;
    this.controlService.homeAxes().subscribe((res) => {
      console.log(res);
      this.actionInProgress = false;
      this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
    });
  }

  unblock() {
    this.actionInProgress = true;
    this.controlService.terminalClearPickup().subscribe((res) => {
      console.log(res);
      this.actionInProgress = false;
      this.store.dispatch(new Navigate(['/core/screensaver']));
    });
  }

  testPosition(): void {
    this.store.dispatch(
      new Navigate(['/core/setup', 'setup-keyboard-input', 'test-position'])
    );
  }

  uncompletedPickupItems(): void {
    // this.uncompletedPickupItemsActive = true;
    this.store.dispatch(
      new Navigate(['/core/setup', 'uncompleted-pickup-items'])
    );
  }

  cancel(): void {
    this.store.dispatch(new Navigate(['/core/screensaver']));
  }

  openLockers(): void {
    console.warn('openLockers');
    this.store.dispatch(
      new Navigate(['/core/setup', 'setup-keyboard-input', 'open-locker'])
    );
  }
  openLockersOb(): void {
    console.warn('openLockers');
    this.store.dispatch(
      new Navigate(['/core/setup', 'setup-keyboard-input', 'open-locker-ob'])
    );
  }

  openMultipleLockersOb(): void {
    console.warn('openMultipleLockers');
    this.store.dispatch(
      new Navigate(['/core/setup', 'setup-keyboard-input', 'open-row-ob'])
    );
  }

  selectLockers(): void {
    console.warn('selectLockers');
    this.store.dispatch(new Navigate(['/core/setup', 'locker-selection']));
  }
}
