import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pickup-list-item',
  templateUrl: './pickup-list-item.component.html',
  styleUrls: ['./pickup-list-item.component.scss']
})
export class PickupListItemComponent implements OnInit {
  @Input() iconname: string;
  @Input() label: string;
  @Input() lockernumber: string;
  @Output() btnClick = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  @Input() disabledReason: string = "";
  constructor() { }

  ngOnInit(): void {
  }

  onClickButton(event) {
    this.btnClick.emit(event);
  }
}
