<div class="flex flex-col items-start justify-between w-full h-screen">
  <app-ski-bg
    [isLight]="false"
    [useAnimation]="true"
    [showImg]="true"
    [imgUrl]="'/assets/mb-mountains-people.jpeg'"
    [showClock]="false"
    [clockCenter]="true"
    [bigSelection]="false"
    [hasCentral]="true"
    [hasStatus]="true"
    [showImg]="false"
  >
    <ng-container slot="top">
      <app-header-ski
        [title]="'LOCKER_SKI.LOCK_WAIT_TITLE' | translate"
        [subtitle]="'LOCKER_SKI.LOCK_WAIT_SUBTITLE' | translate"
        [big]="false"
        [counter]="true"
        [countdown]="countdown"
        [countdownFull]="countdownFull"
      ></app-header-ski>
    </ng-container>

    <!-- central part -->
    <ng-container slot="central">
      <div
        class="flex flex-col items-center justify-center w-2/3 h-auto pt-16 text-center"
        *ngIf="countdown > 0"
      >
        <h1
          class="pb-1 text-7xl animate-pulse font-extrabold leading-[0px] text-primaryx"
        >
          {{ countdown }}
        </h1>
        <span class="px-1 pt-4 text-xl">
          {{ "COMMON.SECONDS" | translate }}
        </span>
      </div>

      <div
        class="flex flex-col items-center justify-center h-auto mx-auto w-96"
      ></div>
    </ng-container>
    <!-- end central part -->

    <!-- left btns -->
    <ng-container slot="btn4">
      <ng-container *ngIf="lockers[0]">
        <app-btn-ski
          (btnClick)="!lockers[0].isEmpty ? openLocker(lockers[0]) : ''"
          [label]="'COMMON.BTN_OPEN' | translate"
          [big]="false"
          [type]="lockerType(0)"
          [right]="false"
          [number]="lockers[0].number"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn3">
      <ng-container *ngIf="lockers[1]">
        <app-btn-ski
          (btnClick)="!lockers[1].isEmpty ? openLocker(lockers[1]) : ''"
          [label]="'COMMON.BTN_OPEN' | translate"
          [big]="false"
          [type]="lockerType(1)"
          [right]="false"
          [number]="lockers[1].number"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn2">
      <ng-container *ngIf="lockers[2]">
        <app-btn-ski
          (btnClick)="!lockers[2].isEmpty ? openLocker(lockers[2]) : ''"
          [label]="'COMMON.BTN_OPEN' | translate"
          [big]="false"
          [type]="lockerType(2)"
          [right]="false"
          [number]="lockers[2].number"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn1">
      <ng-container *ngIf="lockers[3]">
        <app-btn-ski
          (btnClick)="!lockers[3].isEmpty ? openLocker(lockers[3]) : ''"
          [label]="'COMMON.BTN_OPEN' | translate"
          [big]="false"
          [type]="lockerType(3)"
          [right]="false"
          [number]="lockers[3].number"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <!-- end left btns -->

    <!-- right btns -->
    <ng-container slot="btn8"></ng-container>
    <ng-container slot="btn7"></ng-container>
    <ng-container slot="btn6">
      <app-btn-ski
        *ngIf="isAllClosed()"
        (btnClick)="finishRentProcessButtonClick()"
        [label]="'COMMON.BTN_SUBMIT' | translate"
        [big]="false"
        [type]="'subaction'"
        [subActionType]="'finish'"
        [right]="true"
      >
      </app-btn-ski>
    </ng-container>
    <ng-container slot="btn5">
      <app-btn-ski
        (btnClick)="onCancel()"
        [label]="'COMMON.BTN_CANCEL' | translate"
        [big]="false"
        [type]="'subaction'"
        [subActionType]="'cancel'"
        [right]="true"
      >
      </app-btn-ski>
    </ng-container>
    <!-- end right btns -->
    <ng-container slot="status">
      <app-ski-msg
        [msg]="'LOCKER_SKI.LOCK_WAIT_NOTICE' | translate"
        [msgType]="'warning'"
      ></app-ski-msg>
      <!-- msgType ==> warning, error, info, danger, success -->
    </ng-container>
  </app-ski-bg>
</div>
