import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btn-ski',
  templateUrl: './btn-ski.component.html',
  styleUrls: ['./btn-ski.component.scss']
})
export class BtnSkiComponent implements OnInit {

  @Input() label: string;
  @Input() desc: string = "";
  @Input() big: boolean = false;
  @Input() right: boolean = false;
  @Input() withAnimation: boolean = true;
  @Input() type: string = "default"; //default, selected, selectednumber, error, wait, subaction, super
  @Input() subActionType: string = "back"; //unlock, cancel
  @Input() extraCss: string = "";
  @Input() number: number = null;
  @Output() btnClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  onClickButton(event) {
    this.btnClick.emit(event);
  }
}
