import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Idle } from '@ng-idle/core';

import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { element } from 'protractor';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';
import { Retailer } from 'src/app/retailer/state/retailer.actions';
@Component({
  selector: 'app-delivery-positions',
  templateUrl: './delivery-positions.component.html',
  styleUrls: ['./delivery-positions.component.scss'],
})
export class DeliveryPositionsComponent implements OnInit, OnDestroy {
  public theme: string;
  public title: string;
  private deliveryAction: string;
  private deliveryId: number;

  public deliveryPositions: Array<any> = [];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private idle: Idle
  ) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.route.params.subscribe((params) => {
      this.deliveryAction = params.action;
      this.deliveryId = params.deliveryId;

      if (params.action == 'load') {
        this.title = 'Einlagerung';
        this.showPositionsForStoring();
      } else {
        this.title = 'Abholung';
        this.showPositionsForPickup();
      }
    });
  }

  showPositionsForPickup() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.pickupDeliveries) {
      activeUser.pickupDeliveries.forEach((delivery) => {
        if (delivery.id == this.deliveryId) {
          this.deliveryPositions = delivery.positions;
        }
      });
    }
  }

  showPositionsForStoring() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.deliveriesToLoad) {
      activeUser.deliveriesToLoad.forEach((delivery) => {
        if (delivery.id == this.deliveryId) {
          this.deliveryPositions = delivery.positions;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  public processDeliveryPosition(deliveryPosition: any) {
    this.store.dispatch(
      new Navigate([
        '/locker',
        'delivery',
        'locker-preview',
        this.deliveryAction,
        this.deliveryId,
        deliveryPosition.id,
      ])
    );
  }

  isDeliveryItemDisabled(item: any) {
    if (this.deliveryAction == 'load') {
      return item.status != 0;
    } else {
      return item.status != 1;
    }
  }

  getDeliveryItemDisabledReason(item: any) {
    if (this.deliveryAction == 'load') {
      if (item.status == 1) return 'Eingelagert';
      if (item.status == 2) return 'Abgeholt';
      return '';
    } else {
      if (item.status == 0) return 'Nicht eingelagert';
      if (item.status == 2) return 'Abgeholt';
      return '';
    }
  }

  cancel() {
    this.store.dispatch(new Retailer.ActionSelection());
  }
}
