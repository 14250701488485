<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="w-full h-screen flex flex-col items-start justify-between bg-primaryx text-white"
  >
    <app-header [showSearch]="false" class="w-full"></app-header>
    <div
      class="w-full flex flex-col items-center justify-center text-center px-27"
    >
      <h2 class="font-bold mb-8">
        {{ "RETAILER_PICKUP_DETAILS.CODE_CORRECT" | translate }}
      </h2>
      <h2
        *ngIf="(pickup$ | async).product.grossPrice > 0"
        class="title mx-auto text-center self-end font-medium mb-8"
      >
        {{ "RETAILER_PICKUP_DETAILS.MESSAGE_1" | translate }}
      </h2>
      <h2
        *ngIf="(pickup$ | async).product.grossPrice == 0"
        class="title mx-auto text-center self-end font-medium mb-8"
      >
        {{ "RETAILER_PICKUP_DETAILS.MESSAGE_2" | translate }}
      </h2>
      <div
        class="flex w-full h-auto mx-auto flex-col items-center justify-center wow pulse animated my-10 text-white"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="211.83"
          height="211.828"
          viewBox="0 0 211.83 211.828"
        >
          <g
            id="Group_7451"
            data-name="Group 7451"
            transform="translate(0 141.301)"
          >
            <path
              id="Path_13624"
              data-name="Path 13624"
              d="M105.915,70.527A105.915,105.915,0,0,0,211.83-35.388,105.915,105.915,0,0,0,105.915-141.3,105.915,105.915,0,0,0,0-35.388,105.915,105.915,0,0,0,105.915,70.527ZM84.206-3.655,46.242-41.619,37.33-32.708,75.295,5.257l-.094.094,8.912,8.912L174.5-76.126l-8.911-8.911Z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </g>
        </svg>
      </div>
      <div
        *ngIf="(pickup$ | async).product.grossPrice > 0"
        class="w-97 max-w-97 min-w-97 h-auto flex flex-col items-center justify-center py-5 px-7 bg-primaryx-accent rounded-full my-16 text-6xl font-semibold text-center text-white relative mx-auto"
      >
        € {{ (pickup$ | async).product.grossPrice.toFixed(2) }}
        <div
          class="w-full h-full absolute left-0 top-0 rounded-full animate-ping bg-primaryx-accent opacity-50"
        ></div>
      </div>
      <!-- <h2 class="mt-2 mx-auto">Die Ware wird ausgegeben.</h2> -->
    </div>
    <div
      class="w-full self-center flex flex-col justify-start items-start wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <div class="w-9/12 flex flex-col justify-between items-start mx-auto">
        <ng-container *ngIf="(pickup$ | async).product.grossPrice == 0">
          <app-btn-sanusx
            (btnClick)="pickup()"
            [label]="'COMMON.BTN_PICKUP' | translate"
            buttonType="secondary"
            class="mx-auto wow slideInUp animated"
            data-wow-delay="0s"
            data-wow-duration="1s"
          >
          </app-btn-sanusx>
        </ng-container>

        <ng-container *ngIf="(pickup$ | async).product.grossPrice > 0">
          <app-btn-sanusx
            (btnClick)="pickup()"
            [label]="'COMMON.BTN_PAY' | translate"
            buttonType="secondary"
            class="mx-auto wow slideInUp animated"
            data-wow-delay="0s"
            data-wow-duration="1s"
          >
          </app-btn-sanusx>
        </ng-container>

        <app-btn-sanusx
          (btnClick)="cancel()"
          [label]="'COMMON.BTN_CANCEL' | translate"
          buttonType="neutral"
          class="mx-auto wow slideInUp animated mb-20 mt-32"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
        </app-btn-sanusx>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="theme === 'default'">
  <div class="w-full h-screen flex flex-col items-start justify-between">
    <div class="first-block bg-primaryx-success flex flex-row justify-center">
      <div
        class="w-97 h-97 flex items-center justify-center bg-white-20 rounded-full mx-auto wow bounceIn animated mt-10"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div
          class="w-80 h-80 flex items-center justify-center bg-white-40 rounded-full wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex w-53 h-53 bg-green mx-auto rounded-full flex-col items-center justify-center text-white wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="182.66"
              height="182.66"
              viewBox="0 0 182.66 182.66"
              class="wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <path
                id="Path_13266"
                data-name="Path 13266"
                d="M93.33,184.66A91.33,91.33,0,1,0,2,93.33,91.33,91.33,0,0,0,93.33,184.66ZM135.65,78.569a11.415,11.415,0,1,0-16.144-16.144L81.914,100.02,67.153,85.259A11.415,11.415,0,1,0,51.009,101.4l22.834,22.831a11.416,11.416,0,0,0,16.143,0L135.65,78.569Z"
                transform="translate(-2 -2)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="middle-block flex flex-col items-center text-center wow fadeIn animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <h1
        class="title mx-auto text-center text-primaryx-dark self-end font-bold"
      >
        {{ "RETAILER_PICKUP_DETAILS.CODE_CORRECT" | translate }}
      </h1>
      <h2
        *ngIf="(pickup$ | async).product.grossPrice > 0"
        class="title mx-auto text-center text-primaryx-dark self-end font-medium mb-8"
      >
        {{ "RETAILER_PICKUP_DETAILS.MESSAGE_1" | translate }}
      </h2>
      <h2
        *ngIf="(pickup$ | async).product.grossPrice == 0"
        class="title mx-auto text-center text-primaryx-dark self-end font-medium mb-8"
      >
        {{ "RETAILER_PICKUP_DETAILS.MESSAGE_2" | translate }}
      </h2>

      <div
        *ngIf="(pickup$ | async).product.grossPrice > 0"
        class="w-97 max-w-97 min-w-97 h-auto flex flex-col items-center justify-center py-5 px-7 bg-primaryx-accent rounded-full my-16 text-6xl font-semibold text-center text-white relative mx-auto"
      >
        € {{ (pickup$ | async).product.grossPrice.toFixed(2) }}
        <div
          class="w-full h-full absolute left-0 top-0 rounded-full animate-ping bg-primaryx-accent opacity-50"
        ></div>
      </div>
      <h2 class="title mx-auto text-center text-primaryx-dark self-end mb-8">
        {{ "RETAILER_PICKUP_DETAILS.ISSUED" | translate }}
      </h2>
    </div>

    <div
      class="third-block bg-primaryx-bg flex flex-col justify-start items-start wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
    >
      <div class="w-9/12 flex flex-row justify-between items-start mx-auto">
        <button
          class="btn btn-action border-gray-light group active:border-active mx-auto"
          [ngClass]="fullHdHalf && 'btn-action--middle'"
          (click)="cancel()"
        >
          <span class="icon bg-gray group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49.498"
              height="49.498"
              viewBox="0 0 49.498 49.498"
            >
              <path
                id="close_icon"
                data-name="close_icon"
                d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                transform="translate(950.191 -4593.964) rotate(-45)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label text-gray group-active:text-active">
            {{ "COMMON.BTN_CANCEL" | translate }}
          </span>
        </button>
        <button
          class="btn btn-action btn-action--success border-primaryx-success active:border-active mx-auto group"
          [ngClass]="fullHdHalf && 'btn-action--middle'"
          (click)="pickup()"
        >
          <span class="icon bg-primaryx-success group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.086"
              height="21.035"
              viewBox="0 0 28.086 21.035"
            >
              <path
                id="icon_check"
                data-name="icon_check"
                d="M27.017-15.018a3.5,3.5,0,0,0,1.069-2.517,3.517,3.517,0,0,0-3.5-3.5,3.5,3.5,0,0,0-2.517,1.069L10.543-8.441,6.017-12.967A3.5,3.5,0,0,0,3.5-14.035a3.517,3.517,0,0,0-3.5,3.5A3.5,3.5,0,0,0,1.069-8.018l7,7a3.517,3.517,0,0,0,4.949,0Z"
                transform="translate(0 21.035)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </span>
          <span
            *ngIf="(pickup$ | async).product.grossPrice == 0"
            class="label text-primaryx-success group-active:text-active"
          >
            {{ "COMMON.BTN_PICKUP" | translate }}
          </span>
          <span
            *ngIf="(pickup$ | async).product.grossPrice > 0"
            class="label text-primaryx-success group-active:text-active"
          >
            {{ "COMMON.BTN_PAY" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
