import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { LockerSki } from 'src/app/locker/state/locker-ski.actions';
import {
  LockerSkiState,
  LockerSkiStateModel,
} from 'src/app/locker/state/locker-ski.state';
import { LockerSkiHttpService } from 'src/app/locker/services/locker-ski-http.service';
import { switchMap, tap } from 'rxjs';
@Component({
  selector: 'app-lock-wait',
  templateUrl: './lock-wait.component.html',
  styleUrls: ['./lock-wait.component.scss'],
})
export class SkiLockWaitComponent implements OnInit, OnDestroy {
  numberOfLockers: number;
  freeSlots: any[];

  countdown = 30;
  countdownFull = 30;

  closedLockersCheckInterval: number = 5000;
  closedLockersCheckTimer = null;

  timer = null;
  timerIsStopped: boolean = false;

  lockers: Array<any> = [];
  saving: any;

  constructor(
    private store: Store,
    private lockerSkiService: LockerSkiHttpService
  ) {
    this.numberOfLockers =
      this.store.selectSnapshot<LockerSkiStateModel>(
        LockerSkiState
      ).numberOfLockers;
    this.freeSlots =
      this.store.selectSnapshot<LockerSkiStateModel>(LockerSkiState).freeSlots;

    for (let i = 0; i < this.numberOfLockers; i++) {
      this.lockers.push({
        id: i,
        number: 0,
        slotId: 0,
        isEmpty: true,
      });
    }

    this.startTimer();

    this.startClosedLocksCheckTimer();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.stopTimer();
    this.stopClosedLocksCheckTimer();
  }

  checkClosedLocks(result: any): void {
    if (result.controlError != 0) {
      // throw error?
    } else {
      let closedLocks: [] = result.closedLocks;

      closedLocks.forEach((closedLock) => {
        let currentFreeSlot = this.freeSlots.find(
          (freeSlot) =>
            !this.isCurrentlyTaken(closedLock) &&
            freeSlot.slot_index === closedLock
        );
        if (currentFreeSlot) {
          this.storeNewLocker(closedLock, currentFreeSlot);
        }
      });

      if (this.isAllClosed()) {
        this.stopClosedLocksCheckTimer();
      }
    }
  }

  isCurrentlyTaken(closedLock: any): boolean {
    return this.lockers.find((element) => element.number == closedLock);
  }

  storeNewLocker(closedLock: number, currentFreeSlot: any) {
    for (let i = 0; i < this.lockers.length; i++) {
      const element = this.lockers[i];

      if (element.isEmpty) {
        element.number = closedLock;
        element.isEmpty = false;
        element.slotId = currentFreeSlot.id;

        if (this.isAllClosed()) {
          this.countdown = 10;
          this.countdownFull = 10;
        } else {
          this.countdown = 30;
          this.countdownFull = 30;
        }

        return;
      }
    }
  }

  isAllClosed() {
    return this.lockers.every((v) => v.isEmpty === false);
  }

  lockerType(index: number) {
    return this.lockers[index].isEmpty ? 'wait' : 'selected';
  }

  startTimer() {
    this.stopTimer();
    this.timer = setInterval(() => {
      this.timerTick();
    }, 1000);
  }

  private timerTick() {
    this.countdown--;

    if (this.countdown <= 0) {
      this.stopTimer();
      //check if at least one is closed
      if (this.isAtLeastOneClosed()) {
        this.finishRentProcess();
      } else {
        this.countdown = 30;
        this.countdownFull = 30;
        this.onCancel();
      }
    }
  }

  isAtLeastOneClosed() {
    let locker = this.lockers.find((v) => v.isEmpty === false);
    return locker != undefined;
  }

  private finishRentProcess() {
    let fullLockers = this.lockers.filter((v) => v.isEmpty === false);
    if (!this.saving) {
      this.saving = true;
      this.store.dispatch(new LockerSki.FinishRentProcess(fullLockers));
    }
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  startClosedLocksCheckTimer() {
    this.stopClosedLocksCheckTimer();

    this.closedLockersCheckTimer = setInterval(() => {
      this.lockerSkiService.getClosedLocks().subscribe({
        next: (v) => this.checkClosedLocks(v),
        error: (e) => console.error(e),
      });
    }, this.closedLockersCheckInterval);
  }

  stopClosedLocksCheckTimer() {
    if (this.closedLockersCheckTimer) {
      clearInterval(this.closedLockersCheckTimer);
      this.closedLockersCheckTimer = null;
    }
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    switch (event.key) {
      case '4':
        if (this.lockers[0]) {
          this.openLocker(this.lockers[0]);
        }
        break;
      case '3':
        if (this.lockers[1]) {
          this.openLocker(this.lockers[1]);
        }
        break;
      case '2':
        if (this.lockers[2]) {
          this.openLocker(this.lockers[2]);
        }
        break;
      case '1':
        if (this.lockers[3]) {
          this.openLocker(this.lockers[3]);
        }
        break;
      case '8':
        //
        break;
      case '7':
        break;
      case '6':
        this.finishRentProcessButtonClick();
        break;
      case '5':
        this.onCancel();
        break;
    }
  }

  public finishRentProcessButtonClick() {
    if (this.isAllClosed()) {
      this.stopTimer();
      this.finishRentProcess();
    }
  }

  openLocker(locker: any) {
    this.lockerSkiService.unlockSlots([locker.number]).subscribe({
      next: (x) => {
        locker.number = 0;
        locker.isEmpty = true;
        locker.slotId = 0;
        this.countdown = 30;
        this.countdownFull = 30;

        if (!this.closedLockersCheckTimer) {
          this.startClosedLocksCheckTimer();
        }
      },
    });
  }

  onCancel() {
    this.store
      .dispatch(new LockerSki.RevertTransaction())
      .subscribe((x) => this.store.dispatch(new LockerSki.ResetRentProcess()));
  }
}
