import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-initialization',
  templateUrl: './initialization.component.html',
  styleUrls: ['./initialization.component.scss']
})
export class InitializationComponent implements OnInit {
  theme: string;
  portrait: boolean;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;


  constructor() {
    this.theme = localStorage.getItem('theme') ?? 'default';
    
    this.portrait = JSON.parse(localStorage.getItem('portrait')) ?? true;
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
  }

  ngOnInit(): void {
  }
}
