import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Locker } from '../../../state/locker.actions';
import { Idle } from '@ng-idle/core';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class RentSelectionComponent implements OnInit, OnDestroy {
  public theme: string;
  tenant: string;
  constructor(private store: Store, private idle: Idle) {
    this.theme = localStorage.getItem('theme') ?? 'default';

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.tenant = this.store.selectSnapshot<CoreStateModel>(CoreState).config.tenant;

    }
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  keyCollect(): void {
    this.store.dispatch(new Locker.RentProcess('take'));
  }

  handBack(): void {
    this.store.dispatch(new Locker.RentProcess('return'));
  }
}
