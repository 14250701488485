import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() label: string;
  @Input() extraCss: string;


  @Input() buttonType: any;

  @Output() btnClick = new EventEmitter<any>();



  constructor() { }

  ngOnInit(): void {
  }

  onClickButton(event) {
    this.btnClick.emit(event);
  }

}
