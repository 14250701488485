export namespace Locker {
  export class CheckRentCode {
    static readonly type = '[Pickup] CheckRentCode';
    constructor(public pickupCode: string) {}
  }

  export class RentProcess {
    static readonly type = '[Pickup] RentProcess';
    constructor(public actionName: string) {}
  }

  export class ResetRentProcess {
    static readonly type = '[Pickup] ResetRentProcess';
    constructor() {}
  }

  export class ConfirmRentCodeCorrect {
    static readonly type = '[Pickup] ConfirmRentCodeCorrect';
    constructor(public isInterested: boolean) {}
  }

  export class FinishRentProcess {
    static readonly type = '[Pickup] FinishRentProcess';
    constructor() {}
  }

  export class TakeDeliveryItem {
    static readonly type = '[Pickup] TakeDeliveryItem';
    constructor(public deliveryPositionId: number) {}
  }

  export class OpenLocker {
    static readonly type = '[Pickup] OpenLocker';
    constructor(public slotIndex: number, public containerCode: any) {}
  }
  export class CheckLockerDoor {
    static readonly type = '[Pickup] CheckLockerDoor';
    constructor(public slotIndex: number) {}
  }

  export class TakeDropoffItem {
    static readonly type = '[Pickup] TakeDropoffItem';
    constructor(public deliveryPositionId: number, public cardNumber: string) {}
  }

  export class StoreDeliveryItem {
    static readonly type = '[Pickup] StoreDeliveryItem';
    constructor(public deliveryPositionId: number, public slotId: number) {}
  }

  export class StoreDropoffItem {
    static readonly type = '[Pickup] StoreDropoffItem';
    constructor(
      public slotId: number,
      public slotIndex: number,
      public message: string,
      public containerCode: number
    ) {}
  }
  export class StartRentItem {
    static readonly type = '[Pickup] StartRentItem';
    constructor(public slotId: number) {}
  }
  export class FinishRentItem {
    static readonly type = '[Pickup] FinishRentItem';
    constructor(public deliveryId: number, public message: string) {}
  }
}
