import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { MachineError } from '../models/error';
import { CoreStateModel, CoreState } from '../state/core.state';

enum STATE {
  START = 'START',
  SHUTDOWN = 'SHUTDOWN',
  OK = 'OK',
  MAINTENANCE = 'MAINTENANCE',
  ERROR = 'ERROR',
  POWER_CUT = 'POWER_CUT',
  UNKNOWN = 'UNKNOWN',
}

interface Machine {
  id: number;
}
interface MachineState {
  name: STATE;
  label: string;
}

interface ReportMachine {
  machine: Machine;
  state: MachineState;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class PingService {
  constructor(private httpClient: HttpClient, private store: Store) {}

  getReport(machineError: MachineError) {
    let report: ReportMachine;

    if (machineError == null) {
      report = {
        machine: null,
        state: {
          name: STATE.OK,
          label: STATE.OK,
        },
        message: 'Everything ok',
      };
    } else {
      report = {
        machine: null,
        state: {
          name: STATE.MAINTENANCE,
          label: STATE.MAINTENANCE,
        },
        message:
          machineError.module +
          '_' +
          machineError.errorNumber +
          ' - ' +
          machineError.message,
      };
    }

    return report;
  }

  ping(report: any) {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;
    let url;

    if (apiVersion == 2) {
      url = environment.configApi + '/machine/report-machine';
    } else {
      url = environment.api + '/fw/report/reportMachine';
    }

    return this.httpClient.post(url, report);
  }

  sendTemperatureInfo(temperature: number) {
    return this.httpClient.post(this.getReportTemperatureUrl(), {
      temperature: temperature,
      timestamp: new Date(),
    });
  }

  private getReportTemperatureUrl(): string {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return environment.configApi + '/machine/report-temperature';
    } else {
      return environment.api + '/fw/report/reportTemperature';
    }
  }
}
