import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { MachineError } from '../models/error';
import { CoreState, CoreStateModel } from '../state/core.state';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private httpClient: HttpClient, private store: Store) { }

  getConfig() {
    // let apiVersion = this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    // if (apiVersion == 2) {
    //   return this.httpClient.get(environment.configApi + '/machine/customization');
    // } else {
    //   return this.httpClient.get(environment.configApi + '/machine/customization');
    // }
    return this.httpClient.get(environment.configApi + '/machine/customization');
  }

  getMachine() {
    let apiVersion = this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.httpClient.get(environment.configApi + '/machine/data');
    } else {
      return of(null);
    }
  }


}
