<ng-container *ngIf="theme === 'pickup_landscape'">
  <div
    class="w-full h-screen flex flex-col items-start justify-start bg-primaryx text-white"
  >
    <div
      class="w-full px-4 py-4 grid grid-cols-8 gap-y-px gap-x-px"
      *ngIf="selected === null"
    >
      <div>
        <div class="locker-inner locker-inner--disabled">01</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">02</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">03</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">04</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">05</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">06</div>
      </div>
      <div>
        <div
          (click)="onSelectedLocker(07)"
          class="locker-inner locker-inner--free"
        >
          07
          <div class="locker-info">
            <span>30</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div class="row-span-2">
        <div
          (click)="onSelectedLocker(08)"
          class="locker-inner locker-inner--free"
        >
          08

          <div class="locker-info">
            <span>30</span>
            <span>60</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">09</div>
      </div>
      <div class="col-span-2 row-span-2">
        <div
          (click)="onSelectedLocker(10)"
          class="locker-inner locker-inner--free"
        >
          10

          <div class="locker-info">
            <span>60</span>
            <span>60</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">11</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">12</div>
      </div>
      <div>
        <div
          (click)="onSelectedLocker(13)"
          class="locker-inner locker-inner--free"
        >
          13
          <div class="locker-info">
            <span>30</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">14</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">15</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">16</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">17</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">18</div>
      </div>
      <div class="row-span-2">
        <div
          (click)="onSelectedLocker(19)"
          class="locker-inner locker-inner--free"
        >
          19
          <div class="locker-info">
            <span>30</span>
            <span>60</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div class="col-span-3 row-span-2">
        <div
          (click)="onSelectedLocker(20)"
          class="locker-inner locker-inner--free"
        >
          20

          <div class="locker-info">
            <span>90</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">21</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">22</div>
      </div>
      <div class="col-span-2">
        <div
          (click)="onSelectedLocker(23)"
          class="locker-inner locker-inner--free"
        >
          23

          <div class="locker-info">
            <span>60</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div class="col-span-2">
        <div
          (click)="onSelectedLocker(24)"
          class="locker-inner locker-inner--free"
        >
          24

          <div class="locker-info">
            <span>60</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">25</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">26</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">27</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">28</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">29</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">30</div>
      </div>
    </div>

    <!-- <div
      class="w-1/2 h-screen px-4 py-4 grid grid-cols-4 gap-y-px gap-x-px"
      *ngIf="selected === null"
    >
      <div class="row-span-4">
        <div class="locker-inner">01</div>
      </div>
      <div class="col-span-3 row-span-2">
        <div class="locker-inner">02</div>
      </div>
      <div class="col-span-3 row-span-2">
        <div class="locker-inner">03</div>
      </div>
      <div class="row-span-4">
        <div class="locker-inner">04</div>
      </div>
      <div>
        <div class="locker-inner">05</div>
      </div>
      <div class="col-span-2 row-span-4">
        <div class="locker-display">
          <div class="screen"></div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--free">07</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--free">08</div>
      </div>
      <div>
        <div class="locker-inner">09</div>
      </div>
      <div class="row-span-4">
        <div class="locker-inner locker-inner--free">10</div>
      </div>
      <div class="col-span-3 row-span-2">
        <div class="locker-inner">11</div>
      </div>
      <div class="col-span-3 row-span-2">
        <div class="locker-inner">12</div>
      </div>
      <div class="row-span-4">
        <div class="locker-inner locker-inner--free">13</div>
      </div>
      <div class="col-span-3 row-span-2">
        <div class="locker-inner">14</div>
      </div>
      <div class="col-span-3 row-span-2">
        <div class="locker-inner">15</div>
      </div>
    </div> -->

    <div
      class="w-full px-4 py-4 grid grid-cols-8 gap-y-px gap-x-px"
      *ngIf="selected !== null"
    >
      <div>
        <div class="locker-inner locker-inner--disabled">01</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">02</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">03</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">04</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">05</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">06</div>
      </div>
      <div>
        <div
          class="locker-inner {{
            selected === 07
              ? 'locker-inner--selected'
              : 'locker-inner--disabled'
          }}"
        >
          07
          <div class="locker-info">
            <span>30</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div class="row-span-2">
        <div
          class="locker-inner  {{
            selected === 08
              ? 'locker-inner--selected'
              : 'locker-inner--disabled'
          }}"
        >
          08

          <div class="locker-info">
            <span>30</span>
            <span>60</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">09</div>
      </div>
      <div class="col-span-2 row-span-2">
        <div
          class="locker-inner  {{
            selected === 10
              ? 'locker-inner--selected'
              : 'locker-inner--disabled'
          }}"
        >
          10

          <div class="locker-info">
            <span>60</span>
            <span>60</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">11</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">12</div>
      </div>
      <div>
        <div
          class="locker-inner  {{
            selected === 13
              ? 'locker-inner--selected'
              : 'locker-inner--disabled'
          }}"
        >
          13
          <div class="locker-info">
            <span>30</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">14</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">15</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">16</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">17</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">18</div>
      </div>
      <div class="row-span-2">
        <div
          class="locker-inner  {{
            selected === 19
              ? 'locker-inner--selected'
              : 'locker-inner--disabled'
          }}"
        >
          19
          <div class="locker-info">
            <span>30</span>
            <span>60</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div class="col-span-3 row-span-2">
        <div
          class="locker-inner  {{
            selected === 20
              ? 'locker-inner--selected'
              : 'locker-inner--disabled'
          }}"
        >
          20

          <div class="locker-info">
            <span>90</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">21</div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">22</div>
      </div>
      <div class="col-span-2">
        <div
          class="locker-inner  {{
            selected === 23
              ? 'locker-inner--selected'
              : 'locker-inner--disabled'
          }}"
        >
          23

          <div class="locker-info">
            <span>60</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div class="col-span-2">
        <div
          class="locker-inner  {{
            selected === 24
              ? 'locker-inner--selected'
              : 'locker-inner--disabled'
          }}"
        >
          24

          <div class="locker-info">
            <span>60</span>
            <span>30</span>
            <span>50</span>
          </div>
        </div>
      </div>
      <div>
        <div class="locker-inner locker-inner--disabled">25</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">26</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">27</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">28</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">29</div>
      </div>
      <div class="col-span-2">
        <div class="locker-inner locker-inner--disabled">30</div>
      </div>
    </div>

    <div
      class="w-1/2 h-screen flex flex-col items-center justify-center text-center"
    >
      <h2
        class="w-full mx-auto text-4xl font-semibold mt-8"
        *ngIf="selected === null"
      >
        {{ "LOCKER_RENT_LOCKER_SELECTION.SELECT_LOCKER" | translate }}
      </h2>
      <ng-container *ngIf="selected !== null">
        <!-- <div
          *ngIf="!lockerOpened"
          id="countdown"
          class="flex w-32 h-32 mb-4 drop-shadow-xl bg-white-70 mx-auto rounded-full flex-col items-center justify-center text-primaryx text-7xl font-extrabold text-center wow bounceOut animated"
          style="border-radius: 9999px !important"
          data-wow-delay="90s"
          data-wow-duration="1s"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-1 -1 34 34"
            *ngIf="countdown >= 0"
          >
            <circle
              cx="16"
              cy="16"
              r="15.9155"
              class="progress-bar__background"
            />

            <circle
              cx="16"
              cy="16"
              r="15.9155"
              class="progress-bar__progress"
              style="
            stroke-dashoffset:{{ 100 - (countdown - 0.1) * 100 }};
            transition: stroke-dashoffset {{ countdownFull }}s ease-in-out;"
            />
          </svg>
          <span
            class="absolute block w-full h-auto text-gray wow pulse animated"
            data-wow-delay="0"
            data-wow-duration="1s"
            data-wow-iteration="90"
          >
            {{ countdown }}
          </span>
        </div> -->

        <h2
          class="w-full mx-auto text-4xl font-semibold mt-8"
          *ngIf="!lockerOpened"
        >
          {{ "LOCKER_RENT_LOCKER_SELECTION.SENT_1" | translate }}

          <span class="text-secondary"
            >{{ "LOCKER_RENT_LOCKER_SELECTION.SENT_2" | translate }}
            {{ selected }}</span
          >
          {{ "LOCKER_RENT_LOCKER_SELECTION.SENT_3" | translate }}
          <span class="animate-pulse text-secondary">{{ countdown }}</span>
          {{ "LOCKER_RENT_LOCKER_SELECTION.SENT_4" | translate }}
        </h2>

        <h2
          class="w-full mx-auto text-4xl font-semibold mt-8"
          *ngIf="lockerOpened"
        >
          {{ "LOCKER_RENT_LOCKER_SELECTION.SENT_5" | translate }}
        </h2>
        <h2
          class="w-full mx-auto text-4xl font-semibold mt-8"
          *ngIf="lockerOpened"
        >
          {{ "LOCKER_RENT_LOCKER_SELECTION.SENT_6" | translate }}
        </h2>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="theme === 'pickup_portrait'"> </ng-container>
