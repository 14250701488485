import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';
import {
  RetailerState,
  RetailerStateModel,
  VendingTypes,
} from '../../state/retailer.state';

@Component({
  selector: 'app-retrieve',
  templateUrl: './retrieve.component.html',
  styleUrls: ['./retrieve.component.scss'],
})
export class RetrieveComponent implements OnInit {
  public gifSrc: string;
  theme: string;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;
  showThanksForPurchase: boolean = true;
  showNotReleasedProducts: boolean = false;

  countdownFull = 60;
  countdown = 60;

  constructor(private store: Store) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;

    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.isOfficeButler
    ) {
      this.showThanksForPurchase = false;
    }

    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.vendingType ==
      VendingTypes.WWKS2
    ) {
      let cartItems =
        this.store.selectSnapshot<RetailerStateModel>(
          RetailerState
        ).shoppingCart;

      let failedItems = cartItems.filter((item) => !item.isReleased);
      if (failedItems.length > 0) {
        this.showNotReleasedProducts = true;
      }
    }

    this.gifSrc = './assets/counter.gif?a=' + Math.random();

    setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      }
    }, 1000);

    // var percentageComplete = 0.9;
    // var strokeDashOffsetValue = 100 - (percentageComplete * 100);
    // var progressBar = $(".js-progress-bar");
    // progressBar.css("stroke-dashoffset", strokeDashOffsetValue);
  }

  ngOnInit(): void {}
}
