import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';

@Injectable({
  providedIn: 'root',
})
export class LockerHttpService {
  constructor(private http: HttpClient, private store: Store) {}

  public checkRentCode(action: string, code: string): Observable<any> {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.http.get(
        environment.configApi +
          `/machine/office-butler/rent/check/${action}/${code}`
      );
    } else {
      return this.http.get(
        environment.api + `/vendor/rent/check/${action}/${code}`
      );
    }
  }

  checkCardNumber(cardNumber: string) {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.http.get(
        environment.configApi + '/machine/office-butler/card/' + cardNumber
      );
    } else {
      return this.http.get(
        environment.api + '/vendor/order/check-code/' + cardNumber
      );
    }
  }

  public finishRentProcess(
    action: string,
    order: any,
    isInterested: boolean
  ): Observable<any> {
    let url: string;

    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      url = environment.configApi + `/machine/office-butler/rent/${action}`;
    } else {
      url = environment.api + `/vendor/rent/${action}`;
    }

    const body = { deliveryId: order.id, isInterested: isInterested };
    return this.http.post(url, body);
  }

  public startRentProcess(
    slotId: number,
    cardNumber: number,
    userId: number
  ): Observable<any> {
    let url: string;

    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      url = environment.configApi + `/machine/office-butler/rent/start`;
    }

    const body = {
      slotId: slotId,
      cardNumber: cardNumber,
      userId: userId,
    };
    return this.http.post(url, body);
  }
  public finishRentProcessOB(
    deliveryId: number,
    cardNumber: number,
    userId: number,
    message: string
  ): Observable<any> {
    let url: string;

    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      url = environment.configApi + `/machine/office-butler/rent/return-ob`;
    }

    const body = {
      rentId: deliveryId,
      cardNumber: cardNumber,
      userId: userId,
      message: message,
    };
    return this.http.post(url, body);
  }
  public blockLockerOb(selectedSlot: any, message: string): Observable<any> {
    let url: string;
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      url = environment.configApi + `/machine/office-butler/locker/block`;
    }

    const body = {
      slotId: selectedSlot.id,
      message: message,
      slot_index: selectedSlot.slotIndex,
    };
    return this.http.post(url, body);
  }

  public takeDeliveryPosition(
    deliveryPositionIds: any,
    cardNumber: any
  ): Observable<any> {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;
    let url: string;

    if (apiVersion == 2) {
      url = environment.configApi + '/machine/office-butler/delivery/take';
    } else {
      url = environment.api + `/vendor/order/withdrawOrder`;
    }

    const body = {
      deliveryPositionIds: deliveryPositionIds,
      cardNumber: cardNumber,
    };

    return this.http.post(url, body);
  }

  public storeDeliveryPosition(storedDeliveryPositions: any): Observable<any> {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;
    let url: string;

    if (apiVersion == 2) {
      url = environment.configApi + '/machine/office-butler/delivery/store';
    } else {
      url = environment.api + `/vendor/order/storeOrder`;
    }

    const body = storedDeliveryPositions;
    return this.http.post(url, body);
  }

  public storeDropoff(
    slotId: number,
    message: string,
    cardNumber: string
  ): Observable<any> {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;
    let url: string;

    if (apiVersion == 2) {
      url = environment.configApi + '/machine/office-butler/dropoff/store';
    } else {
      url = environment.api + `/vendor/order/store-dropoff`;
    }

    const body = {
      slotId: slotId,
      message: message,
      cardNumber: cardNumber,
    };

    return this.http.post(url, body);
  }

  public takeDropoffPosition(
    deliveryPositionIds: any,
    cardNumber: string
  ): Observable<any> {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;
    let url: string;

    let body = { deliveryPositionIds: deliveryPositionIds };

    if (apiVersion == 2) {
      url = environment.configApi + '/machine/office-butler/dropoff/take';
      body['cardNumber'] = cardNumber;
    } else {
      url = environment.api + `/vendor/order/take-dropoff`;
    }

    return this.http.post(url, body);
  }
}
