import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WwksService {
  purchaseFinishedCB: any;

  constructor(private httpClient: HttpClient) {}

  getAsrProducts(): Observable<any> {
    return this.httpClient
      .post(environment.wwksApi + '/wwks/stock-info-request', {})
      .pipe(
        map((x: any) => {
          return x.articleList.reduce(
            (map, obj) => ((map[obj.id] = obj), map),
            {}
          );
        })
      );
  }
  getAsrProductsCriteria(articles: any): Observable<any> {
    return this.httpClient
      .post(environment.wwksApi + '/wwks/stock-info-request-criteria', articles)
      .pipe(
        map((x: any) => {
          return x.articleList.reduce(
            (map, obj) => ((map[obj.id] = obj), map),
            {}
          );
        })
      );
  }

  outputProducts(articles: any): Observable<any> {
    return this.httpClient
      .post(environment.wwksApi + '/wwks/output-request', articles)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
