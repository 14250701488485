import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';

// import { Pickup } from '../../state/pickup.actions';
// import { PickupState, PickupStateModel } from '../../state/pickup.state';
import { Idle } from '@ng-idle/core';
import Keyboard from 'simple-keyboard';
import { Locker } from 'src/app/locker/state/locker.actions';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import { ModuleType } from 'src/app/core/state/core.state';
import { Retailer } from 'src/app/retailer/state/retailer.actions';

@Component({
  selector: 'app-locker-message',
  templateUrl: './locker-message.component.html',
  styleUrls: ['./locker-message.component.scss'],
})
export class LockerMessageComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  public keyboard: Keyboard;
  public value: string = '';
  public submitted = false;
  public skipMessage = false;
  public error: string;
  public loaderActive: boolean = false;

  private action: string;
  private slotId: number;
  private slotIndex: number;
  private containerCode: number;
  deliveryId: number;

  public theme: string;
  @ViewChild('messageInput')
  public messageInput: ElementRef;

  errors = [
    { id: 0, description: 'No Error', value: '' },
    { id: 1, description: 'Error 1', value: 'Error 1' },
    { id: 2, description: 'Error 2', value: 'Error 2' },
    { id: 3, description: 'Error 3', value: 'Error 3' },
  ];
  disabled = false;
  icon = 'error';

  constructor(
    private store: Store,
    private idle: Idle,
    private route: ActivatedRoute
  ) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.route.params.subscribe((params) => {
      this.action = params.action;
      this.slotId = params.slotId;
      this.slotIndex = params.slotIndex;
      this.containerCode = params.containerCode;
      this.deliveryId = params.deliveryId;
    });
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  ngAfterViewInit() {
    if (this.action != 'finish-rent') {
      setTimeout(() => this.messageInput.nativeElement.focus(), 1000);

      this.keyboard = new Keyboard('.simple-keyboard-locker-message', {
        onChange: (input) => this.onChange(input),
        // onKeyPress: button => this.onKeyPress(button)
      });

      this.keyboard.setOptions({
        physicalKeyboardHighlightPress: true,
        physicalKeyboardHighlight: true,
        layout: {
          default: [
            '1 2 3 4 5 6 7 8 9 0 ß',
            'Q W E R T Z U I O P Ü',
            'A S D F G H J K L Ö Ä',
            'Y X C V B N M {space} {bksp}',
          ],
          shift: [
            '1 2 3 4 5 6 7 8 9 0 ß',
            'Q W E R T Z U I O P Ü',
            'A S D F G H J K L Ö Ä',
            'Y X C V B N M {space} {bksp}',
          ],
        },
        display: {
          '{bksp}': '    ',
          '{space}': 'SPACE',
          ß: '   ',
        },
        theme: 'new-theme-keyboard',
        buttonTheme: [
          {
            class: 'backspace',
            buttons: '{bksp}',
          },
          {
            class: 'ss',
            buttons: 'ß',
          },
          {
            class: 'space-new',
            buttons: '{space}',
          },
        ],
      });
    }
  }

  onBlurKeyboard(event) {
    if (this.messageInput) this.messageInput.nativeElement.focus();
  }

  onChange = (input: string) => {
    this.value = input;
  };

  onErrorSelect(error) {
    console.log(error);
    this.value = error.value;
  }

  public saveMessage(): void {
    this.loaderActive = !this.loaderActive;
    //TODO call open locker and than store droppof

    if (this.action == 'finish-rent') {
      this.loaderActive = !this.loaderActive;
      this.store
        .dispatch(new Locker.OpenLocker(this.slotIndex, this.containerCode))
        .subscribe(
          (x) => {
            this.loaderActive = false;
            this.store.dispatch(
              new Navigate([
                '/locker/delivery',
                'release',
                this.action,
                this.deliveryId,
                this.slotId,
                0,
                this.slotIndex,
                this.value,
              ])
            );
          },
          (error: MachineError) => {
            this.loaderActive = false;
            this.store
              .dispatch(new Core.Error(error, ModuleType.RETAILER))
              .subscribe(() => {
                setTimeout(() => {
                  this.store.dispatch(new Navigate(['/']));
                }, 10000);
              });
          }
        );
    } else {
      this.store
        .dispatch(
          new Locker.StoreDropoffItem(
            this.slotId,
            this.slotIndex,
            this.value.trim(),
            this.containerCode
          )
        )
        .subscribe(
          (x) => {
            this.loaderActive = !this.loaderActive;
            this.store.dispatch(
              new Navigate([
                '/locker/delivery',
                'release',
                this.action,
                0,
                0,
                0,
                this.slotIndex,
                '',
              ])
            );
          },
          (error: MachineError) => {
            this.store
              .dispatch(new Core.Error(error, ModuleType.RETAILER))
              .subscribe(() => {
                setTimeout(() => {
                  this.store.dispatch(new Navigate(['/']));
                }, 10000);
              });
          }
        );
    }
  }

  onEnter(): void {
    this.saveMessage();
  }

  cancel(): void {
    this.store.dispatch(new Retailer.ActionSelection());
  }
}
