<div class="flex flex-col items-start justify-between w-full h-screen">
  <app-ski-bg
    [isLight]="false"
    [useAnimation]="true"
    [showImg]="true"
    [imgUrl]="'/assets/mb-mountains-cards.jpg'"
    [bigSelection]="false"
    [showImg]="false"
    [showClock]="false"
    [hasCentral]="true"
    [hasStatus]="true"
  >
    <ng-container slot="top">
      <app-header-ski
        [title]="'LOCKER_SKI.SCANNER_TITLE' | translate"
        subtitle="
          {{
          skiServiceType === 'rent'
            ? ('LOCKER_SKI.SCANNER_SUBTITLE_RENT' | translate)
            : ('LOCKER_SKI.SCANNER_SUBTITLE_RECLAIM' | translate)
        }}
        "
        [big]="false"
      ></app-header-ski>
    </ng-container>

    <!-- central part -->
    <ng-container slot="central">
      <!-- <div class="w-24 h-24" (click)="onNextStep()">NEXT</div> -->

      <div
        class="flex flex-col items-center justify-center w-2/3 h-auto pt-16 text-center"
      >
        <h1 class="pb-1 text-4xl font-medium leading-[0px] text-primaryx">
          <ng-container *ngIf="skiServiceType === 'rent'">
            {{ "LOCKER_SKI.SCANNER_SELECTED_LOCKERS" | translate }}
            {{ numberOfLockers }}
          </ng-container>
        </h1>
      </div>

      <div
        class="flex flex-col items-center justify-center mx-auto w-80 h-80 text-primaryx"
      >
        <app-svg-stripe [icon]="'scancard'"></app-svg-stripe>
      </div>
    </ng-container>
    <!-- end central part -->

    <!-- left btns -->
    <ng-container slot="btn4"> </ng-container>
    <ng-container slot="btn3"> </ng-container>
    <ng-container slot="btn2"> </ng-container>
    <ng-container slot="btn1"> </ng-container>
    <!-- end left btns -->

    <!-- right btns -->
    <ng-container slot="btn8"> </ng-container>
    <ng-container slot="btn7"> </ng-container>
    <ng-container slot="btn6"> </ng-container>
    <ng-container slot="btn5"> </ng-container>
    <!-- end right btns -->

    <ng-container *ngIf="skiServiceType === 'rent'" slot="status">
      <app-ski-msg
        [msg]="'LOCKER_SKI.SCANNER_SUBTITLE_RENT_HINT' | translate"
        [msgType]="'warning'"
      ></app-ski-msg>
      <!-- msgType ==> warning, error, info, danger, success -->
    </ng-container>
  </app-ski-bg>
</div>
