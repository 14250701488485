import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Retailer } from '../../state/retailer.actions';

@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.scss'],
})
export class ReleaseComponent implements OnInit {
  theme: string;
  showPrintButtons: boolean = true;

  constructor(private store: Store) {
    this.theme = localStorage.getItem('theme') ?? 'default';
  }

  ngOnInit(): void {}

  printConfirm() {
    this.showPrintButtons = false;
    this.store.dispatch(new Retailer.SetPrintReceipt());
  }
  printSkip() {
    this.showPrintButtons = false;
    console.warn('SKIP Printing bill');
  }
}
