import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { LockerState, LockerStateModel } from '../../../state/locker.state';
import { Locker } from '../../../state/locker.actions';
import { Idle } from '@ng-idle/core';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';
import { LangService } from 'src/app/core/services/lang.service';

@Component({
  selector: 'ft-code-correct',
  templateUrl: './code-correct.component.html',
  styleUrls: ['./code-correct.component.scss']
})
export class RentCodeCorrectComponent implements OnInit, OnDestroy {
  back: boolean = false;
  modalOpened: boolean = false;
  theme: string;
  agb: any;
  language: string;

  constructor(private store: Store, private idle: Idle, private langService: LangService) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    const rentAction = this.store.selectSnapshot<LockerStateModel>(LockerState).rentAction;

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.agb = this.store.selectSnapshot<CoreStateModel>(CoreState).config.agb;
    }

    this.back = rentAction === 'return';
    this.language = "_" + langService.getLanguage();
    console.log(this.language);
  }

  getContent(content: string) {
    return this.agb[content + this.language];
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  openAgb(): void {
    this.modalOpened = true;
  }

  closeAgb(): void {
    this.modalOpened = false;
  }

  agree(isInterested: boolean): void {
    this.store.dispatch(new Locker.ConfirmRentCodeCorrect(isInterested));
  }

  cancel(): void {
    this.store.dispatch(new Locker.ResetRentProcess());
  }
}
