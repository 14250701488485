import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

import { Idle } from '@ng-idle/core';
import Keyboard from 'simple-keyboard';
import { Core } from 'src/app/core/state/core.actions';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss'],
})
export class LoginUserComponent implements AfterViewInit, OnInit, OnDestroy {
  public submitted = false;
  public loginFailed = false;
  public loginSuccess = false;
  public error: string;
  public loaderActive: boolean = false;
  public code: string = '';
  public theme: string;
  @ViewChild('cardInput', { read: ElementRef })
  public cardInput: ElementRef;

  constructor(private store: Store, private idle: Idle) {
    this.theme = localStorage.getItem('theme') ?? 'default';
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  ngAfterViewInit() {
    setTimeout((x) => {
      this.cardInput.nativeElement.focus();
    }, 200);
  }

  public loginWithCard(): void {
    this.loaderActive = !this.loaderActive;
    let value: string = this.cardInput.nativeElement.value.trim();

    let cardTrimStartCount: number =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .cardTrimStartCount;

    console.log(cardTrimStartCount);
    console.log(value);
    if (!value) {
      this.showError('Incorrect code');
      // this.store.dispatch(new Navigate(['/core', 'selection-full']));
      return;
    }

    if (value.length > cardTrimStartCount) {
      value = value.slice(cardTrimStartCount);
    }

    this.store.dispatch(new Core.CheckCardNumber(value)).subscribe((x) => {
      let activeUser =
        this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;

      if (activeUser && activeUser.isCodeCorrect) {
        this.loginSuccess = true;
        this.store.dispatch(new Navigate(['/core', 'selection-full']));
      } else {
        this.showError('Incorrect code');
      }
    });
  }

  private showError(errorMessage: string) {
    this.loaderActive = false;
    this.loginFailed = true;
    this.cardInput.nativeElement.value = '';
  }

  onBlur(event) {
    this.cardInput.nativeElement.focus();
  }

  onEnter(): void {
    this.loginWithCard();
  }

  cancel(): void {
    this.store.dispatch(new Navigate(['']));
  }

  errorClose(): void {
    this.loaderActive = false;
    this.loginFailed = false;
    this.error = '';
    this.code = '';
    this.cardInput.nativeElement.focus();
  }
}
