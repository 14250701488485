<!-- left footer -->
<div
  class="pl-6 h-full flex-1 flex rounded-tl-2xl relative flex-row items-center justify-between bg-nex-gray-light"
>
  <!-- search and back footer -->
  <div class="w-auto h-auto flex flex-row items-center justify-start space-x-3">
    <ng-container *ngIf="!hideBackBtn">
      <button
        (click)="
          !backBtnCloseInfoPanel ? backPage(currentRoute) : backCloseAction()
        "
        class="max-w-64 w-auto h-20 bg-transparent flex-row items-center justify-start flex text-nex-gray-dark active:bg-white/70 rounded-xl border-2 border-transparent active:border-nex-gray-light active:shadow-inner-light"
      >
        <div class="p-4 w-16 h-16 justify-start items-start flex">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.3333 18.6667V6.66675H22.6667V16.0001H12.552V8.78141L4 17.3334L12.552 25.8854V18.6667H25.3333Z"
              fill="currentColor"
            />
          </svg>
        </div>

        <div
          class="pr-6 w-auto flex-1 h-full justify-start uppercase flex flex-row items-center text-2xl font-medium"
        >
          {{ "COMMON.BACK" | translate }}
        </div>
      </button>
    </ng-container>

    <ng-container *ngIf="!hideSearchButton && !productPage">
      <button
        (click)="openSearchPanel()"
        class="max-w-64 w-auto h-20 bg-transparent flex-row items-center justify-start flex text-nex-gray-dark active:bg-white/70 rounded-xl border-2 border-transparent active:border-nex-gray-light active:shadow-inner-light"
      >
        <div class="p-4 w-16 h-16 justify-start items-start flex">
          <ng-container *ngIf="!searchPanelOpened">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="wow bounceIn animated"
            >
              <path
                d="M24.0413 22.156L29.752 27.8653L27.8653 29.752L22.156 24.0413C20.0317 25.7443 17.3893 26.6705 14.6667 26.6666C8.04267 26.6666 2.66667 21.2906 2.66667 14.6666C2.66667 8.04263 8.04267 2.66663 14.6667 2.66663C21.2907 2.66663 26.6667 8.04263 26.6667 14.6666C26.6705 17.3893 25.7443 20.0316 24.0413 22.156ZM21.3667 21.1666C23.0588 19.4265 24.0038 17.0939 24 14.6666C24 9.51063 19.8227 5.33329 14.6667 5.33329C9.51067 5.33329 5.33334 9.51063 5.33334 14.6666C5.33334 19.8226 9.51067 24 14.6667 24C17.0939 24.0038 19.4265 23.0588 21.1667 21.3666L21.3667 21.1666Z"
                fill="currentColor"
              />
            </svg>
          </ng-container>
          <ng-container *ngIf="searchPanelOpened">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="wow bounceIn animated"
            >
              <path
                d="M16 29.3337C8.63601 29.3337 2.66667 23.3643 2.66667 16.0003C2.66667 8.63633 8.63601 2.66699 16 2.66699C23.364 2.66699 29.3333 8.63633 29.3333 16.0003C29.3333 23.3643 23.364 29.3337 16 29.3337ZM16 14.115L12.2293 10.343L10.3427 12.2297L14.1147 16.0003L10.3427 19.771L12.2293 21.6577L16 17.8857L19.7707 21.6577L21.6573 19.771L17.8853 16.0003L21.6573 12.2297L19.7707 10.343L16 14.115Z"
                fill="currentColor"
              />
            </svg>
          </ng-container>
        </div>

        <div
          class="wow bounceIn animated pr-6 w-auto flex-1 h-full justify-start uppercase flex flex-row items-center text-2xl font-medium"
        >
          <ng-container *ngIf="!searchPanelOpened">
            {{ "COMMON.SEARCH" | translate }}
          </ng-container>
          <ng-container *ngIf="searchPanelOpened">
            {{ "COMMON.CLOSE_SEARCH" | translate }}
          </ng-container>
        </div>
      </button>
    </ng-container>
  </div>
  <!-- end search and back footer -->

  <!-- cart footer left area -->
  <div class="flex flex-1 w-auto h-full items-center justify-start ml-4">
    <ng-container *ngIf="useShoppingCart">
      <div
        class="w-auto h-[1000px] flex absolute -right-[594px] -bottom-[231px] -rotate-[47deg] -mb-[102px] -mr-[64px] z-50 bg-transparent"
        *ngIf="statusCartMessage"
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 800 800"
          style="enable-background: new 0 0 800 800"
          xml:space="preserve"
        >
          <g class="confetti-cone">
            <path
              class="conf0"
              d="M131.5,172.6L196,343c2.3,6.1,11,6.1,13.4,0l65.5-170.7L131.5,172.6z"
            />
            <path
              class="conf1"
              d="M131.5,172.6L196,343c2.3,6.1,11,6.1,13.4,0l6.7-17.5l-53.6-152.9L131.5,172.6z"
            />

            <path
              class="conf2"
              d="M274.2,184.2c-1.8,1.8-4.2,2.9-7,2.9l-129.5,0.4c-5.4,0-9.8-4.4-9.8-9.8c0-5.4,4.4-9.8,9.9-9.9l129.5-0.4
     				c5.4,0,9.8,4.4,9.8,9.8C277,180,275.9,182.5,274.2,184.2z"
            />
            <polygon
              class="conf3"
              points="231.5,285.4 174.2,285.5 143.8,205.1 262.7,204.7 			"
            />
            <path
              class="conf4"
              d="M166.3,187.4l-28.6,0.1c-5.4,0-9.8-4.4-9.8-9.8c0-5.4,4.4-9.8,9.9-9.9l24.1-0.1c0,0-2.6,5-1.3,10.6
     				C161.8,183.7,166.3,187.4,166.3,187.4z"
            />
            <ellipse
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -89.8523 231.0278)"
              class="conf2"
              cx="233.9"
              cy="224"
              rx="5.6"
              ry="5.6"
            />
            <path
              class="conf5"
              d="M143.8,205.1l5.4,14.3c6.8-2.1,14.4-0.5,19.7,4.8c7.7,7.7,7.6,20.1-0.1,27.8c-1.7,1.7-3.7,3-5.8,4l11.1,29.4
     				l27.7,0l-28-80.5L143.8,205.1z"
            />
            <path
              class="conf2"
              d="M169,224.2c-5.3-5.3-13-6.9-19.7-4.8l13.9,36.7c2.1-1,4.1-2.3,5.8-4C176.6,244.4,176.6,231.9,169,224.2z"
            />
            <ellipse
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -119.0946 221.1253)"
              class="conf6"
              cx="207.4"
              cy="254.3"
              rx="11.3"
              ry="11.2"
            />
          </g>

          <rect
            x="113.7"
            y="135.7"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -99.5348 209.1582)"
            class="conf7"
            width="178"
            height="178"
          />
          <line class="conf7" x1="76.8" y1="224.7" x2="328.6" y2="224.7" />
          <polyline
            class="conf7"
            points="202.7,350.6 202.7,167.5 202.7,98.9 	"
          />
          <!-- here comes the confettis-->

          <circle class="conf2" id="b1" cx="195.2" cy="232.6" r="5.1" />
          <circle class="conf0" id="b2" cx="230.8" cy="219.8" r="5.4" />
          <circle class="conf0" id="c2" cx="178.9" cy="160.4" r="4.2" />
          <circle class="conf6" id="d2" cx="132.8" cy="123.6" r="5.4" />
          <circle class="conf0" id="d3" cx="151.9" cy="105.1" r="5.4" />

          <path
            class="conf0"
            id="d1"
            d="M129.9,176.1l-5.7,1.3c-1.6,0.4-2.2,2.3-1.1,3.5l3.8,4.2c1.1,1.2,3.1,0.8,3.6-0.7l1.9-5.5
      		C132.9,177.3,131.5,175.7,129.9,176.1z"
          />
          <path
            class="conf6"
            id="b5"
            d="M284.5,170.7l-5.4,1.2c-1.5,0.3-2.1,2.2-1,3.3l3.6,3.9c1,1.1,2.9,0.8,3.4-0.7l1.8-5.2
      		C287.4,171.9,286.1,170.4,284.5,170.7z"
          />
          <circle class="conf6" id="c3" cx="206.7" cy="144.4" r="4.5" />
          <path
            class="conf2"
            id="c1"
            d="M176.4,192.3h-3.2c-1.6,0-2.9-1.3-2.9-2.9v-3.2c0-1.6,1.3-2.9,2.9-2.9h3.2c1.6,0,2.9,1.3,2.9,2.9v3.2
      		C179.3,191,178,192.3,176.4,192.3z"
          />
          <path
            class="conf2"
            id="b4"
            d="M263.7,197.4h-3.2c-1.6,0-2.9-1.3-2.9-2.9v-3.2c0-1.6,1.3-2.9,2.9-2.9h3.2c1.6,0,2.9,1.3,2.9,2.9v3.2
      		C266.5,196.1,265.2,197.4,263.7,197.4z"
          />

          <path
            class="conf0"
            id="a1"
            d="M277.5,254.8h-3.2c-1.6,0-2.9-1.3-2.9-2.9v-3.2c0-1.6,1.3-2.9,2.9-2.9h3.2c1.6,0,2.9,1.3,2.9,2.9v3.2
      		C280.4,253.5,279.1,254.8,277.5,254.8z"
          />
          <path
            class="conf3"
            id="c4"
            d="M215.2,121.3L215.2,121.3c0.3,0.6,0.8,1,1.5,1.1l0,0c1.6,0.2,2.2,2.2,1.1,3.3l0,0c-0.5,0.4-0.7,1.1-0.6,1.7v0
      		c0.3,1.6-1.4,2.8-2.8,2l0,0c-0.6-0.3-1.2-0.3-1.8,0h0c-1.4,0.7-3.1-0.5-2.8-2v0c0.1-0.6-0.1-1.3-0.6-1.7l0,0
      		c-1.1-1.1-0.5-3.1,1.1-3.3l0,0c0.6-0.1,1.2-0.5,1.5-1.1v0C212.5,119.8,214.5,119.8,215.2,121.3z"
          />
          <path
            class="conf3"
            id="b3"
            d="M224.5,191.7L224.5,191.7c0.3,0.6,0.8,1,1.5,1.1l0,0c1.6,0.2,2.2,2.2,1.1,3.3v0c-0.5,0.4-0.7,1.1-0.6,1.7l0,0
      		c0.3,1.6-1.4,2.8-2.8,2h0c-0.6-0.3-1.2-0.3-1.8,0l0,0c-1.4,0.7-3.1-0.5-2.8-2l0,0c0.1-0.6-0.1-1.3-0.6-1.7v0
      		c-1.1-1.1-0.5-3.1,1.1-3.3l0,0c0.6-0.1,1.2-0.5,1.5-1.1l0,0C221.7,190.2,223.8,190.2,224.5,191.7z"
          />
          <path
            class="conf3"
            id="a2"
            d="M312.6,242.1L312.6,242.1c0.3,0.6,0.8,1,1.5,1.1l0,0c1.6,0.2,2.2,2.2,1.1,3.3l0,0c-0.5,0.4-0.7,1.1-0.6,1.7v0
      		c0.3,1.6-1.4,2.8-2.8,2l0,0c-0.6-0.3-1.2-0.3-1.8,0h0c-1.4,0.7-3.1-0.5-2.8-2v0c0.1-0.6-0.1-1.3-0.6-1.7l0,0
      		c-1.1-1.1-0.5-3.1,1.1-3.3l0,0c0.6-0.1,1.2-0.5,1.5-1.1v0C309.9,240.6,311.9,240.6,312.6,242.1z"
          />
        </svg>
      </div>

      <div
        (click)="cartQuantity >= 1 && openCart()"
        class="w-auto ml-auto relative rounded-l-2xl overflow-hidden pr-12 pl-4 h-22 flex items-center justify-end"
      >
        <div
          class="p-4 w-16 h-16 justify-start items-start flex text-white relative z-20 mr-auto wow fadeInUp animated"
        >
          <span
            class="wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration=".3s"
            *ngIf="statusCartMessage"
          >
            <app-svg-stripe [icon]="'success'"></app-svg-stripe>
          </span>
          <ng-container *ngIf="!statusCartMessage">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="wow bounceIn animated"
              data-wow-delay="500ms"
              data-wow-duration=".5s"
            >
              <path
                d="M5.33915 8.55551L1.01562 4.23199L2.90124 2.34637L7.22476 6.66989H27.5471C28.2835 6.66989 28.8804 7.26684 28.8804 8.00323C28.8804 8.13299 28.8615 8.26205 28.8243 8.38635L25.6243 19.0531C25.4551 19.6169 24.9359 20.0032 24.3471 20.0032H8.00581V22.6699H22.6725V25.3365H6.67248C5.93609 25.3365 5.33915 24.7396 5.33915 24.0032V8.55551ZM7.33915 30.6699C6.23457 30.6699 5.33915 29.7744 5.33915 28.6699C5.33915 27.5653 6.23457 26.6699 7.33915 26.6699C8.44372 26.6699 9.33915 27.5653 9.33915 28.6699C9.33915 29.7744 8.44372 30.6699 7.33915 30.6699ZM23.3392 30.6699C22.2345 30.6699 21.3392 29.7744 21.3392 28.6699C21.3392 27.5653 22.2345 26.6699 23.3392 26.6699C24.4437 26.6699 25.3392 27.5653 25.3392 28.6699C25.3392 29.7744 24.4437 30.6699 23.3392 30.6699Z"
                fill="currentColor"
              />
            </svg>
          </ng-container>
        </div>

        <div
          class="relative z-20 w-auto flex leading-relaxed flex-1 items-center justify-start text-left text-white text-3xl wow fadeInRight animated"
          data-wow-delay="500ms"
          data-wow-duration=".5s"
        >
          <ng-container *ngIf="cartQuantity < 1">
            {{ "COMMON.CART_IS_EMPTY" | translate }}
          </ng-container>

          <ng-container *ngIf="cartQuantity >= 1">
            <span class="font-semibold min-w-32">
              € {{ cartTotalValue.toFixed(2) }}
            </span>
          </ng-container>
        </div>

        <div
          class="w-full h-full absolute left-0 top-0 block z-10 wow slideInRight animated"
          [ngClass]="
            statusCartMessage
              ? 'bg-nex-green'
              : cartQuantity >= 1
              ? 'bg-nex-green'
              : 'bg-primaryx opacity-50'
          "
          data-wow-delay="0ms"
          data-wow-duration=".6s"
        ></div>
      </div>
    </ng-container>
  </div>
  <!-- end cart footer left area -->
</div>
<!-- end left footer -->

<!-- right footer -->
<ng-container *ngIf="useShoppingCart">
  <div
    class="w-[255px] h-full flex items-center relative justify-start bg-nex-gray-light-ultra"
    [ngClass]="!productPage && !searchPanelOpened ? 'side-area' : ''"
  >
    <div
      class="w-full h-full flex flex-row items-center justify-between bg-nex-gray-light rounded-tr-2xl"
    >
      <!-- cart right area -->
      <div
        class="w-full pr-1 h-22 rounded-r-xl relative overflow-hidden flex items-center justify-end"
      >
        <button
          (click)="cartQuantity >= 1 && openCart()"
          class="w-full h-full relative z-20 flex-row items-center justify-start disabled:opacity-70 flex text-white active:bg-white/70 rounded-r-2xl border-2 border-transparent active:border-nex-gray-light active:shadow-inner-light   {{
            cartQuantity < 1 ? 'invisible' : 'visible wow slideInLeft animated'
          }}"
          [ngClass]="statusCartMessage ? 'bg-nex-green' : 'bg-nex-green'"
          data-wow-delay="0ms"
          data-wow-duration=".6s"
        >
          <div
            class="pr-4 w-auto flex-1 h-full justify-center text-center flex flex-row items-center text-3xl font-medium"
          >
            {{ "COMMON.CART" | translate }}
          </div>
        </button>

        <div
          class="w-full h-full pr-1 absolute left-0 top-0 z-10 flex items-center justify-start"
        >
          <div
            class="w-full h-22 block z-10 rounded-r-2xl wow slideInLeft animated"
            data-wow-delay="0ms"
            data-wow-duration=".6s"
            [ngClass]="
              statusCartMessage
                ? 'bg-nex-green'
                : cartQuantity >= 1
                ? 'bg-nex-green'
                : 'bg-primaryx opacity-50'
            "
          ></div>
        </div>
      </div>
      <!-- end cart right area -->
    </div>
  </div>
</ng-container>
<!-- end right footer -->
