import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Navigate} from '@ngxs/router-plugin';
import {Store} from '@ngxs/store';

import {WwksService} from '../../services/wwks.service';

@Component ( {
  selector : 'app-finish-order-confirm',
  templateUrl : './finish-order-confirm.component.html',
  styleUrls : [ './finish-order-confirm.component.scss' ],
} )
export class FinishOrderConfirmComponent implements OnInit
{
  theme: string;
  finished: boolean = false;
  timeoutId: any;
  source: string;
  productsTaken: boolean = true;

  constructor( private store: Store, private wwksService: WwksService, private route: ActivatedRoute )
  {
    this.theme = localStorage.getItem( 'theme' ) ?? 'default';

    this.timeoutId = setTimeout ( ( ) => {
      console.log( 'timeout for open again expired' );
      this.finishOnClick( );
    }, 15000 );

    this.route.params.subscribe( ( params ) => {
      this.source        = params.source;
      this.productsTaken = params.productsTaken == 'true' ? true : false;
      console.log( 'params', params );
    } );
  }

  ngOnInit( ): void { }

  openOnClick( )
  {
    console.log( 'open again with source:', this.source );
    clearTimeout ( this.timeoutId );
    this.store.dispatch( new Navigate ( [ '/retailer', 'retrieve-wwks', this.source ] ) );
  }

  finishOnClick( )
  {
    clearTimeout ( this.timeoutId );

    this.finished = true;
    console.log( 'finishOnClick' );
    if ( this.wwksService.purchaseFinishedCB )
    {
      console.log( 'finishOnClick 1' );
      this.wwksService.purchaseFinishedCB( );
    }
  }
}
