import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { MachineError } from 'src/app/core/models/error';
import {
  ControlCode,
  ControlService,
} from 'src/app/core/services/control.service';
import { Core } from 'src/app/core/state/core.actions';
import {
  CoreState,
  CoreStateModel,
  ModuleType,
} from 'src/app/core/state/core.state';
import { VendingTypes } from 'src/app/retailer/state/retailer.state';
import { VersionCheckService } from 'src/app/version-check.service';
import { LockerSkiHttpService } from '../services/locker-ski-http.service';
import { LockerSki } from './locker-ski.actions';

export class LockerSkiStateModel {
  action: string;
  freeSlots: any;
  activeRents: any;
  numberOfLockers: number;
  lockerPricePerHour: number;
  selectedSlots: any[];
  customerData: any;
}

@State<LockerSkiStateModel>({
  name: 'lockerski',
  defaults: {
    action: null,
    freeSlots: null,
    activeRents: null,
    numberOfLockers: null,
    lockerPricePerHour: null,
    selectedSlots: null,
    customerData: null,
  },
})
@Injectable()
export class LockerSkiState implements NgxsOnInit {
  constructor(
    private store: Store,
    private lockerHttpService: LockerSkiHttpService,
    private router: Router,
    private versionCheckService: VersionCheckService
  ) {}

  ngxsOnInit(ctx?: StateContext<any>) {}

  resetState(ctx: StateContext<LockerSkiStateModel>) {
    ctx.patchState({
      action: null,
      numberOfLockers: null,
      lockerPricePerHour: null,
      freeSlots: null,
      activeRents: null,
      selectedSlots: null,
      customerData: null,
    });
  }

  @Action(LockerSki.RentProcess)
  startRentProcess(
    ctx: StateContext<LockerSkiStateModel>,
    action: LockerSki.RentProcess
  ) {
    ctx.patchState({
      action: action.actionName,
    });

    this.lockerHttpService
      .getMachingeData()
      .pipe(
        tap((result) => {
          ctx.patchState({
            freeSlots: result.free_slots,
            activeRents: result.active_rents,
          });
        })
      )
      .subscribe({
        next: (v) => {
          if (action.actionName == 'rent') {
            this.store.dispatch(new Navigate(['/locker', 'ski', 'selection']));
          } else {
            this.startReclaimProcess(ctx);
          }
        },
        error: (e) => console.error(e),
      });
  }

  startReclaimProcess(ctx: StateContext<LockerSkiStateModel>) {
    console.log('startReclaimProcess');
    this.store.dispatch(new Navigate(['/locker', 'ski', 'card-scanner']));

    this.lockerHttpService
      .cardHash()
      .pipe(
        tap((res) => {
          let hashedCardNumber: string = res.cardhash;

          let activeRents: any[] = ctx.getState().activeRents;

          let rentForCurrentCard = activeRents.find(
            (el) => el.card_number_hashed === hashedCardNumber
          );

          if (rentForCurrentCard) {
            ctx.patchState({
              customerData: {
                hashedCardNumber: hashedCardNumber,
                currentRent: rentForCurrentCard,
              },
            });

            console.log(ctx.getState());
            this.store.dispatch(
              new Navigate(['/locker', 'ski', 'unlock-selection'])
            );
          } else {
            throw new MachineError(
              res.controlError,
              ModuleType.RETAILER,
              'LOCKER_SKI.ERROR_NO_RENTS',
              false
            );
          }
        })
      )
      .subscribe({
        next: (x) => console.log('next'),
        error: (err) => this.handleError(err),
      });
  }

  @Action(LockerSki.FinishRentProcess)
  finishRentProcess(
    ctx: StateContext<LockerSkiStateModel>,
    action: LockerSki.FinishRentProcess
  ) {
    ctx.patchState({
      selectedSlots: action.slots,
    });

    let data: any = {
      selectedSlots: ctx.getState().selectedSlots,
      customerData: ctx.getState().customerData,
    };

    let lockersToOpen: number[] = [];

    ctx.getState().freeSlots.forEach((element) => {
      if (!action.slots.find((x) => x.number === element.slot_index)) {
        lockersToOpen.push(element.slot_index);
      }
    });

    this.lockerHttpService
      .unlockSlots(lockersToOpen)
      .pipe(switchMap((x) => this.lockerHttpService.storeLockerRent(data)))
      .subscribe({
        next: (v) =>
          this.store.dispatch(new Navigate(['/locker', 'ski', 'thanks'])),
        error: (e) => {
          this.store
            .dispatch(new LockerSki.RevertTransaction())
            .pipe(
              switchMap((x) =>
                this.lockerHttpService.unlockSlots(
                  ctx.getState().freeSlots.map((x) => x.slot_index)
                )
              )
            )
            .subscribe({
              complete: () => {
                this.handleError(
                  new MachineError(
                    10001,
                    ModuleType.RETAILER,
                    'COMMON.ERROR',
                    false
                  )
                );
              },
            });
        },
      });
  }

  @Action(LockerSki.ManualReclaim)
  manualReclaimProcess(
    ctx: StateContext<LockerSkiStateModel>,
    action: LockerSki.ManualReclaim
  ) {
    ctx.patchState({
      customerData: {
        hashedCardNumber: action.selectedRent.transaction_data.cardhash,
        currentRent: action.selectedRent,
      },
    });

    this.store.dispatch(new Navigate(['/locker', 'ski', 'unlock-selection']));
  }

  @Action(LockerSki.FinishReclaimProcess)
  finishReclaimProcess(
    ctx: StateContext<LockerSkiStateModel>,
    action: LockerSki.FinishReclaimProcess
  ) {
    let slots: any[] = action.slots;
    let currentTime: any = new Date();
    let totalAmount = 0;

    slots.forEach((slot) => {
      let hours = Math.ceil(Math.abs(currentTime - slot.startTime) / 36e5);
      let amount = hours * slot.pricePerHour;

      slot.totalHours = hours;
      slot.totalAmount = Math.round((amount + Number.EPSILON) * 100) / 100;

      totalAmount += slot.totalAmount;

      slot.endDate = currentTime;
      slot.number = slot.slotIndex;
    });

    ctx.patchState({
      selectedSlots: slots,
    });

    this.store.dispatch(new Navigate(['/locker', 'ski', 'reclaim-summary']));

    let rentLockedSlots = ctx.getState().customerData.currentRent.rent_items;

    let rentLockedSlotsLength = rentLockedSlots.filter(
      (e) => e.locker_rent_item_status_id === 1
    ).length;

    let shouldPerformPayment = rentLockedSlotsLength === slots.length;

    let paymentObservable: Observable<any>;

    let currentRent = ctx.getState().customerData.currentRent;

    if (shouldPerformPayment) {
      rentLockedSlots
        .filter((e) => e.locker_rent_item_status_id === 3)
        .forEach((element) => {
          totalAmount += element.total_amount;
        });

      if (
        currentRent.reserved_amount &&
        totalAmount > currentRent.reserved_amount
      ) {
        totalAmount = currentRent.reserved_amount;
      }

      let data = {
        fileRefId: currentRent.file_ref_id,
        amount: totalAmount,
        originalStan: currentRent.transaction_data.stan,
      };

      paymentObservable = this.lockerHttpService.captureTransaction(data);
    } else {
      paymentObservable = of({});
    }

    paymentObservable
      .pipe(
        // todo check payment result
        switchMap(() =>
          this.lockerHttpService.unlockSlots(slots.map((x) => x.slotIndex))
        ),
        tap((res) => {
          console.log(res);
          if (res.controlError != ControlCode.SUCCESS) {
            throw new MachineError(
              res.controlError,
              ModuleType.RETAILER,
              'COMMON.Error',
              false
            );
          }
        }),
        switchMap(() =>
          this.lockerHttpService.reclaimLockerRentItems(
            slots,
            ctx.getState().customerData.currentRent.id
          )
        )
      )
      .subscribe({
        next: (x) => {
          setTimeout(() => {
            this.store.dispatch(new Navigate(['/locker', 'ski', 'thanks']));
          }, 10000);
        },
        error: (err) => this.handleError(err),
      });
  }

  @Action(LockerSki.StartAuthorizationProcess)
  startAuthorizationProcess(
    ctx: StateContext<LockerSkiStateModel>,
    action: LockerSki.StartAuthorizationProcess
  ) {
    console.log('startAuthorizationProcess');
    ctx.patchState({
      numberOfLockers: action.numberOfLockers,
      lockerPricePerHour: action.lockerPricePerHour,
    });

    this.store.dispatch(new Navigate(['/locker', 'ski', 'card-scanner']));

    let freeSlots: number[] = [];

    ctx.getState().freeSlots.forEach((element) => {
      freeSlots.push(element.slot_index);
    });

    let reservedAmount: number = 49.0;

    if (action.reservationAmount && action.reservationAmount > 0) {
      reservedAmount = action.reservationAmount;
    }

    this.lockerHttpService
      .unlockSlots(freeSlots)
      .pipe(
        catchError((error) => {
          console.log(error);
          throw new MachineError(
            10000,
            ModuleType.RETAILER,
            'LOCKER_SKI.ERROR_OPEN_LOCKS',
            false
          );
        }),
        tap((res) => {
          console.log(res);
          if (res.controlError != ControlCode.SUCCESS) {
            throw new MachineError(
              res.controlError,
              ModuleType.RETAILER,
              'COMMON.Error',
              false
            );
          }
        }),
        switchMap(() =>
          this.lockerHttpService.transaction(reservedAmount).pipe(
            catchError((error) => {
              console.log(error);
              throw new MachineError(
                10000,
                ModuleType.RETAILER,
                'LOCKER_SKI.ERROR_TRANSACTION_FAILED',
                false
              );
            })
          )
        ),
        switchMap((res) => {
          console.log(res);

          let customerData = {
            reservedAmount: reservedAmount,
            startDate: new Date().toISOString(),
            pricePerHour: action.lockerPricePerHour,
            fileRefId: res.filerefid,
            hashedCardNumber: res.cardhash,
            transactionData: res,
          };

          ctx.patchState({
            customerData: customerData,
          });

          if (this.cardAlreadyUsedForOtherActiveRent(ctx, res.cardhash)) {
            return this.store.dispatch(new LockerSki.RevertTransaction()).pipe(
              tap(() => {
                throw new MachineError(
                  res.controlError,
                  ModuleType.RETAILER,
                  'LOCKER_SKI.ERROR_CARD_ALREADY_USED',
                  false
                );
              })
            );
          } else {
            return of(true);
          }
        }),
        switchMap(() =>
          this.store.dispatch(new Navigate(['/locker', 'ski', 'lock-wait']))
        )
      )
      .subscribe({
        next: (x) => console.log('finished startAuthorizationProcess'),
        error: (err) => this.handleError(err),
      });
  }

  @Action(LockerSki.UnlockSlots)
  unlockSlots(
    ctx: StateContext<LockerSkiStateModel>,
    action: LockerSki.UnlockSlots
  ) {
    console.log('unlockSlots');

    if (this.versionCheckService.noCurentActivity()) {
      this.lockerHttpService
        .getMachingeData()
        .pipe(
          switchMap((result) => {
            let freeSlots: number[] = [];

            result.free_slots.forEach((element) => {
              freeSlots.push(element.slot_index);
            });

            return this.lockerHttpService.unlockSlots(freeSlots);
          })
        )
        .subscribe({
          next: (x) => console.log('finished unlockSlots'),
          error: (err) => console.log(err),
        });
    } else {
      console.log('Unlock slots not on home page');
    }
  }

  handleError(error: any) {
    this.store
      .dispatch(new Core.Error(error, ModuleType.RETAILER))
      .subscribe(() => {
        setTimeout(() => {
          if (this.router.url == '/core/error') {
            this.store.dispatch(new Navigate(['/core/screensaver']));
          }
        }, 5000);
      });
  }

  cardAlreadyUsedForOtherActiveRent(
    ctx: StateContext<LockerSkiStateModel>,
    cardHash: any
  ): boolean {
    let activeRents: any[] = ctx.getState().activeRents;

    return (
      activeRents.filter((e) => e.card_number_hashed === cardHash).length > 0
    );
  }

  @Action(LockerSki.ResetRentProcess)
  resetProcess(ctx: StateContext<LockerSkiStateModel>) {
    let freeSlots: number[] = [];

    ctx.getState().freeSlots.forEach((element) => {
      freeSlots.push(element.slot_index);
    });

    this.resetState(ctx);

    this.lockerHttpService.unlockSlots(freeSlots).subscribe({
      error: (err) =>
        this.store.dispatch(new Navigate(['/core', 'screensaver'])),
      complete: () =>
        this.store.dispatch(new Navigate(['/core', 'screensaver'])),
    });
  }

  @Action(LockerSki.RevertTransaction)
  revertTransaction(ctx: StateContext<LockerSkiStateModel>) {
    let customerData = ctx.getState().customerData;

    let data = {
      fileRefId: customerData.fileRefId,
      amount: 0.0,
      originalStan: customerData.transactionData.stan,
    };
    return this.lockerHttpService.revertTransaction(data);
  }
}
