<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="w-full h-screen flex flex-col items-start justify-start bg-primaryx text-white box-main"
  >
    <div
      class="mt-5 w-11/12 mx-auto flex flex-col flex-1 items-center justify-start text-center pt-10"
    >
      <div class="w-full h-auto flex flex-1 justify-center">
        <!-- locker -->
        <div
          class="w-5/6 h-full grid gap-y-px gap-x-px"
          [class]="lockerColClass"
        >
          <div [class]="item.size" *ngFor="let item of lockers">
            <div
              [class]="item.type"
              [ngClass]="getSlotClass(item)"
              (click)="selectSlot(item)"
            >
              {{ item.number }}
              <div class="locker-info" *ngIf="item.isSlotFree">
                <span>{{ item.height }}</span>
                <span>{{ item.width }}</span>
                <span>{{ item.depth }}</span>
              </div>
              <div *ngIf="item.type == 'locker-display'" class="screen"></div>
            </div>
          </div>
        </div>

        <!-- end locker -->
      </div>

      <div class="w-full flex flex-col items-center justify-between mt-12">
        <ng-container>
          <h2
            class="text-4xl leading-normal font-bold flex flex-col justify-start wow fadeInUp"
            data-wow-delay="0"
            data-wow-duration="0.5s"
          >
            <span>{{ description }}</span>
          </h2>
          <h2
            class="text-2xl leading-normal font-normal text-white-80 wow fadeInUp mb-12"
            data-wow-delay="0"
            data-wow-duration="0.5s"
          ></h2>
        </ng-container>
      </div>
    </div>
    <div
      class="pb-14 w-10/12 mx-auto space-x-16 flex flex-row items-center justify-between pt-4"
    >
      <app-btn-sanusx
        (btnClick)="cancel()"
        [label]="'COMMON.BTN_CANCEL' | translate"
        buttonType="neutral"
        class="wow slideInUp"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
      <app-btn-sanusx
        *ngIf="reopenOption"
        (btnClick)="reopenLocker()"
        [label]="'LOCKER_RENT_OB.REOPEN' | translate"
        buttonType="secondary"
        class="wow slideInUp"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
      <app-btn-sanusx
        (btnClick)="openLocker()"
        [label]="completeButtonTitle | translate"
        buttonType="secondary"
        [redBorder]="true"
        class="wow slideInUp"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
    </div>
  </div>
</ng-container>

<div
  class="w-screen h-screen bg-white-80 backdrop-blur-sm fixed z-90 top-0 left-0 flex flex-col items-center justify-center wow fadeIn animated"
  data-wow-delay="0s"
  data-wow-duration="0.2s"
  *ngIf="loaderActive"
>
  <div
    id="loader-sanusx"
    class="flex flex-grow flex-col items-center justify-center text-primaryx-50 relative z-20"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
      <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

      <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
    </svg>
    <h3 class="uppercase tracking-wider my-10 text-gray font-semibold">
      {{ "COMMON.PLEASE_WAIT" | translate }}
    </h3>
  </div>
</div>
