import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[touchClick]',
})
export class TouchClickDirective implements OnInit {
  @Input() maxDistance = 100;
  @Input() maxTime = 2000;

  @Input() touchClick: boolean;
  start: Touch;
  constructor(private elem: ElementRef) {
    this.start = null;
  }
  ngOnInit(): void {
    // Bind the touches event to the element
    this.bindTouchEvents();
  }
  bindTouchEvents() {
    //if touchcancell happens..call pointer up
    this.elem.nativeElement.addEventListener(
      'touchcancel',
      this.onTouchCancel.bind(this),
      false
    );
    /*this.elem.nativeElement.addEventListener(
      'touchend',
      this.onTouchEnd.bind(this),
      false
    );*/
  }

  onTouchCancel(e: TouchEvent) {
    // hold the touch start position

    //this.elem.nativeElement.click();
    //this.start = e.touches[0];

    // clear the position after 2000 mil (could be set for less).
    //  setTimeout(() => {
    //  this.start = null;
    // }, this.maxTime);

    const mouseup = new MouseEvent('pointerup', {
      bubbles: false, // event bubbles up through the DOM
      cancelable: false, // event can be canceled
      view: window, // the view that the event was generated from
    });
    this.elem.nativeElement.dispatchEvent(mouseup);
  }
  onTouchEnd(e: TouchEvent) {
    // if the timeout was called, there will be no start position
    if (!this.start) {
      return;
    }
    console.log('touch end');

    // calculate the distance between start and end position
    const end = e.changedTouches[0],
      dx = Math.pow(this.start.pageX - end.pageX, 2),
      dy = Math.pow(this.start.pageY - end.pageY, 2),
      distance = Math.round(Math.sqrt(dx + dy));

    // if the distance is fairly small, fire
    // a click event. (default is 20 but you can override it through the constructor)

    const mouseup = new MouseEvent('pointerup', {
      bubbles: false, // event bubbles up through the DOM
      cancelable: false, // event can be canceled
      view: window, // the view that the event was generated from
    });
    if (distance <= this.maxDistance) {
      this.elem.nativeElement.dispatchEvent(mouseup);
    }

    //clear the start position again
    this.start = null;
  }
}
