import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/core/services/lang.service';
import { CoreState, CoreStateModel } from '../state/core.state';



@Injectable({
  providedIn: 'root'
})
export class ContentTxtService {

  constructor(private store: Store, private langService: LangService, private translate: TranslateService) {

  }


  getFieldContentTxt(fieldName: any, defaultLangTag: any) {
    let currentLang = this.langService.getLanguage();
    let contentTxt = this.store.selectSnapshot<CoreStateModel>(CoreState).config.contentTxt;

    if (contentTxt[currentLang] && contentTxt[currentLang][fieldName] && contentTxt[currentLang][fieldName] !== "") {
      return contentTxt[currentLang][fieldName]
    } else {
      return this.translate.instant(defaultLangTag);
    }
  }
}
