import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  theme: string;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;
  constructor() {
    this.theme = localStorage.getItem('theme') ?? 'default';

    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
  }

  ngOnInit(): void {
    console.log('payment started');
  }
}
