import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { MachineError } from '../models/error';
import { CoreState, CoreStateModel } from '../state/core.state';
import { map, Observable } from 'rxjs';
import { DutyPharms } from '../models/duty-pharm';


@Injectable({
  providedIn: 'root'
})
export class DutyPharmService {

  constructor(private httpClient: HttpClient) { }
  getDutyPharm(machineId: number): Observable<DutyPharms[]> {
    const url = environment.configApi + `/machine/pharmacy-group/schedule/` + machineId;
    // const url = `http://localhost:9999/api/v1/machine/pharmacy-group/schedule/{{machineId}}`;

    return this.httpClient.get(url).pipe(
      map(res => res as DutyPharms[])
    );
  }







}
