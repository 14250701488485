import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import {
  LockerSkiState,
  LockerSkiStateModel,
} from 'src/app/locker/state/locker-ski.state';
import { Slot } from 'src/app/core/models/slot';

@Component({
  selector: 'app-reclaim-summary',
  templateUrl: './reclaim-summary.component.html',
  styleUrls: ['./reclaim-summary.component.scss'],
})
export class SkiReclaimSummaryComponent implements OnInit {
  lockers: Array<any> = [];
  totalAmount: number = 0;

  constructor(private store: Store) {
    let selectedSlots =
      this.store.selectSnapshot<LockerSkiStateModel>(
        LockerSkiState
      ).selectedSlots;
    let currentRent =
      this.store.selectSnapshot<LockerSkiStateModel>(LockerSkiState)
        .customerData.currentRent;

    currentRent.rent_items.forEach((rentItem) => {
      let rentedNow = selectedSlots.find(
        (x) => x.slotIndex === rentItem.slot_index
      );

      let amountForLocker = rentedNow
        ? rentedNow.totalAmount
        : rentItem.total_amount;
      this.totalAmount += amountForLocker;

      this.lockers.push({
        id: 0,
        number: rentItem.slot_index,
        selected: rentedNow || rentItem.locker_rent_item_status_id === 3,
        label: amountForLocker + '€',
      });
    });

    this.totalAmount =
      Math.round((this.totalAmount + Number.EPSILON) * 100) / 100;
    if (
      currentRent.reserved_amount &&
      this.totalAmount > currentRent.reserved_amount
    ) {
      this.totalAmount = currentRent.reserved_amount;
    }
  }

  ngOnInit(): void {}
}
