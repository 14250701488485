import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { LangService } from 'src/app/core/services/lang.service';

@Component({
  selector: 'app-nex-lang-switcher',
  templateUrl: './nex-lang-switcher.component.html',
  styleUrls: ['./nex-lang-switcher.component.scss']
})
export class NexLangSwitcherComponent implements OnInit {
  @HostBinding('class') class = 'w-auto flex flex-col items-center justify-center relative z-90';

  public availableLanguages = [];
  showLangList: boolean = false;
  currentLang: string;
  @Input() screenSaver: boolean = false;

  constructor(private langService: LangService) {
    this.currentLang = this.langService.getLanguage();
    this.availableLanguages = this.langService.getAvailableLanguages();
  }

  ngOnInit(): void {
  }


  toggleLanguageList() {
    this.showLangList = !this.showLangList;
  }

  setPublicLanguage() {
    console.log('langService', this.langService.getLanguage());
    console.log('currentLang', this.currentLang);
    switch (this.currentLang) {
      case 'de':
        this.langService.setPublicLanguage('en');
        this.langService.getNewLanguage('en');
        this.langService.setLanguage();
        this.langService.emitChangeLang('en');
        break;
      case 'en':
        this.langService.setPublicLanguage('de');
        this.langService.getNewLanguage('de');
        this.langService.setLanguage();
        this.langService.emitChangeLang('de');
        break;

      default:
    }
    this.currentLang = this.langService.getLanguage();
  }

}
