<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen px-6 bg-white"
  >
    <!-- infowindow header -->
    <div
      class="flex items-center min-h-[30%] max-h-[30%] overflow-hidden justify-center w-full px-8 py-18 bg-nex-gray-light rounded-b-4xl relative z-10 wow bounceInDown animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <!-- sign -->
      <div
        class="flex items-center justify-center mx-auto rounded-full w-80 h-80 bg-white-20 wow bounceIn animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div
          class="flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex flex-col items-center justify-center w-48 h-48 mx-auto bg-white rounded-full text-primaryx wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <svg
              width="50%"
              height="50%"
              viewBox="0 0 185 185"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="wow pulse animated"
              data-wow-delay="1s"
              data-wow-duration="2s"
              data-wow-iteration="5"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.2918 10.2926C16.4538 4.13067 24.8111 0.668945 33.5254 0.668945L149.24 0.668945C147.031 0.668945 145.24 2.45981 145.24 4.66894V26.9547V79.5261V178.098C145.24 180.465 143.966 182.65 141.906 183.816C139.846 184.982 137.317 184.951 135.287 183.732L105.811 166.047L76.3349 183.732C74.2538 184.981 71.654 184.981 69.573 183.732L40.0968 166.047L10.6206 183.732C8.5905 184.951 6.06211 184.982 4.00191 183.816C1.94171 182.65 0.668213 180.465 0.668213 178.098L0.668213 33.5261C0.668213 24.8118 4.12994 16.4545 10.2918 10.2926Z"
                fill="#0074C2"
              />
              <path
                d="M145.24 8.66894V79.5261H182.668C183.611 79.5261 184.083 79.5261 184.375 79.2332C184.668 78.9403 184.668 78.4689 184.668 77.5261V8.66895C184.668 4.89771 184.668 3.01209 183.497 1.84052C182.325 0.668945 180.44 0.668945 176.668 0.668945L153.24 0.668945C149.469 0.668945 147.583 0.668945 146.411 1.84052C145.24 3.01209 145.24 4.89771 145.24 8.66894Z"
                fill="#B2BEC3"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M115.588 52.3931C119.685 55.978 120.1 62.2054 116.515 66.3024L70.5151 118.874C67.1197 122.754 61.3075 123.362 57.1825 120.269L30.8968 100.554C26.5416 97.2879 25.659 91.1094 28.9254 86.7542C32.1917 82.3991 38.3702 81.5164 42.7254 84.7828L61.6981 99.0124L101.679 53.3204C105.263 49.2234 111.491 48.8083 115.588 52.3931Z"
                fill="#B2BEC3"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- end sign -->
    </div>
    <!-- end infowindow header -->

    <!-- infowindow msg part -->
    <div
      class="flex flex-col vert-shadow items-center justify-center flex-1 w-11/12 mx-auto pb-24 pt-28 px-8 h-auto bg-nex-gray-light-ultra relative -top-12 z-5 rounded-b-4xl wow bounceInDown animated"
      data-wow-delay="200ms"
      data-wow-duration=".4s"
    >
      <!-- msg -->
      <div
        class="w-full h-auto flex flex-col items-center justify-center wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <h1
          class="text-6xl font-bold text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="100ms"
          data-wow-duration="1s"
        >
          {{ "PRINT_BILL.TITLE" | translate }}
        </h1>

        <h2
          class="text-4xl font-medium text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "PRINT_BILL.MESSAGE" | translate }}
        </h2>
      </div>
      <!-- end msg -->
    </div>
    <!-- end infowindow msg part -->

    <!-- infowindow empty space at the bottom -->
    <div class="w-full h-52 block"></div>
    <!-- end infowindow empty space at the bottom -->
    <div
      class="w-11/12 mx-auto h-24.2 flex flex-row items-center justify-between wow fadeInUp animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <div
        class="h-full flex-1 flex flex-row space-x-4 items-center justify-center rounded-t-4xl bg-nex-gray-light"
      >
        <!-- BUTTON -->
        <button
          (click)="printConfirm()"
          class="max-w-64 w-auto h-20 bg-nex-green flex-row items-center justify-start flex text-white active:bg-nex-green/70 rounded-xl border-2 border-transparent active:border-nex-green active:shadow-inner-light"
        >
          <div class="p-4 w-16 h-16 justify-start items-start flex">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9998 29.3337C8.63584 29.3337 2.6665 23.3643 2.6665 16.0003C2.6665 8.63633 8.63584 2.66699 15.9998 2.66699C23.3638 2.66699 29.3332 8.63633 29.3332 16.0003C29.3332 23.3643 23.3638 29.3337 15.9998 29.3337ZM14.6705 21.3337L24.0972 11.9057L22.2132 10.0203L14.6705 17.563L10.8985 13.791L9.01317 15.6763L14.6705 21.3337Z"
                fill="currentColor"
              />
            </svg>
          </div>

          <div
            class="pr-6 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
          >
            {{ "PRINT_BILL.CONFIRM" | translate }}
          </div>
        </button>
        <!-- end BUTTON -->
        <!-- BUTTON -->
        <button
          (click)="printSkip()"
          class="max-w-64 w-auto h-20 bg-transparent flex-row items-center justify-start flex text-primaryx active:bg-white/70 rounded-xl border-2 border-primaryx active:border-nex-gray-dark active:shadow-inner-light"
        >
          <div class="p-4 w-16 h-16 justify-start items-start flex">
            <svg width="32" height="32" fill="none" viewBox="0 0 24 24">
              <path
                d="M17 6 17 18H15L15 6 17 6ZM13 12 7 18V6L13 12Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>

          <div
            class="pr-6 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
          >
            {{ "PRINT_BILL.SKIP" | translate }}
          </div>
        </button>
        <!-- end BUTTON -->
      </div>
    </div>
  </div>
</ng-container>
