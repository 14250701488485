import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';


@Injectable({
  providedIn: 'root'
})
export class LangService {

  languageTokenName = 'publicLanguage_derautomat';

  private emitChangeLangSource = new Subject<any>();

  changeLangEmitted$ = this.emitChangeLangSource.asObservable();

  constructor(private translateService: TranslateService) { }




  setLanguage() {
    // Get from user data
    const useLanguage = this.getLanguage();
    this.translateService.use(useLanguage);

    // Language for date pipes etc.
    switch (useLanguage) {
      case 'de':
        registerLocaleData(localeDe);
        break;
      default:
        registerLocaleData(localeEn);
        break;
    }
  }


  setPublicLanguage(languageCode: string) {
    localStorage.setItem(this.languageTokenName, languageCode);
  }


  getAvailableLanguages() {
    return [
      {
        id: localeDe[0],
        name: 'Deutsch',
        icon: 'assets/lang/de_2.png'
      },
      {
        id: localeEn[0],
        name: 'English',
        icon: 'assets/lang/gb_2.png'
      }
    ];
  }

  getNewLanguage(lang: string) {
    this.translateService.use(lang);
  }

  getLanguage() {
    let language = '';


    // Check selected language (fallback)
    if (language === undefined || language === null || language.length === 0) {
      language = localStorage.getItem(this.languageTokenName);
    }

    // Check environment language (fallback)
    if (language === undefined || language === null || language.length === 0) {
      language = environment.locale;
    }

    return language;
  }

  emitChangeLang(languageCode: string) {
    this.emitChangeLangSource.next(languageCode);
  }



}
