<ng-container *ngIf="theme === 'sanusx'"></ng-container>

<ng-container *ngIf="theme === 'default'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <div
      class="flex flex-row justify-center first-block bg-primaryx-bg wow bounceInDown animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16"
      >
        <app-cloud
          class="flex flex-col justify-end w-full h-full"
          [ngClass]="fullHdHalf ? '-mb-40' : fullHd ? ' -mb-36' : ' -mb-24'"
        ></app-cloud>
        <div
          class="absolute flex flex-col items-center justify-center w-64 h-64 mx-auto bg-white rounded-full drop-shadow-xl text-primaryx"
          [ngClass]="
            fullHdHalf
              ? 'mb-4 -ml-6'
              : fullHd
              ? ' ml-[-30px] mb-[4px]'
              : '-ml-6 -mb-12 '
          "
          style="border-radius: 9999px !important"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50%"
            height="50%"
            viewBox="0 0 135.07 124.263"
            class="wow pulse animated"
            data-wow-delay="1s"
            data-wow-duration="2s"
            data-wow-iteration="5"
          >
            <g
              id="Group_7291"
              data-name="Group 7291"
              transform="translate(0 46)"
            >
              <path
                id="Path_13538"
                data-name="Path 13538"
                d="M126.965,18.833V5.272a21.547,21.547,0,0,0-21.557-21.557H21.557a8.1,8.1,0,0,1-8.045-8.48,8.313,8.313,0,0,1,8.442-7.729h83.454a8.037,8.037,0,0,1,7.121,4.347,6.829,6.829,0,0,0,6.062,3.736,6.789,6.789,0,0,0,5.97-9.938A21.545,21.545,0,0,0,105.408-46H21.557A21.558,21.558,0,0,0,0-24.443V56.706A21.547,21.547,0,0,0,21.557,78.263h83.851a21.547,21.547,0,0,0,21.557-21.557V43.145H113.458V56.706a8.07,8.07,0,0,1-8.05,8.05H21.557a8.07,8.07,0,0,1-8.05-8.05V-4.348a21.445,21.445,0,0,0,8.05,1.569h83.851a8.07,8.07,0,0,1,8.05,8.05V18.833Z"
                fill="#4a5568"
              />
              <path
                id="Path_13539"
                data-name="Path 13539"
                d="M77.538-25H38.592A11.592,11.592,0,0,0,27-13.406V3.926A11.592,11.592,0,0,0,38.592,15.521H77.538A11.594,11.594,0,0,0,89.132,3.926V-13.406A11.594,11.594,0,0,0,77.538-25M75.625,2.014H40.507V-11.493H75.625Z"
                transform="translate(45.938 35.729)"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center text-center middle-block">
      <div
        class="w-9/12 mx-auto wow fadeIn animated"
        data-wow-delay="0.5s"
        data-wow-duration="1s"
      >
        <h1
          class="self-end mx-auto font-bold text-center title text-primaryx-dark"
        >
          {{ "RETAILER_SELECT.TITLE" | translate }}
        </h1>
        <h2 class="self-end mx-auto text-center title text-primaryx-dark">
          {{ "RETAILER_SELECT.MESSAGE" | translate }}
        </h2>
        <!-- total amount -->
        <div
          class="flex flex-col items-center justify-center w-8/12 h-24 mx-auto mt-8 mb-4 font-bold text-white rounded-full drop-shadow-2xl text-7xl bg-primaryx"
        >
          € {{ totalForPay }}
        </div>
        <!-- end total amount -->
      </div>
    </div>

    <div
      class="flex flex-col items-start justify-start third-block bg-primaryx-bg wow bounceInUp animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="flex flex-col items-center justify-center w-11/12 h-full mx-auto space-y-10"
      >
        <button
          class="btn btn-action btn-action--success-long border-primaryx group active:border-active"
          (click)="homeDelivery()"
        >
          <span
            class="px-0 mx-auto text-primaryx label group-active:text-active"
            style="padding: 0"
          >
            {{ "RETAILER_SELECT.HOME_DELIVERY" | translate }}
          </span>
        </button>
        <button
          class="btn btn-action btn-action--success-long border-primaryx group active:border-active"
          (click)="payAtTerminal()"
        >
          <span
            class="px-0 mx-auto text-primaryx label group-active:text-active"
            style="padding: 0"
          >
            {{ "RETAILER_SELECT.PAY_AT_TERMINAL" | translate }}
          </span>
        </button>
        <button
          class="btn btn-action btn-action--success-long border-primaryx group active:border-active"
          (click)="printReceipt()"
        >
          <span
            class="px-0 mx-auto text-primaryx label group-active:text-active"
            style="padding: 0"
          >
            {{ "RETAILER_SELECT.PRINT_RECEIPT" | translate }}
          </span>
        </button>
        <button
          class="btn btn-action border-gray-light group active:border-active mx-auto
            {{ rightHand ? 'order-2' : 'order-2' }}"
          [ngClass]="fullHdHalf && 'btn-action--middle'"
          (click)="cancelRetailer()"
        >
          <span class="icon bg-gray group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49.498"
              height="49.498"
              viewBox="0 0 49.498 49.498"
            >
              <path
                id="close_icon"
                data-name="close_icon"
                d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                transform="translate(950.191 -4593.964) rotate(-45)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label text-gray group-active:text-active"
            >ABBRECHEN</span
          >
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="theme === 'nexus'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <div
      class="flex flex-row justify-center first-block bg-primaryx-bg wow bounceInDown animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16"
      >
        <app-cloud
          class="flex flex-col justify-end w-full h-full"
          [ngClass]="fullHdHalf ? '-mb-40' : fullHd ? ' -mb-36' : ' -mb-24'"
        ></app-cloud>
        <div
          class="absolute flex flex-col items-center justify-center w-64 h-64 mx-auto bg-white rounded-full drop-shadow-xl text-primaryx"
          [ngClass]="
            fullHdHalf
              ? 'mb-4 -ml-6'
              : fullHd
              ? ' ml-[-30px] mb-[4px]'
              : '-ml-6 -mb-12 '
          "
          style="border-radius: 9999px !important"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50%"
            height="50%"
            viewBox="0 0 135.07 124.263"
            class="wow pulse animated"
            data-wow-delay="1s"
            data-wow-duration="2s"
            data-wow-iteration="5"
          >
            <g
              id="Group_7291"
              data-name="Group 7291"
              transform="translate(0 46)"
            >
              <path
                id="Path_13538"
                data-name="Path 13538"
                d="M126.965,18.833V5.272a21.547,21.547,0,0,0-21.557-21.557H21.557a8.1,8.1,0,0,1-8.045-8.48,8.313,8.313,0,0,1,8.442-7.729h83.454a8.037,8.037,0,0,1,7.121,4.347,6.829,6.829,0,0,0,6.062,3.736,6.789,6.789,0,0,0,5.97-9.938A21.545,21.545,0,0,0,105.408-46H21.557A21.558,21.558,0,0,0,0-24.443V56.706A21.547,21.547,0,0,0,21.557,78.263h83.851a21.547,21.547,0,0,0,21.557-21.557V43.145H113.458V56.706a8.07,8.07,0,0,1-8.05,8.05H21.557a8.07,8.07,0,0,1-8.05-8.05V-4.348a21.445,21.445,0,0,0,8.05,1.569h83.851a8.07,8.07,0,0,1,8.05,8.05V18.833Z"
                fill="#4a5568"
              />
              <path
                id="Path_13539"
                data-name="Path 13539"
                d="M77.538-25H38.592A11.592,11.592,0,0,0,27-13.406V3.926A11.592,11.592,0,0,0,38.592,15.521H77.538A11.594,11.594,0,0,0,89.132,3.926V-13.406A11.594,11.594,0,0,0,77.538-25M75.625,2.014H40.507V-11.493H75.625Z"
                transform="translate(45.938 35.729)"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center text-center middle-block">
      <div
        class="w-9/12 mx-auto wow fadeIn animated"
        data-wow-delay="0.5s"
        data-wow-duration="1s"
      >
        <h1
          class="self-end mx-auto font-bold text-center title text-primaryx-dark"
        >
          {{ "RETAILER_SELECT.TITLE" | translate }}
        </h1>
        <h2 class="self-end mx-auto text-center title text-primaryx-dark">
          {{ "RETAILER_SELECT.MESSAGE" | translate }}
        </h2>
        <!-- total amount -->
        <div
          class="flex flex-col items-center justify-center w-8/12 h-24 mx-auto mt-8 mb-4 font-bold text-white rounded-full drop-shadow-2xl text-7xl bg-primaryx"
        >
          € {{ totalForPay }}
        </div>
        <!-- end total amount -->
      </div>
    </div>

    <div
      class="flex flex-col items-start justify-start third-block bg-primaryx-bg wow bounceInUp animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="flex flex-col items-center justify-center w-11/12 h-full mx-auto space-y-10"
      >
        <button
          class="btn btn-action btn-action--success-long border-primaryx group active:border-active"
          (click)="homeDelivery()"
        >
          <span
            class="px-0 mx-auto text-primaryx label group-active:text-active"
            style="padding: 0"
          >
            {{ "RETAILER_SELECT.HOME_DELIVERY" | translate }}
          </span>
        </button>
        <button
          class="btn btn-action btn-action--success-long border-primaryx group active:border-active"
          (click)="payAtTerminal()"
        >
          <span
            class="px-0 mx-auto text-primaryx label group-active:text-active"
            style="padding: 0"
          >
            {{ "RETAILER_SELECT.PAY_AT_TERMINAL" | translate }}
          </span>
        </button>
        <button
          class="btn btn-action btn-action--success-long border-primaryx group active:border-active"
          (click)="printReceipt()"
        >
          <span
            class="px-0 mx-auto text-primaryx label group-active:text-active"
            style="padding: 0"
          >
            {{ "RETAILER_SELECT.PRINT_RECEIPT" | translate }}
          </span>
        </button>
        <button
          class="btn btn-action border-gray-light group active:border-active mx-auto
            {{ rightHand ? 'order-2' : 'order-2' }}"
          [ngClass]="fullHdHalf && 'btn-action--middle'"
          (click)="cancelRetailer()"
        >
          <span class="icon bg-gray group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49.498"
              height="49.498"
              viewBox="0 0 49.498 49.498"
            >
              <path
                id="close_icon"
                data-name="close_icon"
                d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                transform="translate(950.191 -4593.964) rotate(-45)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label text-gray group-active:text-active"
            >ABBRECHEN</span
          >
        </button>
      </div>
    </div>
  </div>
</ng-container>
