import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { TokenState } from '../state/token.state';
import { ControlService } from '../services/control.service';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CoreState, CoreStateModel } from '../state/core.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store, private controlService: ControlService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.store.selectSnapshot<any>(TokenState.get('token'));

    let tenant: string = '';

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      tenant =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.tenant;
    }

    if (request.url.indexOf('localhost') == -1 || request.url.indexOf('localhost:9999') > -1) {
      request = request.clone({
        setHeaders: {
          token: token,
          'X-Tenant': tenant,
        },
      });
    }

    let ok: boolean;
    let errorMessage: string = '';
    const started = Date.now();

    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        (event) => (ok = event instanceof HttpResponse ? true : false),
        // Operation failed; error is an HttpErrorResponse
        (error: HttpErrorResponse) => {
          ok = false;
          errorMessage = `Http status code: ${error.status}; Error message: ${error.message}`;
        }
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        if (!ok && !request.urlWithParams.endsWith('logger/log')) {
          const elapsed = Date.now() - started;
          let msg =
            `${request.method} "${request.urlWithParams}"
             status: ${ok} in ${elapsed} ms. ` + errorMessage;

          this.controlService.log(ok ? 'INFO' : 'ERROR', msg).subscribe();
        }
      })
    );
  }
}
