import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { ActivatedRoute } from '@angular/router';
import { Locker } from 'src/app/locker/state/locker.actions';
import {
  CoreState,
  CoreStateModel,
  ModuleType,
} from 'src/app/core/state/core.state';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import { of, timeout } from 'rxjs';

@Component({
  selector: 'app-done-delivery',
  templateUrl: './done-delivery.component.html',
  styleUrls: ['./done-delivery.component.scss'],
})
export class DoneDeliveryComponent implements OnInit {
  theme: string;
  fullHdHalf: boolean = false;
  deliveryAction: string;
  slotId: number;
  slotIndex: number;
  deliveryPositionId: number;
  deliveryId: number;
  message: string;
  public loaderActive: boolean = false;

  constructor(private store: Store, private route: ActivatedRoute) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.route.params.subscribe((params) => {
      this.deliveryAction = params.action;
      this.slotId = params.slotId;
      this.deliveryId = params.deliveryId;
      this.slotIndex = params.slotIndex;
      this.deliveryPositionId = params.deliveryPositionId;
      this.message = params.message;
    });
    this.finishAction();
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .controlSoftwareType === 'mqtt'
    ) {
      this.checkLockerDoor();
    } else {
      setTimeout((x) => {
        this.onComplete();
      }, 11000);
    }
  }

  checkLockerDoor() {
    this.store.dispatch(new Locker.CheckLockerDoor(this.slotIndex)).subscribe(
      (x) => {
        this.loaderActive = false;
        this.onComplete();
      },
      (error: MachineError) => {
        this.loaderActive = false;
      }
    );
  }

  finishAction() {
    let action: any;
    if (this.deliveryAction == 'load') {
      action = new Locker.StoreDeliveryItem(
        this.deliveryPositionId,
        this.slotId
      );
    } else if (this.deliveryAction == 'take-dropoff') {
      action = new Locker.TakeDropoffItem(
        this.deliveryPositionId,
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).activeUser.userData.authenticationId
      );
    } else if (this.deliveryAction == 'dropoff') {
      // TODO
      //  this.store.dispatch(new Navigate(['/core/screensaver']));
      //  return;
    } else if (this.deliveryAction == 'start-rent') {
      //create rent for this slotId
      action = new Locker.StartRentItem(this.deliveryPositionId);
    } else if (this.deliveryAction == 'reopen-rent') {
      // TODO
      //  this.store.dispatch(new Navigate(['/core/screensaver']));
      // return;
    } else if (this.deliveryAction == 'finish-rent') {
      // finish rent for this rentId
      action = new Locker.FinishRentItem(this.deliveryId, this.message);
    } else {
      action = new Locker.TakeDeliveryItem(this.deliveryPositionId);
    }
    if (action) this.dispatch(action);
  }

  private dispatch(action: any) {
    this.store.dispatch(action).subscribe(
      (x) => {},
      (error: MachineError) => {
        this.store
          .dispatch(new Core.Error(error, ModuleType.RETAILER))
          .subscribe(() => {
            setTimeout(() => {
              this.store.dispatch(new Navigate(['/']));
            }, 10000);
          });
      }
    );
  }

  onComplete() {
    let deliveries: any;
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;

    if (this.deliveryAction == 'load') {
      deliveries = activeUser.deliveriesToLoad;
    } else if (this.deliveryAction == 'take-dropoff') {
      deliveries = null;
    } else {
      deliveries = activeUser.pickupDeliveries;
    }

    let redirectTo: Navigate = new Navigate(['/core/screensaver']);
    if (deliveries) {
      deliveries.forEach((delivery) => {
        if (delivery.id == this.deliveryId) {
          let statusToSearch: number = this.deliveryAction == 'load' ? 0 : 1;

          let countForPickup = delivery.positions.filter(function (element) {
            return element.status == statusToSearch;
          }).length;

          if (countForPickup > 0) {
            redirectTo = new Navigate([
              '/locker',
              'delivery',
              'positions',
              this.deliveryAction,
              this.deliveryId,
            ]);
          }
        }
      });
    }

    this.store.dispatch(redirectTo);
  }

  ngOnInit(): void {}
}
