import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import {delay, map, tap} from 'rxjs/operators';
import {VendingTypes} from 'src/app/retailer/state/retailer.state';
import {environment} from 'src/environments/environment';

import {CoreState, CoreStateModel} from '../state/core.state';

export enum ControlCode {
  SUCCESS                 = 0,
  NOT_CONFIGURED          = 1,
  HATCH_OPEN              = 2,
  TIMEOUT                 = 3,
  COMMAND_ERROR           = 4,
  BUSY                    = 5,
  COMMUNICATION_ERROR     = 6,
  TRAY_NOT_CLEAR          = 7,
  MAINTENANCE_IN_PROGRESS = 8,
  BLOCKED                 = 9,
  BOX_NOT_FOUND           = 10,
  PRODUCT_STICKS_OUT      = 11,
  PUSHER_STUCK            = 12,
  UNKNOWN_MACHINE_ERRRO   = 13,
  BOX_ALREADY_EXISTS      = 14,
  OUT_OF_BOUNDS           = 15,
  INVALID_POSITION        = 16,
  FAILED_TO_EXECUTE       = -1,
}

interface ControlResponse
{
  controlError: ControlCode;
  msg: string;
}

interface HealthCheckResponse
{
  controlError: ControlCode;
  status: number;  // Code check steuerung - protocol
  msg: string;
}

interface TemperatureResponse
{
  controlError: ControlCode;
  temperature: number;
}

@Injectable ( {
  providedIn : 'root',
} )
export class ControlService
{
  constructor( private httpClient: HttpClient, private store: Store ) { }

  machineToken( ): Observable< string >
  {
    return this.httpClient.post( environment.controlApi + '/control/machineToken', { } )
      .pipe( map ( ( res ) => ( res as any ).machineToken ) );
  }

  configure( ): Observable< boolean >
  {
    return this.httpClient.post( environment.controlApi + '/control/configure', { } ).pipe( map ( ( res ) => true ) );
  }

  healthCheck( ): Observable< HealthCheckResponse >
  {
    let vendingType = this.store.selectSnapshot< CoreStateModel >( CoreState ).config.vendingType;

    let url: string;

    if ( vendingType === VendingTypes.WWKS2 )
    {
      url = '/terminal/healthCheck';
    }
    else
    {
      url = '/control/healthCheck';
    }

    return this.httpClient.post( environment.controlApi + url, { } ).pipe( map ( ( res ) => res as HealthCheckResponse ) );
  }

  getTemperature( ): Observable< TemperatureResponse >
  {
    return this.httpClient.post( environment.controlApi + '/control/temperature', { } )
      .pipe( map ( ( res ) => res as TemperatureResponse ) );
  }

  releaseContent( boxId: number, container: string ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/control/releaseContent', {
        boxId : boxId,
        container : container,
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  openHatch( ): Observable< ControlResponse >
  {
    console.warn( 'Sending openHatch' );
    return this.httpClient.post( environment.controlApi + '/control/openHatch', { timeout : 60 } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  coffeeBlockPayment( ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/coffee/blockPayment', { } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }
  coffeeEnablePayment( ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/coffee/enablePayment', { } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalOpenHatch( ): Observable< ControlResponse >
  {
    console.warn( 'Sending terminalOpenHatch' );
    return this.httpClient
      .post( environment.controlApi + '/terminal/command', {
        command : 'open_hatch',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }
  terminalCheckHatch( ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/terminal/checkHatch', { } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }
  terminalCheckHatchOnce( ): Observable< any >
  {
    return this.httpClient
      .post( environment.controlApi + '/terminal/command', {
        command : 'check_hatch',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalFinishRelease( ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/terminal/command', {
        command : 'finish_release',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalAbort( ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/terminal/command', {
        command : 'abort',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalLedOn( ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/terminal/command', { command : 'led_on' } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalLedOff( ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/terminal/command', {
        command : 'led_off',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalCloseHatch( ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/terminal/command', {
        command : 'close_hatch',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalReleaseContent( productCount: number ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/terminal/releaseContent', {
        countTimeout : 90,
        hatchTimeout : 60,
        numberOfProducts : productCount,
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalCheckOuttake( ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/terminal/checkOuttake', { } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }
  terminalStartOuttake( ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/terminal/command', {
        command : 'start_outtake',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  terminalClearPickup( ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/terminal/command', {
        command : 'clear_pickup',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  // home(): Observable<ControlResponse> {
  //   return this.httpClient.post(environment.controlApi + '/control/home', {}).pipe(
  //     map(res => {
  //       return res as ControlResponse;
  //     })
  //   );
  // }

  log( type: string, message: string ): Observable< any >
  {
    return this.httpClient.post( environment.controlApi + '/logger/log', {
      type : type,
      message : message,
    } );
  }

  openLocker( boxId: number ): Observable< ControlResponse >
  {
    return this.httpClient
      .post( environment.controlApi + '/control/releaseContent', {
        boxId : boxId,
        container : 'locker',
      } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  moveToBox( boxId: number ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/control/moveToBox', { boxId : boxId } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  push( speed: number ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/control/push', { speed : speed } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  homeAxes( ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/control/home', { } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  testPosition( boxId: number ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/control/testPosition', { boxId : boxId } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  withdraw( ): Observable< ControlResponse >
  {
    return this.httpClient.post( environment.controlApi + '/control/withdraw', { } )
      .pipe( map ( ( res ) => { return res as ControlResponse; } ) );
  }

  restart( ): Observable< any > { return this.httpClient.post( environment.controlApi + '/machine/restart', { } ); }
}
