import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { ActivatedRoute } from '@angular/router';
import { Locker } from 'src/app/locker/state/locker.actions';
import {
  CoreState,
  CoreStateModel,
  ModuleType,
} from 'src/app/core/state/core.state';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import { of } from 'rxjs';

@Component({
  selector: 'app-door-oppened',
  templateUrl: './door-oppened.component.html',
  styleUrls: ['./door-oppened.component.scss'],
})
export class DoorOpenedComponent implements OnInit {
  theme: string;
  fullHdHalf: boolean = false;
  deliveryAction: string;
  slotId: number;
  slotIndex: number;
  deliveryPositionId: number;
  deliveryId: number;
  public loaderActive: boolean = false;

  constructor(private store: Store, private route: ActivatedRoute) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.route.params.subscribe((params) => {});

    // this.openLocker();
  }

  /* openLocker() {
    this.store
      .dispatch(new Locker.OpenLocker(this.selected, containerCode))
      .subscribe(
        (x) => {
          this.loaderActive = false;
        },
        (error: MachineError) => {
          this.loaderActive = false;
          this.store
            .dispatch(new Core.Error(error, ModuleType.RETAILER))
            .subscribe(() => {
              setTimeout(() => {
                this.store.dispatch(new Navigate(['/']));
              }, 10000);
            });
        }
      );
  } */

  ngOnInit(): void {}
}
