import { Component, OnInit, Input, Output, HostBinding, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @HostBinding('class') class = 'btn product_item';
  // click outputs
  @Output() btnOpenDetails = new EventEmitter<any>();
  @Output() btnAddToCard = new EventEmitter<any>();
  // type of screen
  @Input() fullHdHalf: boolean = false;
  @Input() fullHd: boolean = false;
  @Input() flatProductCard: boolean = false; // flat product card will show only image

  // product cards types
  @Input() id: number = null;
  @Input() theme: string = '';
  @Input() image: any = '/assets/placeholder.png';
  @Input() badgeImg: any = '';
  @Input() brandName: string = '';
  @Input() name: string = '';
  @Input() new: boolean = false;
  @Input() promo: boolean = false;
  @Input() action: boolean = false;
  @Input() quantity: number = null; // packagingQuantity
  @Input() grossPrice: number = null;
  @Input() price: number = null;
  @Input() pricePerItem: number = null;
  @Input() priceType: string = '';
  @Input() standardGrossPrice: number = null;
  @Input() standardSalePrice: number = null;
  @Input() standardSaleVatAmount: number = null;
  @Input() standardVatPercent: number = null;
  @Input() vatAmount: number = null;
  @Input() vatPercent: number = null;
  @Input() useShoppingCart: boolean = false;
  @Input() cartIsFull: boolean = false;
  @Input() hasEmptyStock: boolean = false;
  @Input() statusCartMessage: boolean = false;
  priceDifference: number = null;
  percentDifference: number = null;

  constructor() {
  }

  ngOnInit(): void {
    if (this.fullHdHalf && this.theme !== 'nex') {
      this.class += ' product_item--middle';
    }

    if (this.fullHd && this.theme !== 'nex') {
      this.class += ' product_item--fullhd';
    }


    if (this.fullHd && this.theme === 'nex' && this.flatProductCard) {
      this.class += ' product_item--nex-flat';
    }

    if (this.fullHd && this.theme === 'nex' && !this.flatProductCard) {
      this.class += ' product_item--nex-normal';
    }

    if (this.priceType === 'SALE') {
      this.priceDifference = this.standardGrossPrice - this.grossPrice;
      this.percentDifference = (this.priceDifference / this.standardGrossPrice) * 100;
    }
  }

  onOpenDetails(event) {
    this.btnOpenDetails.emit(event);
  }
  onAddToCard(event) {
    this.btnAddToCard.emit(event);
  }

}
