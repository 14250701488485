<div class="flex flex-col items-start justify-between w-full h-screen">
  <app-ski-bg
    [isLight]="false"
    [useAnimation]="true"
    [showImg]="true"
    [clockCenter]="true"
    [bigSelection]="false"
    [showImg]="false"
  >
    <ng-container slot="top">
      <app-header-ski
        [title]="'LOCKER_SKI.UNLOCK_TITLE' | translate"
        subtitle="{{ 'LOCKER_SKI.UNLOCK_RENT_START' | translate }} {{
          rentStart
        }}"
        [big]="false"
      ></app-header-ski>
    </ng-container>

    <!-- left btns -->
    <ng-container slot="btn4">
      <ng-container *ngIf="lockers[0]">
        <app-btn-ski
          (btnClick)="selectLockerToUnlock(0)"
          [label]="
            lockers[0].selected
              ? ('COMMON.UNSELECT' | translate)
              : ('COMMON.SELECT' | translate)
          "
          [big]="false"
          [type]="lockers[0].selected ? 'selected' : 'default'"
          [right]="false"
          [number]="lockers[0].slotIndex"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn3">
      <ng-container *ngIf="lockers[1]">
        <app-btn-ski
          (btnClick)="selectLockerToUnlock(1)"
          [label]="
            lockers[1].selected
              ? ('COMMON.UNSELECT' | translate)
              : ('COMMON.SELECT' | translate)
          "
          [big]="false"
          [type]="lockers[1].selected ? 'selected' : 'default'"
          [right]="false"
          [number]="lockers[1].slotIndex"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn2">
      <ng-container *ngIf="lockers[2]">
        <app-btn-ski
          (btnClick)="selectLockerToUnlock(2)"
          [label]="
            lockers[2].selected
              ? ('COMMON.UNSELECT' | translate)
              : ('COMMON.SELECT' | translate)
          "
          [big]="false"
          [type]="lockers[2].selected ? 'selected' : 'default'"
          [right]="false"
          [number]="lockers[2].slotIndex"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn1">
      <ng-container *ngIf="lockers[3]">
        <app-btn-ski
          (btnClick)="selectLockerToUnlock(3)"
          [label]="
            lockers[3].selected
              ? ('COMMON.UNSELECT' | translate)
              : ('COMMON.SELECT' | translate)
          "
          [big]="false"
          [type]="lockers[3].selected ? 'selected' : 'default'"
          [right]="false"
          [number]="lockers[3].slotIndex"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <!-- end left btns -->

    <!-- right btns -->
    <ng-container slot="btn8"> </ng-container>
    <ng-container slot="btn7"> </ng-container>
    <ng-container slot="btn6">
      <app-btn-ski
        *ngIf="unlockActive"
        (btnClick)="onFinish()"
        [label]="'COMMON.UNLOCK' | translate"
        [big]="false"
        [type]="'subaction'"
        [subActionType]="'finish'"
        [right]="true"
        [withAnimation]="false"
        [extraCss]="unlockActive ? 'right-0' : '-right-[120%]'"
      >
      </app-btn-ski>
    </ng-container>
    <ng-container slot="btn5">
      <app-btn-ski
        (btnClick)="onCancel()"
        [label]="'COMMON.CANCEL' | translate"
        [big]="false"
        [type]="'subaction'"
        [subActionType]="'cancel'"
        [right]="true"
      >
      </app-btn-ski>
    </ng-container>
    <!-- end right btns -->
  </app-ski-bg>
</div>
