import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  Input,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MachineError } from '../../../models/error';

enum SetupActivity {
  OPEN_HATCH,
  RUN_SETUP,
}

@Component({
  selector: 'setup-dialog',
  templateUrl: './setup-dialog.component.html',
})
export class SetupDialog implements OnInit, OnDestroy {
  theme: string;
  title = '';
  mode = 'indeterminate';
  countdownFull = 60;
  countdown = 0;
  error = null;
  showLoaderNumbers: boolean = false;
  actionSubscription: Subscription = null;
  counter = null;
  @Output() hatchLoaderStatus: EventEmitter<boolean> = new EventEmitter();
  @Input() hatchData: any;
  constructor() {
    this.theme = localStorage.getItem('theme') ?? 'default';
  }

  ngOnInit(): void {
    if (this.hatchData.activity == SetupActivity.OPEN_HATCH) {
      this.title = 'Klappe geöffnet';
      this.showLoaderNumbers = true;
      this.countdownFull = 60;
      this.countdown = 60;

      this.counter = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        }
      }, 1000);

      this.actionSubscription = this.hatchData.observable.subscribe(
        () => {
          clearInterval(this.counter);
          this.hatchLoaderStatus.emit(false);
        },
        (error: MachineError) => {
          clearInterval(this.counter);
          this.error = error;
          setTimeout(() => {
            this.hatchLoaderStatus.emit(false);
          }, 10000);
        }
      );
    } else if (this.hatchData.activity == SetupActivity.RUN_SETUP) {
      this.title = 'Setup running';
      this.showLoaderNumbers = false;

      this.actionSubscription = this.hatchData.observable.subscribe(
        () => {
          setTimeout(() => {
            this.hatchLoaderStatus.emit(false);
          }, 1000);
        },
        (error: any) => {
          this.error = error;
          setTimeout(() => {
            this.hatchLoaderStatus.emit(false);
          }, 10000);
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.actionSubscription != null) {
      this.actionSubscription.unsubscribe();
    }
    if (this.counter != null) {
      clearInterval(this.counter);
    }
  }
}
