import { Component, OnInit, Input } from '@angular/core';

import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-modal-notify',
  templateUrl: './modal-notify.component.html',
  styleUrls: ['./modal-notify.component.scss']
})
export class ModalNotifyComponent implements OnInit {
  @Input() iconname: string = '';
  @Input() iconCssClass: string = '';
  @Input() useRedirect: boolean = false;
  @Input() redirectTo: any;
  @Input() theme: string = "sanusx";
  @Input() redirectAfter: number = null;



  constructor(private store: Store) {

  }

  ngOnInit(): void {



    if (this.useRedirect) {
      this.redirection(this.redirectAfter)
    }
  }


  public redirection(redirectAfter: number) {
    if (redirectAfter > 0) {

      let seconds = redirectAfter * 1000;

      setTimeout(() => {
        this.store.dispatch(new Navigate([`/${this.redirectTo.parent}`, this.redirectTo.child]));
      }, seconds);

    }

  }

}
