import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-nex-header',
  templateUrl: './nex-header.component.html',
  styleUrls: ['./nex-header.component.scss']
})
export class NexHeaderComponent implements OnInit {
  @HostBinding('class') class = 'w-full h-24.2 flex flex-row items-center justify-between';
  constructor() { }

  ngOnInit(): void {
  }

}
