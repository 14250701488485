import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import { ModuleType } from 'src/app/core/state/core.state';
import { Retailer } from '../../state/retailer.actions';
import { RetailerError } from '../../state/retailer.state';
import { CoreState, CoreStateModel } from '../../../core/state/core.state';
import Keyboard from 'simple-keyboard';
@Component({
  selector: 'app-pickup',
  templateUrl: './pickup.component.html',
  styleUrls: ['./pickup.component.scss'],
})
export class PickupComponent implements OnInit, AfterViewInit {
  logo: string;
  code: string = '';
  error: string;
  onScreenKeyboard: boolean = false;
  loaderActive: boolean = false;
  valueStatus: boolean = false;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;
  theme: string;
  public keyboard: Keyboard;
  public value: string = '';

  constructor(private store: Store, private idle: Idle) {
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      this.onScreenKeyboard =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.onScreenKeyboard;
    }
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
  }

  @ViewChild('codeInput')
  public codeInput: ElementRef;

  ngAfterViewInit() {
    this.keyboardInit();
    // setTimeout(() => this.codeInput.nativeElement.focus(), 1000);
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  handleBackspaceForRealKeyboard() {
    this.onChange(this.value.slice(0, -1));
  }

  onChange(input: string) {
    this.value = input;

    if (input.length >= 1) {
    } else if (input.length <= 1) {
      this.keyboard.clearInput();
    }
  }

  keyboardInit() {
    setTimeout(() => this.codeInput.nativeElement.focus(), 1000);

    this.keyboard = new Keyboard('.simple-keyboard-pickup', {
      onChange: (input) => this.onChange(input),
      onKeyPress: (button) => {
        if (button === '{bksp}') this.handleBackspaceForRealKeyboard();
      },
    });

    this.keyboard.setOptions({
      physicalKeyboardHighlightPress: true,
      physicalKeyboardHighlight: true,
      layout: {
        default: [
          '1 2 3 4 5 6 7 8 9 0 ß',
          'Q W E R T Z U I O P Ü',
          'A S D F G H J K L Ö Ä',
          'Y X C V B N M {space} {bksp}',
        ],
        shift: [
          '1 2 3 4 5 6 7 8 9 0 ß',
          'Q W E R T Z U I O P Ü',
          'A S D F G H J K L Ö Ä',
          'Y X C V B N M {space} {bksp}',
        ],
      },
      display: {
        '{bksp}': '    ',
        '{space}': 'SPACE',
        ß: '   ',
      },
      theme: 'default-theme-keyboard',
      buttonTheme: [
        {
          class: 'backspace',
          buttons: '{bksp}',
        },
        {
          class: 'ss',
          buttons: 'ß',
        },
        {
          class: 'space-new',
          buttons: '{space}',
        },
      ],
    });
  }

  onBlurKeyboard(event) {
    this.codeInput.nativeElement.focus();
  }

  pickup(): void {
    if (
      !this.codeInput.nativeElement.value ||
      this.codeInput.nativeElement.value.trim() == ''
    ) {
      console.log('empty');
      return;
    }

    this.loaderActive = !this.loaderActive;
    let code = this.codeInput.nativeElement.value.trim();
    this.store.dispatch(new Retailer.CheckPickupCode(code)).subscribe(
      (result) => {
        this.loaderActive = false;
      },
      (error: MachineError) => {
        console.log('error on back ', error);
        if (error.errorNumber == RetailerError.PRODUCT_NOT_EXIST) {
          this.error = 'RETAILER_PICKUP.WRONG_CODE';
          // 'Produkt mit diesem Abhol-Code konnte nicht gefunden werden.';
          this.loaderActive = false;
        } else if (error.errorNumber == RetailerError.NOT_ENOUGH_PRODUCTS) {
          this.error = 'RETAILER_PICKUP.NOT_AVAILABLE';
          //  'Eine Abholung ist noch nicht möglich. Bitte versuch es später';
          this.loaderActive = false;
        } else {
          this.loaderActive = false;
          this.store.dispatch(
            new Core.Error(
              new MachineError(
                error.errorNumber,
                ModuleType.RETAILER,
                'Error checking pickup key',
                false
              ),
              ModuleType.RETAILER
            )
          );
          setTimeout(
            () => this.store.dispatch(new Navigate(['/core/screensaver'])),
            5000
          );
        }
      }
    );
  }

  cancel(): void {
    this.store.dispatch(new Retailer.ActionSelection());
  }

  onEnter(): void {
    this.pickup();
  }

  errorClose(): void {
    this.error = '';
    this.codeInput.nativeElement.focus();
    this.code = '';
  }
}
