<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="w-full h-screen flex flex-col items-start justify-between bg-primaryx text-white"
  >
    <app-header [showSearch]="false" class="w-full"></app-header>
    <div
      class="w-full flex flex-col flex-1 items-center justify-center text-center px-27"
    >
      <h2 class="font-bold">
        {{ "LOCKER_DELIVERY_DONE_DELIVERY.MESSAGE" | translate }}
      </h2>
      <div
        class="flex w-full h-auto mx-auto flex-col items-center justify-center wow pulse animated my-10 text-white"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="211.83"
          height="211.828"
          viewBox="0 0 211.83 211.828"
        >
          <g
            id="Group_7451"
            data-name="Group 7451"
            transform="translate(0 141.301)"
          >
            <path
              id="Path_13624"
              data-name="Path 13624"
              d="M105.915,70.527A105.915,105.915,0,0,0,211.83-35.388,105.915,105.915,0,0,0,105.915-141.3,105.915,105.915,0,0,0,0-35.388,105.915,105.915,0,0,0,105.915,70.527ZM84.206-3.655,46.242-41.619,37.33-32.708,75.295,5.257l-.094.094,8.912,8.912L174.5-76.126l-8.911-8.911Z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </g>
        </svg>
      </div>
      <h2 class="mt-2 mx-auto">
        {{ "LOCKER_DELIVERY_DONE_DELIVERY.MESSAGE_1" | translate }}
      </h2>
    </div>
  </div>
</ng-container>
<div
  class="w-screen h-screen bg-white-80 backdrop-blur-sm fixed z-90 top-0 left-0 flex flex-col items-center justify-center wow fadeIn animated"
  data-wow-delay="0s"
  data-wow-duration="0.2s"
  *ngIf="loaderActive"
>
  <div
    id="loader-sanusx"
    class="flex flex-grow flex-col items-center justify-center text-primaryx-50 relative z-20"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
      <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

      <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
    </svg>
    <h3 class="uppercase tracking-wider my-10 text-gray font-semibold">
      {{ "COMMON.PLEASE_WAIT" | translate }}
    </h3>
  </div>
</div>
