import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { delay, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { Slot } from 'src/app/core/models/slot';
import { CoreStateModel, CoreState } from 'src/app/core/state/core.state';
import { environment } from 'src/environments/environment';
import { Item } from '../../core/models/item';
import { Product } from '../../retailer/models/product'; // Adjust the path if necessary

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private store: Store
  ) {}

  getItems(): Observable<Item[]> {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.getProductsV2();
    } else {
      return this.getProductsV1();
    }
  }

  getProductsV1(): Observable<Item[]> {
    return this.httpClient
      .post(environment.api + '/vendor/getProducts', {})
      .pipe(
        map((res) => res as Item[]),
        mergeMap((items) => items),
        filter(
          (item) =>
            item.slots.length > 0 &&
            item.slots.filter((slot) => slot.quantity > 0).length > 0
        ),
        tap(
          (item) =>
            (item.product.grossPrice =
              Math.round(
                item.product.price * (1 + item.product.vatPercent / 100) * 100
              ) / 100)
        ),
        tap(
          (item) =>
            (item.product.price = Math.round(item.product.price * 100) / 100)
        ),
        tap(
          (item) =>
            (item.product.vatAmount =
              item.product.grossPrice - item.product.price)
        ),
        map((item) => {
          item.slots = item.slots.filter((s) => s.quantity > 0);
          return item;
        }),
        toArray()
      );
  }

  getProductsV2(): Observable<Item[]> {
    const url = environment.configApi + `/machine/products`;

    return this.httpClient.get(url).pipe(
      map((res) => res as Item[]),
      mergeMap((items) => items),
      filter(
        (item) =>
          item.slots.length > 0 &&
          item.slots.filter((slot) => slot.quantity > 0).length > 0
      ),
      map((item) => {
        item.slots = item.slots.filter((s) => s.quantity > 0);
        return item;
      }),
      toArray()
    );
  }


  // getProductsV2(): Observable<Item[]> {
  //   const exampleData: Item[] = [
  //     {
  //       product: {
  //         id: 1,
  //         name: 'Marlboro Red',
  //         description: 'Premium cigarette pack',
  //         imagePaths: ['/assets/marlboro-red.png'],
  //         price: 7.0,
  //         grossPrice: 7.5,
  //         vatPercent: 20,
  //         vatAmount: 1.5,
  //         tags: 'cigarette,red',
  //         categories: [], // Add categories if needed
  //         productType: 1,
  //         errorState: false
  //       } as Product,
  //       slots: [
  //         {
  //           id: 1,
  //           slotIndex: 1,
  //           containerCode: 101,
  //           containerType: 'Pack',
  //           productId: 101,
  //           quantity: 20
  //         } as Slot,
  //         {
  //           id: 2,
  //           slotIndex: 2,
  //           containerCode: 102,
  //           containerType: 'Pack',
  //           productId: 101,
  //           quantity: 0
  //         } as Slot
  //       ],
  //       wwksArticle: null, // Assuming null or an empty object
  //       container: null // Assuming null or an empty object
  //     },
  //     {
  //       product: {
  //         id: 2,
  //         name: 'Camel Blue',
  //         description: 'Smooth cigarette pack',
  //         imagePaths: ['/assets/camel-blue.png'],
  //         price: 6.5,
  //         grossPrice: 7.0,
  //         vatPercent: 20,
  //         vatAmount: 1.3,
  //         tags: 'cigarette,blue',
  //         categories: [],
  //         productType: 1,
  //         errorState: false
  //       } as Product,
  //       slots: [
  //         {
  //           id: 3,
  //           slotIndex: 3,
  //           containerCode: 103,
  //           containerType: 'Pack',
  //           productId: 102,
  //           quantity: 15
  //         } as Slot,
  //         {
  //           id: 4,
  //           slotIndex: 4,
  //           containerCode: 104,
  //           containerType: 'Pack',
  //           productId: 102,
  //           quantity: 10
  //         } as Slot
  //       ],
  //       wwksArticle: null,
  //       container: null
  //     },
  //     {
  //       product: {
  //         id: 3,
  //         name: 'Lucky Strike Original',
  //         description: 'Classic cigarette pack',
  //         imagePaths: ['/assets/lucky-strike.png'],
  //         price: 6.0,
  //         grossPrice: 6.5,
  //         vatPercent: 20,
  //         vatAmount: 1.2,
  //         tags: 'cigarette,original',
  //         categories: [],
  //         productType: 1,
  //         errorState: false
  //       } as Product,
  //       slots: [
  //         {
  //           id: 5,
  //           slotIndex: 5,
  //           containerCode: 105,
  //           containerType: 'Pack',
  //           productId: 103,
  //           quantity: 0
  //         } as Slot,
  //         {
  //           id: 6,
  //           slotIndex: 6,
  //           containerCode: 106,
  //           containerType: 'Pack',
  //           productId: 103,
  //           quantity: 5
  //         } as Slot
  //       ],
  //       wwksArticle: null,
  //       container: null
  //     },
  //     {
  //       product: {
  //         id: 4,
  //         name: 'Parliament Lights',
  //         description: 'Smooth and light cigarette pack',
  //         imagePaths: ['/assets/parliament-lights.png'],
  //         price: 7.2,
  //         grossPrice: 7.7,
  //         vatPercent: 20,
  //         vatAmount: 1.4,
  //         tags: 'cigarette,lights',
  //         categories: [],
  //         productType: 1,
  //         errorState: false
  //       } as Product,
  //       slots: [
  //         {
  //           id: 7,
  //           slotIndex: 7,
  //           containerCode: 107,
  //           containerType: 'Pack',
  //           productId: 104,
  //           quantity: 0
  //         } as Slot,
  //         {
  //           id: 8,
  //           slotIndex: 8,
  //           containerCode: 108,
  //           containerType: 'Pack',
  //           productId: 104,
  //           quantity: 0
  //         } as Slot
  //       ],
  //       wwksArticle: null,
  //       container: null
  //     }
  //   ];

  //   return of(exampleData).pipe(
  //     mergeMap((items) => items),
  //     filter(
  //       (item) =>
  //         item.slots.length > 0 &&
  //         item.slots.filter((slot) => slot.quantity > 0).length > 0
  //     ),
  //     map((item) => {
  //       item.slots = item.slots.filter((s) => s.quantity > 0);
  //       return item;
  //     }),
  //     toArray()
  //   );
  // }



//   getProductsV2(): Observable<Item[]> {
//   const exampleData: Item[] = [
//     {
//       product: {
//         id: 1,
//         name: 'Tobacco1',
//         description: 'Premium cigarette pack',
//         imagePaths: ['/assets/marlboro-red.png'],
//         price: 7.0,
//         grossPrice: 7.5,
//         vatPercent: 20,
//         vatAmount: 1.5,
//         tags: 'cigarette,red',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 1,
//           slotIndex: 1,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 1,
//           quantity: 20
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 2,
//         name: 'Tobacco2',
//         description: 'Smooth cigarette pack',
//         imagePaths: ['/assets/camel-blue.png'],
//         price: 6.5,
//         grossPrice: 7.0,
//         vatPercent: 20,
//         vatAmount: 1.3,
//         tags: 'cigarette,blue',
//         categories: [{ id: 1, parentId: null, name: 'Cigarettes', description: 'All cigarette products', products: [], showChildren: false }], // Associated with "Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 2,
//           slotIndex: 2,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 2,
//           quantity: 15
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 3,
//         name: 'Tobacco3',
//         description: 'Classic cigarette pack',
//         imagePaths: ['/assets/lucky-strike.png'],
//         price: 6.0,
//         grossPrice: 6.5,
//         vatPercent: 20,
//         vatAmount: 1.2,
//         tags: 'cigarette,original',
//         categories: [{ id: 1, parentId: null, name: 'Cigarettes', description: 'All cigarette products', products: [], showChildren: false }], // Associated with "Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 3,
//           slotIndex: 3,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 3,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 4,
//         name: 'Tobacco4',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 4,
//           slotIndex: 4,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 4,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 5,
//         name: 'Tobacco5',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 5,
//           slotIndex: 5,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 5,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 6,
//         name: 'Tobacco6',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 6,
//           slotIndex: 6,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 6,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id:7,
//         name: 'Tobacco7',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 7,
//           slotIndex: 7,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 7,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 8,
//         name: 'Tobacco8',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 8,
//           slotIndex: 8,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 8,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 9,
//         name: 'Tobacco9',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 9,
//           slotIndex: 9,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 9,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 10,
//         name: 'Tobacco10',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 10,
//           slotIndex: 10,
//           containerCode: 1,
//           containerType: 'Tobacco',
//           productId: 10,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },{
//       product: {
//         id: 11,
//         name: 'Vending1',
//         description: 'Premium cigarette pack',
//         imagePaths: ['/assets/marlboro-red.png'],
//         price: 7.0,
//         grossPrice: 7.5,
//         vatPercent: 20,
//         vatAmount: 1.5,
//         tags: 'cigarette,red',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 11,
//           slotIndex: 1,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 11,
//           quantity: 20
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 12,
//         name: 'Vending2',
//         description: 'Smooth cigarette pack',
//         imagePaths: ['/assets/camel-blue.png'],
//         price: 6.5,
//         grossPrice: 7.0,
//         vatPercent: 20,
//         vatAmount: 1.3,
//         tags: 'cigarette,blue',
//         categories: [{ id: 1, parentId: null, name: 'Cigarettes', description: 'All cigarette products', products: [], showChildren: false }], // Associated with "Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 12,
//           slotIndex: 2,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 12,
//           quantity: 15
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 13,
//         name: 'Vending3',
//         description: 'Classic cigarette pack',
//         imagePaths: ['/assets/lucky-strike.png'],
//         price: 6.0,
//         grossPrice: 6.5,
//         vatPercent: 20,
//         vatAmount: 1.2,
//         tags: 'cigarette,original',
//         categories: [{ id: 1, parentId: null, name: 'Cigarettes', description: 'All cigarette products', products: [], showChildren: false }], // Associated with "Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 13,
//           slotIndex: 3,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 13,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 14,
//         name: 'Vending4',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 14,
//           slotIndex: 4,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 14,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 15,
//         name: 'Vending5',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 15,
//           slotIndex: 5,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 15,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 16,
//         name: 'Vending6',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 16,
//           slotIndex: 6,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 16,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id:17,
//         name: 'Vending7',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 17,
//           slotIndex: 7,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 17,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 18,
//         name: 'Vending8',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 18,
//           slotIndex: 8,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 18,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 19,
//         name: 'Vending9',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 19,
//           slotIndex: 9,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 19,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     },
//     {
//       product: {
//         id: 20,
//         name: 'Vending10',
//         description: 'Smooth and light cigarette pack',
//         imagePaths: ['/assets/parliament-lights.png'],
//         price: 7.2,
//         grossPrice: 7.7,
//         vatPercent: 20,
//         vatAmount: 1.4,
//         tags: 'cigarette,lights',
//         categories: [{ id: 2, parentId: 1, name: 'Premium Cigarettes', description: 'High-quality cigarette brands', products: [], showChildren: false }], // Associated with "Premium Cigarettes" category
//         productType: 1,
//         errorState: false
//       } as Product,
//       slots: [
//         {
//           id: 20,
//           slotIndex: 10,
//           containerCode: 1,
//           containerType: 'Vending',
//           productId: 20,
//           quantity: 10
//         } as Slot
//       ],
//       wwksArticle: null,
//       container: null
//     }
//   ];

//   return of(exampleData).pipe(
//     mergeMap((items) => items),
//     filter(
//       (item) =>
//         item.slots.length > 0 &&
//         item.slots.filter((slot) => slot.quantity > 0).length > 0
//     ),
//     map((item) => {
//       item.slots = item.slots.filter((s) => s.quantity > 0);
//       return item;
//     }),
//     toArray()
//   );
// }



  getAllProductsV2(): Observable<any[]> {
    const url = environment.configApi + `/machine/products/all`;

    return this.httpClient.get(url).pipe(
      map((res) => res as any[]),
      mergeMap((items) => items),
      toArray()
    );
  }

  getProductsWithCategories(): Observable<any> {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.getAllProductsV2();
    } else {
      return this.httpClient
        .post(environment.api + '/vendor/productsWithCategories', {})
        .pipe(
          map((res) => res as any[]),
          mergeMap((items) => items),
          tap(
            (item) =>
              (item.grossPrice =
                Math.round(item.price * (1 + item.vatPercent / 100) * 100) /
                100)
          ),
          tap((item) => (item.price = Math.round(item.price * 100) / 100)),
          tap((item) => (item.vatAmount = item.grossPrice - item.price)),
          toArray()
        );
    }
  }

  createSale(item: Item, isOfficeButler: boolean, cardNumber: string) {
    console.log(item);
    let activePayment =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activePayment;

    return this.httpClient.put(this.getCreateSaleUrl(), {
      note: '',
      salePayments: [
        {
          salePaymentType: {
            id: isOfficeButler ? 2 : 1,
          },
          price: item.product.grossPrice,
          cardNumber: cardNumber,
          paymentData: activePayment,
        },
      ],
      saleItems: [
        {
          item: { id: item.product.id },
          label: item.product.name,
          description: item.product.name,
          finalNetPrice: item.product.price,
          finalGrossPrice: item.product.grossPrice,
          vatPercent: item.product.vatPercent,
        },
      ],
    });
  }

  createCartSale(cart: any[], totalPrice: any, note: string) {
    console.log('cart sale');
    console.log(cart);
    let activePayment =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activePayment;

    return this.httpClient.put(this.getCreateSaleUrl(), {
      note: note,
      salePayments: [
        {
          salePaymentType: {
            id: 1,
          },
          price: totalPrice,
          paymentData: activePayment,
        },
      ],
      saleItems: cart.map(function (cartItem) {
        let price = 0;
        let vatPercent = 0;

        if (cartItem.product.price) {
          price = parseFloat(
            (Math.round(cartItem.product.price * 100) / 100).toFixed(2)
          );

          vatPercent = cartItem.product.vatPercent;
        }

        return {
          item: { id: cartItem.product.id },
          label: cartItem.product.name,
          description: cartItem.product.name,
          finalNetPrice: price,
          finalGrossPrice: cartItem.product.grossPrice,
          vatPercent: vatPercent,
        };
      }),
    });
  }

  createCartErrorSale(cart: any[], error: string) {
    let activePayment =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activePayment;

    return this.httpClient.put(this.getCreateSaleUrl(), {
      note: error,
      salePayments: [
        {
          salePaymentType: {
            id: 1,
          },
          price: 0.0,
          paymentData: activePayment,
        },
      ],
      saleItems: cart.map(function (cartItem) {
        return {
          item: { id: cartItem.product.id },
          label: cartItem.product.name,
          description: cartItem.product.name,
          finalNetPrice: 0.0,
          finalGrossPrice: 0.0,
          vatPercent: 0,
        };
      }),
    });
  }

  createErrorSale(
    item: Item,
    error: string,
    isOfficeButler: boolean,
    cardNumber: string
  ) {
    let activePayment =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activePayment;

    let cancelPayment =
      this.store.selectSnapshot<CoreStateModel>(CoreState).cancelPaymentResult;

    return this.httpClient.put(this.getCreateSaleUrl(), {
      note: error,
      salePayments: [
        {
          salePaymentType: {
            id: isOfficeButler ? 2 : 1,
          },
          price: 0.0,
          cardNumber: cardNumber,
          paymentData: {
            authData: activePayment,
            cancelAuthData: cancelPayment,
          },
        },
      ],
      saleItems: [
        {
          item: { id: item.product.id },
          label: item.product.name,
          description: item.product.name,
          finalNetPrice: 0.0,
          finalGrossPrice: 0.0,
          vatPercent: 0,
        },
      ],
    });
  }

  reduceQuantity(slot: Slot) {
    return this.httpClient.post(this.getReduceQuantityUrl(), [
      {
        id: slot.id,
        idSlot: slot.slotIndex,
        quantity: 1,
        initialQuantity: slot.quantity,
      },
    ]);
  }

  getPickup(code: string) {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.getPickupV2(code);
    } else {
      return this.getPickupV1(code);
    }
  }

  getPickupV1(code: string) {
    return this.httpClient
      .get(environment.api + '/vendor/getPickups/' + code)
      .pipe(
        map((item: any) => {
          let result = item.pickup;
          result.container = item.container;

          return result;
        }),
        tap((item: any) => {
          console.log(item);
          if (item) {
            return (item.product.grossPrice =
              Math.round(
                item.product.price * (1 + item.product.vatPercent / 100) * 100
              ) / 100);
          } else {
            return null;
          }
        })
      );
  }

  getPickupV2(code: string) {
    const url = environment.configApi + `/machine/pickups/` + code;

    return this.httpClient.get(url).pipe(
      tap((item: any) => {
        if (item) {
          item.product.price = Math.round(
            ((item.product.grossPrice / (1 + item.product.vatPercent / 100)) *
              100) /
              100
          );
        } else {
          return null;
        }
      })
    );
  }

  getPickupWithItems(code: string) {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.getPickupV2(code);
    } else {
      return this.httpClient
        .get(environment.api + '/vendor/getPickupsWithItems/' + code)
        .pipe(
          tap((item: any) => {
            if (item) {
              return (item.pickup.product.grossPrice =
                Math.round(
                  item.pickup.product.price *
                    (1 + item.pickup.product.vatPercent / 100) *
                    100
                ) / 100);
            } else {
              return null;
            }
          })
        );
    }
  }

  takePickup(code: string) {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.httpClient.post(
        environment.configApi + `/machine/pickups/` + code,
        {}
      );
    } else {
      return this.httpClient.post(
        environment.api + '/vendor/takePickup/' + code,
        {}
      );
    }
  }

  getCreateSaleUrl(): string {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return environment.configApi + '/machine/sales';
    } else {
      return environment.api + '/vendor/sale';
    }
  }

  private getReduceQuantityUrl(): string {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return environment.configApi + '/machine/reduce-quantity';
    } else {
      return environment.api + '/vendor/reduceQuantity';
    }
  }
}
