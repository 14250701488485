import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locker-selection',
  templateUrl: './locker-selection.component.html',
  styleUrls: ['./locker-selection.component.scss']
})
export class RentLockerSelectionComponent implements OnInit {
  public theme: string;
  public selected: number = null;
  public lockerOpened: boolean = false;

  countdownFull = 10;
  countdown = 10;

  constructor() {
    this.theme = localStorage.getItem('theme') ?? 'default';
  }

  ngOnInit(): void {
  }

  onSelectedLocker(numLocker: number) {
    this.selected = numLocker;

    setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      }

    }, 1000);

    setTimeout(() => {
      this.lockerOpened = true;
    }, 10000);


    setTimeout(() => {
      this.selected = null;
    }, 90000);
  }

}
