<ng-container *ngIf="theme === 'sanusx'"></ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'default'">
  <div
    class="flex flex-row items-start justify-between w-full h-screen bg-primaryx-bg"
  >
    <!-- first column -->
    <div
      class="
      bg-white
      h-screen
      shadow-md
      py-10

      pl-10 pr-0
      relative
      z-50
      flex flex-col items-start justify-start
      {{ !menuPanelOpened ? 'w-main-normal' : 'w-main-short' }}
      {{
        rightHand
          ? 'rounded-r-4xl shadow-md order-1'
          : 'rounded-l-4xl shadow-md-inv order-2'
      }}
    "
    >
      <!-- cat title -->
      <div class="flex flex-row items-start justify-start w-full h-auto pr-10">
        <h2
          class="mb-8 font-semibold tracking-wider uppercase text-primaryx-dark"
          [ngClass]="fullHdHalf ? 'text-5xl' : 'text-5xl'"
        >
          {{ "COMMON.CART" | translate }}
        </h2>
        <h2
          class="mb-8 ml-auto font-semibold tracking-wider text-active"
          [ngClass]="fullHdHalf ? 'text-5xl' : 'text-5xl'"
        >
          € {{ totalForPay.toFixed(2) }}
        </h2>
      </div>
      <!-- END: cat title -->

      <div
        class="flex flex-col items-start justify-start flex-grow w-full h-full pr-10 mt-8"
      >
        <!-- EMPTY STATE -->
        <!-- <div
          *ngIf="!(items$ | async) || (items$ | async).length === 0"
          class="absolute top-0 left-0 z-20 flex flex-col items-center justify-center w-full h-full"
        >
          <h4 class="px-12 title" [ngClass]="fullHdHalf && 'text-5xl'">
            Keine Produkte vorhanden
          </h4>
        </div> -->
        <!-- END EMPTY STATE -->
        <div class="flex flex-col flex-1 w-full h-full pb-6 space-y-4">
          <div
            class="flex flex-col flex-1 w-full h-auto space-y-4 cart-vertical-scroll max-h-[55vh]"
          >
            <!-- cart item panel -->

            <div
              *ngFor="let item of cartItemsForDisplay"
              class="cart_item cart_item--wscroll"
            >
              <div class="ci_image">
                <img
                  loading="lazy"
                  [src]="
                    (images[item.product.id] !== null
                      ? images[item.product.id]
                      : '/assets/placeholder.png'
                    ) | safe
                  "
                  alt="{{ item.product.name }}"
                  [ngClass]="fullHdHalf ? 'max-h-28' : 'max-h-28'"
                  style="max-width: 90%"
                />
              </div>
              <!--  title -->
              <div class="ci_title">
                <!-- <span class="text-xl text-left text-gray hiddenx">brand </span> -->
                <span
                  class="font-semibold text-left text-primaryx-dark"
                  [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
                >
                  {{ item.product.name }}
                </span>
              </div>
              <div class="ci_quantity_actions">
                <!-- btn decrease -->
                <span>
                  <button
                    class="btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50 disabled:invisible"
                    [disabled]="item.quantity === 1"
                    (pointerup)="decreaseProductQuantity(item)"
                    [touchClick]
                  >
                    <span class="icon bg-primaryx group-active:bg-active">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        class="text-primaryx"
                      >
                        <g
                          id="Group_7167"
                          data-name="Group 7167"
                          transform="translate(-1.778 -1.778)"
                        >
                          <rect
                            id="Rectangle_17007"
                            data-name="Rectangle 17007"
                            width="40"
                            height="40"
                            rx="20"
                            transform="translate(1.778 1.778)"
                            fill="currentColor"
                          />
                          <g
                            id="Group_6960"
                            data-name="Group 6960"
                            transform="translate(9.778 9.778)"
                          >
                            <path
                              id="Path_13154"
                              data-name="Path 13154"
                              d="M0,0H24V24H0Z"
                              fill="none"
                            />
                            <line
                              id="Line_4"
                              data-name="Line 4"
                              x2="17.695"
                              transform="translate(3.152 11.739)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="6"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </button>
                </span>
                <!-- end btn decrease -->
                <span
                  class="flex items-center justify-center h-full px-4 font-bold text-center text-primaryx"
                  [ngClass]="fullHdHalf ? 'text-6xl' : 'text-4xl'"
                >
                  {{ item.quantity }}
                </span>
                <!-- btn increase -->
                <span>
                  <button
                    class="btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50 disabled:invisible"
                    [disabled]="cartIsFull || hasNoMoreItemsLeftInStock(item)"
                    (pointerup)="increaseProductQuantity(item)"
                    [touchClick]
                  >
                    <span
                      class="rotate-90 icon bg-primaryx group-active:bg-active"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        class="text-primaryx"
                      >
                        <g
                          id="Group_7167"
                          data-name="Group 7167"
                          transform="translate(-1.445 -1.778)"
                        >
                          <rect
                            id="Rectangle_17007"
                            data-name="Rectangle 17007"
                            width="40"
                            height="40"
                            rx="20"
                            transform="translate(1.445 1.778)"
                            fill="currentColor"
                          />
                          <g
                            id="Group_6960"
                            data-name="Group 6960"
                            transform="translate(9.444 9.778)"
                          >
                            <path
                              id="Path_13154"
                              data-name="Path 13154"
                              d="M0,0H24V24H0Z"
                              fill="none"
                            />
                            <line
                              id="Line_3"
                              data-name="Line 3"
                              y2="17.695"
                              transform="translate(12.305 3.197)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="6"
                            />
                            <line
                              id="Line_4"
                              data-name="Line 4"
                              x2="17.695"
                              transform="translate(3.152 11.739)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="6"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </button>
                </span>
                <!-- end btn increase -->
              </div>
              <div
                class="relative ci_price text-primaryx"
                [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
              >
                € {{ item.totalPrice.toFixed(2) }}

                <span class="absolute top-0 right-0 mt-[1.35rem] mr-[-2.25rem]">
                  <button
                    class="btn btn-mini-action btn-mini-action--primary border-red active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                    (pointerup)="removeCartItem(item)"
                    [touchClick]
                  >
                    <span class="icon bg-red group-active:bg-active">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="24"
                        viewBox="0 0 21 24"
                      >
                        <path
                          id="Path_13536"
                          data-name="Path 13536"
                          d="M12,2a1.5,1.5,0,0,0-1.341.829L9.573,5H4.5a1.5,1.5,0,1,0,0,3V23a3,3,0,0,0,3,3h12a3,3,0,0,0,3-3V8a1.5,1.5,0,1,0,0-3H17.427L16.341,2.829A1.5,1.5,0,0,0,15,2ZM9,11a1.5,1.5,0,0,1,3,0v9a1.5,1.5,0,0,1-3,0Zm7.5-1.5A1.5,1.5,0,0,0,15,11v9a1.5,1.5,0,0,0,3,0V11A1.5,1.5,0,0,0,16.5,9.5Z"
                          transform="translate(-3 -2)"
                          fill="currentColor"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>
                </span>
              </div>
            </div>

            <!-- end cart item panel -->
          </div>
          <!-- Gesamtsumme -->
          <div
            class="flex flex-col items-center justify-start w-full h-64 px-6 py-4 mr-10 bg-white max-h-64 border-5 border-gray-light rounded-card"
          >
            <div
              class="w-full h-auto mb-4 text-4xl font-semibold tracking-wider text-primaryx"
            >
              {{ "COMMON.TOTAL_PRICE" | translate }}
            </div>
            <div
              class="grid w-full grid-flow-row grid-cols-5 text-base gap-x-2 text-primaryx"
            >
              <div class="flex flex-col space-y-3">
                <span>{{ "COMMON.VAT_PERCENTAGE" | translate }}</span>
                <ng-container
                  *ngFor="let item of cartItemsForDisplayGroupedByTaxPercent"
                >
                  <span>{{ item.vatPercent }} %</span>
                </ng-container>
              </div>
              <div class="flex flex-col space-y-3">
                <span>{{ "COMMON.GROSS_PRICE" | translate }}</span>
                <ng-container
                  *ngFor="let item of cartItemsForDisplayGroupedByTaxPercent"
                >
                  <span>{{ item.grossPrice.toFixed(2) }}</span>
                </ng-container>
              </div>
              <div class="flex flex-col space-y-3">
                <span>{{ "COMMON.NET_PRICE" | translate }}</span>
                <ng-container
                  *ngFor="let item of cartItemsForDisplayGroupedByTaxPercent"
                >
                  <span>{{ item.netPrice.toFixed(2) }}</span>
                </ng-container>
              </div>
              <div class="flex flex-col space-y-3">
                <span>{{ "COMMON.VAT_AMOUNT" | translate }}</span>
                <ng-container
                  *ngFor="let item of cartItemsForDisplayGroupedByTaxPercent"
                >
                  <span>{{ item.vatAmount.toFixed(2) }}</span>
                </ng-container>
              </div>

              <div
                class="flex items-center justify-center text-3xl font-semibold bg-white border-4 w-28 h-28 rounded-card border-primaryx text-primaryx"
              >
                € {{ totalForPay.toFixed(2) }}
              </div>
            </div>
          </div>
          <!-- end Gesamtsumme -->
        </div>
        <div
          class="flex flex-row items-center self-end justify-between w-full h-auto pr-10 min-h-18"
        >
          <button
            class="btn btn-action border-red group active:border-active
            {{ rightHand ? 'order-1  mr-auto' : 'order-3  ml-auto' }}"
            [ngClass]="fullHdHalf && 'btn-action--middle'"
            (pointerup)="emptyCart()"
            [touchClick]
          >
            <span class="icon bg-red group-active:bg-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="24"
                viewBox="0 0 21 24"
              >
                <path
                  id="Path_13536"
                  data-name="Path 13536"
                  d="M12,2a1.5,1.5,0,0,0-1.341.829L9.573,5H4.5a1.5,1.5,0,1,0,0,3V23a3,3,0,0,0,3,3h12a3,3,0,0,0,3-3V8a1.5,1.5,0,1,0,0-3H17.427L16.341,2.829A1.5,1.5,0,0,0,15,2ZM9,11a1.5,1.5,0,0,1,3,0v9a1.5,1.5,0,0,1-3,0Zm7.5-1.5A1.5,1.5,0,0,0,15,11v9a1.5,1.5,0,0,0,3,0V11A1.5,1.5,0,0,0,16.5,9.5Z"
                  transform="translate(-3 -2)"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg>
            </span>
            <span class="label text-red group-active:text-active">{{
              "COMMON.CART_EMPTY" | translate
            }}</span>
          </button>
          <button
            class="btn btn-action border-gray-light group active:border-active mx-auto
            {{ rightHand ? 'order-2' : 'order-2' }}"
            [ngClass]="fullHdHalf && 'btn-action--middle'"
            (pointerup)="cancelRetailer()"
            [touchClick]
          >
            <span class="icon bg-gray group-active:bg-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49.498"
                height="49.498"
                viewBox="0 0 49.498 49.498"
              >
                <path
                  id="close_icon"
                  data-name="close_icon"
                  d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                  transform="translate(950.191 -4593.964) rotate(-45)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <span class="label text-gray group-active:text-active">{{
              "COMMON.CANCEL" | translate
            }}</span>
          </button>
          <button
            class="btn btn-action btn-action--success border-primaryx-success active:border-active group
            {{ rightHand ? 'order-3 ml-auto' : 'order-1 mr-auto' }}"
            [ngClass]="fullHdHalf && 'btn-action--middle'"
            (pointerup)="submitCart()"
            [touchClick]
          >
            <span class="icon bg-primaryx-success group-active:bg-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.086"
                height="21.035"
                viewBox="0 0 28.086 21.035"
              >
                <path
                  id="icon_check"
                  data-name="icon_check"
                  d="M27.017-15.018a3.5,3.5,0,0,0,1.069-2.517,3.517,3.517,0,0,0-3.5-3.5,3.5,3.5,0,0,0-2.517,1.069L10.543-8.441,6.017-12.967A3.5,3.5,0,0,0,3.5-14.035a3.517,3.517,0,0,0-3.5,3.5A3.5,3.5,0,0,0,1.069-8.018l7,7a3.517,3.517,0,0,0,4.949,0Z"
                  transform="translate(0 21.035)"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg>
            </span>
            <span
              class="label text-primaryx-success group-active:text-active"
              >{{ "COMMON.BTN_SUBMIT" | translate }}</span
            >
          </button>
        </div>
      </div>
    </div>
    <!-- END: first column -->

    <!-- side column -->
    <app-sidebar
      [backBtnCloseInfoPanel]="infoPanelOpened"
      (infoPanelStatus)="infoPanelHandler($event)"
      [productPage]="true"
      [hideCategories]="true"
      [fullHdHalf]="fullHdHalf"
      [menuPanelOpened]="menuPanelOpened"
      (menuPanelStatus)="menuPanelHandler($event)"
      [searchPanelOpened]="searchPanelOpened"
      (searchPanelStatus)="searchPanelHandler($event)"
      [rightHand]="rightHand"
      (rightHandStatus)="rightHandHandler($event)"
      class="{{ rightHand ? 'order-2 pr-3' : 'order-1 pl-3 ' }}"
      *ngIf="!menuPanelOpened"
    ></app-sidebar>
    <!-- END: side column -->
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen bg-white"
  >
    <!-- central adv banner -->
    <app-nex-central-ad
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    ></app-nex-central-ad>
    <!-- end central adv banner -->

    <!-- nex cetral wrapper -->
    <div class="w-full h-full flex-1 flex flex-row">
      <!-- nex product area -->
      <div
        class="flex-1 h-full bg-nex-white px-6 py-6 space-y-6 flex flex-col items-start justify-between"
      >
        <!-- header area -->
        <div class="w-full h-auto flex flex-row items-center justify-between">
          <!-- logo -->
          <div class="w-1/3">
            <img
              class="h-16 max-h-16 object-contain"
              *ngIf="logo"
              [src]="logo"
            />
          </div>
          <!-- end logo -->

          <!-- active category -->
          <div
            class="w-auto flex flex-1 justify-end items-center text-right text-2xl font-medium text-nex-gray"
          ></div>
          <!-- end active category -->
        </div>
        <!-- end header area -->

        <!-- breadcrumbs area -->
        <div class="w-full h-13 flex flex-row items-center justify-between">
          <div class="w-auto flex-1 flex flex-row items-center justify-start">
            <button
              (click)="backPage()"
              class="w-13 h-13 bg-nex-gray-light flex-row items-center justify-start flex text-nex-gray-dark active:bg-nex-gray-light/70 rounded-xl border-2 border-transparent active:border-nex-gray-light active:shadow-inner-light"
            >
              <div class="w-full h-13 justify-center items-center flex">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6667 26.6667C26.6667 27.0203 26.5262 27.3594 26.2762 27.6095C26.0261 27.8595 25.687 28 25.3333 28H6.66668C6.31305 28 5.97392 27.8595 5.72387 27.6095C5.47382 27.3594 5.33334 27.0203 5.33334 26.6667V14.6667H1.33334L15.1027 2.14934C15.3482 1.92597 15.6681 1.80219 16 1.80219C16.3319 1.80219 16.6519 1.92597 16.8973 2.14934L30.6667 14.6667H26.6667V26.6667ZM10 17.3333C10 18.9246 10.6322 20.4508 11.7574 21.576C12.8826 22.7012 14.4087 23.3333 16 23.3333C17.5913 23.3333 19.1174 22.7012 20.2426 21.576C21.3679 20.4508 22 18.9246 22 17.3333H19.3333C19.3333 18.2174 18.9822 19.0652 18.357 19.6904C17.7319 20.3155 16.8841 20.6667 16 20.6667C15.116 20.6667 14.2681 20.3155 13.643 19.6904C13.0179 19.0652 12.6667 18.2174 12.6667 17.3333H10Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
            <!-- ovdje staviti kada se klikne na ovu tipku a korpa puna, da prvo mora isprazniti korpu da nastavi kupovinu, napraviti da se otvori box -->

            <span class="text-nex-gray-dark text-2xl font-medium px-4">
              {{ "COMMON.CART" | translate }}
            </span>
          </div>

          <!-- sorting tools -->
          <div
            class="w-auto pl-3 pr-8 flex flex-row font-semibold text-5xl text-primaryx tracking-wider items-center justify-end space-x-3"
          >
            € {{ totalForPay.toFixed(2) }}
          </div>
          <!-- end sorting tools -->
        </div>
        <!-- end breadcrumbs area -->

        <!-- cart area -->
        <div class="flex flex-1 flex-col w-full h-full space-y-6">
          <!-- cart items list -->
          <div class="w-full h-auto flex flex-1 flex-col space-y-6">
            <!-- cart item panel -->
            <div
              *ngFor="let item of cartItemsForDisplay"
              class="cart_item cart_item--wscroll"
            >
              <div class="ci_image">
                <img
                  loading="lazy"
                  [src]="
                    (images[item.product.id] !== null
                      ? images[item.product.id]
                      : '/assets/placeholder.png'
                    ) | safe
                  "
                  alt="{{ item.product.name }}"
                  class="max-h-24"
                />
              </div>
              <!--  title -->
              <div class="ci_title">
                <span class="text-lg text-left text-nex-gray-dark">
                  {{ item.product.brandName }}
                </span>
                <span class="font-semibold text-left text-nex-black text-2xl">
                  {{ item.product.name }}
                </span>
              </div>
              <div class="ci_quantity_actions">
                <!-- btn decrease -->
                <span>
                  <button
                    (click)="decreaseProductQuantity(item)"
                    [disabled]="item.quantity === 1"
                    class="w-13 h-13 bg-white flex-row items-center justify-start flex text-primaryx active:bg-nex-gray-dark/70 rounded-xl border-2 border-primaryx active:border-nex-gray-light active:shadow-inner-light disabled:cursor-not-allowed disabled:opacity-50 disabled:invisible"
                  >
                    <div
                      class="w-full h-13 p-2 justify-center items-center flex"
                    >
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5714 12.5713H19.4286H30.6667V19.4284H19.4286H12.5714H1.33333V12.5713H12.5714Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </button>
                </span>
                <!-- end btn decrease -->
                <span
                  class="flex items-center justify-center h-full px-4 font-bold text-center text-primaryx"
                  [ngClass]="fullHdHalf ? 'text-6xl' : 'text-4xl'"
                >
                  {{ item.quantity }}
                </span>
                <!-- btn increase -->
                <span>
                  <button
                    (click)="increaseProductQuantity(item)"
                    [disabled]="cartIsFull || hasNoMoreItemsLeftInStock(item)"
                    class="w-13 h-13 bg-white flex-row items-center justify-start flex text-primaryx active:bg-nex-gray-dark/70 rounded-xl border-2 border-primaryx active:border-nex-gray-light active:shadow-inner-light disabled:cursor-not-allowed disabled:opacity-50 disabled:invisible"
                  >
                    <div
                      class="w-full h-13 p-2 justify-center items-center flex"
                    >
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5714 11.5716V0.333496H18.4286V11.5716H29.6667V18.4287H18.4286V29.6668H11.5714V18.4287H0.333344V11.5716H11.5714Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </button>
                </span>
                <!-- end btn increase -->
              </div>
              <div
                class="relative ci_price text-primaryx"
                [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
              >
                € {{ item.totalPrice.toFixed(2) }}

                <span
                  class="absolute top-0 right-0 w-13 h-full flex items-center justify-center -mr-[1.9rem]"
                >
                  <button
                    (click)="removeCartItem(item)"
                    class="w-13 h-13 bg-white flex-row items-center justify-start flex text-nex-red active:bg-nex-red/70 rounded-xl border-2 border-nex-red active:border-nex-gray-light active:shadow-inner-light"
                  >
                    <div
                      class="w-full h-13 p-2 justify-center items-center flex"
                    >
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.33335 7.99984V3.99984C9.33335 3.64622 9.47383 3.30708 9.72388 3.05703C9.97393 2.80698 10.3131 2.6665 10.6667 2.6665H21.3334C21.687 2.6665 22.0261 2.80698 22.2762 3.05703C22.5262 3.30708 22.6667 3.64622 22.6667 3.99984V7.99984H29.3334V10.6665H26.6667V27.9998C26.6667 28.3535 26.5262 28.6926 26.2762 28.9426C26.0261 29.1927 25.687 29.3332 25.3334 29.3332H6.66669C6.31307 29.3332 5.97393 29.1927 5.72388 28.9426C5.47383 28.6926 5.33335 28.3535 5.33335 27.9998V10.6665H2.66669V7.99984H9.33335ZM17.8854 18.6665L20.2427 16.3092L18.3574 14.4238L16 16.7812L13.6427 14.4238L11.7574 16.3092L14.1147 18.6665L11.7574 21.0238L13.6427 22.9092L16 20.5518L18.3574 22.9092L20.2427 21.0238L17.8854 18.6665ZM12 5.33317V7.99984H20V5.33317H12Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </button>
                </span>
              </div>
            </div>
            <!-- end cart item panel -->
          </div>
          <!-- end cart items list -->

          <!-- Gesamtsumme -->
          <div
            class="flex flex-col items-center justify-start w-full min-h-64 h-auto px-8 py-8 mr-10 bg-nex-gray-light-ultra max-h-64 border-5 border-nex-gray-light rounded-2xl"
          >
            <div class="w-full h-auto flex items-center justify-start mb-8">
              <span class="flex items-center h-8 justify-center text-primaryx">
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.88288 1.88289C3.08848 0.677294 4.72361 0 6.42857 0H30.8571C31.0216 0 31.1789 0.0308951 31.3235 0.0871996C29.5869 0.462862 28.2857 2.00805 28.2857 3.85714V5.13972C28.2857 5.14077 28.2857 5.14181 28.2857 5.14286L28.2857 15.4286L28.2857 34.7143C28.2857 35.1775 28.0366 35.6049 27.6335 35.8331C27.2304 36.0613 26.7357 36.0551 26.3385 35.8168L20.5714 32.3565L14.8044 35.8168C14.3972 36.0611 13.8885 36.0611 13.4814 35.8168L7.71429 32.3565L1.94721 35.8168C1.55001 36.0551 1.05533 36.0613 0.652244 35.8331C0.249162 35.6049 0 35.1775 0 34.7143V6.42857C0 4.72361 0.677294 3.08848 1.88288 1.88289Z"
                    fill="currentColor"
                  />
                  <path
                    d="M28.2857 3.85714V15.4286H35C35.5523 15.4286 36 14.9809 36 14.4286V3.85714C36 1.7269 34.2731 0 32.1428 0C30.0126 0 28.2857 1.7269 28.2857 3.85714Z"
                    fill="#B2BEC3"
                  />
                </svg>
              </span>

              <span
                class="pl-6 flex flex-1 items-center justify-start text-4xl font-semibold tracking-wider text-primaryx"
              >
                {{ "COMMON.TOTAL_PRICE" | translate }}
              </span>
            </div>
            <div
              class="grid w-full grid-flow-row grid-cols-5 text-xl gap-x-2 text-primaryx"
            >
              <div class="flex flex-col space-y-3">
                <span class="font-bold">{{
                  "COMMON.VAT_PERCENTAGE" | translate
                }}</span>
                <ng-container
                  *ngFor="let item of cartItemsForDisplayGroupedByTaxPercent"
                >
                  <span>{{ item.vatPercent }} %</span>
                </ng-container>
              </div>
              <div class="flex flex-col space-y-3">
                <span class="font-bold">{{
                  "COMMON.GROSS_PRICE" | translate
                }}</span>
                <ng-container
                  *ngFor="let item of cartItemsForDisplayGroupedByTaxPercent"
                >
                  <span>{{ item.grossPrice.toFixed(2) }}</span>
                </ng-container>
              </div>
              <div class="flex flex-col space-y-3">
                <span class="font-bold">{{
                  "COMMON.NET_PRICE" | translate
                }}</span>
                <ng-container
                  *ngFor="let item of cartItemsForDisplayGroupedByTaxPercent"
                >
                  <span>{{ item.netPrice.toFixed(2) }}</span>
                </ng-container>
              </div>
              <div class="flex flex-col space-y-3">
                <span class="font-bold">{{
                  "COMMON.VAT_AMOUNT" | translate
                }}</span>
                <ng-container
                  *ngFor="let item of cartItemsForDisplayGroupedByTaxPercent"
                >
                  <span>{{ item.vatAmount.toFixed(2) }}</span>
                </ng-container>
              </div>

              <div class="w-auto h-auto flex items-center justify-end">
                <div
                  class="flex items-center justify-center text-3xl font-extrabold bg-white border-4 w-32 h-28 rounded-card border-nex-gray-light text-primaryx"
                >
                  € {{ totalForPay.toFixed(2) }}
                </div>
              </div>
            </div>
          </div>
          <!-- end Gesamtsumme -->
        </div>
        <!-- end product area -->
      </div>
      <!-- end nex cart area -->
    </div>
    <!-- end nex cetral wrapper -->

    <!-- nex action footer area -->
    <div class="w-full h-24.2 flex flex-row items-center justify-between">
      <div
        class="px-6 w-full h-full flex flex-row items-center justify-between rounded-t-2xl space-x-3 bg-nex-gray-light"
      >
        <button
          (click)="backPage()"
          class="max-w-64 w-auto h-20 bg-transparent transition-all flex-row items-center justify-start flex text-nex-gray-dark active:bg-white/70 rounded-xl border-2 border-transparent active:border-nex-gray-light active:shadow-inner-light"
        >
          <div class="p-4 w-16 h-16 justify-start items-start flex">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.3333 18.6667V6.66675H22.6667V16.0001H12.552V8.78141L4 17.3334L12.552 25.8854V18.6667H25.3333Z"
                fill="currentColor"
              />
            </svg>
          </div>

          <div
            class="pr-6 w-auto flex-1 h-full justify-start uppercase flex flex-row items-center text-2xl font-medium"
          >
            {{ "COMMON.BACK" | translate }}
          </div>
        </button>

        <div class="w-auto h-auto flex-1 grid grid-cols-2 grid-rows-1 gap-x-3">
          <!-- <button
            (click)="emptyCart()"
            class="max-w-64 w-auto h-20 bg-transparent flex-row items-center justify-start flex text-nex-red active:bg-white/70 rounded-xl border-4 border-nex-red active:border-nex-gray-light active:shadow-inner-light"
          >
            <div class="p-4 w-16 h-16 justify-start items-start flex">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33334 8.00033V4.00033C9.33334 3.6467 9.47381 3.30756 9.72386 3.05752C9.97391 2.80747 10.313 2.66699 10.6667 2.66699H21.3333C21.687 2.66699 22.0261 2.80747 22.2761 3.05752C22.5262 3.30756 22.6667 3.6467 22.6667 4.00033V8.00033H29.3333V10.667H26.6667V28.0003C26.6667 28.3539 26.5262 28.6931 26.2761 28.9431C26.0261 29.1932 25.687 29.3337 25.3333 29.3337H6.66667C6.31305 29.3337 5.97391 29.1932 5.72386 28.9431C5.47381 28.6931 5.33334 28.3539 5.33334 28.0003V10.667H2.66667V8.00033H9.33334ZM17.8853 18.667L20.2427 16.3097L18.3573 14.4243L16 16.7817L13.6427 14.4243L11.7573 16.3097L14.1147 18.667L11.7573 21.0243L13.6427 22.9097L16 20.5523L18.3573 22.9097L20.2427 21.0243L17.8853 18.667ZM12 5.33366V8.00033H20V5.33366H12Z"
                  fill="currentColor"
                />
              </svg>
            </div>

            <div
              class="pr-6 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
            >
              {{ "COMMON.CART_EMPTY" | translate }}
            </div>
          </button> -->

          <button
            (click)="emptyCart()"
            class="Xmax-w-64 w-auto h-20 bg-transparent flex-row items-center justify-start flex text-nex-gray-dark active:bg-white/70 rounded-xl border-4 border-nex-gray-dark active:border-nex-gray-light active:shadow-inner-light"
          >
            <div class="p-4 w-16 h-16 justify-start items-start flex">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 29.3337C8.63601 29.3337 2.66667 23.3643 2.66667 16.0003C2.66667 8.63633 8.63601 2.66699 16 2.66699C23.364 2.66699 29.3333 8.63633 29.3333 16.0003C29.3333 23.3643 23.364 29.3337 16 29.3337ZM16 14.115L12.2293 10.343L10.3427 12.2297L14.1147 16.0003L10.3427 19.771L12.2293 21.6577L16 17.8857L19.7707 21.6577L21.6573 19.771L17.8853 16.0003L21.6573 12.2297L19.7707 10.343L16 14.115Z"
                  fill="currentColor"
                />
              </svg>
            </div>

            <div
              class="pr-6 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
            >
              {{ "COMMON.CANCEL_PURCHASE" | translate }}
            </div>
          </button>

          <button
            (click)="submitCart()"
            class="w-auto flex-1 h-20 bg-nex-green flex-row items-center justify-start flex text-white active:bg-nex-green/70 rounded-xl border-4 border-nex-green active:border-nex-gray-light active:shadow-inner-light"
          >
            <div class="p-4 w-16 h-16 justify-start items-start flex">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 29.3337C8.63599 29.3337 2.66666 23.3643 2.66666 16.0003C2.66666 8.63633 8.63599 2.66699 16 2.66699C23.364 2.66699 29.3333 8.63633 29.3333 16.0003C29.3333 23.3643 23.364 29.3337 16 29.3337ZM14.6707 21.3337L24.0973 11.9057L22.2133 10.0203L14.6707 17.563L10.8987 13.791L9.01332 15.6763L14.6707 21.3337Z"
                  fill="currentColor"
                />
              </svg>
            </div>

            <div
              class="pr-4 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
            >
              {{ "COMMON.PROCEED_TO_PAY" | translate }}
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- end nex action footer area -->
  </div>

  <!-- modal -->
  <div
    class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-nex-gray-light/80 backdrop-blur-sm z-90"
    *ngIf="cartIsFullModal"
  >
    <div
      class="flex flex-col justify-between w-9/12 px-8 pb-8 mx-auto rounded-2xl bg-white h-auto min-h-97 wow bounceIn animated"
      data-wow-delay="0s"
      data-wow-duration=".3s"
    >
      <div class="w-full h-auto mb-10">
        <div
          class="flex items-center justify-center w-24 h-24 mx-auto -mt-12 bg-white border-white rounded-full text-primaryx border-10"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11V17H13V11H11ZM11 7V9H13V7H11Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <div
        class="self-center flex flex-col items-center space-y-4 flex-1 w-full h-auto mx-auto text-6xl font-semibold text-center text-nex-black"
      >
        <span class="text-primaryx">
          {{ "CART.CART_IS_FULL" | translate }}
        </span>

        <span class="text-4xl font-medium">
          {{ "CART.CART_IS_FULL_MSG" | translate }}
        </span>
      </div>

      <div
        class="flex flex-row items-center self-end justify-center w-10/12 mx-auto mt-10"
      >
        <button
          (click)="closeCartIsFullModal()"
          class="max-w-64 w-auto h-20 bg-transparent flex-row items-center justify-start flex text-nex-gray-dark active:bg-white/70 rounded-xl border-4 border-nex-gray-dark active:border-nex-gray-light active:shadow-inner-light"
        >
          <div class="p-4 w-16 h-16 justify-start items-start flex">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 29.3337C8.63601 29.3337 2.66667 23.3643 2.66667 16.0003C2.66667 8.63633 8.63601 2.66699 16 2.66699C23.364 2.66699 29.3333 8.63633 29.3333 16.0003C29.3333 23.3643 23.364 29.3337 16 29.3337ZM16 14.115L12.2293 10.343L10.3427 12.2297L14.1147 16.0003L10.3427 19.771L12.2293 21.6577L16 17.8857L19.7707 21.6577L21.6573 19.771L17.8853 16.0003L21.6573 12.2297L19.7707 10.343L16 14.115Z"
                fill="currentColor"
              />
            </svg>
          </div>

          <div
            class="pr-6 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
          >
            {{ "COMMON.CLOSE" | translate }}
          </div>
        </button>
      </div>
    </div>
  </div>
  <!-- END modal -->
</ng-container>
