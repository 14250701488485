import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btn-sanusx',
  templateUrl: './btn-sanusx.component.html',
  styleUrls: ['./btn-sanusx.component.scss']
})
export class BtnSanusxComponent implements OnInit {
  @Input() label: string;
  @Input() buttonType: string;
  @Input() fullwidth: boolean = false;
  @Input() squared: boolean = false;
  @Input() disabled: boolean = false;
  @Input() redBorder: boolean = false;
  @Output() btnClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickButton(event) {
    this.btnClick.emit(event);
  }
}
