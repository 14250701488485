import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { Routes, RouterModule } from '@angular/router';



import { SetupComponent } from './setup.component';
import { PasswordDialog } from './password-dialog/password-dialog.component';
import { SetupDialog } from './setup-dialog/setup-dialog.component';
import { SetupMenuComponent } from './setup-menu/setup-menu.component';
import { SetupKeyboardComponent } from './setup-keyboard-input/setup-keyboard-input.component';
import { SetupLockerSelectionComponent } from './setup-locker-selection/setup-locker-selection.component';

export const routes: Routes = [
  {
    path: 'core/setup',
    component: SetupComponent,
    children: [
      {
        path: 'setup-menu',
        component: SetupMenuComponent
      },
      {
        path: 'locker-selection',
        component: SetupLockerSelectionComponent
      },
      {
        path: 'setup-keyboard-input/:action',
        component: SetupKeyboardComponent
      }
    ]
  }

];

@NgModule({
  declarations: [SetupComponent, PasswordDialog, SetupDialog, SetupMenuComponent, SetupKeyboardComponent, SetupLockerSelectionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class SetupModule { }
