import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finish-order',
  templateUrl: './finish-order.component.html',
  styleUrls: ['./finish-order.component.scss']
})
export class FinishOrderComponent implements OnInit {
  theme: string;

  constructor() {
    this.theme = localStorage.getItem('theme') ?? 'default';

  }

  ngOnInit(): void {
  }

}
