<ng-container *ngIf="theme === 'pickup_landscape'">
  <div
    class="w-full h-screen flex flex-col items-start justify-between bg-primaryx text-white"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="w-full flex flex-col items-center justify-center text-center px-27 {{
        back ? 'flex-1' : ''
      }}"
    >
      <div
        class="flex w-[120] h-[120] rounded-full mx-auto flex-col items-center justify-center wow pulse animated mt-4 mb-12 text-secondary bg-white"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
      >
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          version="1.1"
          style="
            fill: currentColor;
            fill-rule: evenodd;
            clip-rule: evenodd;
            stroke-linejoin: round;
            stroke-miterlimit: 2;
          "
        >
          <g transform="matrix(1,0,0,1,-713.634,-1210.05)">
            <g transform="matrix(1.2,0,0,1.2,-142.727,-242.01)">
              <path
                d="M763.634,1310.05C791.063,1310.05 813.634,1287.48 813.634,1260.05C813.633,1232.62 791.062,1210.05 763.634,1210.05C736.205,1210.05 713.634,1232.62 713.634,1260.05C713.634,1287.48 736.204,1310.05 763.634,1310.05ZM757.134,1293.96L757.134,1244.83L770.134,1244.83L770.134,1293.96L757.134,1293.96ZM757.134,1238.17L757.134,1226.14L770.134,1226.14L770.134,1238.17L757.134,1238.17Z"
              />
            </g>
          </g>
        </svg>
      </div>
      <h2 class="w-full mx-auto text-4xl font-semibold mb-8" *ngIf="!back">
        {{ "LOCKER_RENT_WARNING.SENT_1" | translate }}
      </h2>
      <h2 class="w-full mx-auto text-4xl font-semibold mb-8" *ngIf="back">
        {{ "LOCKER_RENT_WARNING.SENT_2" | translate }}
      </h2>
      <h2 class="mt-2 w-full mx-auto text-4xl font-semibold" *ngIf="!back">
        {{ "LOCKER_RENT_WARNING.SENT_3." + tenant | translate }}
      </h2>

      <h2 class="mt-2 w-full mx-auto text-4xl font-semibold" *ngIf="back">
        {{ "LOCKER_RENT_WARNING.SENT_4" | translate }}
      </h2>
    </div>
    <div class="w-full self-center flex flex-col justify-start items-start">
      <div
        class="w-full h-auto flex flex-row space-x-16 items-center justify-center py-14 self-end"
        *ngIf="!back"
      >
        <app-btn-sanusx
          (btnClick)="cancel()"
          [label]="'COMMON.BTN_CANCEL' | translate"
          [squared]="true"
          buttonType="neutral"
          class="wow slideInLeft animated"
        >
        </app-btn-sanusx>
        <app-btn-sanusx
          (btnClick)="agree()"
          [label]="'COMMON.BTN_SUBMIT' | translate"
          [squared]="true"
          [redBorder]="true"
          buttonType="secondary"
          class="wow slideInRight animated"
        >
        </app-btn-sanusx>
      </div>
      <div
        class="w-full h-auto flex flex-row space-x-16 items-center justify-center py-14 self-end"
        *ngIf="back"
      >
        <app-btn-sanusx
          (btnClick)="agree()"
          [label]="'COMMON.BTN_OPEN_LOCKER' | translate"
          [squared]="true"
          [redBorder]="true"
          buttonType="secondary"
          class="wow slideInUp animated"
        >
        </app-btn-sanusx>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="theme === 'pickup_portrait'">
  <div
    class="w-full h-screen flex flex-col items-start justify-between bg-primaryx text-white"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="w-full flex flex-col items-center justify-center text-center px-27 {{
        back ? 'flex-1' : ''
      }}"
    >
      <h2 class="w-full mx-auto text-6xl font-bold mb-8" *ngIf="!back">
        {{ "LOCKER_RENT_WARNING.SENT_1" | translate }}
      </h2>
      <h2 class="w-full mx-auto text-6xl font-bold mb-8" *ngIf="back">
        {{ "LOCKER_RENT_WARNING.SENT_2" | translate }}
      </h2>

      <div
        class="flex w-80 h-80 rounded-full mx-auto flex-col items-center justify-center wow pulse animated my-6 text-red relative"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-full h-full relative z-50"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
        <div
          class="absolute rounded-full bg-white block z-20 top-16 left-16"
          style="width: 15rem; height: 15rem"
        ></div>
      </div>

      <h2 class="mt-2 w-full mx-auto text-6xl font-bold" *ngIf="!back">
        {{ "LOCKER_RENT_WARNING.SENT_3." + tenant | translate }}
      </h2>

      <h2 class="mt-2 w-full mx-auto text-6xl font-bold" *ngIf="back">
        {{ "LOCKER_RENT_WARNING.SENT_4" | translate }}
      </h2>
    </div>
    <div
      class="w-full self-center flex flex-col justify-start items-start wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1.5s"
      *ngIf="!back"
    >
      <div
        class="w-full h-auto flex flex-col space-y-16 items-center justify-center pt-6 pb-14 self-end"
      >
        <app-btn-sanusx
          (btnClick)="agree()"
          [label]="'COMMON.BTN_SUBMIT' | translate"
          [squared]="true"
          [redBorder]="true"
          buttonType="secondary"
        >
        </app-btn-sanusx>
        <app-btn-sanusx
          (btnClick)="cancel()"
          [label]="'COMMON.BTN_CANCEL' | translate"
          [squared]="true"
          buttonType="neutral"
        >
        </app-btn-sanusx>
      </div>
    </div>
  </div>
</ng-container>

<div
  class="w-screen h-screen bg-white-80 backdrop-blur-sm fixed z-90 top-0 left-0 flex flex-col items-center justify-center wow fadeIn animated"
  data-wow-delay="0s"
  data-wow-duration="0.2s"
  *ngIf="loaderActive"
>
  <div
    id="loader-sanusx"
    class="flex flex-grow flex-col items-center justify-center text-primaryx-50 relative z-20"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
      <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

      <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
    </svg>
    <h3 class="uppercase tracking-wider my-10 text-gray font-semibold">
      {{ "COMMON.PLEASE_WAIT" | translate }}
    </h3>
  </div>
</div>
