import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { LockerState, LockerStateModel } from '../../../state/locker.state';
import { Locker } from '../../../state/locker.actions';
import { MachineError } from 'src/app/core/models/error';
import { ModuleType } from 'src/app/core/state/core.state';
import { Core } from 'src/app/core/state/core.actions';
import { Navigate } from '@ngxs/router-plugin';
import { Idle } from '@ng-idle/core';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class RentWarningComponent implements OnInit, OnDestroy {
  back: boolean = false;
  theme: string;
  tenant: string;
  public loaderActive: boolean = false;

  constructor(private store: Store, private idle: Idle) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    const rentAction =
      this.store.selectSnapshot<LockerStateModel>(LockerState).rentAction;

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.tenant = this.store.selectSnapshot<CoreStateModel>(CoreState).config.tenant;

    }

    this.back = rentAction === 'return';
  }

  ngOnDestroy(): void {
    if (!this.back) {
      this.idle.stop();
    }
  }

  ngOnInit(): void {
    if (!this.back) {
      this.idle.watch();
    }
  }

  agree(): void {
    this.loaderActive = true;

    this.store.dispatch(new Locker.FinishRentProcess()).subscribe(() => {
      this.store.dispatch(new Navigate(['locker', 'rent', 'complete']));
    },
      (error: any) => {
        let machineError = new MachineError(
          4444,
          ModuleType.RETAILER,
          error.message,
          false
        );

        this.store
          .dispatch(new Core.Error(machineError, ModuleType.RETAILER))
          .subscribe(() => {
            setTimeout(() => {
              this.store.dispatch(new Navigate(['/core/screensaver']));
            }, 10000);
          });
      });
  }

  cancel(): void {
    this.store.dispatch(new Locker.ResetRentProcess());
  }
}
