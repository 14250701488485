import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { MachineError } from '../../models/error';
import { CoreState, CoreStateModel } from '../../state/core.state';
import { GlobalEnums } from '../../state/core.state';
import { VendingTypes } from 'src/app/retailer/state/retailer.state';

// Should become dialog window
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public GlobalEnums = GlobalEnums;
  theme: string;
  logo: string;
  error: Observable<any>;
  showDetailedError: boolean = true;
  openSetupCount: number = 0;
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  contactInfo: string;

  // Screen size condition
  screen15_landscape: boolean = false;

  constructor(private store: Store) {
    // this.theme = localStorage.getItem('theme') ?? 'default';
    this.theme = 'default';
    this.logo = localStorage.getItem('logo') ?? this.GlobalEnums.defaultLogo;
    this.error = store.select(CoreState.error).pipe(
      filter((error) => error != null),
      map((error) => {
        for (let key of Object.keys(error)) {
          if (error[key] != null) {
            return error[key];
          }
        }
      })
    );

    // Screen size condition
    this.screenSize();
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.contactInfo =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.contactInfo;
    }
  }

  ngOnInit(): void {}

  screenSize() {
    // Screen size condition
    this.screen15_landscape =
      JSON.parse(localStorage.getItem('screen15_landscape')) ?? false;
    console.warn('this.screen15_landscape', this.screen15_landscape);
  }

  openSetup() {
    this.openSetupCount++;

    if (this.openSetupCount === this.GlobalEnums.openSetupCount) {
      this.store.dispatch(new Navigate(['/core', 'setup']));
    }
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case '4':
        this.goToStart();
        break;
      case '3':
        this.goToStart();
        break;
      case '2':
        this.goToStart();
        break;
      case '1':
        this.goToStart();
        break;
      case '8':
        this.goToStart();
        break;
      case '7':
        this.goToStart();
        break;
      case '6':
        this.goToStart();
        break;
      case '5':
        this.goToStart();
        break;
    }
  }

  goToStart() {
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config &&
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .vendingType === VendingTypes.SKI
    ) {
      this.store.dispatch(new Navigate(['/core', 'selection-buttons']));
    }
  }

  swipe(e: TouchEvent, when: string): void {
    // console.warn('TouchEvent', e);
    // console.warn('when', when);

    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1],
      ];
      const duration = time - this.swipeTime;

      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        const swipeDirection = direction[0] < 0 ? 'left' : 'right';
        // Do whatever you want with swipe
        // console.warn('horizontal swipe', swipeDirection);

        // this.showDetailedError = false;
      } else if (
        duration < 1000 && //
        Math.abs(direction[1]) > 30 && // Long enough
        Math.abs(direction[1]) > Math.abs(direction[0] * 3)
      ) {
        // Vertical enough
        const swipeDirection = direction[1] < 1 ? 'up' : 'down';

        switch (swipeDirection) {
          case 'up':
            this.showDetailedError = false;
            break;
          case 'down':
            this.showDetailedError = true;
            break;
        }
      }
    }
  }
}
