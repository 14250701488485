import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { of, throwError, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MachineError } from 'src/app/core/models/error';
import {
  ControlCode,
  ControlService,
} from 'src/app/core/services/control.service';
import { LockerHttpService } from '../services/locker-http.service';
import { Locker } from './locker.actions';
import {
  CoreState,
  CoreStateModel,
  ModuleType,
} from 'src/app/core/state/core.state';
import { Core } from 'src/app/core/state/core.actions';
import { MqttHelperService } from 'src/app/core/services/mqtt.service';

export class LockerStateModel {
  rentAction: string;
  order: any;
  isInterested: boolean;
  openLockerData: any;
}

@State<LockerStateModel>({
  name: 'locker',
  defaults: {
    order: null,
    rentAction: null,
    isInterested: false,
    openLockerData: null,
  },
})
@Injectable()
export class LockerState implements NgxsOnInit {
  constructor(
    private store: Store,
    private lockerHttpService: LockerHttpService,
    private controlService: ControlService,
    private mqttService: MqttHelperService
  ) {}

  ngxsOnInit(ctx?: StateContext<any>) {}

  resetState(ctx: StateContext<LockerStateModel>) {
    ctx.patchState({
      order: null,
      rentAction: null,
      isInterested: false,
    });
  }

  @Action(Locker.CheckRentCode)
  checkCode(ctx: StateContext<LockerStateModel>, action: Locker.CheckRentCode) {
    return this.lockerHttpService
      .checkRentCode(ctx.getState().rentAction, action.pickupCode)
      .pipe(
        tap((order) => {
          ctx.patchState({
            order: order,
          });
        })
      );
  }

  @Action(Locker.RentProcess)
  startRentProcess(
    ctx: StateContext<LockerStateModel>,
    action: Locker.RentProcess
  ) {
    ctx.patchState({
      rentAction: action.actionName,
    });

    this.store.dispatch(new Navigate(['/locker', 'rent', 'start-scanner']));
  }

  @Action(Locker.ResetRentProcess)
  resetProcess(ctx: StateContext<LockerStateModel>) {
    this.resetState(ctx);

    this.store.dispatch(new Navigate(['/core', 'screensaver']));
  }

  @Action(Locker.ConfirmRentCodeCorrect)
  confirmCodeCorrect(
    ctx: StateContext<LockerStateModel>,
    action: Locker.ConfirmRentCodeCorrect
  ) {
    ctx.patchState({
      isInterested: action.isInterested,
    });

    this.store.dispatch(new Navigate(['/locker', 'rent', 'warning']));
  }

  @Action(Locker.FinishRentProcess)
  finishRentProcess(ctx: StateContext<LockerStateModel>) {
    // release content
    return this.controlService
      .releaseContent(ctx.getState().order.slot.slotIndex, null)
      .pipe(
        tap((res) => {
          if (res.controlError != ControlCode.SUCCESS) {
            throw new MachineError(
              res.controlError,
              ModuleType.RETAILER,
              'Produktausgabe fehlgeschlagen.',
              false
            );
          }
        }),
        switchMap(() =>
          this.lockerHttpService.finishRentProcess(
            ctx.getState().rentAction,
            ctx.getState().order,
            ctx.getState().isInterested
          )
        )
      );
  }
  @Action(Locker.CheckLockerDoor)
  checkLockerDoor(
    ctx: StateContext<LockerStateModel>,
    action: Locker.CheckLockerDoor
  ) {
    return this.mqttService.checkLockerDoor().pipe(
      tap((res) => {
        if (!res.success) {
          console.log('check door close failed');
        }
      })
    );
  }

  @Action(Locker.OpenLocker)
  openLocker(ctx: StateContext<LockerStateModel>, action: Locker.OpenLocker) {
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .controlSoftwareType === 'mqtt'
    ) {
      return this.mqttService.openLocker(action).pipe(
        tap((res) => {
          if (!res.success) {
            throw new MachineError(
              res.controlError,
              ModuleType.RETAILER,
              'Locker open failed.' + res.message,
              false
            );
          }
        })
      );
    } else {
      return this.controlService.openLocker(action.slotIndex).pipe(
        tap((res) => {
          if (res.controlError != ControlCode.SUCCESS) {
            throw new MachineError(
              res.controlError,
              ModuleType.RETAILER,
              'Produktausgabe fehlgeschlagen.',
              false
            );
          }
        })
      );
    }
  }
  @Action(Locker.StartRentItem)
  startRentItem(
    ctx: StateContext<LockerStateModel>,
    action: Locker.StartRentItem
  ) {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;

    return this.lockerHttpService
      .startRentProcess(
        +action.slotId,
        activeUser.userData.authenticationId,
        activeUser.userData.userId
      )
      .pipe(switchMap(() => this.store.dispatch(new Core.ReloadLockerData())));
  }

  @Action(Locker.FinishRentItem)
  finishRentItem(
    ctx: StateContext<LockerStateModel>,
    action: Locker.FinishRentItem
  ) {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;

    return this.lockerHttpService
      .finishRentProcessOB(
        +action.deliveryId,
        activeUser.userData.authenticationId,
        activeUser.userData.userId,
        action.message
      )
      .pipe(switchMap(() => this.store.dispatch(new Core.ReloadLockerData())));
  }

  @Action(Locker.TakeDeliveryItem)
  takeDeliveryItem(
    ctx: StateContext<LockerStateModel>,
    action: Locker.TakeDeliveryItem
  ) {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;

    return this.lockerHttpService
      .takeDeliveryPosition(
        [action.deliveryPositionId],
        activeUser.userData.authenticationId
      )
      .pipe(switchMap(() => this.store.dispatch(new Core.ReloadLockerData())));
  }

  @Action(Locker.TakeDropoffItem)
  takeDropoffItem(
    ctx: StateContext<LockerStateModel>,
    action: Locker.TakeDropoffItem
  ) {
    return this.lockerHttpService
      .takeDropoffPosition([action.deliveryPositionId], action.cardNumber)
      .pipe(switchMap(() => this.store.dispatch(new Core.ReloadLockerData())));
  }

  @Action(Locker.StoreDeliveryItem)
  storeDeliveryItem(
    ctx: StateContext<LockerStateModel>,
    action: Locker.StoreDeliveryItem
  ) {
    return this.lockerHttpService
      .storeDeliveryPosition([
        {
          deliveryPositionId: action.deliveryPositionId,
          slotId: action.slotId,
        },
      ])
      .pipe(switchMap(() => this.store.dispatch(new Core.ReloadLockerData())));
  }

  @Action(Locker.StoreDropoffItem)
  storeDropoffItem(
    ctx: StateContext<LockerStateModel>,
    action: Locker.StoreDropoffItem
  ) {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;

    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .controlSoftwareType === 'mqtt'
    ) {
      return this.mqttService.openLocker(action).pipe(
        tap((res) => {
          if (!res.success) {
            throw new MachineError(
              res.controlError,
              ModuleType.RETAILER,
              'Locker open failed:' + res.message,
              false
            );
          }
        }),
        switchMap(() =>
          this.lockerHttpService.storeDropoff(
            +action.slotId,
            action.message,
            activeUser.userData.authenticationId
          )
        ),
        switchMap(() => this.store.dispatch(new Core.ReloadLockerData()))
      );
    } else {
      return this.controlService.openLocker(+action.slotIndex).pipe(
        tap((res) => {
          if (res.controlError != ControlCode.SUCCESS) {
            throw new MachineError(
              res.controlError,
              ModuleType.RETAILER,
              'Locker open failed.',
              false
            );
          }
        }),
        switchMap(() =>
          this.lockerHttpService.storeDropoff(
            +action.slotId,
            action.message,
            activeUser.userData.authenticationId
          )
        ),
        switchMap(() => this.store.dispatch(new Core.ReloadLockerData()))
      );
    }
  }
}
