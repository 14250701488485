import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeFormatting'
})
export class RemoveFormattingPipe implements PipeTransform {
  transform(html: string): string {
    const parser = new DOMParser();
    const document = parser.parseFromString(html, 'text/html');
    return document.body.textContent || '';
  }
}
