import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';

// import { Pickup } from '../../state/pickup.actions';
// import { PickupState, PickupStateModel } from '../../state/pickup.state';
import { Idle } from '@ng-idle/core';
import Keyboard from 'simple-keyboard';
import { ControlService } from 'src/app/core/services/control.service';
import { LockerSkiHttpService } from 'src/app/locker/services/locker-ski-http.service';
import { MqttHelperService } from 'src/app/core/services/mqtt.service';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';

@Component({
  selector: 'setup-keyboard-input',
  templateUrl: './setup-keyboard-input.component.html',
  styleUrls: ['./setup-keyboard-input.component.scss'],
})
export class SetupKeyboardComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @HostBinding('class') class = 'w-full h-full flex flex-col items-center';

  public keyboard: Keyboard;
  public value: string = '';
  public submitted = false;
  public skipMessage = false;
  public error: string;
  public loaderActive: boolean = false;

  public action: string;
  actionInProgress: boolean = false;

  public theme: string;
  @ViewChild('messageInput')
  public messageInput: ElementRef;
  isOfficeButler: boolean = false;
  isMqtt: boolean = false;
  rows: any[] = [];

  constructor(
    private store: Store,
    private idle: Idle,
    private route: ActivatedRoute,
    private controlService: ControlService,
    private lockerHttpService: LockerSkiHttpService,
    private mqttHelperService: MqttHelperService
  ) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.route.params.subscribe((params) => {
      this.action = params.action;
    });

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config) {
      this.isOfficeButler =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.isOfficeButler;

      if (
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .controlSoftwareType == 'mqtt'
      ) {
        this.isMqtt = true;
      }

      if (
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.machineRows
      ) {
        this.rows =
          this.store.selectSnapshot<CoreStateModel>(
            CoreState
          ).config.machineRows;
      }
    }
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  clickOk() {
    this.actionInProgress = true;
    if (this.action == 'move-to-box') {
      this.controlService.moveToBox(+this.value).subscribe((res) => {
        console.log(res);
        this.actionInProgress = false;
        this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
      });
    } else if (this.action == 'push') {
      this.controlService.push(+this.value).subscribe((res) => {
        console.log(res);
        this.actionInProgress = false;
        this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
      });
    } else if (this.action == 'test-position') {
      this.controlService.testPosition(+this.value).subscribe((res) => {
        console.log(res);
        this.actionInProgress = false;
        this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
      });
    } else if (this.action == 'open-locker') {
      let lockersToOpen: number[] = [];
      lockersToOpen.push(+this.value);
      this.lockerHttpService.unlockSlots(lockersToOpen).subscribe((res) => {
        console.log(res);
        this.actionInProgress = false;
        this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
      });
    } else if (this.action == 'open-locker-ob') {
      let lockersToOpen: number[] = [];
      lockersToOpen.push(+this.value);
      this.mqttHelperService
        .openLocker({ containerCode: 1, slotIndex: this.value })
        .subscribe((res) => {
          console.log(res);
          this.actionInProgress = false;
          this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
        });
    } else if (this.action == 'open-row-ob') {
      let lockersToOpen: number[] = [];
      if (this.rows[+this.value]) {
        lockersToOpen = this.rows[+this.value];
      } else {
        this.actionInProgress = false;
        this.value = '';
      }
      this.mqttHelperService
        .openMultipleLocker({ containerCode: 1, slots: lockersToOpen })
        .subscribe((res) => {
          console.log(res);
          this.actionInProgress = false;
          this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
        });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.messageInput.nativeElement.focus(), 1000);

    this.keyboard = new Keyboard('.setup-keyboard-input-keyboard', {
      onChange: (input) => this.onChange(input),
      // onKeyPress: button => this.onKeyPress(button)
    });

    this.keyboard.setOptions({
      physicalKeyboardHighlightPress: true,
      physicalKeyboardHighlight: true,
      layout: {
        default: ['1 2 3 4 5 6 7 8 9 0 {bksp}'],
        shift: ['1 2 3 4 5 6 7 8 9 0 {bksp}'],
      },
      display: {
        '{bksp}': '    ',
        '{space}': 'SPACE',
        ß: '   ',
      },
      theme: 'new-theme-keyboard',
      buttonTheme: [
        {
          class: 'backspace',
          buttons: '{bksp}',
        },
        {
          class: 'ss',
          buttons: 'ß',
        },
        {
          class: 'space-new',
          buttons: '{space}',
        },
      ],
    });
  }

  onBlurKeyboard(event) {
    this.messageInput.nativeElement.focus();
  }

  onChange = (input: string) => {
    this.value = input;
  };

  public saveMessage(): void {}

  onEnter(): void {
    this.saveMessage();
  }

  cancel(): void {
    this.store.dispatch(new Navigate(['/core/setup/setup-menu']));
  }

  login() {}
}
