<div
  class="flex flex-row items-center justify-center w-9/12 h-16 px-6 py-2 space-x-6 text-2xl text-center text-white ring-8 wow bounceInUp animated rounded-t-3xl"
  data-wow-delay="0"
  data-wow-duration="1s"
  [ngClass]="
    msgType === 'warning'
      ? 'bg-secondary/80 ring-secondary/20'
      : msgType === 'error'
      ? 'bg-red/80 ring-red/50'
      : msgType === 'info'
      ? 'bg-blue-700/80 ring-blue-700/20'
      : msgType === 'danger'
      ? 'bg-secondary/80 ring-secondary/20'
      : msgType === 'success'
      ? 'bg-success/80 ring-success/20'
      : ''
  "
>
  <!-- warning, error, info, danger, success -->
  <span class="h-8 mr-auto text-white justify-self-start">
    <app-svg-stripe [icon]="icon"></app-svg-stripe>
  </span>
  <span class="flex-1 mx-auto text-center">
    {{ msg }}
  </span>
</div>
