<div class="flex flex-col items-start justify-between w-full h-screen">
  <app-ski-bg
    [isLight]="false"
    [useAnimation]="true"
    [showImg]="false"
    [clockCenter]="true"
    [bigSelection]="true"
    [hasCentral]="true"
    [hasStatus]="true"
  >
    <ng-container slot="top">
      <app-header-ski [big]="true"></app-header-ski>
    </ng-container>

    <!-- central part -->
    <ng-container slot="central">
      <div
        class="grid items-center justify-center w-8/12 h-auto grid-cols-2 grid-rows-2 pt-4 pb-4 mx-auto text-center gap-x-12 gap-y-18"
      >
        <ng-container *ngFor="let item of lockers">
          <div
            class="flex flex-row items-center justify-start odd:flex-row-reverse"
          >
            <app-btn-ski
              (btnClick)="('')"
              [label]=""
              [big]="false"
              [type]="'selectednumber'"
              [right]="false"
              [number]="item.number"
              [extraCss]="
                !item.selected
                  ? 'btn-ski--selectednumber-notChecked grayscale opacity-70'
                  : ''
              "
            >
            </app-btn-ski>
            <span class="px-12 text-3xl font-semibold">
              <ng-container *ngIf="item.selected">
                {{ item.label }}
              </ng-container>
              <ng-container *ngIf="!item.selected">{{
                "COMMON.LOCKED" | translate
              }}</ng-container>
            </span>
          </div>
        </ng-container>
      </div>
      <div class="flex w-full pt-16">
        <div
          class="flex flex-row items-center justify-center w-4/12 px-4 py-2 mx-auto space-x-6 text-4xl font-semibold text-center text-white uppercase rounded-full ring-8 ring-success/50 bg-success"
        >
          <span> {{ "COMMON.TOTAL_PRICE" | translate }} </span>
          <span> {{ totalAmount }} € </span>
        </div>
      </div>
    </ng-container>
    <!-- end central part -->

    <ng-container slot="status">
      <div
        class="flex flex-col items-start justify-start ski-summary-block bg-success wow bounceInUp animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div
          class="flex items-center justify-center mx-auto rounded-full w-52 h-52 bg-white-20 -mt-36 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex items-center justify-center w-40 h-40 rounded-full bg-white-40 wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <div
              class="flex flex-col items-center justify-center p-4 mx-auto text-white rounded-full w-28 h-28 bg-success wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <div
                class="flex flex-col items-center justify-center w-full h-full bg-white rounded-full text-success"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80%"
                  height="80%"
                  viewBox="0 0 129.294 140.033"
                >
                  <g
                    id="Group_7923"
                    data-name="Group 7923"
                    transform="matrix(0.914, 0.407, -0.407, 0.914, -233.295, -468.456)"
                  >
                    <g
                      id="Group_39"
                      data-name="Group 39"
                      transform="translate(448 317)"
                    >
                      <g
                        id="Group_18"
                        data-name="Group 18"
                        transform="translate(0 0)"
                      >
                        <g
                          id="Group_17"
                          data-name="Group 17"
                          transform="translate(0 0)"
                        >
                          <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M111.518-.65H97.556V-56.113a1.887,1.887,0,0,0-1.165-1.743,1.887,1.887,0,0,0-2.056.409L88.991-52.1l-5.344-5.344a1.887,1.887,0,0,0-2.668,0L75.635-52.1l-5.344-5.344a1.887,1.887,0,0,0-2.668,0L62.279-52.1l-5.344-5.344a1.887,1.887,0,0,0-2.668,0L48.923-52.1l-5.344-5.344a1.887,1.887,0,0,0-2.668,0L35.567-52.1l-5.344-5.344a1.885,1.885,0,0,0-2.655-.01,1.88,1.88,0,0,0-.563,1.394V39.722a9.821,9.821,0,0,0,9.809,9.809H103.6a9.821,9.821,0,0,0,9.809-9.809V1.237A1.886,1.886,0,0,0,111.518-.65Zm-74.7,46.408a6.043,6.043,0,0,1-6.037-6.037V-51.556L34.233-48.1a1.887,1.887,0,0,0,2.668,0l5.344-5.344L47.589-48.1a1.887,1.887,0,0,0,2.668,0L55.6-53.445,60.946-48.1a1.887,1.887,0,0,0,2.668,0l5.344-5.344L74.3-48.1a1.887,1.887,0,0,0,2.668,0l5.344-5.344L87.658-48.1a1.887,1.887,0,0,0,2.668,0l3.458-3.458v52.8c0,.017,0,.034,0,.051V39.722a9.758,9.758,0,0,0,2.086,6.037H36.815Zm72.817-6.037a6.037,6.037,0,1,1-12.073,0V3.123h12.073Z"
                            transform="translate(-27.005 58)"
                            fill="currentColor"
                            stroke="currentColor"
                            stroke-width="5"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_7313"
                        data-name="Group 7313"
                        transform="translate(18.228 27.274)"
                      >
                        <path
                          id="Path_12"
                          data-name="Path 12"
                          d="M173.206,402h-20.8a1.493,1.493,0,0,0,0,2.964h20.8a1.493,1.493,0,0,0,0-2.964Z"
                          transform="translate(-141.169 -402.003)"
                          fill="currentColor"
                        />
                        <g
                          id="Group_36"
                          data-name="Group 36"
                          transform="translate(0 0)"
                        >
                          <g id="Group_35" data-name="Group 35">
                            <path
                              id="Path_13"
                              data-name="Path 13"
                              d="M101.688,402.556a1.885,1.885,0,1,0,.553,1.334A1.9,1.9,0,0,0,101.688,402.556Z"
                              transform="translate(-98.468 -402.003)"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_7314"
                        data-name="Group 7314"
                        transform="translate(18.228 42.281)"
                      >
                        <path
                          id="Path_12-2"
                          data-name="Path 12"
                          d="M173.206,402h-20.8a1.493,1.493,0,0,0,0,2.964h20.8a1.493,1.493,0,0,0,0-2.964Z"
                          transform="translate(-141.169 -402.003)"
                          fill="currentColor"
                        />
                        <g
                          id="Group_36-2"
                          data-name="Group 36"
                          transform="translate(0 0)"
                        >
                          <g id="Group_35-2" data-name="Group 35">
                            <path
                              id="Path_13-2"
                              data-name="Path 13"
                              d="M101.688,402.556a1.885,1.885,0,1,0,.553,1.334A1.9,1.9,0,0,0,101.688,402.556Z"
                              transform="translate(-98.468 -402.003)"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_7315"
                        data-name="Group 7315"
                        transform="translate(18.228 57.288)"
                      >
                        <path
                          id="Path_12-3"
                          data-name="Path 12"
                          d="M173.206,402h-20.8a1.493,1.493,0,0,0,0,2.964h20.8a1.493,1.493,0,0,0,0-2.964Z"
                          transform="translate(-141.169 -402.003)"
                          fill="currentColor"
                        />
                        <g
                          id="Group_36-3"
                          data-name="Group 36"
                          transform="translate(0 0)"
                        >
                          <g id="Group_35-3" data-name="Group 35">
                            <path
                              id="Path_13-3"
                              data-name="Path 13"
                              d="M101.688,402.556a1.885,1.885,0,1,0,.553,1.334A1.9,1.9,0,0,0,101.688,402.556Z"
                              transform="translate(-98.468 -402.003)"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_7316"
                        data-name="Group 7316"
                        transform="translate(18.228 72.295)"
                      >
                        <path
                          id="Path_12-4"
                          data-name="Path 12"
                          d="M173.206,402h-20.8a1.493,1.493,0,0,0,0,2.964h20.8a1.493,1.493,0,0,0,0-2.964Z"
                          transform="translate(-141.169 -402.003)"
                          fill="currentColor"
                        />
                        <g
                          id="Group_36-4"
                          data-name="Group 36"
                          transform="translate(0 0)"
                        >
                          <g id="Group_35-4" data-name="Group 35">
                            <path
                              id="Path_13-4"
                              data-name="Path 13"
                              d="M101.688,402.556a1.885,1.885,0,1,0,.553,1.334A1.9,1.9,0,0,0,101.688,402.556Z"
                              transform="translate(-98.468 -402.003)"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </app-ski-bg>
</div>
