import { Component, OnInit } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from '../../state/core.state';
import { LangService } from 'src/app/core/services/lang.service';
import { ContentTxtService } from 'src/app/core/services/contentTxt.service';
import { Retailer } from 'src/app/retailer/state/retailer.actions';
import { VendingTypes } from 'src/app/retailer/state/retailer.state';
import { MqttHelperService } from '../../services/mqtt.service';

@Component({
  selector: 'app-selection-full',
  templateUrl: './selection-full.component.html',
  styleUrls: ['./selection-full.component.scss'],
})
export class SelectionFullComponent implements OnInit {
  theme: string;
  logo: string;
  personPhoto: string;
  tenant: string;
  modules: any;
  portrait: boolean;
  fullHd: boolean = false;
  fullHdHalf: boolean = false;
  modulesAvailableList: Array<any> = [];
  modulesList: Array<any> = [];
  numberOfModules: number = 6;
  beiText: string = 'bei der';
  machineText: string = '24/7 Automat!';
  multiLanguage: boolean = false;
  currentLang: string;
  welcomeMsg: string = '';
  public availableLanguages = [];
  showProductPrices: boolean = true;

  constructor(
    private store: Store,
    private idle: Idle,
    private langService: LangService,
    private contentTxtService: ContentTxtService,
    private mqttHelperService: MqttHelperService
  ) {
    this.currentLang = this.langService.getLanguage();
    this.availableLanguages = this.langService.getAvailableLanguages();

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      this.personPhoto =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.personPhoto;
      this.modules =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.modules;
      this.tenant =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.tenant;
      this.multiLanguage =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.multiLanguage;
      this.showProductPrices =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.showProductPrices;

      this.theme = localStorage.getItem('theme') ?? 'default';

      this.welcomeMsg = this.contentTxtService.getFieldContentTxt(
        'welcomeMsg',
        'SELECTION.WELCOME_FULL'
      );

      if (
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .isOfficeButler
      ) {
        this.beiText = 'SELECTION.BY_ISOFFICEBUTLER';
        this.machineText = 'SELECTION.TO_MACHINE_ISOFFICEBUTLER';
      }

      if (
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .vendingType == VendingTypes.PREORDER ||
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .vendingType == VendingTypes.WWKS2
      ) {
        this.machineText = 'SELECTION.TO_MACHINE_TERMINAL';
      }

      this.modulesAvailableList = [
        {
          id: 0,
          icon: this.theme === 'sanusx' ? 'pickup' : 'pickup2',
          title: 'SELECTION_MENU.ABHOLEN',
          // title: "Abholen",
          method: () => this.retailerPickup(),
          enabled: this.modules.retailerPickup,
        },
        {
          id: 1,
          icon: this.theme === 'sanusx' ? 'retailer' : 'retailer2',
          title: this.showProductPrices
            ? 'SELECTION_MENU.EINKAUFEN'
            : 'SELECTION_MENU.ABHOLEN',
          // title: "Einkaufen",
          method: () => {
            if (
              this.store.selectSnapshot<CoreStateModel>(CoreState).config
                .vendingType === VendingTypes.PREORDER
            ) {
              this.demoCategories();
            } else {
              this.retailer();
            }
          },
          enabled: this.modules.retailer,
        },
        {
          id: 2,
          icon: 'rent',
          title: 'SELECTION_MENU.RENT',
          // title: "Rent",
          method: () => this.rent(),
          enabled: this.modules.rent,
        },
        {
          id: 2,
          icon: 'send',
          title: 'SELECTION_MENU.RENT_RETURN',
          // title: "Rent",
          method: () => this.retrunRent(),
          enabled: this.modules.rent,
        },
        {
          id: 3,
          icon: 'pickup',
          title: 'SELECTION_MENU.ABHOLUNG',
          // title: "Abholung",
          method: () => this.takeOrder(),
          enabled: this.modules.deliveries,
        },
        {
          id: 4,
          icon: 'receive',
          title: 'SELECTION_MENU.ABGABE',
          // title: "Abgabe",
          method: () => this.dropoff(),
          enabled: this.modules.dropoff,
        },
        {
          id: 5,
          icon: 'send',
          title: 'SELECTION_MENU.EINLAGERUNG',
          // title: "Einlagerung",
          method: () => this.loadDeliveries(),
          enabled: this.isLoadDeliveriesEnabled(),
        },
        {
          id: 6,
          icon: 'truck-plus',
          title: 'SELECTION_MENU.ABGABE_ABHOLEN',
          // title: "Abgabe abholen",
          method: () => this.loadTakeDropoffs(),
          enabled: this.isTakeDropoffEnabled(),
        },
        {
          id: 7,
          icon: 'logout',
          title: 'COMMON.BTN_LOGOUT',
          // title: "Abgabe abholen",
          method: () => {
            this.store.dispatch(new Navigate([''])).subscribe(() => {
              this.store.dispatch(new Retailer.Reset());
            });
          },
          enabled:
            this.store.selectSnapshot<CoreStateModel>(CoreState).config
              .isOfficeButler,
        },
        {
          id: 8,
          icon: 'chat',
          title: 'SELECTION_MENU.DIGITAL_ASSISTANT',
          // title: "Abgabe abholen",
          method: () => {
            console.log('chat');
          },
          enabled: this.modules.chat,
        },
      ];

      this.modulesList = this.modulesAvailableList.filter(
        (module) => module.enabled
      );
      // this.modulesList = this.modulesAvailableList.splice(1, 4);
      this.numberOfModules = this.modulesList.length;
    }

    // if tenant not defined reload screen

    if (this.tenant === undefined) {
      this.ifTenantEmpty();
    } else if (this.tenant !== undefined) {
      console.warn('tenant defined', this.tenant);
    }

    if (this.numberOfModules === 1 && this.modules.retailer) {
      this.retailer();
    }

    this.portrait = JSON.parse(localStorage.getItem('portrait')) ?? true;
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
    localStorage.setItem('hand', 'right-hand');
  }

  isLoadDeliveriesEnabled() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;

    return (
      this.modules.deliveries &&
      activeUser &&
      activeUser.userData &&
      activeUser.userData.isSuperUser
    );
  }

  isTakeDropoffEnabled() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;

    return (
      this.modules.dropoff &&
      activeUser &&
      activeUser.userData &&
      activeUser.userData.isSuperUser
    );
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  demoCategories() {
    this.store.dispatch(new Navigate(['/retailer', 'categories']));
  }

  retailer() {
    console.log('retail product selection clicked');
    this.store.dispatch(new Navigate(['/retailer', 'selection']));
  }

  retailerPickup() {
    console.log('retail pickup clicked');
    this.store.dispatch(new Navigate(['/retailer', 'pickup']));
  }

  rent() {
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.isOfficeButler
    ) {
      this.store.dispatch(
        new Navigate(['/locker', 'delivery', 'deliveries', 'start-rent'])
      );
    } else {
      this.store.dispatch(new Navigate(['/locker', 'rent', 'start-scanner']));
    }
  }

  retrunRent() {
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.isOfficeButler
    ) {
      this.store.dispatch(
        new Navigate(['/locker', 'delivery', 'deliveries', 'finish-rent'])
      );
    } else {
      this.store.dispatch(new Navigate(['/locker', 'rent', 'start-scanner']));
    }
  }

  takeOrder() {
    this.store.dispatch(
      new Navigate(['/locker', 'delivery', 'deliveries', 'take'])
    );
  }

  dropoff() {
    this.store.dispatch(
      new Navigate([
        '/locker',
        'delivery',
        'locker-preview',
        'dropoff',
        '0',
        '0',
      ])
    );
  }

  loadDeliveries(): void {
    this.store.dispatch(
      new Navigate(['/locker', 'delivery', 'deliveries', 'load'])
    );
  }

  loadTakeDropoffs(): void {
    this.store.dispatch(
      new Navigate(['/locker', 'delivery', 'deliveries', 'take-dropoff'])
    );
  }

  setPublicLanguage() {
    switch (this.currentLang) {
      case 'de':
        this.langService.setPublicLanguage('en');
        this.langService.getNewLanguage('en');
        this.langService.setLanguage();
        this.langService.emitChangeLang('en');
        break;
      case 'en':
        this.langService.setPublicLanguage('de');
        this.langService.getNewLanguage('de');
        this.langService.setLanguage();
        this.langService.emitChangeLang('de');
        break;

      default:
    }
    this.currentLang = this.langService.getLanguage();
  }

  ifTenantEmpty() {
    console.log(
      `%c Tennant not defined!`,
      'color: black; background: orange; font-size: 15px'
    );
    // location.reload();
  }
}
