<div class="flex flex-col items-start justify-between w-full h-screen">
  <app-ski-bg
    [isLight]="false"
    [useAnimation]="true"
    [showImg]="false"
    [clockCenter]="true"
    [bigSelection]="true"
    [hasCentral]="true"
    [hasStatus]="true"
  >
    <ng-container slot="top">
      <app-header-ski
        [title]="'LOCKER_SKI.THANKS_TITLE' | translate"
        [subtitle]="
          skiServiceType === 'rent'
            ? ('LOCKER_SKI.THANKS_SUBTITLE_RENT' | translate)
            : ('LOCKER_SKI.THANKS_SUBTITLE_RECLAIM' | translate)
        "
        [subtitleThird]="'LOCKER_SKI.THANKS_PRESS_ANY_KEY' | translate"
        [big]="true"
        [thanks]="true"
      ></app-header-ski>
    </ng-container>

    <!-- central part -->
    <ng-container slot="central">
      <ng-container *ngIf="skiServiceType === 'rent'">
        <div
          class="flex flex-row items-center justify-center w-full h-auto pt-44 space-x-12 text-center"
        >
          <ng-container *ngFor="let item of lockers">
            <app-btn-ski
              (btnClick)="('')"
              [label]=""
              [big]="false"
              [type]="'selectednumber'"
              [right]="false"
              [number]="item.number"
            >
            </app-btn-ski>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="skiServiceType === 'reclaim'">
        <div
          class="flex flex-col items-center justify-center w-full pt-40 space-y-2"
        >
          <span class="text-2xl font-medium text-primary-dark/70">
            {{ "LOCKER_SKI.THANKS_MSG_1" | translate }}
          </span>
          <span class="text-2xl font-medium text-primaryx"> {{ slots }} </span>
          <span class="text-2xl font-medium text-primary-dark/70">
            {{ "LOCKER_SKI.THANKS_MSG_2" | translate }}
          </span>
        </div>
      </ng-container>
    </ng-container>
    <!-- end central part -->

    <ng-container slot="status">
      <div
        class="flex flex-col items-start justify-start ski-thanks-block bg-primaryx wow bounceInUp animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div
          class="flex items-center justify-center mx-auto rounded-full w-52 h-52 bg-white-20 -mt-36 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex items-center justify-center w-40 h-40 rounded-full bg-white-40 wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <div
              class="flex flex-col items-center justify-center p-4 mx-auto text-white rounded-full w-28 h-28 bg-primaryx wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <div
                class="flex flex-col items-center justify-center w-full h-full bg-white rounded-full text-primaryx"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60%"
                  height="60%"
                  viewBox="0 0 135.07 130.127"
                  class="wow tada animated"
                  data-wow-delay="0"
                  data-wow-duration="1s"
                  data-wow-iteration="2"
                >
                  <g
                    id="Group_7413"
                    data-name="Group 7413"
                    transform="translate(0 48)"
                  >
                    <path
                      id="Path_13557"
                      data-name="Path 13557"
                      d="M92.183-4.624H76.351C87.2-48,57.374-48,57.374-48H49.865A12.5,12.5,0,0,0,37.448-33.93l.38,3.118a12.741,12.741,0,0,1-1.816,8.35L26.952-9.42A6.786,6.786,0,0,0,29.169-.091a6.787,6.787,0,0,0,9.348-2.22l11.43-16.9a13.722,13.722,0,0,0,2.006-7.1,11.668,11.668,0,0,0-.108-1.681L51.464-31A3.06,3.06,0,0,1,54.5-34.445h2.874a8.588,8.588,0,0,1,5.368,2.874C64.206-29.7,67.134-23.682,63.2-7.9L59,8.931H91.072a6.19,6.19,0,0,1,5.964,7.862L86.273,55.885a6.238,6.238,0,0,1-5.991,4.554H21.21c-3.953,0-7.434,3.242-7.2,7.19a6.753,6.753,0,0,0,6.764,6.365H80.933a18.865,18.865,0,0,0,18.218-13.88l11.278-40.8A18.925,18.925,0,0,0,92.183-4.624"
                      transform="translate(23.955)"
                      fill="currentColor"
                    />
                    <path
                      id="Path_13558"
                      data-name="Path 13558"
                      d="M35.243,33.027a3.031,3.031,0,0,1-3.036,3.036H16.591a3.031,3.031,0,0,1-3.036-3.036V-12.409a3.031,3.031,0,0,1,3.036-3.036H32.207a3.031,3.031,0,0,1,3.036,3.036ZM40.15-29H8.648A8.654,8.654,0,0,0,0-20.352V40.971a8.654,8.654,0,0,0,8.648,8.648h31.5A8.654,8.654,0,0,0,48.8,40.971V-20.352A8.654,8.654,0,0,0,40.15-29"
                      transform="translate(0 32.509)"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </div>
              <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                width="182.66"
                height="182.66"
                viewBox="0 0 182.66 182.66"
                class="wow bounceIn animated"
                data-wow-delay="0"
                data-wow-duration="1s"
              >
                <path
                  id="Path_13266"
                  data-name="Path 13266"
                  d="M93.33,184.66A91.33,91.33,0,1,0,2,93.33,91.33,91.33,0,0,0,93.33,184.66ZM135.65,78.569a11.415,11.415,0,1,0-16.144-16.144L81.914,100.02,67.153,85.259A11.415,11.415,0,1,0,51.009,101.4l22.834,22.831a11.416,11.416,0,0,0,16.143,0L135.65,78.569Z"
                  transform="translate(-2 -2)"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg> -->
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </app-ski-bg>
</div>
