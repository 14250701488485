import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import { Location } from '@angular/common';

import {
  GlobalEnums,
  ModuleType,
  CoreState,
  CoreStateModel,
} from 'src/app/core/state/core.state';
import { ImageService } from '../../services/image.service';
import { WwksService } from '../../services/wwks.service';
import { Retailer } from '../../state/retailer.actions';
import {
  RetailerState,
  RetailerStateModel,
  VendingTypes,
} from '../../state/retailer.state';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  public GlobalEnums = GlobalEnums;

  theme: string;
  fullHdHalf: boolean = false;
  rightHand: boolean = true;
  cartItems: Array<any> = [];
  cartItemsForDisplay: Array<any> = [];
  cartItemsForDisplayGroupedByTaxPercent: Array<any> = [];
  totalForPay: Number = 0;
  cartQuantityLimit: Number = 5;
  cartIsFull: boolean = false;
  cartIsFullModal: boolean = false;
  logo: string = '';

  private images = [];

  constructor(
    private router: Router,
    private location: Location,
    private store: Store,
    private imageService: ImageService,
    private idle: Idle
  ) {
    console.log('cart oppened');
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;

    this.getHandDataFromStorage();

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      this.cartQuantityLimit =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.cartQuantityLimit;
    }
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
    this.loadCartItems();
    this.checkCartQuantityLimit();
  }

  loadCartItems(): void {
    this.cartItems =
      this.store.selectSnapshot<RetailerStateModel>(RetailerState).shoppingCart;
    console.log(this.cartItems);
    this.cartItemsForDisplay.splice(0, this.cartItemsForDisplay.length);
    this.cartItemsForDisplayGroupedByTaxPercent.splice(
      0,
      this.cartItemsForDisplayGroupedByTaxPercent.length
    );

    this.cartItems.forEach((cartItem) => {
      const index = this.cartItemsForDisplay.findIndex((object) => {
        return object.product.id === cartItem.product.id;
      });

      if (index == -1) {
        if (
          cartItem.product.imagePaths &&
          cartItem.product.imagePaths.length > 0
        ) {
          if (!this.images[cartItem.product.id]) {
            this.imageService
              .getImage(cartItem.product.imagePaths[0])
              .subscribe((image) => {
                this.images[cartItem.product.id] = image;
              });
          }
        }

        this.cartItemsForDisplay.push({
          product: cartItem.product,
          quantity: 1,
          grossPrice: cartItem.product.grossPrice,
          totalPrice: cartItem.product.grossPrice,
          wwksArticle: cartItem.wwksArticle,
        });
      } else {
        this.cartItemsForDisplay[index].quantity += 1;
        this.cartItemsForDisplay[index].totalPrice +=
          cartItem.product.grossPrice;
      }

      const vatIndex = this.cartItemsForDisplayGroupedByTaxPercent.findIndex(
        (object) => {
          return object.vatPercent === cartItem.product.vatPercent;
        }
      );

      if (vatIndex == -1) {
        this.cartItemsForDisplayGroupedByTaxPercent.push({
          vatPercent: cartItem.product.vatPercent,
          grossPrice: cartItem.product.grossPrice,
          netPrice: cartItem.product.price,
          vatAmount: cartItem.product.grossPrice - cartItem.product.price,
        });
      } else {
        this.cartItemsForDisplayGroupedByTaxPercent[vatIndex].grossPrice +=
          cartItem.product.grossPrice;
        this.cartItemsForDisplayGroupedByTaxPercent[vatIndex].netPrice +=
          cartItem.product.price;
        this.cartItemsForDisplayGroupedByTaxPercent[vatIndex].vatAmount +=
          cartItem.product.vatAmount;
      }
    });

    this.cartItemsForDisplayGroupedByTaxPercent.sort(
      (a, b) => a.vatPercent - b.vatPercent
    );
    this.cartItemsForDisplay.sort((a, b) => a.grossPrice - b.grossPrice);

    this.payAmountCount();
    this.checkCartQuantityLimit();
  }

  payAmountCount(): void {
    this.totalForPay = Number(
      this.cartItems
        .map((item) => item.product.grossPrice)
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    );
  }

  checkCartQuantityLimit() {
    this.cartIsFull = this.cartItems.length === this.cartQuantityLimit;

    if (this.cartItems.length === 0) {
      this.router.navigate(['/retailer/selection']);
    }
  }

  increaseProductQuantity(item: any): void {
    this.store
      .dispatch(new Retailer.AddProductToCart(item.product.id, 1))
      .subscribe((x) => {
        this.loadCartItems();
      });
  }

  decreaseProductQuantity(item: any): void {
    this.store
      .dispatch(new Retailer.RemoveProductFromCart(item.product.id))
      .subscribe((x) => {
        this.loadCartItems();
      });
  }

  removeCartItem(item: any): void {
    console.log(item);
    this.store
      .dispatch(new Retailer.RemoveAllProductInstancesFromCart(item.product.id))
      .subscribe((x) => {
        this.loadCartItems();
      });
  }

  rightHandHandler(status: boolean) {
    this.rightHand = status;
  }

  backPage() {
    if (this.cartIsFull) {
      this.cartIsFullModal = !this.cartIsFullModal;
    } else {
      this.location.back();
    }
  }

  closeCartIsFullModal() {
    this.cartIsFullModal = false;
  }

  getHandDataFromStorage() {
    let hand = localStorage.getItem('hand');

    if (hand === 'right-hand') {
      this.rightHand = true;
    } else if (hand === 'left-hand') {
      this.rightHand = false;
    } else {
      this.rightHand = true;
    }
  }

  emptyCart() {
    this.store.dispatch(new Retailer.EmptyCart()).subscribe((x) => {
      this.router.navigate(['/retailer/selection']);
    });
  }

  cancelRetailer() {
    this.router.navigate(['/retailer/selection']);
  }

  submitCart(): void {
    let vendingType =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.vendingType;

    if (
      vendingType == VendingTypes.WWKS2 ||
      vendingType == VendingTypes.STANDARD ||
      vendingType == VendingTypes.NO_RELEASE
    ) {
      console.log('start of buying');
      this.store.dispatch(new Retailer.BuyCart(null)).subscribe(
        () => {
          console.log('end 1 of buying');
          setTimeout(() => {
            this.store.dispatch(new Navigate(['/core/screensaver']));
          }, 2000);
        },
        (error: MachineError) => {
          console.log('end 2 of buying with error');
          this.store
            .dispatch(new Core.Error(error, ModuleType.RETAILER))
            .subscribe(() => {
              setTimeout(() => {
                this.store.dispatch(new Navigate(['/core/screensaver']));
              }, 10000);
            });
        }
      );
    } else if (vendingType == VendingTypes.PREORDER) {
      this.store.dispatch(new Navigate(['/retailer/total-selection']));
      // this.store.dispatch(new Navigate(['/retailer/print-bill']));
    }
  }

  hasNoMoreItemsLeftInStock(item: any) {
    let currentQuantity = item.quantity;
    let quantityInStock = 9999; // TODO check stock
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .vendingType === VendingTypes.WWKS2
    ) {
      quantityInStock = item.wwksArticle.quantity;
    }

    return currentQuantity >= quantityInStock;
  }
}
