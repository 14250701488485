<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen text-white bg-primaryx"
  >
    <app-header [showSearch]="false" class="w-full"></app-header>
    <div
      class="flex flex-col items-center justify-center flex-1 w-full text-center px-27"
    >
      <h2 class="font-bold">
        {{ "RETAILER_RELEASE.TITLE" | translate }}
      </h2>
      <div
        class="flex flex-col items-center justify-center w-full h-auto mx-auto my-10 text-white wow pulse animated"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="211.83"
          height="211.828"
          viewBox="0 0 211.83 211.828"
        >
          <g
            id="Group_7451"
            data-name="Group 7451"
            transform="translate(0 141.301)"
          >
            <path
              id="Path_13624"
              data-name="Path 13624"
              d="M105.915,70.527A105.915,105.915,0,0,0,211.83-35.388,105.915,105.915,0,0,0,105.915-141.3,105.915,105.915,0,0,0,0-35.388,105.915,105.915,0,0,0,105.915,70.527ZM84.206-3.655,46.242-41.619,37.33-32.708,75.295,5.257l-.094.094,8.912,8.912L174.5-76.126l-8.911-8.911Z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </g>
        </svg>
      </div>
      <h2 class="mx-auto mt-2" style="white-space: pre-line">
        {{ "RETAILER_RELEASE.MESSAGE" | translate }}
      </h2>
    </div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'default'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen bg-gray-light"
  >
    <div
      class="flex flex-col items-center flex-1 w-full h-full text-center middle-block"
    >
      <div class="w-9/12 mx-auto">
        <h1 class="self-end mx-auto font-bold text-center title text-gray">
          {{ "RETAILER_RELEASE.TITLE" | translate }}
        </h1>
      </div>
    </div>

    <div
      class="flex flex-col items-start justify-start success-block wow bounceInUp animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="flex items-center justify-center mx-auto rounded-full w-97 h-97 bg-white-20 -mt-57 wow bounceIn animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div
          class="flex items-center justify-center rounded-full w-80 h-80 bg-white-40 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex flex-col items-center justify-center mx-auto text-white rounded-full w-53 h-53 bg-green wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="182.66"
              height="182.66"
              viewBox="0 0 182.66 182.66"
              class="wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <path
                id="Path_13266"
                data-name="Path 13266"
                d="M93.33,184.66A91.33,91.33,0,1,0,2,93.33,91.33,91.33,0,0,0,93.33,184.66ZM135.65,78.569a11.415,11.415,0,1,0-16.144-16.144L81.914,100.02,67.153,85.259A11.415,11.415,0,1,0,51.009,101.4l22.834,22.831a11.416,11.416,0,0,0,16.143,0L135.65,78.569Z"
                transform="translate(-2 -2)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center w-9/12 mx-auto mt-20">
        <h2
          class="self-end mx-auto font-semibold text-center text-white title"
          style="white-space: pre-line"
        >
          {{ "RETAILER_RELEASE.MESSAGE" | translate }}
        </h2>
      </div>
    </div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen px-6 bg-white"
  >
    <!-- infowindow header -->
    <div
      class="flex flex-col items-stretch min-h-[40%] max-h-[40%] justify-between w-full px-8 pt-18 bg-nex-green rounded-b-4xl relative z-10 wow bounceInDown animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <!-- msg 2 -->
      <div
        class="flex flex-col items-center justify-center flex-1 w-full h-auto wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <h1
          class="text-6xl font-bold text-center text-white wow fadeIn animated"
          data-wow-delay="100ms"
          data-wow-duration="1s"
        >
          {{ "RETAILER_RELEASE.TITLE" | translate }}
        </h1>
        <h2
          class="text-6xl font-medium text-center text-white wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "RETAILER_RELEASE.MESSAGE" | translate }}
        </h2>
      </div>
      <!-- end msg 2 -->
      <!-- screen sign symbol -->
      <div class="self-end w-full h-auto justify-self-end">
        <!-- sign -->
        <div
          class="flex items-center justify-center mx-auto rounded-full -mb-37 w-80 h-80 bg-white-20 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="relative flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <div
              class="flex flex-col items-center justify-center w-48 h-48 p-6 mx-auto text-white rounded-full bg-nex-green wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="182.66"
                height="182.66"
                viewBox="0 0 182.66 182.66"
                class="wow bounceIn animated"
                data-wow-delay="0"
                data-wow-duration="1s"
              >
                <path
                  id="Path_13266"
                  data-name="Path 13266"
                  d="M93.33,184.66A91.33,91.33,0,1,0,2,93.33,91.33,91.33,0,0,0,93.33,184.66ZM135.65,78.569a11.415,11.415,0,1,0-16.144-16.144L81.914,100.02,67.153,85.259A11.415,11.415,0,1,0,51.009,101.4l22.834,22.831a11.416,11.416,0,0,0,16.143,0L135.65,78.569Z"
                  transform="translate(-2 -2)"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div
            class="absolute flex items-center justify-center text-nex-green w-67 h-67"
          >
            <app-loader-mini></app-loader-mini>
          </div>
        </div>
        <!-- end sign -->
      </div>
      <!-- end screen sign symbol -->
    </div>
    <!-- end infowindow header -->

    <!-- infowindow msg part -->
    <div
      class="relative flex flex-col items-center justify-center flex-1 w-11/12 h-auto px-8 pb-24 mx-auto vert-shadow pt-28 bg-nex-gray-light-ultra -top-12 z-5 rounded-b-4xl wow bounceInDown animated"
      data-wow-delay="200ms"
      data-wow-duration=".4s"
    >
      <!-- msg -->
      <div
        *ngIf="showPrintButtons"
        class="flex flex-col items-center justify-center w-full h-auto wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <h1
          class="text-6xl font-bold text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="100ms"
          data-wow-duration="1s"
        >
          {{ "PRINT_BILL.TITLE" | translate }}
        </h1>

        <h2
          class="text-4xl font-medium text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "PRINT_BILL.MESSAGE" | translate }}
        </h2>
        <div
          class="flex flex-row items-center justify-center flex-1 h-auto pt-8 space-x-4"
        >
          <!-- BUTTON -->
          <button
            (click)="printConfirm()"
            class="flex flex-row items-center justify-start w-auto h-20 text-white border-2 border-transparent max-w-64 bg-nex-green active:bg-nex-green/70 rounded-xl active:border-nex-green active:shadow-inner-light"
          >
            <div class="flex items-start justify-start w-16 h-16 p-4">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9998 29.3337C8.63584 29.3337 2.6665 23.3643 2.6665 16.0003C2.6665 8.63633 8.63584 2.66699 15.9998 2.66699C23.3638 2.66699 29.3332 8.63633 29.3332 16.0003C29.3332 23.3643 23.3638 29.3337 15.9998 29.3337ZM14.6705 21.3337L24.0972 11.9057L22.2132 10.0203L14.6705 17.563L10.8985 13.791L9.01317 15.6763L14.6705 21.3337Z"
                  fill="currentColor"
                />
              </svg>
            </div>

            <div
              class="flex flex-row items-center justify-start flex-1 w-auto h-full pr-6 text-2xl font-medium"
            >
              {{ "PRINT_BILL.CONFIRM" | translate }}
            </div>
          </button>
          <!-- end BUTTON -->
          <!-- BUTTON -->
          <button
            (click)="printSkip()"
            class="flex flex-row items-center justify-start w-auto h-20 bg-transparent border-2 max-w-64 text-primaryx active:bg-white/70 rounded-xl border-primaryx active:border-nex-gray-dark active:shadow-inner-light"
          >
            <div class="flex items-start justify-start w-16 h-16 p-4">
              <svg width="32" height="32" fill="none" viewBox="0 0 24 24">
                <path
                  d="M17 6 17 18H15L15 6 17 6ZM13 12 7 18V6L13 12Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>

            <div
              class="flex flex-row items-center justify-start flex-1 w-auto h-full pr-6 text-2xl font-medium"
            >
              {{ "PRINT_BILL.SKIP" | translate }}
            </div>
          </button>
          <!-- end BUTTON -->
        </div>
      </div>
      <!-- end msg -->
    </div>
    <!-- end infowindow msg part -->

    <!-- infowindow empty space at the bottom -->
    <div class="block w-full h-52"></div>
    <!-- end infowindow empty space at the bottom -->
  </div>
</ng-container>
