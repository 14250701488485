<!-- logo -->
<div
  class="w-full flex flex-row justify-center text-center  {{
    rightHand ? 'pl-3' : 'pr-3'
  }}"
>
  <img
    (click)="openSetup()"
    class="h-20x max-h-20x rounded-xl"
    *ngIf="logo"
    [src]="logo"
  />
</div>
<!-- END:logo -->

<!-- menu -->
<div
  class="
    w-full
    flex flex-col

    items-start
    text-center
    self-start
    
    mt-48
    space-y-3
    animated
    {{ rightHand ? 'slideInLeftx' : 'slideInRightx' }}
  "
  [ngClass]="fullHd ? ' justify-center' : ' justify-start'"
>
  <ng-container>
    <!-- category button -->
    <ng-container *ngIf="!hideCategories">
      <button
        class="btn btn-side bg-primaryx active:bg-active {{
          rightHand ? 'btn-side-r' : 'btn-side-l'
        }}"
        (pointerup)="goToMainCategory()"
        [touchClick]
        [ngClass]="fullHdHalf && 'btn-side--middle'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-home-2"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
          <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
          <path d="M10 12h4v4h-4z" />
        </svg>
        <span class="label" [ngClass]="fullHdHalf && 'label--middle'">
          {{ "COMMON.ALL_PRODUCTS" | translate }}
        </span>
      </button>
    </ng-container>
    <ng-container *ngIf="!hideCategories && !menuPanelOpened">
      <button
        class="btn btn-side bg-primaryx active:bg-active {{
          rightHand ? 'btn-side-r' : 'btn-side-l'
        }}"
        (pointerup)="openMenuPanel()"
        [touchClick]
        [ngClass]="fullHdHalf && 'btn-side--middle'"
      >
        <span class="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="41.143"
            viewBox="0 0 48 41.143"
          >
            <path
              id="Path_13160"
              data-name="Path 13160"
              d="M3,7.429A3.429,3.429,0,0,1,6.429,4H47.571a3.429,3.429,0,1,1,0,6.857H6.429A3.429,3.429,0,0,1,3,7.429ZM3,24.571a3.429,3.429,0,0,1,3.429-3.429H27A3.429,3.429,0,1,1,27,28H6.429A3.429,3.429,0,0,1,3,24.571ZM3,41.714a3.429,3.429,0,0,1,3.429-3.429H47.571a3.429,3.429,0,1,1,0,6.857H6.429A3.429,3.429,0,0,1,3,41.714Z"
              transform="translate(-3 -4)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>
        </span>
        <span class="label" [ngClass]="fullHdHalf && 'label--middle'">
          {{ "COMMON.CATEGORIES" | translate }}
        </span>
      </button>
    </ng-container>

    <ng-container *ngIf="menuPanelOpened">
      <button
        class="btn btn-side bg-gray-dark active:bg-active shadow-md-soft {{
          rightHand ? 'btn-side-r' : 'btn-side-l'
        }}"
        (pointerup)="openMenuPanel()"
        [touchClick]
        [ngClass]="fullHdHalf && 'btn-side--middle'"
      >
        <span class="w-12 h-12 icon text-primaryx">
          <app-svg-stripe [icon]="'cancel'" class="z-0"></app-svg-stripe>
          <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="41.143"
            viewBox="0 0 48 41.143"
          >
            <path
              id="Path_13160"
              data-name="Path 13160"
              d="M3,7.429A3.429,3.429,0,0,1,6.429,4H47.571a3.429,3.429,0,1,1,0,6.857H6.429A3.429,3.429,0,0,1,3,7.429ZM3,24.571a3.429,3.429,0,0,1,3.429-3.429H27A3.429,3.429,0,1,1,27,28H6.429A3.429,3.429,0,0,1,3,24.571ZM3,41.714a3.429,3.429,0,0,1,3.429-3.429H47.571a3.429,3.429,0,1,1,0,6.857H6.429A3.429,3.429,0,0,1,3,41.714Z"
              transform="translate(-3 -4)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg> -->
        </span>
        <span
          class="label text-primaryx"
          [ngClass]="fullHdHalf && 'label--middle'"
        >
          {{ "COMMON.CLOSE_CATEGORIES" | translate }}
        </span>
      </button>
    </ng-container>
    <!-- search button -->
    <ng-container *ngIf="!hideSearchButton">
      <button
        class="btn btn-side bg-primaryx active:bg-active {{
          rightHand ? 'btn-side-r' : 'btn-side-l'
        }}"
        (pointerup)="openSearchPanel()"
        [touchClick]
        *ngIf="!hideSearch && !productPage"
      >
        <span class="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40.003"
            viewBox="0 0 40 40.003"
          >
            <path
              id="Path_13162"
              data-name="Path 13162"
              d="M17,7A10,10,0,1,0,27,17,10,10,0,0,0,17,7ZM2,17a15,15,0,1,1,27.225,8.69L41.268,37.734a2.5,2.5,0,1,1-3.535,3.535l-12.04-12.04A15,15,0,0,1,2,17Z"
              transform="translate(-2 -1.999)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>
        </span>
        <span class="label" [ngClass]="fullHdHalf && 'label--middle'">
          {{ "COMMON.SEARCH" | translate }}
        </span>
      </button>
    </ng-container>

    <!-- shopping cart button useShoppingCart -->
    <button
      class="btn btn-side  active:bg-active z-10 relative {{
        rightHand ? 'btn-side-r' : 'btn-side-l'
      }}"
      (pointerup)="cartQuantity >= 1 && openCart()"
      [touchClick]
      [ngClass]="statusCartMessage ? 'bg-green' : 'bg-primaryx'"
      *ngIf="!hideSearch && useShoppingCart"
    >
      <span
        class="flex icon w-14 h-14"
        [ngClass]="cartIsFull ? 'text-red' : 'text-white'"
      >
        <span class="wow bounceIn animated" *ngIf="statusCartMessage">
          <app-svg-stripe [icon]="'success'"></app-svg-stripe>
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45.175"
          height="48"
          viewBox="0 0 45.175 48"
          *ngIf="!statusCartMessage"
        >
          <path
            id="Path_13163"
            data-name="Path 13163"
            d="M4.824,1a2.824,2.824,0,1,0,0,5.647H8.268l.861,3.45q.013.06.028.119l3.834,15.332-2.521,2.519a5.646,5.646,0,0,0,3.992,9.64H38.706a2.824,2.824,0,0,0,0-5.647H14.463l2.824-2.824h18.6a2.824,2.824,0,0,0,2.524-1.561l8.471-16.941a2.824,2.824,0,0,0-2.524-4.086H14.085l-.875-3.51A2.824,2.824,0,0,0,10.471,1ZM41.529,44.765a4.235,4.235,0,1,1-4.235-4.235A4.235,4.235,0,0,1,41.529,44.765ZM14.706,49a4.235,4.235,0,1,0-4.235-4.235A4.235,4.235,0,0,0,14.706,49Z"
            transform="translate(-2 -1)"
            fill="currentColor"
          />
        </svg>
        <span
          *ngIf="
            !statusCartMessage && cartQuantity !== null && cartQuantity >= 1
          "
          class="cart-quantity wow bounceIn animated"
          >{{ cartQuantity }}</span
        >
      </span>
      <span class="label" [ngClass]="fullHdHalf && 'label--middle'">
        {{ "COMMON.CART" | translate }}</span
      >
    </button>

    <!-- <span
      *ngIf="useShoppingCart"
      class="under-cart-btn text-primaryx {{
        rightHand ? 'under-cart-btn-r' : 'under-cart-btn-l'
      }}"
      style="margin-top: -1rem !important"
    >
      3 Artikel
    </span> -->
    <!-- <button
      class="btn btn-side bg-primaryx active:bg-active {{
        rightHand ? 'btn-side-r' : 'btn-side-l'
      }}"
      (click)="openPickup()"
    >
      <span class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45.175"
          height="45.514"
          viewBox="0 0 45.175 45.514"
        >
          <g id="Group_7323" data-name="Group 7323" transform="translate(0 50)">
            <path
              id="Path_13561"
              data-name="Path 13561"
              d="M40.214-16.977,29.874-12.707,31.6-8.5l10.341-4.26a2.273,2.273,0,0,0,1.411-2.1,2.266,2.266,0,0,0-.173-.874,2.285,2.285,0,0,0-2.967-1.238"
              transform="translate(-2.681 -2.948)"
              fill="currentColor"
            />
            <path
              id="Path_13562"
              data-name="Path 13562"
              d="M10.3-48.57s0,0,0,0a2.353,2.353,0,0,0-.137-.26c-.027-.047-.047-.1-.076-.146a2.2,2.2,0,0,0-.2-.242c-.029-.033-.053-.072-.084-.1a2.278,2.278,0,0,0-2.467-.509L1.425-47.454a2.276,2.276,0,0,0-1.26,2.961,2.275,2.275,0,0,0,2.961,1.261l3.809-1.535,9.426,23.1a2.277,2.277,0,0,0,3.064,1.208,2.278,2.278,0,0,0,1.154-2.929Z"
              transform="translate(0 0)"
              fill="currentColor"
            />
            <path
              id="Path_13563"
              data-name="Path 13563"
              d="M41.5-28.161,31.135-23.9a.768.768,0,0,1-1-.419l-3.221-7.841a.768.768,0,0,1,.419-1L37.7-37.423a.768.768,0,0,1,1,.419l3.221,7.841a.768.768,0,0,1-.419,1m5.367-.934-4.7-11.437a3.374,3.374,0,0,0-4.4-1.84L23.8-36.634a3.374,3.374,0,0,0-1.84,4.4l4.7,11.437a3.374,3.374,0,0,0,4.4,1.84L45.029-24.69a3.375,3.375,0,0,0,1.84-4.4"
              transform="translate(-1.948 -0.662)"
              fill="currentColor"
            />
            <path
              id="Path_13564"
              data-name="Path 13564"
              d="M24.321-15a6.827,6.827,0,0,0-6.827,6.827,6.827,6.827,0,0,0,6.827,6.827,6.827,6.827,0,0,0,6.827-6.827A6.827,6.827,0,0,0,24.321-15m0,4.551A2.279,2.279,0,0,1,26.6-8.173,2.279,2.279,0,0,1,24.321-5.9a2.279,2.279,0,0,1-2.276-2.276,2.279,2.279,0,0,1,2.276-2.276"
              transform="translate(-1.57 -3.141)"
              fill="currentColor"
            />
          </g>
        </svg>
      </span>
      <span class="label">Abholung</span>
    </button> -->
  </ng-container>

  <!-- <button
    class="btn btn-side {{ rightHand ? 'btn-side-r' : 'btn-side-l' }}"
    (click)="openCart()"
  >
    <span class="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45.175"
        height="48"
        viewBox="0 0 45.175 48"
      >
        <path
          id="Path_13163"
          data-name="Path 13163"
          d="M4.824,1a2.824,2.824,0,1,0,0,5.647H8.268l.861,3.45q.013.06.028.119l3.834,15.332-2.521,2.519a5.646,5.646,0,0,0,3.992,9.64H38.706a2.824,2.824,0,0,0,0-5.647H14.463l2.824-2.824h18.6a2.824,2.824,0,0,0,2.524-1.561l8.471-16.941a2.824,2.824,0,0,0-2.524-4.086H14.085l-.875-3.51A2.824,2.824,0,0,0,10.471,1ZM41.529,44.765a4.235,4.235,0,1,1-4.235-4.235A4.235,4.235,0,0,1,41.529,44.765ZM14.706,49a4.235,4.235,0,1,0-4.235-4.235A4.235,4.235,0,0,0,14.706,49Z"
          transform="translate(-2 -1)"
          fill="currentColor"
        />
      </svg>
    </span>
    <span class="label"> {{ "COMMON.CART" | translate }}</span>
  </button> -->
</div>
<!-- END: menu -->

<ng-container *ngIf="!hideBackBtn">
  <!-- back button -->
  <div
    class="w-full flex flex-row text-center mt-16 {{
      rightHand ? 'ml-auto justify-end' : 'mr-auto justify-start '
    }}"
    [ngClass]="fullHdHalf ? 'justify-center' : ''"
  >
    <button
      class="btn btn-back bg-primaryx active:bg-active"
      (pointerup)="
        !backBtnCloseInfoPanel ? backPage(currentRoute) : backCloseAction()
      "
      [touchClick]
    >
      <span class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 48 39.838"
        >
          <g
            id="Group_7410"
            data-name="Group 7410"
            transform="translate(0 8.308)"
          >
            <path
              id="Path_13172"
              data-name="Path 13172"
              d="M12.279,9.766H33.522a7.286,7.286,0,0,1,0,14.571H30.8a3.6,3.6,0,1,0,0,7.193h2.721a14.478,14.478,0,1,0,0-28.956H12.279l4.742-4.743a3.6,3.6,0,0,0,0-5.086,3.6,3.6,0,0,0-5.086,0L1.053,3.626a3.562,3.562,0,0,0-.616.824L.411,4.5l-.078.158-.05.115-.02.046-.029.076L.2,4.98.18,5.048.162,5.1l-.013.045-.016.061-.019.065L.1,5.313.09,5.37l-.017.075-.007.037-.01.058-.015.082,0,.031L.019,5.8,0,5.985V6L0,6.17c0,.059,0,.118,0,.178l.012.167,0,.023.018.149,0,.031L.056,6.8l.017.1.017.074L.1,7.026l.01.042.019.065.016.061.013.045.018.053L.2,7.359l.017.046.016.041.029.077.07.161.078.158.026.047a3.542,3.542,0,0,0,.616.824L11.935,19.594a3.6,3.6,0,0,0,5.086-5.086Z"
              transform="translate(0 0)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </g>
        </svg>
      </span>
      <span class="label">
        {{ "COMMON.BTN_BACK" | translate }}
      </span>
    </button>
  </div>
  <!-- END: back button -->
</ng-container>
<ng-container *ngIf="showHomeBtn">
  <!-- back button -->
  <div
    class="w-full flex flex-row text-center mt-16 {{
      rightHand ? 'ml-auto justify-end' : 'mr-auto justify-start '
    }}"
    [ngClass]="fullHdHalf ? 'justify-center' : ''"
  >
    <button
      class="btn btn-back bg-primaryx active:bg-active"
      (pointerup)="goToHome()"
      [touchClick]
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-logout"
        width="60"
        height="60"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
        />
        <path d="M9 12h12l-3 -3" />
        <path d="M18 15l3 -3" />
      </svg>
      <span class="label">
        {{ "COMMON.EXIT" | translate }}
      </span>
    </button>
  </div>
  <!-- END: back button -->
</ng-container>

<!-- switchers -->
<div
  class="w-full flex flex-col text-center self-end  {{
    rightHand ? 'justify-end items-end ' : 'justify-start items-start '
  }}"
>
  <!-- toggle toggle hand switcher -->
  <div
    class="hand_switcher btn mb-3x group {{ rightHand ? '' : 'checked' }}"
    (pointerup)="changeHand()"
    [touchClick]
  >
    <div class="flex items-center cursor-pointer">
      <!-- toggle -->

      <!-- input -->
      <!-- <input type="checkbox" id="toggleHandSwitcher" class="sr-only" /> -->
      <!-- line -->
      <div
        class="flex items-center w-27 rounded-full relative
        {{ rightHand ? 'justify-start' : 'justify-end' }}"
        [ngClass]="fullHdHalf ? 'h-27' : 'bg-primaryx-30 h-18'"
      >
        <!-- dot -->
        <div
          class="dot absolute border-4 border-primaryx group-active:border-active p-1.5 bg-white rounded-full transition-all duration-100 ease-in-out flex flex-col items-center justify-center"
          [ngClass]="
            fullHdHalf ? 'w-27 h-27' : 'left-0 top-0 dot--normal w-18 h-18'
          "
        >
          <div
            class="option option-A bg-primaryx group-active:bg-active"
            *ngIf="rightHand"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 25.699 29.37"
            >
              <path
                id="Path_13345"
                data-name="Path 13345"
                d="M17.685,3.836a1.836,1.836,0,1,0-3.671,0v10.1a.918.918,0,1,1-1.836,0V5.671a1.836,1.836,0,1,0-3.671,0v8.26a.918.918,0,1,1-1.836,0V9.343A1.836,1.836,0,1,0,3,9.343v9.178a12.849,12.849,0,1,0,25.7,0V14.849a1.836,1.836,0,1,0-3.671,0v4.589a.918.918,0,1,1-1.836,0V5.671a1.836,1.836,0,1,0-3.671,0v8.26a.918.918,0,1,1-1.836,0Z"
                transform="translate(-3 -2)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <div
            class="option option-B bg-primaryx group-active:bg-active"
            *ngIf="!rightHand"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 25.699 29.37"
            >
              <path
                id="Path_13345"
                data-name="Path 13345"
                d="M14.014,3.836a1.836,1.836,0,0,1,3.671,0v10.1a.918.918,0,1,0,1.836,0V5.671a1.836,1.836,0,1,1,3.671,0v8.26a.918.918,0,0,0,1.836,0V9.343a1.836,1.836,0,1,1,3.671,0v9.178a12.849,12.849,0,1,1-25.7,0V14.849a1.836,1.836,0,1,1,3.671,0v4.589a.918.918,0,1,0,1.836,0V5.671a1.836,1.836,0,1,1,3.671,0v8.26a.918.918,0,1,0,1.836,0Z"
                transform="translate(-3 -2)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: toggle hand switcher -->

  <!-- toggle LANG 2 switcher -->
  <ng-container *ngIf="multiLanguage">
    <div
      class="lang_switcher btn mt-4 group {{
        currentLang === 'de' ? '' : 'checked'
      }}"
      (pointerup)="setPublicLanguage()"
      [touchClick]
    >
      <div class="flex items-center cursor-pointer">
        <!-- toggle -->

        <!-- line -->
        <div
          class="flex items-center w-27 rounded-full relative
        {{ currentLang !== 'de' ? 'justify-start' : 'justify-end' }}"
          [ngClass]="fullHdHalf ? 'h-27' : 'bg-primaryx-30 h-18'"
        >
          <!-- dot -->
          <div
            class="dot w-full h-18 absolute border-4 border-primaryx group-active:border-active p-1.5 bg-white rounded-full transition-all duration-100 ease-in-out flex flex-col items-center justify-center"
          >
            <!-- [ngClass]="
              fullHdHalf ? 'w-27 h-27' : 'left-0 top-0 dot--normal w-18 h-18'
            " -->
            <div
              class="overflow-hidden rounded-full option option-A bg-primaryx group-active:bg-active"
              *ngIf="currentLang === 'de'"
            >
              <img src="/assets/lang/gb_2.png" />
            </div>
            <div
              class="overflow-hidden rounded-full option option-B bg-primaryx group-active:bg-active"
              *ngIf="currentLang !== 'de'"
            >
              <img src="/assets/lang/de_2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- END: toggle LANG 2 switcher -->
</div>
<!-- END: switchers -->
