<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="w-full h-screen flex flex-col items-start justify-start bg-primaryx text-white box-main"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full wow fadeInDown"
    ></app-header>
    <div
      class="w-11/12 mx-auto flex flex-row items-center pt-16 justify-between mb-14"
    >
      <div class="w-13 h-13 block mr-9">
        <app-svg-stripe [icon]="'box'" [white]="true"></app-svg-stripe>
      </div>
      <div class="flex flex-1 text-4xl font-medium text-white-80 text-left">
        {{ title }}
      </div>
      <!-- <div class="flex text-2.5xl text-white-80 items-center flex-row space-x-4">
          <span class="text-active w-6 h-6">
            <app-svg-stripe [icon]="'folder'"></app-svg-stripe>
          </span>
          <span>{{ deliveries.length }} deliveries</span>
        </div> -->
    </div>
    <div
      class="w-11/12 mx-auto flex grow flex-col items-center justify-start text-center overflow-y-scroll"
    >
      <div class="w-11/12 mx-auto h-3/6 flex flex-col space-y-6">
        <app-pickup-list-item
          (btnClick)="selectDelivery(item)"
          [iconname]="'box'"
          [label]="item.description"
          [lockernumber]="item.positions.length"
          *ngFor="let item of deliveries"
        ></app-pickup-list-item>
      </div>
    </div>

    <div
      class="w-10/12 mx-auto mt-5 h-auto flex flex-row space-x-16 items-center justify-between pt-4 pb-14 self-end"
    >
      <app-btn-sanusx
        (btnClick)="cancel()"
        [label]="'COMMON.BTN_CANCEL' | translate"
        buttonType="neutral"
        class="wow slideInLeft"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
    </div>

    <!-- <div class="w-full h-6 block bg-white self-end rounded-t-xl wow fadeInUp"></div> -->
  </div>
</ng-container>
