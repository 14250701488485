export namespace LockerSki {
  export class RentProcess {
    static readonly type = '[LockerSki] RentProcess';
    constructor(public actionName: string) {}
  }

  export class UnlockSlots {
    static readonly type = '[LockerSki] UnlockSlots';
    constructor() {}
  }

  export class StartAuthorizationProcess {
    static readonly type = '[LockerSki] StartAuthorizationProcess';
    constructor(
      public numberOfLockers: number,
      public lockerPricePerHour: number,
      public reservationAmount: number
    ) {}
  }

  export class ResetRentProcess {
    static readonly type = '[LockerSki] ResetRentProcess';
    constructor() {}
  }

  export class RevertTransaction {
    static readonly type = '[LockerSki] RevertTransaction';
    constructor() {}
  }

  export class FinishRentProcess {
    static readonly type = '[LockerSki] FinishRentProcess';
    constructor(public slots: any[]) {}
  }

  export class FinishReclaimProcess {
    static readonly type = '[LockerSki] FinishReclaimProcess';
    constructor(public slots: any[]) {}
  }

  export class ManualReclaim {
    static readonly type = '[LockerSki] ManualReclaim';
    constructor(public selectedRent: any) {}
  }
}
